import { Flex } from '@chakra-ui/layout';
import { Text, Image, Link } from '@chakra-ui/react';
import chakraStyled from 'utils/chakra.styled';
import logo from 'assets/logo_white.png';

export const Section = chakraStyled(Flex, {
  w: '100%',
  backgroundColor: 'primary.500',
  alignItems: 'center',
  justifyContent: 'center',
  pl: {
    base: '14%',
    sm: '8%',
    md: '14%',
  },
  pr: {
    base: '12%',
    sm: '6%',
    md: '12%',
  },
  py: {
    base: '4%',
    sm: '2%',
    md: '4%',
  },
});

export const Title = chakraStyled(Text, {
  fontSize: '26px',
  fontWeight: 'bold',
  color: '#fff',
  cursor: 'pointer',
});

export const Logo = chakraStyled(Image, {
  maxW: '60px',
  cursor: 'pointer',
  src: logo,
});

export const SubTitle = chakraStyled(Text, {
  fontSize: { base: '14px', md: '16px' },
  fontWeight: '400',
  color: 'white',
  textAlign: 'center',
});

export const LinkItem = chakraStyled(Link, {
  fontSize: { base: '14px', md: '16px' },
  fontWeight: '400',
  color: 'white',
});
