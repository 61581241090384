import { IconProps } from './type';

const TwinBed = (props: IconProps) => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.24987 6.5C8.51212 6.5 7.91406 7.09806 7.91406 7.83581V12.3506H7.83581C7.09806 12.3506 6.5 12.9486 6.5 13.6864V17.3652V18.3654V21.2906H8.38477V22.4621C8.38477 22.9691 8.79573 23.38 9.30267 23.38H10.8218C11.3287 23.38 11.7397 22.9691 11.7397 22.4621V21.2906H19.2168V22.4621C19.2168 22.9691 19.6278 23.38 20.1347 23.38H21.6538C22.1608 23.38 22.5717 22.9691 22.5717 22.4621V21.2906H24.4554V18.3654V17.3652V13.6864C24.4554 12.9486 23.8574 12.3506 23.1196 12.3506H23.0436V7.83581C23.0436 7.09806 22.4455 6.5 21.7078 6.5H9.24987ZM20.6873 12.3506H22.0436V7.83581C22.0436 7.65035 21.8933 7.5 21.7078 7.5H9.24987C9.06441 7.5 8.91406 7.65035 8.91406 7.83581V12.3506H10.2676V9.92523C10.2676 9.41828 10.6785 9.00732 11.1855 9.00732H19.7694C20.2763 9.00732 20.6873 9.41828 20.6873 9.92523V12.3506ZM7.83581 13.3506H7.91406V13.3507H23.0436V13.3506H23.1196C23.3051 13.3506 23.4554 13.5009 23.4554 13.6864V17.3652H7.5V13.6864C7.5 13.5009 7.65035 13.3506 7.83581 13.3506ZM7.5 20.2906V18.3654H23.4554V20.2906H22.5717V20.2905H19.2168V20.2906H11.7397V20.2905H8.38477V20.2906H7.5ZM20.2168 22.38V21.2906H21.5717V22.38H20.2168ZM9.38477 21.2906H10.7397V22.38H9.38477V21.2906ZM11.2676 12.3506V10.0073H19.6873V12.3506H11.2676Z"
        fill="#353535"
      />
    </svg>
  );
};

export default TwinBed;
