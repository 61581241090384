import React from 'react';

import { $user } from 'stores';

import { AccountProps } from './Account.props';

import AccountView from './Account.view';

const AccountContainer = (props: AccountProps) => {
  const user = $user.selectors.useUser();

  return <AccountView userData={user} />;
};

export default AccountContainer;
