import React, { FC, useRef, useCallback, memo } from 'react';

import {
  Box,
  Flex,
  IconButton,
  Text,
  useTab,
  useToast,
} from '@chakra-ui/react';

import { MdDelete, MdUpload } from 'react-icons/md';

import { useField } from 'formik';

import { AssetsPickerProps } from './AssetsPicker.props';
import { Asset } from './AssetsPicker.style';
import { getPhotoUrl } from 'utils';
import { useInitializer } from 'hooks';

const props: any = {
  h: '200px',
  w: '240px',
  borderRadius: '10px',
  border: '2px solid',
  bg: 'secondary.100',
  borderColor: 'secondary.500',
  cursor: 'pointer',
  direction: 'column',
  align: 'center',
  justify: 'center',
  color: 'secondary.500',
  fontSize: '20px',
};

export type AssetItemFileProps = {
  file: File;
  onDelete: (name: string) => void;
  disableDelete?: boolean;
};

export type AssetItemUrlProps = {
  url: string;
  onDelete: (name: string) => void;
  disableDelete?: boolean;
};

const AssetItemFile = React.memo<AssetItemFileProps>(
  ({ file, onDelete, disableDelete = false }) => {
    const url = URL.createObjectURL(file);
    return (
      <Box
        position="relative"
        overflow="hidden"
        borderRadius="md"
        boxShadow="md">
        <Asset src={url} />
        {!disableDelete && onDelete && (
          <IconButton
            isRound={true}
            position="absolute"
            w={30}
            top={2}
            right={2}
            icon={<MdDelete />}
            colorScheme="red"
            aria-label="Delete"
            size="sm"
            onClick={() => onDelete(file.name)}
          />
        )}
      </Box>
    );
  },
);

const AssetItemURL = React.memo<AssetItemUrlProps>(
  ({ url, onDelete, disableDelete = false }) => {
    return (
      <Box
        position="relative"
        overflow="hidden"
        borderRadius="md"
        boxShadow="md">
        <Asset src={getPhotoUrl(url)} />
        {!disableDelete && onDelete && (
          <IconButton
            isRound={true}
            position="absolute"
            w={30}
            top={2}
            right={2}
            icon={<MdDelete />}
            colorScheme="red"
            aria-label="Delete"
            size="sm"
            onClick={() => onDelete(url)}
          />
        )}
      </Box>
    );
  },
);

const AssetPicker: FC<AssetsPickerProps> & {
  NetworkImage: typeof AssetItemURL;
  AssetImage: typeof AssetItemFile;
} = ({ name }) => {
  const [field, _, helpers] = useField(name);

  const inputRef = useRef<HTMLInputElement>(null);

  const toast = useToast();

  const handleFileChange = useCallback(
    (_prevFiles: File[]) => (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.files && e.target.files.length > 0) {
        const newFiles = Array.from(e.target.files);
        const uniqueFiles = newFiles.filter(
          (newFile) =>
            !_prevFiles.some((prevFile) => prevFile.name === newFile.name),
        );

        if (uniqueFiles.length < newFiles.length) {
          //  // Show toast indicating duplicate files
          toast({
            status: 'warning',
            title: 'Duplicate Files',
            description: 'Some files are already added.',
            duration: 3000,
            isClosable: true,
          });
        }

        helpers.setValue([..._prevFiles, ...uniqueFiles]);
      }
    },
    [helpers],
  );

  const onInputClick = useCallback(() => {
    inputRef?.current?.click();
  }, []);

  return (
    <>
      <Flex onClick={onInputClick} {...props}>
        <MdUpload size="30px" />
        Upload photos
        <Text textAlign="center" fontSize={12} mx={4} mt={2}>
          Maximum upload limit is 10. <br /> Supported Formats (JPG, JPEG, PNG).
        </Text>
      </Flex>

      <input
        id={'file-input'}
        type="file"
        ref={inputRef}
        multiple
        accept="image/jpg, image/jpeg, image/png"
        style={{ display: 'none' }}
        onChange={handleFileChange(field.value)}
      />
    </>
  );
};

AssetPicker.AssetImage = AssetItemFile;
AssetPicker.NetworkImage = AssetItemURL;

export default AssetPicker;
