import {
  ReactNode,
  forwardRef,
  ComponentRef,
  ComponentProps,
  ComponentPropsWithoutRef,
} from 'react';

import { ChakraComponent } from '@chakra-ui/react';

import { motion, MotionProps } from 'framer-motion';

type ChakraMotionProps<
  T extends ChakraComponent<keyof JSX.IntrinsicElements, any>,
> = ComponentProps<T> & MotionProps;

const chakraMotionStyled = <
  T extends ChakraComponent<keyof JSX.IntrinsicElements, any>,
>(
  Component: T,
  chakraProps: ComponentPropsWithoutRef<T>,
  motionProps?: MotionProps,
) => {
  const MotionComponent = motion(Component);

  const ChakraMotionComponent = forwardRef<
    ComponentRef<T>,
    ChakraMotionProps<T>
  >(({ children, ...props }, ref) => {
    const combinedProps = {
      ...(chakraProps as ComponentProps<T>),
      ...motionProps,
      ...props,
    };

    return (
      <MotionComponent {...combinedProps} ref={ref}>
        {children as ReactNode}
      </MotionComponent>
    );
  });

  // set display name for debug
  ChakraMotionComponent.displayName = `Motion(${
    Component.displayName || Component.name
  })`;

  return ChakraMotionComponent;
};

export default chakraMotionStyled;
