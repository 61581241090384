import React, { FC, useState } from 'react';

import {
  Box,
  Tag,
  Text,
  Flex,
  Input,
  Stack,
  HStack,
  Button,
  TagLabel,
  TagCloseButton,
} from '@chakra-ui/react';

import { Form, Formik, useField } from 'formik';

import { CommonModal } from '../CommonModal';

import FormikInput from 'components/primitives/FormikInput';

import { CreateAttributeModalViewProps } from './CreateAttributeModal.props';

import { initialValues, validationSchema } from './CreateAttributeModal.utils';

const VariantsInput: FC = () => {
  const [inputValue, setInputValue] = useState('');

  const [field, meta, helpers] = useField<any[]>('variants');

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && inputValue.trim() !== '') {
      helpers.setValue([inputValue, ...field.value]);
      setInputValue('');
    }
  };

  const removeVariant = (index: number) => {
    helpers.setValue(field.value.filter((_, i) => i !== index));
  };

  return (
    <Box w="100%">
      <Text>Property values</Text>
      <Input
        size="md"
        value={inputValue}
        onKeyDown={handleKeyDown}
        isInvalid={meta.touched && !!meta.error}
        onBlur={() => helpers.setTouched(true)}
        onChange={(e) => setInputValue(e.target.value)}
        _placeholder={{
          color: '#ccc',
          fontSize: '14px',
        }}
        placeholder="Type and press Enter to add a variant"
      />
      {meta.touched && meta.error && (
        <Text color="red.500" fontSize="sm" mt={1}>
          {meta.error}
        </Text>
      )}
      <HStack spacing={2} mt={2}>
        {field.value.map((variant, index) => (
          <Tag key={index} size="md" variant="solid" colorScheme="teal">
            <TagLabel>{variant}</TagLabel>
            <TagCloseButton onClick={() => removeVariant(index)} />
          </Tag>
        ))}
      </HStack>
    </Box>
  );
};

const CreateAttributeModalView: FC<CreateAttributeModalViewProps> = ({
  isOpen,
  onClose,
  onSubmit,
  isLoading,
}) => {
  return (
    <CommonModal isOpen={isOpen} onClose={onClose}>
      <Flex p="50px" direction="column" justify="center">
        <Text fontSize="20px" fontWeight="600" textAlign="center">
          Create Attribute
        </Text>
        <Formik
          onSubmit={onSubmit}
          initialValues={initialValues}
          validationSchema={validationSchema}>
          {({ handleSubmit }) => (
            <Form>
              <Stack mt="30px" gap="20px" align="center">
                <FormikInput name="name" label="Property Name" />
                <VariantsInput />
                <Stack>
                  <Button onClick={() => handleSubmit()} isLoading={isLoading}>
                    Save
                  </Button>
                  <Button variant="outline" onClick={onClose}>
                    Cancel
                  </Button>
                </Stack>
              </Stack>
            </Form>
          )}
        </Formik>
      </Flex>
    </CommonModal>
  );
};

export default CreateAttributeModalView;
