import { routesSettings } from 'constants/routes';
import React, { FC } from 'react';

import { useHistory } from 'react-router-dom';

import { ProductsCarouselProps } from './ProductsCarousel.props';

import ProductsCarouselView from './ProductsCarousel.view';

const ProductsCarouselContainer: FC<ProductsCarouselProps> = (props) => {
  const history = useHistory();

  const onCreateClick = () => {
    history.push(routesSettings.MAIN_CREATE_EXPERIENCE.path);
  };

  return <ProductsCarouselView onCreateClick={onCreateClick} />;
};

export default ProductsCarouselContainer;
