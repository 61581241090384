import { Flex, Image } from '@chakra-ui/react';
import { chakraStyled } from 'utils';

export const AssetsContainer = chakraStyled(Flex, {
  w: '100%',
  direction: 'row',
  justify: 'flex-start',
  align: 'center',
  gap: '15px',
  flexWrap: 'wrap',
  p: '10px',
  borderRadius: '5px',
  boxShadow: '0px 0px 5px rgba(0,0,0,0.1)',
});
