import React from 'react';

import { PrivacyPolicyProps } from './PrivacyPolicy.props';

import PrivacyPolicyView from './PrivacyPolicy.view';

const PrivacyPolicyContainer = (props: PrivacyPolicyProps) => {
  return <PrivacyPolicyView />;
};

export default PrivacyPolicyContainer;
