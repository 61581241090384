import React, { FC } from 'react';

import { useToast } from '@chakra-ui/react';

import { useHistory } from 'react-router-dom';

import { deleteProduct } from 'api/products';
import { useMutation, useQueryClient } from 'react-query';

import { ProductOptionsProps } from './ProductOptions.props';

import ProductOptionsView from './ProductOptions.view';

const ProductOptionsContainer: FC<ProductOptionsProps> = ({ product }) => {
  const toast = useToast();
  const history = useHistory();
  const queryClient = useQueryClient();

  const experienceId = '';

  const { mutate, isLoading } = useMutation(
    () => deleteProduct(product?._id || ''),
    {
      onSuccess: () => {
        toast({
          status: 'success',
          title: 'Product Deleted',
          isClosable: true,
          duration: 3000,
        });

        queryClient.invalidateQueries({
          queryKey: ['fetch-experience', experienceId],
        });

        queryClient.invalidateQueries({
          queryKey: ['fetch-rooms'],
        });

        queryClient.invalidateQueries({
          queryKey: ['fetch-products'],
        });
      },
    },
  );

  const onEdit = () => {
    history.push(`/product/edit/${product?._id}`, product);
  };

  return (
    <ProductOptionsView
      onEdit={onEdit}
      onDelete={mutate}
      isDeleting={isLoading}
    />
  );
};

export default ProductOptionsContainer;
