import React, { FC, useEffect } from 'react';

import { Flex } from '@chakra-ui/react';

import { PrivacyPolicyViewProps } from './PrivacyPolicy.props';
import privacyPolicyHtmlText from './privacy-policy-data';
import { useNavbar } from 'contexts/navbarContext';
import { useFooter } from 'contexts/footerContext';

const PrivacyPolicyView: FC<PrivacyPolicyViewProps> = (props) => {
  const { hide: hideNavbar } = useNavbar();
  const { hide: hideFooter } = useFooter();

  useEffect(() => {
    hideNavbar();
    hideFooter();
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  return (
    <Flex
      paddingY={8}
      paddingX={12}
      dangerouslySetInnerHTML={{ __html: privacyPolicyHtmlText }}></Flex>
  );
};

export default PrivacyPolicyView;
