import React from 'react';

import { Box, useTheme } from '@chakra-ui/react';

import { SimpleProgressProps } from './SimpleProgress.props';

const SimpleProgress: React.FC<SimpleProgressProps> = ({
  value,
  max = 100,
  min = 0,
  colorScheme = 'blue',
  borderRadius = 'md',
  width = '100%',
  height = '1rem',
}) => {
  const theme = useTheme();
  const color = theme.colors[colorScheme]?.[500] || colorScheme;
  const percentage = ((value - min) / (max - min)) * 100;

  return (
    <Box
      className="simple-progress-container"
      bg="gray.200"
      borderRadius={borderRadius}
      width={'100%'}
      maxW={width}
      minW="200px"
      height={height}
      position="relative">
      <Box
        className="simple-progress-bar"
        alignSelf="flex-start"
        bg={color}
        borderRadius={borderRadius}
        width={`${percentage}%`}
        height="100%"
        transition="width 0.2s"
        position="absolute"
        left="0"
      />
    </Box>
  );
};

export default SimpleProgress;
