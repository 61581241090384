import React, { FC } from 'react';

import {
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  ModalCloseButton,
} from '@chakra-ui/react';

import { CommonModalProps } from './CommonModal.props';

const CommonModalView: FC<CommonModalProps> = ({
  children,
  isClosable = true,
  ...props
}) => {
  return (
    <Modal
      isCentered
      size="xl"
      scrollBehavior="inside"
      motionPreset="scale"
      {...props}>
      <ModalOverlay />
      <ModalContent borderRadius="30px" overflow="hidden">
        {isClosable && <ModalCloseButton />}
        <ModalBody p="0">{children}</ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default CommonModalView;
