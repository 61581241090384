import React, { FC, useMemo, useState } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Field, FieldProps, useFormik } from 'formik';

import {
  Input,
  Button,
  Center,
  Text,
  Spinner,
  Checkbox,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';

import { useAttributes } from 'queries';

import { generateCombinations } from 'utils';

import { useMutation } from 'react-query';
import { getDefaultAttribute } from 'api/attributes';

import { Form, Formik, useField } from 'formik';

import { Header } from 'components/layouts/Header';

import FormikInput from 'components/primitives/FormikInput';

import { Product } from 'types/product';
import { Attribute } from 'types/attributes';
import { CreateProductViewProps } from './CreateProduct.props';

import { initialValues, validationSchema } from './CreateProduct.utils';

import { Row, Label, Column, Container } from './CreateProduct.style';
import FormikRichText from 'components/primitives/FormikRichText';
import MultiImagePicker from 'components/primitives/MultiImagePicker';
import ProductVariantInput from 'components/forms/ProductVariantInput';

const CreateProductView: FC<CreateProductViewProps> = ({
  onSubmit,
  onBackClick,
  isLoading,
}) => {
  const toast = useToast();
  return (
    <Container>
      <Header isCentered isBackable onBackClick={onBackClick}>
        Create standard product
      </Header>

      <Formik<Partial<Product<File>>>
        onSubmit={(value, { setFieldError }) => {
          console.log(value);

          if (!value.featuredImage) {
            setFieldError('featuredImage', 'Please select featured image.');
            toast({
              description: 'Please select featured image.',
              status: 'error',
              duration: 5000,
              isClosable: true,
            });
            return;
          }
          console.log('Pass');
          onSubmit(value);
        }}
        initialValues={initialValues}
        validationSchema={validationSchema}>
        <Form>
          <Container p="40px">
            <Row gap="25px" align="flex-start">
              <Column flex={2}>
                <Row gap="20px" flexWrap="wrap" align="flex-start">
                  <FormikInput
                    name="name"
                    label="Name"
                    containerProps={{ flex: 2, minW: '250px' }}
                  />
                  <FormikInput
                    type="number"
                    name="price"
                    label="Price"
                    containerProps={{ flex: 1, minW: '250px' }}
                  />
                </Row>
                <FormikRichText name="description" label="Description" />
              </Column>
              <Column>
                <MultiImagePicker />
              </Column>
            </Row>
            <Label mt="20px">Variations</Label>
            <ProductVariantInput />
          </Container>

          <Container align="center" justify="center" pt="20px" pb="150px">
            <Button type="submit" w="250px" isLoading={isLoading}>
              Confirm
            </Button>
          </Container>
        </Form>
      </Formik>
    </Container>
  );
};

export default CreateProductView;
