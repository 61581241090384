import { chakra, Flex, Image, Tab, Text } from '@chakra-ui/react';

import { chakraStyled } from 'utils';

export const StyledTab = chakraStyled(Tab, {
  w: '200px',
  bg: '#EDEDED',
  color: '#686868',
  borderTopRadius: '10px',
  borderColor: '#EDEDED',
  _selected: {
    bg: '#fff',
    color: 'primary.500',
    border: '2px solid',
  },
});

export const Label = chakraStyled(Text, {
  mb: '20px',
  fontSize: '16px',
  textAlign: 'left',
});

export const Container = chakraStyled(Flex, {
  p: '10px 20px',
  flex: 1,
  direction: 'column',
  maxH: '60vh',
  overflowY: 'auto',
});

export const Row = chakraStyled(Flex, {
  flex: 1,
  direction: 'row',
  gap: '20px',
  flexWrap: 'wrap',
  pb: '100px',
});

export const ProductCard = chakraStyled(Flex, {
  p: '15px',
  gap: '15px',
  flex: 1,
  minW: '300px',
  maxW: '300px',
  direction: 'column',
  borderRadius: '10px',
  boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.1)',
  position: 'relative',
});

export const ProductImage = chakraStyled(Image, {
  h: '150px',
  w: '100%',
  objectFit: 'cover',
  borderRadius: '10px',
  overflow: 'hidden',
  bg: '#f1f1f1',
});

export const Column = chakraStyled(Flex, {
  flex: 1,
  gap: '10px',
  direction: 'column',
});

export const Title = chakraStyled(Text, {
  fontSize: '14px',
  textAlign: 'left',
});

export const AddProductBg = chakraStyled(Flex, {
  h: '150px',
  w: '100%',
  justify: 'center',
  align: 'center',
  borderRadius: '10px',
  overflow: 'hidden',
  color: 'gray.500',
  bg: '#f1f1f1',
});

export const SubTitle = chakraStyled(Text, {
  fontSize: '12px',
  textAlign: 'left',
  opacity: 0.8,
});

export const Price = chakraStyled(chakra.span, {
  fontSize: '18px',
  textAlign: 'left',
  opacity: 0.8,
  fontWeight: '600',
});
