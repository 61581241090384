import api from './instance';

import { Amenity } from 'types/amenities';

const URL = `${process.env.REACT_APP_API_URL}/amenities`;

export const fetchAmenities = async () => {
  const { data } = await api.get(`${URL}/list`, {
    params: {
      limit: 100,
    },
  });

  return data?.items as Amenity[];
};
