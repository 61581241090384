import React, { FC, useState } from 'react';

import {
  Box,
  Button,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spinner,
} from '@chakra-ui/react';

import { useHistory } from 'react-router-dom';
import { Header } from 'components/layouts/Header';

import { ProductsViewProps } from './Products.props';

import { Row, Label, Container } from './Products.style';
import { CustomTable } from 'components/primitives/CustomTable';
import { SlOptions } from 'react-icons/sl';
import useMarkFeaturedExperience from 'mutations/useMarkFeaturedExperience';
import { queryClient } from 'utils';
import { FaStar } from 'react-icons/fa';
import { BiSolidHide } from 'react-icons/bi';
import useSetExperienceVisibility from 'mutations/useSetExperienceVisibility';
import { ExperienceVisibilityPayload } from 'types/experience';

const ProductsView: FC<ProductsViewProps> = ({
  isLoading,
  pagination,
  experiences,
  featuredExperience,
  onCreateClick,
}) => {
  const history = useHistory();

  const [updateExperienceId, setUpdateExperienceId] = useState<string | null>(
    null,
  );

  const { mutate, isLoading: MarkFeaturedLoading } = useMarkFeaturedExperience(
    () => {
      queryClient.invalidateQueries({
        queryKey: ['fetch-featured-experience'],
      });
      queryClient.refetchQueries({
        queryKey: ['fetch-featured-experience'],
      });
    },
  );

  const hideExperience = (id: string) => {
    const payload: ExperienceVisibilityPayload = {
      id,
      visibility: false,
    };
    setExperienceVisibility(payload);
  };

  const unHideExperience = (id: string) => {
    const payload: ExperienceVisibilityPayload = {
      id,
      visibility: true,
    };
    setExperienceVisibility(payload);
  };

  const {
    mutate: setExperienceVisibility,
    isLoading: isSettingVisibilityLoading,
  } = useSetExperienceVisibility(() => {
    queryClient.invalidateQueries({
      queryKey: ['fetch-experiences'],
    });
    queryClient.refetchQueries({
      queryKey: ['fetch-experiences'],
    });
  });

  const Loader = () => {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        w={30}
        h={30}>
        <Spinner />
      </Box>
    );
  };

  const isMarkFeaturedLoading = (experienceId: string) =>
    MarkFeaturedLoading && updateExperienceId === experienceId;

  const isHideUnhideLoading = (experienceId: string) =>
    isSettingVisibilityLoading && updateExperienceId === experienceId;

  return (
    <Container>
      <Header>
        <Row>
          Experiences
          <Button w="auto" mr="40px" onClick={onCreateClick}>
            Create new experience
          </Button>
        </Row>
      </Header>
      <Container p="20px">
        <CustomTable
          minW="1000px"
          data={experiences}
          isLoading={isLoading}
          isSelectable={false}
          pagination={pagination}>
          {{
            headerRow: (
              <>
                <Label flex={1.5}>Experience</Label>
                <Label flex={1.5}>Date created</Label>
                <Label flex={2}>Location</Label>
                <Label flex={1}></Label>
                {/* <Label flex={1}>Availability</Label> */}
              </>
            ),
            renderRow: (experience) => (
              <Row
                onClick={() => {
                  history.push(`/experience/details/${experience._id}`);
                }}>
                <Label flex={1.5} noOfLines={1}>
                  {experience?.title}{' '}
                </Label>
                <Label flex={1.5}>
                  {new Date(experience?.createdAt).toDateString()}
                </Label>
                <Label flex={2}>{experience?.address || '-'}</Label>
                <Label flex={1}>
                  <Box
                    display="flex"
                    justifyContent="end"
                    alignItems="center"
                    gap="20px">
                    {featuredExperience?._id == experience._id ? (
                      <Button
                        size="sm"
                        w="80px"
                        colorScheme="whatsapp"
                        onClick={(e) => {
                          e.stopPropagation();
                        }}>
                        Featured
                      </Button>
                    ) : (
                      ''
                    )}

                    {experience.visibility == false && (
                      <Button
                        size="sm"
                        w="80px"
                        colorScheme="orange"
                        onClick={(e) => {
                          e.stopPropagation();
                        }}>
                        Hidden
                      </Button>
                    )}

                    {isMarkFeaturedLoading(experience._id) ||
                    isHideUnhideLoading(experience._id) ? (
                      <Loader />
                    ) : (
                      <Menu>
                        <MenuButton
                          onClick={(e) => {
                            e.stopPropagation(); // Stop the event from propagating to the row
                          }}
                          as={IconButton}
                          aria-label="Options"
                          icon={<SlOptions />}
                          bg="transparent"
                          color="black"
                          width={30}
                          _hover={{ bg: 'primary.500', color: 'white' }}
                        />
                        <MenuList>
                          <MenuItem
                            style={{
                              display: 'flex',
                              justifyItems: 'start',
                              gap: '8px',
                              alignItems: 'center',
                            }}
                            onClick={(e) => {
                              e.stopPropagation();
                              setUpdateExperienceId(experience._id);
                              mutate(experience._id);
                            }}>
                            <span>
                              <FaStar />
                            </span>
                            <span> Make Featured</span>
                          </MenuItem>
                          {experience.visibility == false ? (
                            <MenuItem
                              style={{
                                display: 'flex',
                                justifyItems: 'start',
                                gap: '8px',
                                alignItems: 'center',
                              }}
                              onClick={(e) => {
                                e.stopPropagation();
                                setUpdateExperienceId(experience._id);
                                unHideExperience(experience._id);
                              }}>
                              <span>
                                <BiSolidHide />
                              </span>
                              <span> Unhide Experience</span>
                            </MenuItem>
                          ) : (
                            <MenuItem
                              style={{
                                display: 'flex',
                                justifyItems: 'start',
                                gap: '8px',
                                alignItems: 'center',
                              }}
                              onClick={(e) => {
                                e.stopPropagation();
                                setUpdateExperienceId(experience._id);
                                hideExperience(experience._id);
                              }}>
                              <span>
                                <BiSolidHide />
                              </span>
                              <span> Hide Experience</span>
                            </MenuItem>
                          )}
                        </MenuList>
                      </Menu>
                    )}
                  </Box>
                </Label>
              </Row>
            ),
          }}
        </CustomTable>
      </Container>
    </Container>
  );
};

export default ProductsView;
