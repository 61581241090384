import React, { FC } from 'react';

import { Form, Formik } from 'formik';

import FormikInput from 'components/primitives/FormikInput';

import { LoginViewProps } from './Login.props';

import { initialValues, validationSchema } from './Login.utils';

import { Text } from '@chakra-ui/react';

import {
  Row,
  Card,
  Title,
  Store,
  Safe,
  Cards,
  Gift,
  Span,
  Column,
  Spacer,
  CardTitle,
  Container,
  FooterText,
  SubmitButton,
  FormContainer,
  Logo,
  CoverTitle,
} from './Login.style';
import { $session } from 'stores';

const containerProps = {
  minW: { base: '300px', md: '400px' },
};

const LoginView: FC<LoginViewProps> = ({ onSubmit, goToSignUp, isLoading }) => {
  const globalError = $session.selectors.useGlobalError() ?? '';

  return (
    <Container>
      <Column align="center">
        <Column maxW="500px" w="100%">
          <Row>
            <Card>
              <Store />
              <CardTitle>Set up your store and customize it</CardTitle>
            </Card>
            <Spacer />
          </Row>
          <Row>
            <Spacer />
            <Card>
              <Cards />
              <CardTitle>Set payment methods</CardTitle>
            </Card>
          </Row>
          <Row>
            <Card>
              <Safe />
              <CardTitle>Safe platform</CardTitle>
            </Card>
            <Spacer />
          </Row>
          <Row>
            <Spacer />
            <Card>
              <Gift />
              <CardTitle>Benefits for hosts and guests</CardTitle>
            </Card>
          </Row>
        </Column>
      </Column>
      <Column flex={1.5}>
        <FormContainer>
          <Row justifyContent="center" gap="20px" marginY="10px">
            <Logo />
            <CoverTitle>UltiSell</CoverTitle>
          </Row>
          <Title>Login into your account</Title>
          <Formik
            onSubmit={onSubmit}
            initialValues={initialValues}
            validationSchema={validationSchema}>
            <Form>
              <FormikInput
                type="text"
                name="username"
                placeholder="Username"
                containerProps={containerProps}
              />
              <FormikInput
                type="password"
                name="password"
                placeholder="Password"
                containerProps={containerProps}
              />
              <Text height="1px" mt="10px" color="red.500" fontSize="sm">
                {globalError}
              </Text>
              <Column mt="30px">
                <Span>Forgot Pasword?</Span>
              </Column>
              <SubmitButton isLoading={isLoading}>LOGIN</SubmitButton>
              <FooterText>
                You don't have an account?{' '}
                <Span onClick={goToSignUp}>Create one here :)</Span>
              </FooterText>
            </Form>
          </Formik>
        </FormContainer>
      </Column>
    </Container>
  );
};

export default LoginView;
