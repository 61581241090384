import React, { forwardRef, memo, useImperativeHandle } from 'react';

import { useDisclosure, useToast } from '@chakra-ui/react';

import { createAttribute } from 'api/attributes';
import { useMutation, useQueryClient } from 'react-query';

import {
  CreateAttributeModalProps,
  CreateAttributeModalRef,
} from './CreateAttributeModal.props';

import CreateAttributeModalView from './CreateAttributeModal.view';

const Container = forwardRef<
  CreateAttributeModalRef,
  CreateAttributeModalProps
>((_, ref) => {
  const toast = useToast();
  const queryClient = useQueryClient();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const { mutate, isLoading } = useMutation(createAttribute, {
    onSuccess: () => {
      toast({
        title: 'Attribute created',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });

      queryClient.invalidateQueries({
        queryKey: ['fetch-attributes'],
      });

      queryClient.invalidateQueries({
        queryKey: [`fetch-all-attributes`],
      });

      onClose();
    },
  });

  useImperativeHandle(ref, () => ({ onOpen }), []);

  return (
    <CreateAttributeModalView
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={mutate}
      isLoading={isLoading}
    />
  );
});

const CreateAttributeModal = memo(Container);

export default CreateAttributeModal;
