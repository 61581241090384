import { Flex, Text } from '@chakra-ui/react';
import { chakraStyled } from 'utils';

export const Container = chakraStyled(Flex, {
  w: '100%',
  direction: 'column',
});

export const Row = chakraStyled(Flex, {
  w: '100%',
  direction: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  flexWrap: 'wrap',
  paddingX: '20px',
});

export const Label = chakraStyled(Text, {
  fontSize: '14px',
});

export const Title = chakraStyled(Text, {
  fontSize: '20px',
  fontWeight: '500',
});

export const Section = chakraStyled(Flex, {
  w: '100%',
  padding: '20px',
  display: 'block',
});

export const SectionFlex = chakraStyled(Flex, {
  w: '100%',
  padding: '20px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

export const SubTitle = chakraStyled(Text, {
  fontSize: '12px',
  textAlign: 'left',
  opacity: 0.8,
});

export const RoomImage = chakraStyled(Flex, {
  width: '90px',
  height: '62px',
  borderRadius: '10px',
  backgroundColor: '#cfcfcf73',
  backgroundSize: 'cover',
  mr: '20px',
});

export const ProductImage = chakraStyled(Flex, {
  width: '70px',
  height: '60px',
  borderRadius: '10px',
  backgroundColor: '#cfcfcf73',
  backgroundSize: 'cover',
  mr: '20px',
});
