import React, { FC } from 'react';

import {
  Tabs,
  Button,
  TabList,
  TabPanel,
  TabPanels,
  useTab,
  useToast,
} from '@chakra-ui/react';

import { Field, FieldProps, Form, Formik } from 'formik';

import { Header } from 'components/layouts/Header';

import FormikInput from 'components/primitives/FormikInput';
import EditProductVariants from 'components/modules/EditProductVariants';

import { EditProductViewProps } from './EditProduct.props';

import { getInitialValues, validationSchema } from './EditProduct.utils';

import { Row, Label, Column, StyledTab, Container } from './EditProduct.style';
import FormikRichText from 'components/primitives/FormikRichText';
import MultiImagePicker from 'components/primitives/MultiImagePicker';
import { useHistory } from 'react-router-dom';
import ProductVariantInput from 'components/forms/ProductVariantInput';

const EditProductView: FC<EditProductViewProps> = ({
  product,
  onSubmit,
  isUpdating,
  beforeDeleteCallback,
}) => {
  const history = useHistory();
  const toast = useToast();
  return (
    <Container>
      <Header isCentered isBackable>
        Edit product
      </Header>
      <Tabs my="20px" align="center" variant="soft-rounded">
        <TabList gap="20px">
          <StyledTab>Basic Info</StyledTab>
          {/* <StyledTab>Variations</StyledTab> */}
        </TabList>
        <TabPanels>
          <TabPanel>
            <Formik
              onSubmit={(value, { setFieldError }) => {
                console.log(value);
                if (!value.featuredImage) {
                  setFieldError(
                    'featuredImage',
                    'Please select featured image.',
                  );
                  toast({
                    description: 'Please select featured image.',
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                  });
                  return;
                }
                onSubmit(value);
              }}
              initialValues={getInitialValues(product)}
              validationSchema={validationSchema}>
              <Form>
                <Container p="40px" overflowY="auto" maxH="60vh">
                  <Row gap="25px" align="flex-start">
                    <Column flex={3}>
                      <Row gap="20px" flexWrap="wrap" align="flex-start">
                        <FormikInput
                          name="name"
                          label="Name"
                          containerProps={{ flex: 2, minW: '250px' }}
                        />
                        <FormikInput
                          type="number"
                          name="price"
                          label="Price"
                          containerProps={{ flex: 1, minW: '250px' }}
                        />
                      </Row>
                      <FormikRichText name="description" label="Description" />
                      <Label mt="20px">Variations</Label>
                      <ProductVariantInput editMode={true} />
                      <></>
                    </Column>
                    <Column flex={2}>
                      <MultiImagePicker
                        featuredImage={product?.featuredImage}
                        beforeDeleteCallback={beforeDeleteCallback}
                        onDelete={(currentPhotos: (string | File)[]) => {
                          history.replace(history.location.pathname, {
                            ...(history.location.state as any),
                            photos: currentPhotos,
                          } as any);
                        }}
                      />
                    </Column>
                  </Row>
                </Container>
                <Container align="center" justify="center" pt="20px">
                  <Button
                    colorScheme="secondary"
                    type="submit"
                    w="250px"
                    isLoading={isUpdating}>
                    Update
                  </Button>
                </Container>
              </Form>
            </Formik>
          </TabPanel>
          {/* <TabPanel>
            <EditProductVariants />
          </TabPanel> */}
        </TabPanels>
      </Tabs>
    </Container>
  );
};

export default EditProductView;
