import api from './instance';

import { User } from 'types/auth';
import { ApiResponse } from 'types/common';

const URL = `${process.env.REACT_APP_API_URL}`;

export const fetchUserById = async (id: string) => {
  const { data } = await api.get<ApiResponse<User>>(
    `${URL}/users/detail/${id}`,
  );

  return data.item;
};
