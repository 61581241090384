import { Flex, Tab } from '@chakra-ui/react';
import { chakraStyled } from 'utils';

export const Container = chakraStyled(Flex, {
  w: '100%',
  direction: 'column',
});

export const Row = chakraStyled(Flex, {
  w: '100%',
  direction: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  flexWrap: 'wrap',
});

export const TabTitle = chakraStyled(Tab, {
  bg: '#C2D4E2',
  color: '#000',
  borderRadius: '15px 15px 0px 0px',
});
