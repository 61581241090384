import React, { FC, useEffect, useState } from 'react';

import { Button, Input } from '@chakra-ui/react';

import { SketchPicker, SwatchesPicker } from 'react-color';

import { $styling } from 'stores';

import {
  Label,
  Column,
  BackButton,
  PreviewBox,
  PreviewText,
  MotionContainer,
} from '../CustomSideBar.style';
import useInputField from 'hooks/useInputField';
import useIsDataChanged from 'hooks/useIsDataChanged';
import SwatchColorPicker from 'components/primitives/SwatchColorPicker';

export const ColorsForm: FC = () => {
  const [dataChanged, setDataChanged] = useState<boolean>(false);
  const { data: backgroundColor, set: setBackgroundColor } = useInputField(
    'colors.backgroundColor',
  );

  const { data: headerColor, set: setHeaderColor } =
    useInputField('colors.headerColor');

  const { data: primaryColor, set: setPrimaryColor } = useInputField(
    'colors.primaryColor',
  );

  const { data: footerColor, set: setFooterColor } =
    useInputField('colors.footerColor');

  const { isDataChanged } = useIsDataChanged([
    backgroundColor,
    headerColor,
    primaryColor,
    footerColor,
  ]);

  const goBack = () => {
    $styling.actions.setSelectedSection(null);
  };

  const onConfirm = () => {
    goBack();
  };

  console.log('Rendering..');

  return (
    <MotionContainer flex={1} key="colors-form">
      <BackButton onClick={goBack}>Colors</BackButton>
      <Column flex={1}>
        <Column gap={4}>
          <SwatchColorPicker
            label="Background"
            initialValue={backgroundColor}
            onChange={(color) => {
              setBackgroundColor(color);
            }}
          />
          <SwatchColorPicker
            label="Primary"
            initialValue={primaryColor}
            onChange={(color) => {
              setPrimaryColor(color);
            }}
          />
          <SwatchColorPicker
            label="Header"
            initialValue={headerColor}
            onChange={(color) => {
              setHeaderColor(color);
            }}
          />
          <SwatchColorPicker
            label="Footer"
            initialValue={footerColor}
            onChange={(color) => {
              setFooterColor(color);
            }}
          />
        </Column>
      </Column>

      <Column>
        <Column direction="row" gap="5px">
          <Button size="sm" onClick={goBack}>
            Back
          </Button>
          <Button
            size="sm"
            variant="solid"
            colorScheme="whiteAlpha"
            onClick={onConfirm}
            isDisabled={!isDataChanged}>
            Confirm
          </Button>
        </Column>
      </Column>
    </MotionContainer>
  );
};
