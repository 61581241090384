import React from 'react';

import { ComingSoonProps } from './ComingSoon.props';

import ComingSoonView from './ComingSoon.view';

const ComingSoonContainer = (props: ComingSoonProps) => {
  return <ComingSoonView />;
};

export default ComingSoonContainer;
