import { getFeaturedExperience } from 'api/experience';
import { useQuery } from 'react-query';

const useFeaturedExperience = (websiteId: string) => {
  const queryKey = ['fetch-featured-experience', websiteId];
  const queryFn = () => getFeaturedExperience(websiteId);
  const options = {
    enabled: !!websiteId,
  };

  const query = useQuery(queryKey, queryFn, options);

  return query;
};

export default useFeaturedExperience;
