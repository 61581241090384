import React, { FC } from 'react';

import {
  Box,
  Text,
  Flex,
  Stack,
  Button,
  Center,
  Spinner,
  Image,
  Link,
  Card,
} from '@chakra-ui/react';

import { $session } from 'stores';

import { useFormikContext } from 'formik';

import { useQuery } from 'react-query';
import { fetchExperiences } from 'api/experience';

import { Template } from 'types/style';
import { TemplateExploreSectionProps } from './TemplateExploreSection.props';

import {
  Container,
  Row,
  SubTitle,
  Section,
  Description,
} from 'styles/template.style';

import { getPhotoUrl } from 'utils';
import { ExploreHeading } from 'routes/main/CreateTemplate/CreateTemplate.style';

const TemplateExploreSectionView: FC<TemplateExploreSectionProps> = (props) => {
  const userId = $session.selectors.useUserId() || '';

  const { values } = useFormikContext<Template>();

  const { data, isLoading } = useQuery(
    [`fetch-experiences`, 'store'],
    () =>
      fetchExperiences({
        page: 1,
        host: userId,
        limit: 10,
      }),
    { enabled: !!userId },
  );

  const experiences = (data?.items || [])?.slice(0, 3);

  const experience = experiences[0] ?? null;

  const store = values.store;
  const colors = values.colors;

  if (store.enabled === false) {
    return null;
  }

  return (
    <Section
      id="store"
      backgroundColor={values.colors.backgroundColor}
      color={values.colors.primaryColor ?? '#000'}>
      <Row justify="space-between" alignItems="start">
        <Container>
          <SubTitle>
            {store?.value?.title || 'Experience title goes here'}
          </SubTitle>
          <Description>
            {store?.value?.description || 'Experience description goes here'}
          </Description>
        </Container>
      </Row>

      {isLoading ? (
        <Center flex={1} minH="200px">
          <Spinner />
        </Center>
      ) : experiences.length > 0 ? (
        <>
          <Card
            height="400px"
            w="100%"
            cursor="pointer"
            _hover={{ boxShadow: 'lg' }}
            transition="ease-in-out 0.2s"
            boxShadow="md"
            borderRadius="lg"
            overflow="hidden">
            <Image
              src={getPhotoUrl(experience.photos?.[0])}
              alt={experience.title}
              height="320px"
              objectFit="cover"
            />
            <Box p="6">
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="baseline">
                <Text
                  fontWeight="semibold"
                  fontSize="lg"
                  mr={2}
                  whiteSpace="nowrap"
                  overflow="hidden"
                  color={values.colors.primaryColor ?? '#000'}
                  textOverflow="ellipsis"
                  maxWidth="80%">
                  {experience.title}
                </Text>
                <Text
                  color={values.colors.primaryColor ?? '#000'}
                  fontWeight="bold"
                  fontSize="sm">
                  ${experience.price}
                </Text>
              </Box>
            </Box>
          </Card>
        </>
      ) : (
        <Center flex={1} minH="200px">
          <ExploreHeading fontStyle="italic" opacity="0.5">
            Create some experiences
          </ExploreHeading>
        </Center>
      )}

      <Row justifyContent="end" px="20px">
        <Link>See all</Link>
      </Row>
    </Section>
  );

  // return (
  //   <ExploreSection>
  //     <Box>
  //       <Flex justifyContent="space-between" flexWrap="wrap">
  //         <ExploreHeading>
  //           {store?.value?.title || 'Store title goes here'}
  //         </ExploreHeading>
  //         <Text fontSize="14px" minW={{ base: 'auto', md: '500px' }}>
  //           {store?.value?.description || 'Store description goes here'}
  //         </Text>
  //       </Flex>
  //       <ExploreBox>
  //         {isLoading ? (
  //           <Center flex={1} minH="200px">
  //             <Spinner />
  //           </Center>
  //         ) : experiences.length > 0 ? (
  //           <>
  //             {experiences.map((exp) => (
  //               <ExploreSectionBox key={exp._id}>
  //                 <ExpImage src={getPhotoUrl(exp.photos?.[0])} />
  //                 <ExpInfoSection>
  //                   <Stack>
  //                     <Text>{exp.title || '-'}</Text>
  //                   </Stack>
  //                   <Stack>
  //                     <Button
  //                       w="80px"
  //                       bg={colors.button}
  //                       _hover={{ bg: `${colors.button}95` }}
  //                       _active={{ bg: colors.button }}>
  //                       More
  //                     </Button>
  //                   </Stack>
  //                 </ExpInfoSection>
  //               </ExploreSectionBox>
  //             ))}
  //           </>
  //         ) : (
  //           <Center flex={1} minH="200px">
  //             <ExploreHeading fontStyle="italic" opacity="0.5">
  //               Create some experiences
  //             </ExploreHeading>
  //           </Center>
  //         )}
  //       </ExploreBox>
  //     </Box>
  //   </ExploreSection>
  // );
};

export default TemplateExploreSectionView;
