import { Flex, Icon, Text, Box } from '@chakra-ui/react';

import { chakraStyled } from 'utils';

export const Container = chakraStyled(Flex, {
  w: '100%',
  direction: 'column',
});

export const Title = chakraStyled(Text, {
  fontSize: '19px',
  marginBottom: '20px',
  fontWeight: 'bold',
  color: 'primary.500',
});

export const AccountDetailBox = chakraStyled(Box, {
  boxShadow: '2xl',
  rounded: 'md',
  width: ['100%', '95%'],
  mx: 'auto',
  mt: ['10px', '30px'],
  px: ['10px', '30px'],
  py: ['10px', '20px'],
  bg: '#FFFFFF',
  borderRadius: ['10px', '20px'],
});

export const DetailsBox = chakraStyled(Box, {
  w: '100%',
  maxW: '100vw',
  p: '10px',
});

export const TableBox = chakraStyled(Box, {
  h: '70px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

export const PricingFeatureBox = chakraStyled(Box, {
  height: '70px',
});

export const PricingFeatureText = chakraStyled(Text, {
  color: 'white',
  mt: '25px',
  pl: '10px',
});

export const PricingTableBox = chakraStyled(Box, {
  width: ['100%', '95%'],
  mx: 'auto',
  mt: ['10px', '30px'],
  px: ['10px', '30px'],
  py: ['10px', '20px'],
  bg: '#FFFFFF',
});

export const BasicInfo = chakraStyled(Flex, {
  direction: {
    base: 'column',
    md: 'row',
  },
  marginBottom: '25px',
});

export const EmailNotificationFlex = chakraStyled(Flex, {
  direction: 'column',
});

export const ActiveText = chakraStyled(Text, {
  color: '#FF0000',
  marginTop: '2px',
  marginLeft: '15px',
});

export const EmailBox = chakraStyled(Box, {
  flex: '1',
  marginRight: {
    base: 0,
    md: 4,
  },
  marginBottom: {
    base: 4,
    md: 0,
  },
});

export const NameBox = chakraStyled(Box, {
  flex: '1',
  marginBottom: {
    base: 4,
    md: 0,
  },
});

export const TitleText = chakraStyled(Text, {
  marginBottom: '10px',
  fontWeight: 'medium',
});
