import React, { FC, useState } from 'react';

import { DeleteImageAlertDialogProps } from './DeleteImageAlertDialog.props';
import {
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  Button,
} from '@chakra-ui/react';

const DeleteImageAlertDialogView: FC<DeleteImageAlertDialogProps> = ({
  onClickDelete,
  onClose,
  isOpen,

  isLoading = false,
}) => {
  const cancelRef = React.useRef<HTMLButtonElement | null>(null);

  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onClose}>
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            Delete Image
          </AlertDialogHeader>
          <AlertDialogBody>
            Are you sure you want to delete this image?
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button variant="outline" onClick={onClose}>
              Cancel
            </Button>
            <Button
              isLoading={isLoading}
              colorScheme="red"
              ml={3}
              onClick={onClickDelete}>
              Delete
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};

export default DeleteImageAlertDialogView;
