import React, { FC, useEffect, useState } from 'react';

import { Button, Input, Spacer, Switch, Textarea } from '@chakra-ui/react';

import { useFormikContext } from 'formik';

import { $styling } from 'stores';

import {
  Label,
  Column,
  ItemFlex,
  ItemText,
  BackButton,
  MotionContainer,
} from '../CustomSideBar.style';

import { Template } from 'types/style';
import useIsDataChanged from 'hooks/useIsDataChanged';

type LocalValue = {
  phone: string;
  email: string;
  description: string;
};

const inpProps = {
  bg: '#fff',
  color: '#000',
  size: 'sm',
};

export const ContactForm: FC = () => {
  const [index, setIndex] = useState<0 | 1>(0);

  const { values, setFieldValue } = useFormikContext<Template>();

  const [localValue, setLocalValue] = useState<LocalValue>({
    phone: values.contact.value.phone,
    email: values.contact.value.email,
    description: values.contact.value.description,
  });

  const [socials, setSocials] = useState(values.contact.value.socials);

  useEffect(() => {
    setFieldValue('contact.value.socials', socials);
  }, [socials]);

  const goBack = () => {
    $styling.actions.setSelectedSection(null);
  };

  // const { isDataChanged: isLocalValueChanged } = useIsDataChanged([]);

  useEffect(() => {
    setFieldValue('contact.value.phone', localValue.phone);
    setFieldValue('contact.value.email', localValue.email);
    setFieldValue('contact.value.description', localValue.description);
    setFieldValue('contact.value.availability', {});
  }, [localValue]);

  const onNext = () => {
    setFieldValue('contact.value.phone', localValue.phone);
    setFieldValue('contact.value.email', localValue.email);
    setFieldValue('contact.value.description', localValue.description);
    setFieldValue('contact.value.availability', {});

    setIndex(1);
  };

  const onSave = () => {
    setIndex(0);
    goBack();
  };

  return (
    <MotionContainer flex={1} key="contact-form">
      <BackButton fontSize="14px" onClick={goBack}>
        Contact Info
      </BackButton>

      {index === 0 ? (
        <Column flex={1}>
          <Column flex={1}>
            <Column gap="0px">
              <Label>Phone</Label>
              <Input
                {...inpProps}
                type="number"
                value={localValue.phone}
                onChange={(e) => {
                  setLocalValue((prev) => ({
                    ...prev,
                    phone: e.target.value,
                  }));
                }}
              />
            </Column>

            <Column gap="0px">
              <Label>Email</Label>
              <Input
                {...inpProps}
                type="email"
                value={localValue.email}
                onChange={(e) => {
                  setLocalValue((prev) => ({
                    ...prev,
                    email: e.target.value,
                  }));
                }}
              />
            </Column>

            {/* <Column gap="0px">
              <Label>Description</Label>
              <Textarea
                {...inpProps}
                minH="200px"
                value={localValue.description}
                onChange={(e) => {
                  setLocalValue((prev) => ({
                    ...prev,
                    description: e.target.value,
                  }));
                }}
              />
            </Column> */}
          </Column>

          <Column direction="row" gap="5px">
            <Button size="sm" onClick={goBack}>
              Back
            </Button>
            <Button
              size="sm"
              variant="solid"
              colorScheme="whiteAlpha"
              onClick={onNext}>
              Next
            </Button>
          </Column>
        </Column>
      ) : (
        <Column flex={1}>
          <Column flex={1}>
            <Column gap="0px" bg="#fff" borderRadius="10px">
              <ItemFlex w="100%">
                <ItemText>Facebook</ItemText>
                <Spacer />
                <Switch
                  size="sm"
                  colorScheme="green"
                  isChecked={socials.facebook.enabled}
                  onChange={(e) =>
                    setSocials((prev) => ({
                      ...prev,
                      facebook: {
                        ...prev.facebook,
                        enabled: e.target.checked,
                      },
                    }))
                  }
                />
              </ItemFlex>
              <Column p="5px 10px">
                <Input
                  size="xs"
                  variant="flushed"
                  color="#000"
                  placeholder="Enter url"
                  isReadOnly={!socials.facebook.enabled}
                  value={socials.facebook.value}
                  onChange={(e) =>
                    setSocials((prev) => ({
                      ...prev,
                      facebook: {
                        ...prev.facebook,
                        value: e.target.value,
                      },
                    }))
                  }
                />
              </Column>
            </Column>

            <Column gap="0px" bg="#fff" borderRadius="10px">
              <ItemFlex w="100%">
                <ItemText>Instagram</ItemText>
                <Spacer />
                <Switch
                  size="sm"
                  colorScheme="green"
                  isChecked={socials.instagram.enabled}
                  onChange={(e) =>
                    setSocials((prev) => ({
                      ...prev,
                      instagram: {
                        ...prev.instagram,
                        enabled: e.target.checked,
                      },
                    }))
                  }
                />
              </ItemFlex>
              <Column p="5px 10px">
                <Input
                  size="xs"
                  variant="flushed"
                  color="#000"
                  placeholder="Enter url"
                  isReadOnly={!socials.instagram.enabled}
                  value={socials.instagram.value}
                  onChange={(e) =>
                    setSocials((prev) => ({
                      ...prev,
                      instagram: {
                        ...prev.instagram,
                        value: e.target.value,
                      },
                    }))
                  }
                />
              </Column>
            </Column>

            <Column gap="0px" bg="#fff" borderRadius="10px">
              <ItemFlex w="100%">
                <ItemText>Telegram</ItemText>
                <Spacer />
                <Switch
                  size="sm"
                  colorScheme="green"
                  isChecked={socials.telegram.enabled}
                  onChange={(e) =>
                    setSocials((prev) => ({
                      ...prev,
                      telegram: {
                        ...prev.telegram,
                        enabled: e.target.checked,
                      },
                    }))
                  }
                />
              </ItemFlex>
              <Column p="5px 10px">
                <Input
                  size="xs"
                  variant="flushed"
                  color="#000"
                  placeholder="Enter url"
                  isReadOnly={!socials.telegram.enabled}
                  value={socials.telegram.value}
                  onChange={(e) =>
                    setSocials((prev) => ({
                      ...prev,
                      telegram: {
                        ...prev.telegram,
                        value: e.target.value,
                      },
                    }))
                  }
                />
              </Column>
            </Column>

            <Column gap="0px" bg="#fff" borderRadius="10px">
              <ItemFlex w="100%">
                <ItemText>Twitter</ItemText>
                <Spacer />
                <Switch
                  size="sm"
                  colorScheme="green"
                  isChecked={socials.twitter.enabled}
                  onChange={(e) =>
                    setSocials((prev) => ({
                      ...prev,
                      twitter: {
                        ...prev.twitter,
                        enabled: e.target.checked,
                      },
                    }))
                  }
                />
              </ItemFlex>
              <Column p="5px 10px">
                <Input
                  size="xs"
                  variant="flushed"
                  color="#000"
                  placeholder="Enter url"
                  isReadOnly={!socials.twitter.enabled}
                  value={socials.twitter.value}
                  onChange={(e) =>
                    setSocials((prev) => ({
                      ...prev,
                      twitter: {
                        ...prev.twitter,
                        value: e.target.value,
                      },
                    }))
                  }
                />
              </Column>
            </Column>
          </Column>
          <Column direction="row" gap="5px">
            <Button size="sm" onClick={() => setIndex(0)}>
              Back
            </Button>
            <Button
              size="sm"
              variant="solid"
              colorScheme="whiteAlpha"
              onClick={onSave}>
              Save
            </Button>
          </Column>
        </Column>
      )}
    </MotionContainer>
  );
};
