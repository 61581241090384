import React from 'react';

import { $user } from 'stores';

import { OnboardingModal } from 'components/modules/OnboardingModal';

import { DashboardProps } from './Dashboard.props';

import DashboardView from './Dashboard.view';
import useAnalytics from 'queries/useAnalytics';

const DashboardContainer = (props: DashboardProps) => {
  const user = $user.selectors.useUser();

  const search = new URLSearchParams(props.location.search);
  const isOnboarding = search.get('onboarding') === 'true';

  const { data: analyticsData, isLoading: isAnalyticsLoading } = useAnalytics();

  const onClearSearch = () => {
    props.history.replace(props.location.pathname);
  };

  return (
    <>
      <DashboardView
        userData={user}
        analyticsData={analyticsData}
        isAnalyticsLoading={isAnalyticsLoading}
      />
      <OnboardingModal isOpen={isOnboarding} onClearSearch={onClearSearch} />
    </>
  );
};

export default DashboardContainer;
