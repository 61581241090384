import { Flex, Text } from '@chakra-ui/react';

import { chakraStyled } from 'utils';

export const Container = chakraStyled(Flex, {
  direction: 'column',
  gap: '10px',
  m: '25px',
});

export const Label = chakraStyled(Text, {
  fontSize: '16px',
  userSelect: 'none',
});

export const PickerContainer = chakraStyled(Flex, {
  p: '30px',
  w: '100%',
  gap: '10px',
  justify: 'center',
  align: 'center',
  bg: 'primary.50',
  borderRadius: '10px',
  color: 'primary.500',
  border: '2px dashed #2B689B',
  cursor: 'pointer',
  h: '150px',
});

export const PlaceholderText = chakraStyled(Text, {
  fontSize: '20px',
  fontWeight: '600',
  userSelect: 'none',
});
