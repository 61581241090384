export function handleTimeZone(date?: string): Date {
  if (!date) return new Date();
  const isoDateString = new Date(date).toISOString();
  const isoStringWithoutZ = isoDateString.replace('Z', '');
  const dateTime = new Date(isoStringWithoutZ);
  const result = new Date(dateTime.getTime());
  return result;
}

export function formatDateYYYYMMDD(date: Date) {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Adding leading zero if needed
  const day = String(date.getDate()).padStart(2, '0'); // Adding leading zero if needed
  return `${year}-${month}-${day}`;
}
