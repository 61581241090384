import { Button, Flex, Image, Box, Text } from '@chakra-ui/react';

import { chakraStyled } from 'utils';

import stripe from 'assets/onboarding/stripe.png';

export const Title = chakraStyled(Text, {
  fontSize: '22px',
  fontWeight: 'bold',
  color: 'primary.500',
});

export const Logo = chakraStyled(Image, {
  h: 'auto',
  w: '50px',
  objectFit: 'contain',
  alignSelf: { base: 'center', md: 'flex-start' },
  src: stripe,
});

export const StripeButton = chakraStyled(Button, {
  w: '200px',
  ml: '10px',
  bg: '#2672F2',
  color: '#fff',
  _hover: { bg: '#2672F280' },
  _active: { bg: '#2672F2' },
});

export const FlexBox = chakraStyled(Box, {
  marginBottom: {
    base: 4,
    md: 0,
  },
});

export const StripeBox = chakraStyled(Box, {
  boxShadow: '2xl',
  rounded: 'md',
  width: ['100%', '95%'],
  mx: 'auto',
  mt: ['10px', '30px'],
  px: ['10px', '30px'],
  py: ['10px', '20px'],
  bg: '#FFFFFF',
  borderRadius: ['10px', '20px'],
});

export const StripeFlex = chakraStyled(Flex, {
  direction: {
    base: 'column',
    md: 'row',
  },
  justifyContent: 'space-between',
  alignItems: {
    base: 'flex-start',
    md: 'center',
  },
  marginBottom: '15px',
});

export const StripeTitle = chakraStyled(Text, {
  color: '#000000',
  marginRight: '10px',
  marginBottom: '10px',
  fontSize: '22px',
  fontWeight: 'bold',
});
