import React, { FC } from 'react';

import { Flex, Text } from '@chakra-ui/react';

import { IoIosAlert } from 'react-icons/io';

import { $stripe } from 'stores';

import { StripeConnectButton } from '../StripeConnectButton';

import { StripeConnectViewProps } from './StripeConnect.props';

import {
  FlexBox,
  StripeBox,
  StripeFlex,
  StripeTitle,
} from './StripeConnect.style';

const StripeConnectView: FC<StripeConnectViewProps> = (props) => {
  const isAccountEnabled = $stripe.selectors.useIsAccountEnabled();
  const isDetailsSubmitted = $stripe.selectors.useIsDetailsSubmitted();
  return (
    <StripeBox>
      <StripeFlex>
        <FlexBox>
          <Flex>
            <StripeTitle>Stripe Connect</StripeTitle>
            {!isDetailsSubmitted && <IoIosAlert size={30} color="#FF0000" />}
          </Flex>
          <Text mt="2">
            {isAccountEnabled
              ? 'Your Stripe is successfully connected, if you need to change your info, please click the button.'
              : isDetailsSubmitted
                ? 'Your Stripe Account is created, however you have not submitted all the required details. Kindly click on the "Update Information" button and submit all the required details.'
                : `You need to connect Stripe so we can send you payments, please click the button and set up Stripe.`}
          </Text>
        </FlexBox>
        <StripeConnectButton />
      </StripeFlex>
    </StripeBox>
  );
};

export default StripeConnectView;
