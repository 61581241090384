import { IconProps } from './type';

const SingleWaterBed = (props: IconProps) => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M21.5 12C20.8073 12 20.2174 11.7175 19.7305 11.1526C19.2435 10.5877 19 9.9 19 9.08947C19 8.70263 19.0638 8.33268 19.1914 7.97961C19.319 7.62654 19.5 7.31491 19.7344 7.04474L21.5 5L23.2656 7.04474C23.5 7.31491 23.681 7.62654 23.8086 7.97961C23.9362 8.33268 24 8.70263 24 9.08947C24 9.9 23.7565 10.5877 23.2695 11.1526C22.7826 11.7175 22.1927 12 21.5 12ZM11.3437 12.0076C10.8367 12.0076 10.4258 12.4185 10.4258 12.9255V15.3506H9.75391V10.8358C9.75391 10.6503 9.90425 10.5 10.0897 10.5H18.1844C18.0842 10.1856 18.0248 9.85226 18.0063 9.5H10.0897C9.35197 9.5 8.75391 10.0981 8.75391 10.8358V15.3531C8.0543 15.3954 7.5 15.9762 7.5 16.6864V20.3655V20.8654V20.8655V21.3654V23.7908V24.2908H8H9.17188V25.4624C9.17188 25.9693 9.58283 26.3803 10.0898 26.3803H11.3435C11.8504 26.3803 12.2614 25.9693 12.2614 25.4624V24.2908H17.1113V25.4624C17.1113 25.9693 17.5223 26.3803 18.0292 26.3803H19.2829C19.7899 26.3803 20.2008 25.9693 20.2008 25.4624V24.2908H21.3729H21.8729V23.7908V21.3654V20.8655V20.8654V20.3655V16.6864C21.8729 15.9763 21.3188 15.3956 20.6194 15.3531V12.8901C20.2623 12.7963 19.9289 12.6424 19.6194 12.4285V15.3506H18.9481V12.9255C18.9481 12.4185 18.5371 12.0076 18.0302 12.0076H11.3437ZM18.0434 16.3507H20.5443C20.7264 16.3545 20.8729 16.5033 20.8729 16.6864V20.3654H8.5V16.6864C8.5 16.5033 8.6465 16.3545 8.82867 16.3507H11.3304L11.3437 16.3508H18.0302L18.0434 16.3507ZM17.9481 15.3506V13.0076H11.4258V15.3506H17.9481ZM20.2008 23.2908V23.2908H19.7008H17.6113H17.1113V23.2908H12.2614V23.2908H11.7614H9.67188H9.17188V23.2908H8.5V21.3655H20.8729V23.2908H20.2008ZM18.1113 24.2908V25.3803H19.2008V24.2908H18.1113ZM11.2614 24.2908H10.1719V25.3803H11.2614V24.2908Z"
        fill="#353535"
      />
    </svg>
  );
};

export default SingleWaterBed;
