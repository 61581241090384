import { useQuery } from 'react-query';
import { fetchProducts } from 'api/products';

import { $session } from 'stores';

const useProducts = (experience: string) => {
  const userId = $session.selectors.useUserId() || '';

  const queryKey = ['fetch-products', userId, experience];
  const queryFn = () => fetchProducts(userId, experience);
  const options = {
    enabled: !!userId,
  };

  const query = useQuery(queryKey, queryFn, options);

  return query;
};

export default useProducts;
