import React, { FC } from 'react';

import { FormikCheckBoxProps } from './FormikCheckBox.props';
import {
  Box,
  Checkbox,
  FormControl,
  FormHelperText,
  FormLabel,
  Text,
} from '@chakra-ui/react';
import { Field, FieldProps, useField } from 'formik';

const FormikCheckBoxView: FC<FormikCheckBoxProps> = ({
  label,
  mt,
  name,
  helperText,
  error,
  hideError = false,
  hint,
  ...props
}) => {
  const [checkBoxfield, meta, helpers] = useField(name);
  return (
    <FormControl mt={mt} {...props}>
      <Field name={name}>
        {({ field }: FieldProps) => (
          <Box display="flex" gap="10px" alignItems="center">
            <Checkbox
              {...field}
              name={name}
              isChecked={checkBoxfield.value}
              onChange={(e) => {
                field.onChange(e);
                helpers.setValue(e.target.checked);
              }}
            />
            {label && (
              <Text
                cursor="pointer"
                userSelect="none"
                onClick={() => helpers.setValue(!checkBoxfield.value)}>
                {label}
              </Text>
            )}
          </Box>
        )}
      </Field>
      {hint && (
        <Text textAlign="start" color="gray.500" fontSize={'sm'}>
          {hint}
        </Text>
      )}
      {(error || meta.error) && !hideError && (
        <Text color="red.500" fontSize="sm">
          {(meta.touched && meta.error) || error}
        </Text>
      )}
    </FormControl>
  );
};

export default FormikCheckBoxView;
