import React, { FC } from 'react';
import { Text } from '@chakra-ui/react';
import { FormikRichTextProps } from './FormikRichText.props';
import { Label } from './FormikRichText.style';
import { Field, FieldProps, useField } from 'formik';
import ReactQuill from 'react-quill';
import { FormHelperText } from '@chakra-ui/react';

const FormikRichTextView: FC<FormikRichTextProps> = ({
  helperText,
  onlyShowError,
  label,
  name,
}) => {
  const [field, meta, helpers] = useField(name);

  return (
    <>
      <Label mt="10px" mb="10px">
        {label}
      </Label>
      <Field name={name}>
        {({ field }: FieldProps<string>) => (
          <ReactQuill
            style={{
              height: '15rem',
              marginBottom: '40px',
            }}
            value={field.value}
            onChange={field.onChange(field.name)}
          />
        )}
      </Field>
      {helperText && (
        <FormHelperText hidden={onlyShowError}>{helperText}</FormHelperText>
      )}
      {meta.touched && meta.error && (
        <Text color="red.500" fontSize="sm">
          {meta.error}
        </Text>
      )}
    </>
  );
};

export default FormikRichTextView;
