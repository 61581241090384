import React, { useState } from 'react';

import { $session, $user } from 'stores';

import { useQuery } from 'react-query';
import { fetchExperiences } from 'api/experience';

import { ProductsProps } from './Products.props';

import ProductsView from './Products.view';
import { routesSettings } from 'constants/routes';
import { useFeaturedExperience } from 'queries';

const ITEMS_PER_PAGE = 10;

const ProductsContainer = (props: ProductsProps) => {
  const userId = $session.selectors.useUserId() || '';
  const websiteId = $user.selectors.useWebsiteId() || '';
  const [page, setPage] = useState(1);

  const { data: featuredExperience, isLoading: isFeatureExperienceLoading } =
    useFeaturedExperience(websiteId);

  const queryKey = [`fetch-experiences`, page, userId];
  const queryFn = () =>
    fetchExperiences({
      page,
      host: userId,
      limit: ITEMS_PER_PAGE,
    });

  const options = { enabled: !!userId };

  const { data, isLoading, isFetching } = useQuery(queryKey, queryFn, options);

  const experiences = data?.items || [];
  const totalCount = data?.total || 0;
  const totalPages = data?.totalPages || 0;

  const pagination = {
    page,
    setPage,
    totalPages,
    totalCount,
  };

  const onCreateClick = () => {
    props.history.push(routesSettings.MAIN_CREATE_EXPERIENCE.path);
  };

  return (
    <ProductsView
      pagination={pagination}
      featuredExperience={featuredExperience}
      experiences={experiences}
      onCreateClick={onCreateClick}
      isLoading={isLoading || isFetching || isFeatureExperienceLoading}
    />
  );
};

export default ProductsContainer;
