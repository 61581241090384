import React, { memo, forwardRef, useImperativeHandle } from 'react';

import { useDisclosure } from '@chakra-ui/react';

import { OnboardingModalProps, RefType } from './OnboardingModal.props';

import OnboardingModalView from './OnboardingModal.view';

const Container = forwardRef<RefType, OnboardingModalProps>((props, ref) => {
  const { isOpen, onOpen, onClose } = useDisclosure({
    defaultIsOpen: props.isOpen,
  });

  useImperativeHandle(
    ref,
    () => ({
      onOpen,
    }),
    [],
  );

  if (isOpen === false) {
    return null;
  }

  return (
    <OnboardingModalView
      isOpen={isOpen}
      onClose={onClose}
      onClearSearch={props.onClearSearch}
    />
  );
});

const OnboardingModal = memo(Container);

export default OnboardingModal;
