import React, { FC, useEffect, useState } from 'react';

import { Button, Spacer, Switch } from '@chakra-ui/react';

import { useField } from 'formik';

import { $styling } from 'stores';

import PlacesAutocomplete from 'components/primitives/PlacesAutocomplete';

import {
  Label,
  Column,
  ItemFlex,
  ItemText,
  BackButton,
  MotionContainer,
} from '../CustomSideBar.style';
import useIsDataChanged from 'hooks/useIsDataChanged';

export const LocationForm: FC = () => {
  const [locField, , locHelpers] = useField('location.value');
  const [showMapField, , showMapHelpers] = useField('location.showMap');

  const [localValue, setLocalValue] = useState({
    lat: Number(locField?.value?.lat || '0'),
    lng: Number(locField?.value?.lng || '0'),
    address: String(locField?.value?.address || ''),
  });

  const [isChecked, setIsChecked] = useState<boolean>(showMapField.value);

  const { isDataChanged } = useIsDataChanged([localValue]);

  const goBack = () => {
    $styling.actions.setSelectedSection(null);
  };

  useEffect(() => {
    locHelpers.setValue(localValue);
    showMapHelpers.setValue(isChecked);
  }, [localValue]);

  const onConfirm = () => {
    locHelpers.setValue(localValue);
    showMapHelpers.setValue(isChecked);
    goBack();
  };

  return (
    <MotionContainer flex={1} key="location-form">
      <BackButton onClick={goBack}>Location</BackButton>

      <Column flex={1}>
        <Column gap="0px">
          <Label>Address</Label>
          <PlacesAutocomplete
            name="address"
            labelText=""
            value={localValue?.address || ''}
            onSelect={([result]) => {
              const address = result?.formatted_address;
              const lat = result?.geometry?.location?.lat();
              const lng = result?.geometry?.location?.lng();

              setLocalValue({
                lat,
                lng,
                address,
              });
            }}
          />
        </Column>

        <ItemFlex>
          <ItemText>Show Map</ItemText>
          <Spacer />
          <Switch
            size="sm"
            colorScheme="green"
            isChecked={isChecked}
            onChange={(e) => {
              setIsChecked(e.target.checked);
            }}
          />
        </ItemFlex>
      </Column>

      <Column direction="row" gap="5px">
        <Button size="sm" onClick={goBack}>
          Back
        </Button>
        <Button
          size="sm"
          variant="solid"
          colorScheme="whiteAlpha"
          isDisabled={!isDataChanged}
          onClick={onConfirm}>
          Confirm
        </Button>
      </Column>
    </MotionContainer>
  );
};
