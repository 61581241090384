import { useQuery } from 'react-query';

import { fetchUserById } from 'api/user';

import { delay } from 'utils';

import { $user, $session } from 'stores';

const useFetchUser = () => {
  const userId = $session.selectors.useUserId();

  const queryKey = [`fetch-user`, userId];
  const queryFn = async () => {
    const user = await fetchUserById(String(userId));

    await delay(2000);

    return user;
  };

  const query = useQuery(queryKey, queryFn, {
    enabled: !!userId,
    onSuccess: (_user) => {
      $user.actions.setUser(_user);
    },
  });

  return query;
};

export default useFetchUser;
