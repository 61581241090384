import React, { FC } from 'react';

import { MdAddCircle } from 'react-icons/md';

import { ProductsCarouselViewProps } from './ProductsCarousel.props';

import {
  Label,
  Container,
  PickerContainer,
  PlaceholderText,
} from './ProductsCarousel.style';

const ProductsCarouselView: FC<ProductsCarouselViewProps> = ({
  onCreateClick,
}) => {
  return (
    <Container>
      <Label>Popular Experiences</Label>
      <PickerContainer onClick={onCreateClick}>
        <MdAddCircle size="24px" />
        <PlaceholderText>Start creating experience</PlaceholderText>
      </PickerContainer>
    </Container>
  );
};

export default ProductsCarouselView;
