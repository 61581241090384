import { disableToast, enableToast } from 'utils/queryClient';
import api from './instance';
import { AnalyticsResponse } from 'types/dashboard';

const URL = `${process.env.REACT_APP_API_URL}`;

export const fetchAnalytics = async () => {
  const { data } = await api.get<AnalyticsResponse>(
    `${URL}/orders/host/order/dashboard`,
  );
  return data?.item;
};
