import React, { useEffect } from 'react';

import { Flex } from '@chakra-ui/react';

import { withMotion } from 'utils';

import { Switch, useHistory } from 'react-router-dom';

import ROUTES, { routesSettings } from 'constants/routes';

// IMPORT ROUTE
import ComingSoon from 'routes/auth/ComingSoon';
import PrivacyPolicy from 'routes/auth/PrivacyPolicy';
import Login from 'routes/auth/Login';
import Landing from 'routes/auth/Landing';
import Register from 'routes/auth/Register';

import NavBar from 'components/core/NavBar';
import RouteWithSubRoutes from 'components/core/RouteWithSubRoutes';
import Footer from 'components/core/Footer';
import NavbarProvider from 'contexts/navbarContext';
import FooterProvider from 'contexts/footerContext';

const routeConfig = Object.freeze({
  // AUTH ROUTE ENTRY
  [ROUTES.AUTH_COMING_SOON]: {
    component: ComingSoon,
    path: routesSettings.AUTH_COMING_SOON.path,
  },
  [ROUTES.AUTH_PRIVACY_POLICY]: {
    component: PrivacyPolicy,
    path: routesSettings.AUTH_PRIVACY_POLICY.path,
  },
  [ROUTES.AUTH_REGISTER]: {
    component: Register,
    path: routesSettings.AUTH_REGISTER.path,
  },
  [ROUTES.AUTH_LANDING]: {
    component: Landing,
    path: routesSettings.AUTH_LANDING.path,
  },
  [ROUTES.AUTH_LOGIN]: {
    component: Login,
    path: routesSettings.AUTH_LOGIN.path,
  },
});

const authPaths = Object.values(routeConfig).map((val) => val.path);

const AuthRoutes: React.FC = () => {
  const history = useHistory();

  useEffect(() => {
    const isNotFound = !authPaths.includes(history.location.pathname);

    if (isNotFound) {
      history.replace(routeConfig['AUTH_LANDING'].path);
    }
  }, []);

  return (
    <NavbarProvider>
      <FooterProvider>
        <NavBar />
        <Flex flex={1} bg="#fff">
          <Flex
            bg="#fff"
            flexGrow={1}
            flexBasis={0}
            marginX="auto"
            overflow="auto">
            <Switch>
              {Object.entries(routeConfig).map(([key, val]) => (
                <RouteWithSubRoutes
                  exact
                  key={key}
                  path={val.path}
                  component={withMotion(val.component)}
                />
              ))}
            </Switch>
          </Flex>
        </Flex>
        <Footer />
      </FooterProvider>
    </NavbarProvider>
  );
};

export default AuthRoutes;
