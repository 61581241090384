import React from 'react';

import { register } from 'api/auth';
import { useMutation } from 'react-query';

import { $session } from 'stores';
import { routesSettings } from 'constants/routes';

import { RegisterProps } from './Register.props';

import RegisterView from './Register.view';

const RegisterContainer = (props: RegisterProps) => {
  const { mutate, isLoading } = useMutation(register, {
    onSuccess: ({ tokens, id, username }) => {
      $session.actions.setSession({
        id,
        tokens,
        username,
      });
      props.history.replace(
        routesSettings.MAIN_DASHBOARD.path + '?onboarding=true',
      );
    },
  });

  const goToLogin = () => {
    props.history.push('/login');
  };

  return (
    <RegisterView
      isLoading={isLoading}
      goToLogin={goToLogin}
      onSubmit={(values) => {
        mutate(values);
      }}
    />
  );
};

export default RegisterContainer;
