import * as Yup from 'yup';

export const initialValues = {
  name: '',
  photos: [],
  description: '',
  details: {
    beds: 0,
    rooms: 0,
    bathrooms: 0,
  },
  availability: {
    startDate: '',
    endDate: '',
  },
  price: 0,
  weekendPrice: 0,
  quantity: 0,
  // roomQuantity: 1,
  type: '',
  featuredImage: '',
  amenities: [] as Array<string>,
  features: [''] as Array<string>,
  marketing: [] as Array<string>,
};

export const validationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  photos: Yup.array()
    .min(1, 'please upload at least 1 photo')
    .required('Photos are required'),
  description: Yup.string().required('Description is required'),
  details: Yup.object().shape({
    beds: Yup.number().required('Beds are required'),
    rooms: Yup.number().required('Rooms are required'),
    bathrooms: Yup.number().required('Bathrooms are required'),
  }),
  availability: Yup.object().shape({
    startDate: Yup.string().required('Start date is required'),
    endDate: Yup.string().required('End date is required'),
  }),
  price: Yup.number().required('Price is required'),
  weekendPrice: Yup.number().required('Weekend Price is required'),
  quantity: Yup.number().required('Quantity is required'),
  // roomQuantity: Yup.number(),
  type: Yup.string().required('Type is required'),
  amenities: Yup.array().min(1, 'Please Add at least 1 amenity'),
  features: Yup.array().min(1, 'Please Add at least 1 feature'),
  marketing: Yup.array().min(1, 'Please Add at least 1 marketing item'),
});
