import { Experience } from 'types/experience';
import { formatDateYYYYMMDD, handleTimeZone } from 'utils/dateTime';

export const getInitialValues = (experience?: Experience) => {
  let start = handleTimeZone(experience?.availability?.startDate || '');
  let end = handleTimeZone(experience?.availability?.endDate || '');

  const startDate = formatDateYYYYMMDD(start);
  const endDate = formatDateYYYYMMDD(end);

  const tempPhotos = new Set([...(experience?.photos ?? [])]);
  if (experience?.featuredImage) {
    tempPhotos.add(experience?.featuredImage!);
  }
  const photos = Array.from(tempPhotos);

  return {
    price: experience?.price || '',
    photos: photos,
    description: experience?.description || '',
    availability: {
      startDate: startDate,
      endDate: endDate,
    },
    fixedDateRange: experience?.fixedDateRange || false,
    featuredImage: experience?.featuredImage,
    title: experience?.title || '',
    amenities: experience?.amenities || [],
    address: experience?.address || '',
    lat: experience?.lat || 0,
    lng: experience?.lng || 0,
    terms: experience?.terms || '',
  };
};
