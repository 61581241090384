import { FC } from 'react';

import { LuRefrigerator } from 'react-icons/lu';
import {
  MdAcUnit,
  MdAccessAlarm,
  MdOutlineIron,
  MdOutlineTableRestaurant,
  MdOutlineMicrowave,
  MdOutlineRadio,
  MdCoffeeMaker,
  MdTv,
  MdWifi,
  MdBathtub,
  MdKitchen,
  MdShower,
  MdOutdoorGrill,
} from 'react-icons/md';

import { BiBed } from 'react-icons/bi';
import { GiBed, GiGunStock } from 'react-icons/gi';

import { FieldArray, useFormikContext } from 'formik';

import { Room } from 'types/room';
import { IconType } from 'react-icons/lib';
import { Grid, IconButton, Text, Tooltip } from '@chakra-ui/react';

const ICONS = [
  'Wifi',
  'Barbeque',
  'Bathtub',
  'Refrigerator',
  'TV',
  'Shower',
  'Air Conditioned',
  'Microwave',
  'Personal Refrigerator',
  'Hairdryer',
  'Iron',
  'Ironing Board',
  'Alarm Clock',
  'Coffeemaker',
  'Radio',
];

const ICONS_MAP: Record<string, IconType> = {
  Wifi: MdWifi,
  Barbeque: MdOutdoorGrill,
  Bathtub: MdBathtub,
  Shower: MdShower,
  Refrigerator: MdKitchen,
  TV: MdTv,
  'Air Conditioned': MdAcUnit,
  'Alarm Clock': MdAccessAlarm,
  Coffeemaker: MdCoffeeMaker,
  Hairdryer: GiGunStock,
  Iron: MdOutlineIron,
  'Ironing Board': MdOutlineTableRestaurant,
  Microwave: MdOutlineMicrowave,
  'Personal Refrigerator': LuRefrigerator,
  Radio: MdOutlineRadio,
};

export const Amenities: FC = () => {
  const { values, errors } = useFormikContext<Room>();

  const error = errors?.amenities;
  const amenities = values.amenities;

  return (
    <>
      <FieldArray name="amenities">
        {({ remove, push }) => (
          <Grid
            mt="20px"
            templateColumns="1fr 1fr 1fr 1fr 1fr"
            gap={3}
            maxW="350px">
            {ICONS.map((item) => {
              const Icon = ICONS_MAP[item];
              const isPresent = amenities.includes(item);
              return (
                <Tooltip label={item}>
                  <IconButton
                    w="50px"
                    h="50px"
                    isRound
                    key={item}
                    colorScheme="secondary"
                    aria-label={item}
                    icon={<Icon size="24px" />}
                    opacity={isPresent ? 0.5 : 1}
                    onClick={() => {
                      if (isPresent) {
                        const _index = amenities.indexOf(item);
                        remove(_index);
                      } else {
                        push(item);
                      }
                    }}
                  />
                </Tooltip>
              );
            })}
          </Grid>
        )}
      </FieldArray>
      {error && (
        <Text mt="10px" fontSize="14px" color="red.500">
          {error}
        </Text>
      )}
    </>
  );
};
