import { FC, useMemo } from 'react';

import { $session } from 'stores';

import AuthRoutes from './auth';
import MainRoutes from './main';

const RootRoutes: FC = () => {
  const isAuth = $session.selectors.useIsAuth();

  const Routes = useMemo(() => (isAuth ? MainRoutes : AuthRoutes), [isAuth]);

  return <Routes />;
};

export default RootRoutes;
