import React, { FC } from 'react';

import { MdAddCircleOutline } from 'react-icons/md';
import { Button, Center, Spinner, Text } from '@chakra-ui/react';

import { useRooms } from 'queries';

import { useHistory, useLocation } from 'react-router-dom';

import { getPhotoUrl } from 'utils';
import { routesSettings } from 'constants/routes';

import {
  Row,
  Label,
  Title,
  Price,
  Column,
  SubTitle,
  RoomCard,
  RoomImage,
  Container,
  AddRoomBg,
} from './CreatePlaces.style';
import RoomOptions from '../RoomOptions';

export const Rooms: FC<{ experienceId: string }> = ({ experienceId }) => {
  const { push } = useHistory();
  const { pathname } = useLocation();

  const { data, isLoading, isFetching } = useRooms(experienceId);

  const isDetailsPage = pathname.includes('/details/');

  return (
    <Container>
      {isLoading || isFetching ? (
        <Center h="400px">
          <Spinner size="lg" />
        </Center>
      ) : (
        <>
          <Label>Available Rooms</Label>
          <Row>
            <RoomCard>
              <AddRoomBg>
                <MdAddCircleOutline size="50px" />
              </AddRoomBg>
              <Column>
                <Column>
                  <Title>Room</Title>
                </Column>
                <Button
                  onClick={() => {
                    if (isDetailsPage) {
                      push({
                        pathname: routesSettings.MAIN_CREATE_ROOM.path,
                        state: {
                          experienceId,
                          redirect: pathname,
                        },
                      });

                      return;
                    }

                    push({
                      pathname: routesSettings.MAIN_CREATE_ROOM.path,
                      state: {
                        index: 1,
                        experienceId,
                        filled: {
                          0: true,
                          1: true,
                          2: false,
                        },
                      },
                    });
                  }}>
                  Create room
                </Button>
              </Column>
            </RoomCard>
            {data?.map((room) => (
              <RoomCard key={room?._id}>
                {isDetailsPage && <RoomOptions room={room} />}
                <RoomImage src={getPhotoUrl(room?.featuredImage)} />
                <Column>
                  <Column gap="0">
                    <Title>{room?.name}</Title>
                    {room.features?.map((feat: any) => (
                      <SubTitle key={feat}>{feat}</SubTitle>
                    ))}
                  </Column>
                  <SubTitle>
                    <Price>${room?.price}/</Price>night
                  </SubTitle>
                </Column>
              </RoomCard>
            ))}
          </Row>
        </>
      )}
    </Container>
  );
};
