import React, { FC } from 'react';

import { useToast } from '@chakra-ui/react';

import { useHistory } from 'react-router-dom';

import { deleteRoom } from 'api/rooms';
import { useMutation, useQueryClient } from 'react-query';

import { RoomOptionsProps } from './RoomOptions.props';

import RoomOptionsView from './RoomOptions.view';

const RoomOptionsContainer: FC<RoomOptionsProps> = ({ room }) => {
  const toast = useToast();
  const history = useHistory();
  const queryClient = useQueryClient();

  const experienceId =
    typeof room?.experience !== 'string' ? room?.experience?._id : '';

  const { mutate, isLoading } = useMutation(() => deleteRoom(room?._id || ''), {
    onSuccess: () => {
      toast({
        status: 'success',
        title: 'Room Deleted',
        isClosable: true,
        duration: 3000,
      });

      queryClient.invalidateQueries({
        queryKey: ['fetch-experience', experienceId],
      });

      queryClient.invalidateQueries({
        queryKey: ['fetch-rooms', experienceId],
      });
    },
  });

  const onEdit = () => {
    history.push(`/room/edit/${room?._id}`, room);
  };

  return (
    <RoomOptionsView onDelete={mutate} isDeleting={isLoading} onEdit={onEdit} />
  );
};

export default RoomOptionsContainer;
