import React, { memo, forwardRef, useImperativeHandle } from 'react';

import { MdCheckCircleOutline } from 'react-icons/md';
import { Button, Flex, Text, useDisclosure } from '@chakra-ui/react';

import { CommonModal } from '../CommonModal';

import { RefType, SuccessModalProps } from './SuccessModal.props';

const SuccessModalView = forwardRef<RefType, SuccessModalProps>(
  (props, ref) => {
    const { isOpen, onOpen, onClose } = useDisclosure();

    useImperativeHandle(ref, () => ({ onOpen }), []);

    return (
      <CommonModal
        isOpen={isOpen}
        onClose={() => {
          onClose();
          props.onCancel();
        }}>
        <Flex direction="column" gap="20px" align="center" p="20px">
          <MdCheckCircleOutline size="100px" color="#EE8764" />
          <Text fontSize="22px">Experience Created!</Text>

          <Flex gap="20px" align="center">
            <Button
              variant="outline"
              onClick={() => {
                onClose();
                props.onCancel();
              }}>
              GO BACK
            </Button>
            <Button w="auto" onClick={props.onComplete}>
              GO TO DETAILS PAGE
            </Button>
          </Flex>
        </Flex>
      </CommonModal>
    );
  },
);

const SuccessModal = memo(SuccessModalView);

export default SuccessModal;
