import React, { FC } from 'react';

import { TitleIconWithDataProps } from './TitleIconWithData.props';
import {
  Container,
  SubTitle,
  Title,
} from 'routes/main/ManagementDetails/ManagementDetails.style';
import { Box, Flex, Icon } from '@chakra-ui/react';

const TitleIconWithDataView: FC<TitleIconWithDataProps> = ({
  title,
  icon,
  data,
}) => {
  return (
    <Container>
      <SubTitle>{title ?? ''}</SubTitle>
      <Box h={1} />
      <Flex>
        <Icon color="red" fontSize={22}>
          {icon}
        </Icon>
        <Box w={2} />
        <SubTitle opacity={1} fontWeight={600}>
          {data}
        </SubTitle>
      </Flex>
    </Container>
  );
};

export default TitleIconWithDataView;
