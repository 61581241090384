import React, { FC } from 'react';

import { Button, Flex, Stack, Text } from '@chakra-ui/react';

import { SimpleProgress } from 'components/primitives/SimpleProgress';

import { ExperienceFormFooterProps } from './ExperienceFormFooter.props';

const ExperienceFormFooterView: FC<ExperienceFormFooterProps> = ({
  onSubmit,
  onCancel,
  progress,
  isLoading,
  isDisabled,
  buttonText = 'Next',
}) => {
  return (
    <Flex
      px="20px"
      py="20px"
      left="0"
      bottom="0"
      right="0"
      position="fixed"
      align="center"
      flexWrap="wrap"
      zIndex={3}
      bg="#fff"
      gap="10px"
      boxShadow="0px 0px 10px 0px rgba(0,0,0,0.1)">
      <Flex flex={1} align="center" gap="20px">
        <SimpleProgress
          value={progress}
          width="800px"
          height="15px"
          colorScheme="secondary"
        />
        <Text fontWeight="bold" color="secondary.500">
          {progress}%
        </Text>
      </Flex>
      <Stack mx="20px" isInline spacing={4}>
        <Button variant="outline" onClick={onCancel}>
          Cancel
        </Button>
        <Button
          onClick={onSubmit}
          isLoading={isLoading}
          isDisabled={isDisabled}>
          {buttonText}
        </Button>
      </Stack>
    </Flex>
  );
};

export default ExperienceFormFooterView;
