import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

import { Tokens } from 'types/auth';

type State = {
  isAuth: boolean;
  id: string | null;
  tokens: null | Tokens;
  username: string | null;
  globalError?: string | null;
};

const STORAGE_KEY = 'ULTISELL-SESSION';

const useSessionStore = create(
  persist(
    immer<State>(() => ({
      id: null,
      tokens: null,
      isAuth: false,
      username: null,
    })),
    {
      name: STORAGE_KEY,
    },
  ),
);

const selectors = {
  useUserId: () => useSessionStore((state) => state.id),
  useIsAuth: () => useSessionStore((state) => state.isAuth),
  useUsername: () => useSessionStore((state) => state.username),
  useGlobalError: () => useSessionStore((state) => state.globalError),
};

const actions = {
  setSession: ({
    id,
    tokens,
    username,
  }: {
    id: string;
    tokens: Tokens;
    username: string;
  }) => {
    const set = useSessionStore.setState;

    set((state) => {
      state.id = id;
      state.tokens = tokens;
      state.username = username;

      state.isAuth = true;
    });
  },

  setGlobalError: (error: string | null) => {
    const set = useSessionStore.setState;
    set((state) => {
      state.globalError = error;
    });
    //clear global start after 3 seconds
    setTimeout(() => {
      set((state) => {
        state.globalError = null;
      });
    }, 3000);
  },
  clearSession: () => {
    const set = useSessionStore.setState;

    set((state) => {
      state.isAuth = false;

      state.id = null;
      state.tokens = null;
      state.username = null;
    });
  },

  // getters
  getTokens: () => useSessionStore.getState().tokens,
  getUsername: () => useSessionStore.getState().username,
};

/**
 * **Session Store**
 *
 * this store is used to store session tokens
 *
 * **"$"** is a convention to indicate that this is a store
 */
const $session = {
  actions,
  selectors,
};

export default $session;
