import React, { useCallback } from 'react';

import { useToast } from '@chakra-ui/react';

import { useMutation, useQueryClient } from 'react-query';
import {
  updateExperience,
  updateExperienceImages,
  uploadImages,
} from 'api/experience';

import { EditExperienceProps } from './EditExperience.props';

import EditExperienceView from './EditExperience.view';
import sanitizeHtml from 'sanitize-html';
import { initialValues } from 'components/modules/CreateActivity/CreateActivity.utils';

const EditExperienceContainer = (props: EditExperienceProps) => {
  const experience = props.location.state;
  const experienceId = props.match.params.id;

  const toast = useToast();
  const queryClient = useQueryClient();

  const beforeDeleteCallback = useCallback(
    async (photos: string[], featuredImage: string) => {
      const otherImages = photos.filter(
        (e: any) => typeof e !== 'string' && e.name !== featuredImage,
      );

      await updateExperienceImages({
        _id: experienceId,
        photos: otherImages,
      });
    },
    [],
  );

  const { mutate, isLoading } = useMutation(
    async (payload: typeof initialValues) => {
      const featureImageName = payload.featuredImage;

      let photos: string[] = payload?.photos?.filter(
        (p: any) => typeof p === 'string' && p !== featureImageName,
      );

      const featuredImage = payload.photos.filter(
        (e: any) => typeof e !== 'string' && e.name === featureImageName,
      );

      const otherImages = payload.photos.filter(
        (e: any) => typeof e !== 'string' && e.name !== featureImageName,
      );

      if (otherImages?.length > 0) {
        const uploadedFiles = await uploadImages(otherImages);
        photos = [...photos, ...uploadedFiles];
      }

      if (featuredImage.length > 0) {
        const uploadedFeaturedImage = await uploadImages(featuredImage);
        payload.featuredImage = uploadedFeaturedImage[0];
      }

      if (payload.description) {
        payload.description = sanitizeHtml(payload.description);
      }

      return updateExperience(
        {
          ...payload,
          photos,
          lat: Number(payload?.lat || '0'),
          lng: Number(payload?.lng || '0'),
          price: Number(payload?.price || '0'),
          featuredImage: payload.featuredImage,
          terms: String(payload?.terms) || 'No Terms And Condition',
        },
        experienceId,
      );
    },
    {
      onSuccess: () => {
        toast({
          status: 'success',
          title: 'Experience Updated',
          duration: 3000,
          isClosable: true,
        });
        queryClient.invalidateQueries({
          queryKey: ['fetch-experience', experienceId],
        });
        queryClient.invalidateQueries({
          queryKey: [`fetch-experiences`],
        });
        props.history.goBack();
      },
    },
  );

  return (
    <EditExperienceView
      onSubmit={mutate}
      isLoading={isLoading}
      experience={experience}
      beforeDeleteCallback={beforeDeleteCallback}
    />
  );
};

export default EditExperienceContainer;
