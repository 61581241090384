import { chakra, Flex, Text } from '@chakra-ui/react';

import { chakraStyled } from 'utils';

export const Container = chakraStyled(Flex, {
  w: '100%',
  direction: 'column',
});

export const Title = chakraStyled(Text, {
  fontSize: '22px',
  fontWeight: 'bold',
  color: 'primary.500',
});

export const SubTitle = chakraStyled(Text, {
  fontSize: '16px',
  color: 'primary.500',
});

export const Span = chakraStyled(chakra.span, {
  color: '#fff',
  fontSize: '22px',
  fontWeight: 'bold',
  textAlign: 'center',
});

export const Row = chakraStyled(Flex, {
  w: '100%',
  direction: 'row',
  flexWrap: 'wrap',
  gap: '20px',
  p: '25px',
  pt: '10px',
});
