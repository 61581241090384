import React, { FC, useMemo } from 'react';

import { Image, Text } from '@chakra-ui/react';

import { useFormikContext } from 'formik';

import { Template } from 'types/style';
import { TemplateLocationProps } from './TemplateLocation.props';

import { HeroBox, HeadingText, MapBox } from './TemplateLocation.style';
import { Description, Section, Title } from 'styles/template.style';

const apiKey = process.env.REACT_APP_GOOGLE_PLACES_API_KEY;

const TemplateLocationView: FC<TemplateLocationProps> = (props) => {
  const { values } = useFormikContext<Template>();

  const location = values.location;

  const src = useMemo(() => {
    const box = document.getElementById('map-box');

    const lat = location?.value?.lat || 0;
    const lng = location?.value?.lng || 0;

    return `https://maps.googleapis.com/maps/api/staticmap?center=${lat},${lng}&zoom=12&size=1200x400&key=${apiKey}&markers=color:red%7C${lat},${lng}`;
  }, [location?.value?.lat, location?.value?.lng]);

  if (location.enabled === false) {
    return null;
  }

  return (
    <Section
      alignItems="center"
      justifyContent="center"
      color={values.colors.primaryColor ?? '#000'}
      backgroundColor={values.colors.backgroundColor}>
      <Title>Location</Title>
      <Description align="center">
        {location?.value?.address || 'Address goes here'}
      </Description>
      {location?.showMap ? (
        <MapBox borderRadius="10px" overflow="hidden">
          <Image
            src={src}
            w="100%"
            h="100%"
            objectFit="cover"
            bgPosition="center"
            userSelect="none"
            draggable={false}
          />
        </MapBox>
      ) : null}
    </Section>
  );
};

export default TemplateLocationView;
