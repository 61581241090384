import React, { useEffect, useState } from 'react';

import { Button, Center } from '@chakra-ui/react';
import { CircularProgress } from '@chakra-ui/progress';

import { CustomTableProps } from './CustomTable.props';
import {
  Row,
  Page,
  Check,
  HeaderRow,
  ItemRow,
  ItemsContainer,
  MainContainer,
  TableContainer,
  PaginationContainer,
  TableOverflowContainer,
} from './CustomTable.style';

const LIMITS_PER_PAGE = 10;

const CustomTableView = <T extends object>(props: CustomTableProps<T>) => {
  const [checked, setChecked] = useState<string[]>([]);

  const {
    data,
    mt = 2,
    isLoading,
    maxH = '60vh',
    minW = '1200px',
    pagination,
    onChecked,
    isSelectable = true,
    children: { renderRow, renderEmpty = () => null, headerRow },
  } = props;

  const hasChanged = data.toString();

  useEffect(() => {
    setChecked([]);
  }, [hasChanged]);

  const isChecked =
    data?.length > 0
      ? data?.map((j: any) => j?._id)?.every((i) => checked.includes(i))
      : false;

  const onCheckChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!data) {
      return;
    }

    if (data.length === 0) {
      return;
    }

    if (e.target.checked) {
      setChecked(data.map((i: any) => i._id as string));
    } else {
      setChecked([]);
    }
  };

  useEffect(() => {
    if (onChecked) {
      onChecked(checked);
    }
  }, [checked, onChecked]);

  return (
    <MainContainer mt={mt}>
      <TableContainer>
        <TableOverflowContainer minW={minW}>
          <HeaderRow>
            {isSelectable && (
              <Check isChecked={isChecked} onChange={onCheckChange} />
            )}
            {headerRow}
          </HeaderRow>
          <ItemsContainer maxH={maxH}>
            {isLoading ? (
              <Center minH="50vh">
                <CircularProgress isIndeterminate color="primary.400" />
              </Center>
            ) : data.length > 0 ? (
              data.map((item: any, index) => {
                return (
                  <ItemRow key={`${JSON.stringify(item?._id)}-${index}`}>
                    {isSelectable && (
                      <Check
                        isChecked={checked.includes(item?._id)}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setChecked((prev) => [...prev, item?._id]);
                          } else {
                            setChecked((prev) => [
                              ...prev.filter((i) => i !== item?._id),
                            ]);
                          }
                        }}
                      />
                    )}
                    {renderRow(item)}
                  </ItemRow>
                );
              })
            ) : (
              renderEmpty?.()
            )}
          </ItemsContainer>
        </TableOverflowContainer>
      </TableContainer>
      {pagination && pagination.totalCount > LIMITS_PER_PAGE && (
        <PaginationContainer>
          <Row>
            Showing{' '}
            {pagination.page * LIMITS_PER_PAGE > pagination.totalCount
              ? pagination.totalCount
              : pagination.page * LIMITS_PER_PAGE}{' '}
            of {pagination?.totalCount} data
          </Row>
          <Button
            rounded="full"
            variant="outline"
            colorScheme="secondary"
            isDisabled={pagination.page === 1}
            onClick={() => {
              pagination.setPage((old: number) => (data ? old - 1 : old));
            }}>
            Previous
          </Button>
          <Page>{pagination.page}</Page>
          <Button
            rounded="full"
            variant="outline"
            colorScheme="secondary"
            isDisabled={
              data.length > 0 ? pagination.totalPages <= pagination.page : true
            }
            onClick={() =>
              pagination.setPage((old: number) => (data ? old + 1 : old))
            }>
            Next
          </Button>
        </PaginationContainer>
      )}
    </MainContainer>
  );
};

export default CustomTableView;
