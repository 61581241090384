import React, { FC, ReactNode, useState } from 'react';
import { useField, useFormikContext } from 'formik';
import {
  FormControl,
  Menu,
  MenuButton,
  Button,
  MenuList,
  MenuItem,
  Text,
  FormLabel,
  Icon,
} from '@chakra-ui/react';
import { MdArrowDropDown } from 'react-icons/md';
import { SelectMenuProps } from './SelectMenu.props';
import { Option, Props } from './SelectMenu.utils';

const Item: FC<Option> = ({ leftIcon, value, rightIcon }) => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
      }}>
      <span style={{ marginRight: '10px' }}>{leftIcon}</span>
      {value}
      <span style={{ marginLeft: '10px' }}>{rightIcon}</span>
    </div>
  );
};

const SelectMenuView: FC<Props> & { Item: FC<Option> } = ({
  children,
  placeHolder,
  name,
  label,
  hideError,
  labelProps,
  onChange,
  initialValue,
}) => {
  const { values, setFieldValue } = useFormikContext<any>();
  const [field, meta, helpers] = useField(name);

  const generateInitialData = () => {
    return (
      <Item value={initialValue?.value!} leftIcon={initialValue?.leftIcon} />
    );
  };

  const [selectedChild, setSelectedChild] = useState<any>(
    initialValue ? generateInitialData() : null,
  );

  return (
    <FormControl mt={3}>
      {label && (
        <FormLabel htmlFor={name} {...labelProps} textTransform="capitalize">
          {label}
        </FormLabel>
      )}

      <Menu>
        {({ isOpen }) => (
          <>
            <MenuButton
              style={{
                width: '100%',
                backgroundColor: 'transparent',
                border: '2px solid #e2e8f0',
                color: '#000000aa',
              }}
              rightIcon={<MdArrowDropDown />}
              isActive={isOpen}
              as={Button}>
              {values[name] ? selectedChild : placeHolder ?? 'Select Option'}
            </MenuButton>
            <MenuList>
              {React.Children.map(children, (child, index) => {
                return (
                  <MenuItem
                    onClick={() => {
                      setSelectedChild(child);
                      onChange(index, setFieldValue);
                    }}>
                    {child}
                  </MenuItem>
                );
              })}
            </MenuList>
          </>
        )}
      </Menu>
      {meta.error && !hideError && (
        <Text color="red.500" fontSize="sm">
          {(meta.touched && meta.error) || meta.error}
        </Text>
      )}
    </FormControl>
  );
};

SelectMenuView.Item = Item;

export default SelectMenuView;
