import { IconProps } from './type';

const BunkBed = (props: IconProps) => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.67162 22V3.83581C3.67162 3.3742 3.29741 3 2.83581 3C2.3742 3 2 3.3742 2 3.83581V25.9847C2 26.2155 2.1871 26.4026 2.4179 26.4026H3.25371C3.48452 26.4026 3.67162 26.2155 3.67162 25.9847V24H19.9688V25.9848C19.9688 26.2156 20.1559 26.4027 20.3867 26.4027H21.2225C21.4533 26.4027 21.6404 26.2156 21.6404 25.9848V24H25.4023V25.9849C25.4023 26.2157 25.5894 26.4028 25.8202 26.4028H26.6561C26.8869 26.4028 27.074 26.2157 27.074 25.9849V3.836C27.074 3.3744 26.6998 3.0002 26.2381 3.0002C25.7765 3.0002 25.4023 3.3744 25.4023 3.836V6.76142L19.9688 6.76123V10L4 10L4 12L19.9688 12V22H3.67162ZM25.4023 22V19H21.6404V22H25.4023ZM25.4023 14V17H21.6404V14H25.4023ZM25.4023 12V8.01514L21.6404 8.01514V12H25.4023Z"
        stroke="#353535"
        stroke-miterlimit="10"
      />
      <path
        d="M3.67187 18.4624L3.67188 21.8057H19.9701V18.4624L3.67187 18.4624Z"
        stroke="#353535"
        stroke-miterlimit="10"
      />
      <path
        d="M3.67187 6.76126L3.67188 10.1045L19.9701 10.1045V6.76126L3.67187 6.76126Z"
        stroke="#353535"
        stroke-miterlimit="10"
      />
      <path
        d="M9.94057 4.25391H4.92572C4.69491 4.25391 4.50781 4.44101 4.50781 4.67181V6.34343C4.50781 6.57423 4.69491 6.76133 4.92572 6.76133H9.94057C10.1714 6.76133 10.3585 6.57423 10.3585 6.34343V4.67181C10.3585 4.44101 10.1714 4.25391 9.94057 4.25391Z"
        stroke="#353535"
        stroke-miterlimit="10"
      />
      <path
        d="M9.94057 15.9556H4.92572C4.69491 15.9556 4.50781 16.1427 4.50781 16.3735V18.0451C4.50781 18.2759 4.69491 18.463 4.92572 18.463H9.94057C10.1714 18.463 10.3585 18.2759 10.3585 18.0451V16.3735C10.3585 16.1427 10.1714 15.9556 9.94057 15.9556Z"
        stroke="#353535"
        stroke-miterlimit="10"
      />
    </svg>
  );
};

export default BunkBed;
