import React, { FC } from 'react';

import { Flex, Grid, GridItem } from '@chakra-ui/react';
import { Section, SubTitle } from './Footer.style';
import { Logo, Title, LinkItem } from '../Footer/Footer.style';
import { useHistory } from 'react-router';

import {
  MdOutlineLocationOn,
  MdOutlinePhone,
  MdOutlineEmail,
} from 'react-icons/md';
import { useFooter } from 'contexts/footerContext';
import { scrollIntoViewById } from 'utils/common';

const FooterView: FC = () => {
  const { push } = useHistory();

  const { isVisible } = useFooter();

  if (!isVisible) return <></>;

  const navigate = (route: string) => push(route);

  const date = new Date();
  const currentYear = date.getFullYear();

  return (
    <Section direction={'column'} alignItems="left">
      <Grid
        w="100%"
        gap="20px"
        justifyContent="end"
        paddingY={{
          base: '20px',
          md: '0px',
        }}
        alignItems="start"
        templateColumns={{
          base: 'repeat(1, 1fr)',
          md: 'repeat(6, 1fr)',
        }}>
        <GridItem colSpan={4}>
          <Flex direction={'column'} gap="20px">
            <Flex alignItems="end" gap="10px">
              <Logo onClick={() => push('/')} />
              <Title>Ultisell</Title>
            </Flex>
            <Flex alignItems="center" gap="10px">
              <MdOutlineLocationOn size={24} color="white" />
              <SubTitle>St. Paul MN 55102</SubTitle>
            </Flex>
            <Flex alignItems="center" gap="10px">
              <MdOutlinePhone size={24} color="white" />
              <SubTitle>(612) 234-5361‬</SubTitle>
            </Flex>
            <Flex alignItems="center" gap="10px">
              <MdOutlineEmail size={24} color="white" />
              <SubTitle>
                <a href="mailto:info@ultisell.net">info@ultisell.net</a>
              </SubTitle>
            </Flex>
          </Flex>
        </GridItem>
        <GridItem>
          <Flex direction={'column'} gap="15px">
            <Title fontSize={{ base: '14px', md: '16px' }} fontWeight="600">
              About
            </Title>
            <LinkItem onClick={() => navigate('coming-soon')}>
              Help Center
            </LinkItem>
            <LinkItem href="mailto:info@ultisell.net">Contact Us</LinkItem>
            {/* <LinkItem>Careers</LinkItem> */}
            <LinkItem onClick={() => scrollIntoViewById('about-section')}>
              About us
            </LinkItem>
          </Flex>
        </GridItem>

        <GridItem>
          <Flex direction={'column'} gap="15px">
            <Title fontSize={{ base: '14px', md: '16px' }} fontWeight="600">
              Support
            </Title>
            <LinkItem onClick={() => navigate('privacy-policy')}>
              Privacy Policy
            </LinkItem>
            <LinkItem
              href={
                'https://app.termly.io/document/terms-of-service/1549951d-00dd-48fe-8e9f-8a4230c8f5f6'
              }
              target={'_blank'}>
              Terms of Service
            </LinkItem>
          </Flex>
        </GridItem>
      </Grid>

      <SubTitle textAlign="left" mt="20px">
        @{currentYear} Aasen Enterprises LLC. All Rights Reserved
      </SubTitle>
    </Section>
  );
};

export default FooterView;
