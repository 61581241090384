import React, { FC } from 'react';

import { Flex, Text } from '@chakra-ui/react';

import { Logo, StripeButton } from '../StripeConnect/StripeConnect.style';

import { StripeConnectButtonViewProps } from './StripeConnectButton.props';

const StripeConnectButtonView: FC<StripeConnectButtonViewProps> = ({
  sx,
  isLoading,
  onConnectClick,
  isAccountEnabled,
  isDetailsSubmitted,
}) => {
  const moreProps = isAccountEnabled
    ? {
        bg: 'green.500',
        _hover: {
          bg: 'green.600',
        },
        _active: {
          bg: 'green.800',
        },
        rightIcon: (
          <Flex gap="6px" align="center">
            <Logo />
            <Text>Dashboard</Text>
          </Flex>
        ),
      }
    : isDetailsSubmitted
      ? {
          w: '220px',
          bg: 'yellow.500',
          _hover: {
            bg: 'yellow.600',
          },
          _active: {
            bg: 'yellow.800',
          },
          rightIcon: (
            <Flex gap="6px" align="center">
              <Logo />
              <Text>Information</Text>
            </Flex>
          ),
        }
      : {
          rightIcon: <Logo />,
        };

  return (
    <StripeButton
      sx={sx}
      isLoading={isLoading}
      onClick={onConnectClick}
      {...moreProps}>
      {isAccountEnabled ? 'Go to' : isDetailsSubmitted ? 'Update' : 'Connect'}
    </StripeButton>
  );
};

export default StripeConnectButtonView;
