import {
  Box,
  Button,
  Flex,
  IconButton,
  Image,
  Progress,
  Text,
} from '@chakra-ui/react';
import { MdAddCircleOutline, MdDelete } from 'react-icons/md';

import { chakraStyled } from 'utils';

export const OverflowContainer = chakraStyled(Flex, {
  maxH: '65vh',
  //   maxW: '100vw',
  //   overflowY: 'auto',
});

export const Container = chakraStyled(Flex, {
  flex: 1,
  width: '100%',
  //   p: '10px 20px',
  direction: 'column',
  gap: '10px',
  minH: {
    base: '220vh',
    sm: '200vh',
    md: '100vh',
  },
});

export const Label = chakraStyled(Text, {
  fontSize: '16px',
  textAlign: 'left',
});

export const Row = chakraStyled(Flex, {
  w: '100%',
  direction: 'row',
  justify: 'flex-start',
  align: 'flex-start',
  gap: '25px',
  flexWrap: 'wrap',
});

export const Column = chakraStyled(Flex, {
  gap: '10px',
  direction: 'column',
  flex: 1,
  minW: '300px',
});

export const AssetsContainer = chakraStyled(Flex, {
  w: '100%',
  direction: 'row',
  justify: 'flex-start',
  align: 'center',
  gap: '15px',
  flexWrap: 'wrap',
  p: '10px',
  borderRadius: '5px',
  boxShadow: '0px 0px 5px rgba(0,0,0,0.1)',
});

export const Asset = chakraStyled(Image, {
  h: '200px',
  w: '240px',
  borderRadius: '10px',
  overflow: 'hidden',
  objectFit: 'cover',
  bg: '#f1f1f1',
  fallbackSrc: 'https://via.placeholder.com/240x200?text=x',
});

export const PickerContainer = chakraStyled(Flex, {
  h: '200px',
  w: '240px',
  borderRadius: '10px',
  border: '2px solid',
  bg: 'secondary.100',
  borderColor: 'secondary.500',
  cursor: 'pointer',
  direction: 'column',
  align: 'center',
  justify: 'center',
  color: 'secondary.500',
  fontSize: '20px',
});

export const ProgressContainer = chakraStyled(Flex, {
  align: 'center',
  w: '100%',
  gap: '10px',
  pr: '10px',
});

export const ChakraProgress = chakraStyled(Progress, {
  size: 'sm',
  zIndex: 1,
  borderRadius: '20px',
  colorScheme: 'primary',
  hasStripe: true,
  w: '100%',
});

export const Percent = chakraStyled(Text, {
  fontSize: '12px',
});

export const AmenitiesContainer = chakraStyled(Flex, {
  my: '10px',
  p: '10px',
  flex: 1,
  direction: 'column',
  maxH: '60vh',
  overflowY: 'auto',
});

export const AmenityItem = chakraStyled(Flex, {
  align: 'center',
  gap: '15px',
  mb: '5px',
});

export const RemoveButton = chakraStyled(IconButton, {
  w: '20px',
  size: 'sm',
  isRound: true,
  variant: 'ghost',
  icon: MdDelete({ size: '20px' }),
  colorScheme: 'red',
  'aria-label': 'delete-amenity',
});

export const AddAmenityButton = chakraStyled(Button, {
  mt: '10px',
  variant: 'ghost',
  colorScheme: 'secondary',
  leftIcon: MdAddCircleOutline({ size: '20px' }),
});
