import React, { FC } from 'react';

import {
  Link,
  Stack,
  Drawer,
  IconButton,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  useDisclosure,
  useMediaQuery,
  Flex,
} from '@chakra-ui/react';

import { MdMenu } from 'react-icons/md';

import { useHistory, useLocation } from 'react-router-dom';

import { NavBarProps } from './NavBar.props';

import {
  Row,
  Logo,
  Title,
  Container,
  NavSolidButton,
  NavBorderButton,
  Hug,
  NavButton,
} from './NavBar.style';
import { useNavbar } from 'contexts/navbarContext';
import { scrollIntoViewById } from 'utils/common';

const NAV_ITEMS = [
  {
    title: 'Home',
  },
  {
    title: 'About Us',
    onClick: () => {
      scrollIntoViewById('about-section');
    },
  },
  {
    title: 'How it Works?',
    onClick: () => {
      scrollIntoViewById('how-it-works-section');
    },
  },
  {
    title: 'Benefits',
    onClick: () => {
      scrollIntoViewById('host-benifits-section');
    },
  },
];

const NavBarView: FC<NavBarProps> = () => {
  const { push } = useHistory();
  const { pathname } = useLocation();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [isMobile] = useMediaQuery('(max-width: 1000px)');

  const { isVisible } = useNavbar();

  if (!isVisible) return <></>;

  return (
    <Container>
      <Row>
        <Logo onClick={() => push('/')} />
        <Title onClick={() => push('/')}>UltiSell</Title>

        {!isMobile && (
          <Flex marginLeft="50px">
            {pathname === '/' &&
              NAV_ITEMS.map((item) => (
                <Hug>
                  <Link
                    display={isMobile ? 'none' : 'block'}
                    color="#fff"
                    onClick={() => item.onClick && item.onClick()}
                    fontSize="16px"
                    key={item.title}>
                    {item.title}
                  </Link>
                </Hug>
              ))}
          </Flex>
        )}
      </Row>
      {/* {!isMobile && (
        <Row>
          <NavBorderButton onClick={() => push('/register')}>
            Register
          </NavBorderButton>
          <NavSolidButton onClick={() => push('/login')}>Login</NavSolidButton>
        </Row>
      )} */}

      {isMobile && (
        <IconButton
          w="20px"
          aria-label="ham-menu"
          icon={<MdMenu />}
          onClick={onOpen}
        />
      )}
      {isMobile && (
        <Drawer placement="right" onClose={onClose} isOpen={isOpen}>
          <DrawerOverlay />
          <DrawerContent>
            <DrawerBody>
              <Stack h="100%" justify="space-between">
                <Stack>
                  <Title mb="50px" color="primary.500">
                    UltiSell
                  </Title>
                  {NAV_ITEMS.map((item) => (
                    <Link key={item.title}>{item.title}</Link>
                  ))}
                </Stack>

                {/* <Stack pb="20px" isInline>
                  <NavButton
                    color="primary.500"
                    onClick={() => {
                      onClose();
                      push('/register');
                    }}>
                    Register
                  </NavButton>
                  <NavButton
                    color="primary.500"
                    onClick={() => {
                      onClose();
                      push('/login');
                    }}>
                    Login
                  </NavButton>
                </Stack> */}
              </Stack>
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      )}
    </Container>
  );
};

export default NavBarView;
