import React from 'react';

import { useToast } from '@chakra-ui/react';
import { useMutation, useQueryClient } from 'react-query';
import { ExperienceDetailsProps } from './ExperienceDetails.props';

import ExperienceDetailsView from './ExperienceDetails.view';
import useExperience from 'queries/useExperience';
import { useProducts, useRooms } from 'queries';
import { deleteExperienceById } from 'api/experience';

const ExperienceDetailsContainer = (props: ExperienceDetailsProps) => {
  const queryClient = useQueryClient();
  const experienceId = props.match.params.id;
  const { data, isLoading, isFetching } = useExperience(experienceId);
  const rooms = useRooms(experienceId);
  const products = useProducts(experienceId);

  const toast = useToast();
  const { mutate, isLoading: isDeleting } = useMutation(deleteExperienceById, {
    onSuccess: () => {
      toast({
        title: 'Experience deleted successfully',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
      queryClient.invalidateQueries({
        queryKey: [`fetch-experiences`],
      });
      props.history.goBack();
    },
  });

  return (
    <ExperienceDetailsView
      experience={data}
      isLoading={isLoading || isFetching}
      roomsList={rooms.data}
      productsList={products.data}
      onDeleteClick={mutate}
      isDeleting={isDeleting}
    />
  );
};

export default ExperienceDetailsContainer;
