import React, { useEffect } from 'react';

import { useToast } from '@chakra-ui/react';

import { createProduct, uploadImages } from 'api/products';
import { useMutation, useQueryClient } from 'react-query';

import { routesSettings } from 'constants/routes';

import { Product, ProductType } from 'types/product';
import { CreateProductProps } from './CreateProduct.props';

import CreateProductView from './CreateProduct.view';
import sanitize from 'sanitize-html';

const CreateProductContainer = (props: CreateProductProps) => {
  const toast = useToast();
  const queryClinet = useQueryClient();

  const state = props.location.state;

  const { mutate, isLoading } = useMutation(
    async (payload: any) => {
      console.log(payload);

      const featureImageName = payload.featuredImage;

      const featuredImage = payload.photos.filter(
        (e: File) => e.name === featureImageName,
      );

      const otherImages = payload.photos.filter(
        (e: File) => e.name !== featureImageName,
      );

      const uploadedFeaturedImage = await uploadImages(featuredImage);

      payload.featuredImage = uploadedFeaturedImage[0];

      const uploadedFiles = await uploadImages(otherImages);

      //sanitise descrpition data
      if (payload.description) {
        payload.description = sanitize(payload.description);
      }

      return await createProduct({ ...payload, photos: uploadedFiles });
    },
    {
      onSuccess: (res) => {
        toast({
          title: 'Product created.',
          duration: 3000,
          status: 'success',
          isClosable: true,
        });
        queryClinet.invalidateQueries({
          queryKey: ['fetch-products'],
        });
        onBackClick();
      },
    },
  );

  const onBackClick = () => {
    if ('redirect' in state) {
      props.history.replace(state.redirect);

      return;
    }

    props.history.replace({
      pathname: routesSettings.MAIN_CREATE_EXPERIENCE.path,
      state,
    });
  };

  const onSubmit = (payload: Partial<Product<File>>) => {
    if (!state.experienceId) {
      return;
    }

    mutate({
      featuredImage: payload.featuredImage || '',
      description: payload?.description || '',
      name: payload?.name || '',
      photos: payload?.photos || [],
      productOptions: payload?.productOptions || [],
      type: ProductType.STANDARD,
      experience: state.experienceId,
      price: Number(payload?.price || '0'),
    });
  };

  useEffect(() => {
    const unlisten = props.history.listen((location, action) => {
      if (action === 'POP') {
        if ('redirect' in state) {
          props.history.replace(state.redirect);

          return;
        }

        props.history.replace({
          pathname: routesSettings.MAIN_CREATE_EXPERIENCE.path,
          state: location.state,
        });
      }
    });

    return () => {
      unlisten();
    };
  }, []);

  return (
    <CreateProductView
      onSubmit={onSubmit}
      isLoading={isLoading}
      onBackClick={onBackClick}
    />
  );
};

export default CreateProductContainer;
