import React, { FC, useState } from 'react';

import { Form, Formik } from 'formik';

import FormikInput from 'components/primitives/FormikInput';

import { RegisterViewProps } from './Register.props';

import { initialValues, validationSchema } from './Register.utils';

import {
  Row,
  Card,
  Title,
  Store,
  Safe,
  Cards,
  Gift,
  Span,
  Column,
  Spacer,
  CardTitle,
  Container,
  FooterText,
  SubmitButton,
  FormContainer,
  Logo,
  CoverTitle,
} from './Register.style';

import { Text } from '@chakra-ui/react';
import { $session } from 'stores';
import PassworkValidatingStatusView from 'components/forms/PassworkValidatingStatus';

const containerProps = {
  minW: { base: '300px', md: '400px' },
};

const RegisterView: FC<RegisterViewProps> = ({
  onSubmit,
  goToLogin,
  isLoading,
}) => {
  const globalError = $session.selectors.useGlobalError() ?? '';
  const [showPasswordStatus, setShowPasswordStatus] = useState<boolean>(false);

  return (
    <Container>
      <Column align="center">
        <Column maxW="500px" w="100%">
          <Row>
            <Card>
              <Store />
              <CardTitle>Set up your store and customize it</CardTitle>
            </Card>
            <Spacer />
          </Row>
          <Row>
            <Spacer />
            <Card>
              <Cards />
              <CardTitle>Set payment methods</CardTitle>
            </Card>
          </Row>
          <Row>
            <Card>
              <Safe />
              <CardTitle>Safe platform</CardTitle>
            </Card>
            <Spacer />
          </Row>
          <Row>
            <Spacer />
            <Card>
              <Gift />
              <CardTitle>Benefits for hosts and guests</CardTitle>
            </Card>
          </Row>
        </Column>
      </Column>
      <Column flex={1.5}>
        <FormContainer>
          <Row justifyContent="center" gap="20px" marginY="10px">
            <Logo />
            <CoverTitle>UltiSell</CoverTitle>
          </Row>
          <Title>Create your account</Title>
          <Formik
            onSubmit={onSubmit}
            initialValues={initialValues}
            validationSchema={validationSchema}>
            <Form>
              <FormikInput
                type="text"
                name="username"
                placeholder="Username"
                containerProps={containerProps}
              />
              <FormikInput
                type="email"
                name="email"
                placeholder="Email Address"
                containerProps={containerProps}
              />
              <FormikInput
                hideError={true}
                type="password"
                name="password"
                placeholder="Password"
                onKeyUp={() => setShowPasswordStatus(true)}
                containerProps={containerProps}
              />
              <PassworkValidatingStatusView hide={!showPasswordStatus} />
              <FormikInput
                type="password"
                name="confirmationPassword"
                placeholder="Confirm Password"
                containerProps={containerProps}
              />

              <Text mt="10px" color="red.500" fontSize="sm">
                {globalError}
              </Text>

              <SubmitButton isLoading={isLoading}>CREATE ACCOUNT</SubmitButton>
              <FooterText>
                Already have an account?{' '}
                <Span onClick={goToLogin}> Login here :)</Span>
              </FooterText>
            </Form>
          </Formik>
        </FormContainer>
      </Column>
    </Container>
  );
};

export default RegisterView;
