import React, { FC } from 'react';

import { StandardProductsProps } from './StandardProducts.props';

import StandardProductsView from './StandardProducts.view';

const StandardProductsContainer: FC<StandardProductsProps> = (props) => {
  return (
    <StandardProductsView
      experienceId={props.experienceId}
      onFinishClick={props.onFinishClick}
    />
  );
};

export default StandardProductsContainer;
