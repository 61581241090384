import api from './instance';

import { Order } from 'types/orders';
import { ApiResponse, ListResponse } from 'types/common';

export const fetchOrders = async (params: {
  search?: string;
  experience?: string | null;
  sort?: number | null;
  page: number;
  limit: number;
  checkIn?: string | null;
  checkOut?: string | null;
  noOfPersons?: number | null;
}) => {
  params.sort = -1;
  const { data } = await api.get<ListResponse<Order>>(
    '/orders/host/get-orders',
    {
      params,
    },
  );
  console.log(data);
  return data;
};

export const fetchOrderDetails = async (orderId: string) => {
  const { data } = await api.get<ApiResponse<Order>>(
    `/orders/detail/${orderId}`,
  );
  return data;
};
