import sanitizeHtml from 'sanitize-html';
import api from './instance';

import { $session } from 'stores';

import { Product, ProductImages } from 'types/product';

const URL = `${process.env.REACT_APP_API_URL}/products`;

export const fetchProducts = async (host: string, experience: string) => {
  const { data } = await api.get(`${URL}/host/list`, {
    params: {
      host,
      experience,
      limit: 100,
    },
  });

  return data?.items as Product[];
};

export const uploadImages = (
  files: File[],
  onProgress?: (progress: number) => void,
) => {
  return new Promise<string[]>((resolve, reject) => {
    const formData = new FormData();

    files.forEach((file) => {
      formData.append('images', file);
    });

    const jwtToken = $session.actions.getTokens()?.jwtToken || '';

    const url = `${URL}/host/upload-images`;

    const xhr = new XMLHttpRequest();

    xhr.open('POST', url, true);
    xhr.setRequestHeader('Authorization', `Bearer ${jwtToken}`);

    xhr.upload.onprogress = (e) => {
      if (e.lengthComputable) {
        const progress = (e.loaded / e.total) * 100;

        onProgress?.(progress);
      }
    };

    xhr.onload = () => {
      if (xhr.status === 201) {
        const response = JSON.parse(xhr.response);

        const files = response?.item as string[];

        resolve(files);
      } else {
        reject(
          new Error(
            `Request failed. Status: ${xhr.status}. Content: ${
              JSON.parse(xhr.response)?.message
            }`,
          ),
        );
      }
    };

    xhr.send(formData);
  });
};

export const createProduct = async (payload: Product<File>) => {
  const { data } = await api.post(`${URL}/host/create`, {
    ...payload,
  });

  return data;
};

export const deleteProduct = async (productId: string) => {
  const { data } = await api.delete(`${URL}/host/remove-many`, {
    data: {
      ids: [productId],
    },
  });

  return data;
};

export const updateProduct = async ({
  _id,
  ...payload
}: Omit<Product, 'type'>) => {
  const { data } = await api.patch(`${URL}/host/update/${_id}`, payload);

  return data;
};

export const updateProductImages = async ({
  _id,
  ...payload
}: ProductImages) => {
  const { data } = await api.patch(`${URL}/host/update/${_id}`, payload);
  return data;
};
