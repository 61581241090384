import React, { FC } from 'react';

import { useFormikContext } from 'formik';

import { Template } from 'types/style';
import { TemplateInfoSectionProps } from './TemplateInfoSection.props';

import { Section, Description, Title } from 'styles/template.style';

const TemplateInfoSectionView: FC<TemplateInfoSectionProps> = (props) => {
  const { values } = useFormikContext<Template>();

  const info = values.info;

  if (info.enabled === false) {
    return null;
  }

  return (
    <Section
      id="info"
      color={values.colors.primaryColor ?? '#000'}
      justifyContent="center"
      backgroundColor={values.colors.backgroundColor}>
      <Title>{info?.value?.title || 'Info title goes here'}</Title>
      <Description>
        {info?.value?.description || 'Info description goes here'}
      </Description>
    </Section>
  );
};

export default TemplateInfoSectionView;
