import { fetchRooms } from 'api/rooms';
import { useQuery } from 'react-query';

const useRooms = (experienceId: string) => {
  const queryKey = ['fetch-rooms', experienceId];
  const queryFn = () => fetchRooms(experienceId);
  const options = {
    enabled: !!experienceId,
  };

  const query = useQuery(queryKey, queryFn, options);

  return query;
};

export default useRooms;
