import * as Yup from 'yup';

export const initialValues = {
  name: '',
  productOptions: [],
  photos: [],
  featuredImage: '',
  price: 0,
};

export const validationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  productOptions: Yup.array(),
  photos: Yup.array()
    .min(2, 'at least upload 2 photos')
    .required('Photos are required'),
  price: Yup.string().required('Price is required'),
});
