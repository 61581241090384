import React, { useCallback, useState } from 'react';

import { useQuery } from 'react-query';
import { fetchOrders } from 'api/orders';

import { ManagementProps } from './Management.props';

import ManagementView from './Management.view';
import { OrderFilterData } from 'types/orders';
import { useHistory, useLocation } from 'react-router-dom';

const filterTypes = [
  'experience',
  'checkIn',
  'checkOut',
  'noOfPersons',
  'sort',
];

const ITEMS_PER_PAGE = 10;

const ManagementContainer = (props: ManagementProps) => {
  const { search, pathname } = useLocation();
  const params = new URLSearchParams(search);
  const [page, setPage] = useState(1);

  const checkIn = params.get('checkIn') || '';
  const checkOut = params.get('checkOut') || '';
  const numberOfPeople = params.get('noOfPersons') || '';
  const experience = params.get('experience') || '';
  const sort = params.get('sort') || '';
  const location = useLocation();
  const history = useHistory();

  const createSearchParams = () => new URLSearchParams(location.search);

  const searchParams = createSearchParams();

  const queryKey = [
    'fetch-orders',
    page,
    checkIn,
    checkOut,
    numberOfPeople,
    experience,
    sort,
  ];

  const queryFn = () =>
    fetchOrders({
      page,
      limit: ITEMS_PER_PAGE,
      experience: experience,
      sort: parseInt(sort) > 0 ? parseInt(sort) : null,
      checkIn: checkIn === '' ? null : checkIn,
      checkOut: checkOut === '' ? null : checkOut,
      noOfPersons:
        parseInt(numberOfPeople) > 0 ? parseInt(numberOfPeople) : null,
    });

  const { data, isFetching } = useQuery(queryKey, queryFn);

  const orders = data?.items || [];
  const totalCount = data?.total || 0;
  const totalPages = data?.totalPages || 0;

  const pagination = {
    page,
    setPage,
    totalPages,
    totalCount,
  };

  const onApplyFilter = useCallback((filter: OrderFilterData): void => {
    filterTypes.forEach((type) => {
      let filterData = filter[type];
      if (filterData) {
        searchParams.set(type, filterData);
      } else {
        searchParams.delete(type);
      }
    });
    history.replace({
      pathname,
      search: searchParams.toString(),
    });
  }, []);

  const onResetFilter = useCallback((): void => {}, []);

  return (
    <ManagementView
      orders={orders}
      isLoading={isFetching}
      pagination={pagination}
      onApplyFilter={onApplyFilter}
      onResetFilter={onResetFilter}
    />
  );
};

export default ManagementContainer;
