import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

import { Template, TemplateKeys } from 'types/style';

import { initialValues } from 'constants/stylingForm';

type State = {
  values: Template;
  isDraft: boolean;
  selectedSection: TemplateKeys | null;

  previewLogo: string | null;
  previewCoverBg: string | null;
};

const STORAGE_KEY = 'ULTISELL-STYLING';

const useStylingStore = create(
  persist(
    immer<State>(() => ({
      isDraft: false,
      values: initialValues,
      selectedSection: null,
      previewLogo: null,
      previewCoverBg: null,
    })),
    {
      name: STORAGE_KEY,
    },
  ),
);

const actions = {
  setValues: (_values: Template, _isDraft: boolean) => {
    const set = useStylingStore.setState;

    set((state) => {
      state.values = _values;
      state.isDraft = _isDraft;
    });
  },
  reset: () => {
    const set = useStylingStore.setState;

    set((state) => {
      state.isDraft = false;
      state.values = initialValues;
      state.selectedSection = null;

      state.previewLogo = null;
      state.previewCoverBg = null;
    });
  },
  setPreviewLogo: (_logo: string | null) => {
    const set = useStylingStore.setState;

    set((state) => {
      state.previewLogo = _logo;
    });
  },
  setPreviewCoverBg: (_logo: string | null) => {
    const set = useStylingStore.setState;

    set((state) => {
      state.previewCoverBg = _logo;
    });
  },
  setSelectedSection: (_selected: State['selectedSection']) => {
    const set = useStylingStore.setState;

    set((state) => {
      state.selectedSection = _selected;
    });
  },

  //getters
  getIsDraft: () => useStylingStore.getState().isDraft,
  getInitialValues: () => useStylingStore.getState().values,
};

const selectors = {
  useIsDraft: () => useStylingStore((state) => state.isDraft),
  useFormValues: () => useStylingStore((state) => state.values),
  usePreviewItems: () =>
    useStylingStore((state) => ({
      logo: state.previewLogo,
      coverBg: state.previewCoverBg,
    })),
  useSelectedSection: () => useStylingStore((state) => state.selectedSection),
};

/**
 * **Styling Form Store**
 *
 * this store is used to save website styling form progress.
 * the store will be reset upon form submission.
 *
 * **"$"** is a convention to indicate that this is a store
 */
export const $styling = {
  actions,
  selectors,
};
