import React, { FC, useState } from 'react';
import { $session } from 'stores';

import { OrderFilterProps } from './OrderFilter.props';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Select,
  Stack,
  useDisclosure,
} from '@chakra-ui/react';
import { FaFilter } from 'react-icons/fa';
import { useQuery } from 'react-query';
import { fetchExperiences } from 'api/experience';
import { OrderFilterData } from 'types/orders';
import { useLocation } from 'react-router-dom';

const OrderFilterView: FC<OrderFilterProps> = ({
  onApplyFilter,
  onResetFilter,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  // State for filters
  var [checkIn, setCheckIn] = useState(params.get('checkIn') || '');
  var [checkOut, setCheckOut] = useState(params.get('checkOut') || '');
  var [experience, setExperienceId] = useState(params.get('experience') || '');
  var [sort, setSort] = useState(params.get('sort') || '');
  var [noOfPersons, setNoOfPersons] = useState(
    params.get('noOfPersons') || '0',
  );

  const userId = $session.selectors.useUserId() || '';

  const queryKey = [`fetch-experiences`, userId];
  const queryFn = () => fetchExperiences();

  const options = { enabled: !!userId };

  const { data, isFetching } = useQuery(queryKey, queryFn, options);

  const experiences = data?.items || [];

  const orderFilter = (): OrderFilterData => ({
    checkIn: checkIn,
    checkOut: checkOut,
    noOfPersons: noOfPersons,
    experience: experience,
    sort: sort,
  });

  // Validation function for checkIn and checkOut
  const isCheckInCheckOutValid = () => {
    return (checkIn && checkOut) || (!checkIn && !checkOut);
  };

  return (
    <Box>
      <Button
        mr="3%"
        rounded="full"
        variant="outline"
        onClick={onOpen}
        colorScheme="secondary">
        <Box mr={1}>
          <FaFilter size={12} />
        </Box>
        Filter
      </Button>
      <Modal onClose={onClose} isOpen={isOpen} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Filter</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box p={4}>
              <Stack spacing={4}>
                <Flex>
                  <FormControl mr="20px">
                    <FormLabel>Check In</FormLabel>
                    <Input
                      type="date"
                      value={checkIn}
                      onChange={(e) => {
                        setCheckIn(e.target.value);
                      }}
                    />
                  </FormControl>

                  <FormControl>
                    <FormLabel>Check Out</FormLabel>
                    <Input
                      type="date"
                      value={checkOut}
                      onChange={(e) => {
                        setCheckOut(e.target.value);
                      }}
                    />
                  </FormControl>
                </Flex>

                {!isCheckInCheckOutValid() && (
                  <Box fontSize="12px" color="red">
                    Please select both Check In and Check Out or leave both
                    empty.
                  </Box>
                )}

                <FormControl>
                  <FormLabel>Number of people</FormLabel>
                  <NumberInput
                    defaultValue={0}
                    min={0}
                    clampValueOnBlur={true}
                    value={noOfPersons}
                    onChange={(value) => {
                      setNoOfPersons(value.toString());
                    }}>
                    <NumberInputField />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>
                </FormControl>

                <FormControl>
                  <FormLabel>Experience Name</FormLabel>
                  <Select
                    isDisabled={isFetching}
                    placeholder="Select experience"
                    value={experience}
                    onChange={(e) => {
                      setExperienceId(e.target.value);
                    }}>
                    {experiences.map((exp) => (
                      <option key={exp._id} value={exp._id}>
                        {exp.title}
                      </option>
                    ))}
                  </Select>
                </FormControl>
                <FormControl>
                  <FormLabel>Sort By</FormLabel>
                  <Select
                    value={sort}
                    onChange={(e) => {
                      setSort(e.target.value);
                    }}>
                    <option value="1">Latest</option>
                    <option value="-1">Oldest</option>
                  </Select>
                </FormControl>
              </Stack>
            </Box>
          </ModalBody>
          <ModalFooter>
            <Flex>
              {/* <Button
                w="auto"
                mr="5%"
                rounded="full"
                variant="outline"
                colorScheme="secondary"
                size="sm"
                onClick={onResetFilter}>
                Reset Filter
              </Button> */}
              <Button
                w="auto"
                rounded="full"
                variant="solid"
                colorScheme="secondary"
                size="sm"
                onClick={() =>
                  onApplyFilter &&
                  isCheckInCheckOutValid() &&
                  onApplyFilter(orderFilter())
                }>
                Apply Filters
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default OrderFilterView;
