import React, { FC, useRef, useState } from 'react';

import { MdCloudUpload } from 'react-icons/md';
import { Button, Image } from '@chakra-ui/react';

import { useField } from 'formik';
import { useDropzone } from 'react-dropzone';

import { $styling } from 'stores';

import { useMutation } from 'react-query';
import { uploadResources } from 'api/style';

import {
  Label,
  Column,
  BackButton,
  HelperText,
  PickerContainer,
  MotionContainer,
} from '../CustomSideBar.style';

export const LogoForm: FC = () => {
  const fileRef = useRef<File | null>(null);
  const [field, _, helpers] = useField<string>('logo');
  const { logo } = $styling.selectors.usePreviewItems();
  const [localValue, setLocalValue] = useState(() =>
    field.value ? field.value : null,
  );

  const { mutate, isLoading } = useMutation(uploadResources, {
    onSuccess: ({ fileName, previewUrl }) => {
      helpers.setValue(fileName);
      $styling.actions.setPreviewLogo(previewUrl);

      goBack();
    },
  });

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/*': [],
    },
    onDrop: ([file]) => {
      if (file) {
        const fileUrl = URL.createObjectURL(file);

        setLocalValue(fileUrl);
        $styling.actions.setPreviewLogo(fileUrl);

        fileRef.current = file;
      }
    },
  });

  const goBack = () => {
    $styling.actions.setSelectedSection(null);
  };

  const onConfirm = async () => {
    if (localValue === null) {
      return;
    }

    if (fileRef.current) {
      mutate(fileRef.current);

      fileRef.current = null;
    }
  };

  return (
    <MotionContainer flex={1} key="logo-form">
      <BackButton onClick={goBack}>Logo</BackButton>

      <Column flex={1} gap="5px">
        <Label>Logo Image</Label>
        <PickerContainer w="120px" h="120px" {...getRootProps()}>
          {localValue ? (
            <Image
              src={
                localValue.includes('blob') || localValue?.includes('https')
                  ? localValue
                  : logo || undefined
              }
              h="120px"
              w="120px"
              objectFit="cover"
            />
          ) : (
            <MdCloudUpload size="25px" />
          )}
          <input {...getInputProps()} />
        </PickerContainer>
        <HelperText>Size 120px X 120px {'\n'}PNG image</HelperText>
      </Column>

      <Column direction="row" gap="5px">
        <Button size="sm" onClick={goBack}>
          Back
        </Button>
        <Button
          size="sm"
          variant="solid"
          colorScheme="whiteAlpha"
          onClick={onConfirm}
          isLoading={isLoading}
          isDisabled={localValue === null || localValue === field.value}>
          Confirm
        </Button>
      </Column>
    </MotionContainer>
  );
};
