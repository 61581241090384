import React, { useEffect } from 'react';

import { useToast } from '@chakra-ui/react';

import { routesSettings } from 'constants/routes';

import { createRoom, uploadRoomImages } from 'api/rooms';
import { useMutation, useQueryClient } from 'react-query';

import { Room } from 'types/room';
import { CreateRoomProps } from './CreateRoom.props';

import CreateRoomView from './CreateRoom.view';
import sanitizeHtml from 'sanitize-html';

const CreateRoomContainer = (props: CreateRoomProps) => {
  const toast = useToast();
  const queryClinet = useQueryClient();

  const state = props.location.state;

  const { mutate, isLoading } = useMutation(
    async (payload: Room<File>) => {
      const featureImageName = payload.featuredImage;

      const featuredImage = payload.photos.filter(
        (e: File) => e.name === featureImageName,
      );

      const otherImages = payload.photos.filter(
        (e: File) => e.name !== featureImageName,
      );

      const uploadedFeaturedImage = await uploadRoomImages(featuredImage);

      payload.featuredImage = uploadedFeaturedImage[0];

      const uploadedFiles = await uploadRoomImages(otherImages);

      if (payload.description) {
        payload.description = sanitizeHtml(payload.description);
      }

      const response = await createRoom({
        ...payload,
        experience: state.experienceId,
        photos: uploadedFiles,
      });

      return response;
    },
    {
      onSuccess: (res) => {
        queryClinet.invalidateQueries({
          queryKey: ['fetch-rooms', state.experienceId],
        });

        toast({
          title: 'Success',
          description: 'Your room has been created',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });

        onBackClick();
      },
    },
  );

  const onBackClick = () => {
    if ('redirect' in state) {
      props.history.goBack();
      return;
    }

    props.history.replace({
      pathname: routesSettings.MAIN_CREATE_EXPERIENCE.path,
      state,
    });
  };

  useEffect(() => {
    const unlisten = props.history.listen((location, action) => {
      if (action === 'POP') {
        if ('redirect' in state) {
          props.history.replace(state.redirect);

          return;
        }

        props.history.replace({
          pathname: routesSettings.MAIN_CREATE_EXPERIENCE.path,
          state: location.state,
        });
      }
    });

    return () => {
      unlisten();
    };
  }, []);

  const onSubmit = (payload: Room<File>) => {
    payload = {
      ...payload,
      details: {
        beds: Number(payload.details.beds || '1'),
        rooms: Number(payload.details.rooms || '1'),
        bathrooms: Number(payload.details.bathrooms || '1'),
      },
      price: Number(payload.price || '0'),
      weekendPrice: Number(payload.weekendPrice || '0'),
      quantity: Number(payload.quantity || '0'),
    };

    mutate(payload);
  };

  return (
    <CreateRoomView
      isCreating={isLoading}
      onSubmit={onSubmit}
      onBackClick={onBackClick}
    />
  );
};

export default CreateRoomContainer;
