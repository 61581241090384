import { Button, chakra, Flex, Image, Text } from '@chakra-ui/react';

import { chakraStyled } from 'utils';

export const Container = chakraStyled(Flex, {
  w: '100%',
  direction: 'column',
});

export const OuterContainer = chakraStyled(Flex, {
  direction: 'column',
  boxShadow: '2xl',
  rounded: 'lg',
  m: '40px',
});

export const ExpSubTitle = chakraStyled(Flex, {
  fontSize: '15px',
  opacity: 0.8,
});

export const Tab = chakraStyled(Button, {
  w: '280px',
  h: '60px',
  mt: '20px',
  _hover: {
    backgroundColor: 'primary.500',
    color: '#fff',
  },
});

export const RoomCard = chakraStyled(Flex, {
  p: '15px',
  gap: '15px',
  flex: 1,
  minW: '350px',
  maxW: '350px',
  borderRadius: '10px',
  boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.2)',
});

export const RoomImage = chakraStyled(Image, {
  h: '150px',
  w: '180px',
  objectFit: 'cover',
  borderRadius: '10px',
  overflow: 'hidden',
  bg: '#f1f1f1',
});

export const Column = chakraStyled(Flex, {
  flex: 1,
  gap: '10px',
  direction: 'column',
});

export const Title = chakraStyled(Text, {
  fontSize: '16px',
  textAlign: 'left',
});

export const AddRoomBg = chakraStyled(Flex, {
  h: '150px',
  w: '180px',
  justify: 'center',
  align: 'center',
  borderRadius: '10px',
  overflow: 'hidden',
  color: 'gray.500',
  bg: '#f1f1f1',
});

export const SubTitle = chakraStyled(Text, {
  fontSize: '12px',
  textAlign: 'left',
  opacity: 0.6,
});

export const Price = chakraStyled(chakra.span, {
  fontSize: '18px',
  textAlign: 'left',
  opacity: 0.6,
  fontWeight: '600',
});

export const ProductCard = chakraStyled(Flex, {
  justifyContent: 'space-between',
  flexDirection: 'column',
  p: '10px',
  m: '20px',
  ml: '10px',
  gap: '20px',
  flex: 1,
  minW: '300px',
  maxW: '300px',
  borderRadius: '10px',
  boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.2)',
});

export const ProductImage = chakraStyled(Image, {
  h: '150px',
  w: '280px',
  borderRadius: '10px',
  objectFit: 'cover',
  overflow: 'hidden',
  bg: '#a2a2a2',
});

export const ProductTitle = chakraStyled(Text, {
  fontSize: '16px',
});

export const ProductSubTitle = chakraStyled(Text, {
  fontSize: '14px',
  opacity: 0.8,
});

export const DeleteText = chakraStyled(Text, {
  color: 'primary.400',
  fontSize: '18px',
  fontWeight: '400',
  ml: '1%',
});
