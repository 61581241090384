import { $session } from 'stores';
import { GetTemplateResponse, Template } from 'types/style';
import { ListResponse } from 'types/common';

import api from './instance';

const URL = `${process.env.REACT_APP_API_URL}/websites`;

export const uploadResources = async (file: File) => {
  return new Promise<{ fileName: string; previewUrl: string }>(
    (resolve, reject) => {
      const formData = new FormData();

      formData.append('images', file);

      const jwtToken = $session.actions.getTokens()?.jwtToken || '';

      const url = `${URL}/host/upload-resources`;

      const xhr = new XMLHttpRequest();

      xhr.open('POST', url, true);
      xhr.setRequestHeader('Authorization', `Bearer ${jwtToken}`);

      // xhr.upload.onprogress = (e) => {
      //   if (e.lengthComputable) {
      //     const progress = (e.loaded / e.total) * 100;

      //     onProgress?.(progress);
      //   }
      // };

      xhr.onload = () => {
        if (xhr.status === 201) {
          const response = JSON.parse(xhr.response);

          const fileName = response?.item?.uploadedFiles?.[0];
          const previewUrl = response?.item?.signedURLs?.[0];

          resolve({ fileName, previewUrl });
        } else {
          reject(
            new Error(
              `Request failed. Status: ${xhr.status}. Content: ${
                JSON.parse(xhr.response)?.message
              }`,
            ),
          );
        }
      };

      xhr.send(formData);
    },
  );
};

export const publishSite = async (payload: Partial<Template>) => {
  console.log(payload);
  const { data } = await api.patch(`${URL}/update`, payload);

  return data?.item as Template;
};

export const fetchTemplate = async (templateId: string) => {
  const { data } = await api.get<{ item: GetTemplateResponse }>(
    `${URL}/detail/${templateId}`,
  );

  return data?.item;
};

export const fetchAllTemplates = async (host: string) => {
  const { data } = await api.get<ListResponse<Template>>(`${URL}/list`, {
    params: {
      host,
    },
  });

  return data;
};
