const Cards = () => {
  return (
    <svg
      width="54"
      height="50"
      viewBox="0 0 54 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 4C0 1.79 1.8 0 4 0H39.29C41.5 0 43.29 1.79 43.29 4V25.54C43.29 27.75 41.49 29.54 39.29 29.54H4C1.79 29.54 0 27.75 0 25.54V4Z"
        fill="#430062"
      />
      <path
        d="M1 4V25.54C1 27.19 2.34 28.54 4 28.54H39.29C40.94 28.54 42.29 27.2 42.29 25.54V4C42.29 2.35 40.95 1 39.29 1H4C2.35 1 1 2.34 1 4Z"
        fill="#7781BD"
      />
      <path
        d="M1.17969 2.96977C1.53969 1.97977 2.41969 1.22977 3.47969 1.04977L37.3997 1.00977C37.9397 1.00977 38.3797 1.44977 38.3797 1.98977C38.3797 2.52977 37.9297 2.96977 37.3997 2.96977H1.17969Z"
        fill="#8890C5"
      />
      <path
        d="M1.66016 13.2998C1.38016 13.2998 1.16016 13.0798 1.16016 12.7998C1.16016 12.5198 1.38016 12.2998 1.66016 12.2998H5.66016C5.94016 12.2998 6.16016 12.5198 6.16016 12.7998C6.16016 13.0798 5.94016 13.2998 5.66016 13.2998H1.66016ZM7.66016 13.2998C7.38016 13.2998 7.16016 13.0798 7.16016 12.7998C7.16016 12.5198 7.38016 12.2998 7.66016 12.2998H11.6602C11.9402 12.2998 12.1602 12.5198 12.1602 12.7998C12.1602 13.0798 11.9402 13.2998 11.6602 13.2998H7.66016ZM13.6602 13.2998C13.3802 13.2998 13.1602 13.0798 13.1602 12.7998C13.1602 12.5198 13.3802 12.2998 13.6602 12.2998H17.6602C17.9402 12.2998 18.1602 12.5198 18.1602 12.7998C18.1602 13.0798 17.9402 13.2998 17.6602 13.2998H13.6602ZM19.6602 13.2998C19.3802 13.2998 19.1602 13.0798 19.1602 12.7998C19.1602 12.5198 19.3802 12.2998 19.6602 12.2998H23.6602C23.9402 12.2998 24.1602 12.5198 24.1602 12.7998C24.1602 13.0798 23.9402 13.2998 23.6602 13.2998H19.6602ZM25.6602 13.2998C25.3802 13.2998 25.1602 13.0798 25.1602 12.7998C25.1602 12.5198 25.3802 12.2998 25.6602 12.2998H29.6602C29.9402 12.2998 30.1602 12.5198 30.1602 12.7998C30.1602 13.0798 29.9402 13.2998 29.6602 13.2998H25.6602ZM31.6602 13.2998C31.3802 13.2998 31.1602 13.0798 31.1602 12.7998C31.1602 12.5198 31.3802 12.2998 31.6602 12.2998H35.6602C35.9402 12.2998 36.1602 12.5198 36.1602 12.7998C36.1602 13.0798 35.9402 13.2998 35.6602 13.2998H31.6602V13.2998ZM37.6602 13.2998C37.3802 13.2998 37.1602 13.0798 37.1602 12.7998C37.1602 12.5198 37.3802 12.2998 37.6602 12.2998H41.6602C41.9402 12.2998 42.1602 12.5198 42.1602 12.7998C42.1602 13.0798 41.9402 13.2998 41.6602 13.2998H37.6602Z"
        fill="#430062"
      />
      <path d="M1 4.28027H42.29V10.8403H1V4.28027Z" fill="#424393" />
      <path
        d="M6.24023 14.7695H37.0502V21.3295H6.24023V14.7695Z"
        fill="#858EC4"
      />
      <path
        d="M10.5009 22.6396C11.7709 22.6396 12.7909 23.6696 12.7909 24.9396C12.7909 26.2096 11.7609 27.2396 10.5009 27.2396C9.24094 27.2396 8.21094 26.2096 8.21094 24.9396C8.21094 23.6696 9.24094 22.6396 10.5009 22.6396Z"
        fill="#C9EAFC"
      />
      <path
        d="M6.5693 22.6396C7.8393 22.6396 8.8593 23.6696 8.8593 24.9396C8.8593 26.2096 7.8293 27.2396 6.5693 27.2396C5.3093 27.2396 4.2793 26.2096 4.2793 24.9396C4.2793 23.6696 5.3093 22.6396 6.5693 22.6396V22.6396Z"
        fill="#C9EAFC"
      />
      <path
        opacity="0.32"
        d="M8.21094 23.0498C8.21094 20.2898 10.4509 18.0498 13.2009 18.0498H42.2909V28.5398H8.21094V23.0498V23.0498Z"
        fill="#7781BD"
      />
      <path
        d="M9.83008 23.6699C9.83008 21.4599 11.6301 19.6699 13.8301 19.6699H49.1201C51.3301 19.6699 53.1201 21.4599 53.1201 23.6699V45.2099C53.1201 47.4199 51.3201 49.2099 49.1201 49.2099H13.8301C11.6201 49.2099 9.83008 47.4199 9.83008 45.2099V23.6699Z"
        fill="#430062"
      />
      <path
        d="M10.8301 23.6699V45.2099C10.8301 46.8599 12.1701 48.2099 13.8301 48.2099H49.1201C50.7701 48.2099 52.1201 46.8699 52.1201 45.2099V23.6699C52.1201 22.0199 50.7801 20.6699 49.1201 20.6699H13.8301C12.1801 20.6699 10.8301 22.0099 10.8301 23.6699Z"
        fill="#FF554B"
      />
      <path
        opacity="0.35"
        d="M51.4998 21.2004C51.8898 21.5604 52.1298 22.0704 52.1298 22.6404V24.7204C47.7298 31.8604 40.0798 40.8304 27.0698 48.2104H12.7998C11.7198 48.2104 10.8398 47.3204 10.8398 46.2404V32.8704C19.6198 31.9804 39.2698 29.1704 51.4998 21.1904V21.2004Z"
        fill="#FF9B91"
      />
      <path
        d="M52.1195 36.0499C47.7695 35.9699 44.2695 32.4199 44.2695 28.0499C44.2695 24.7299 46.2895 21.8799 49.1695 20.6699C50.7995 20.6999 52.1195 22.0299 52.1195 23.6699V36.0499Z"
        fill="#FFC500"
      />
      <path
        d="M11.0195 22.6397C11.3895 21.6397 12.2695 20.8997 13.3395 20.7197L45.2495 20.6797C45.7895 20.6797 46.2295 21.1197 46.2295 21.6597C46.2295 22.1997 45.7795 22.6397 45.2495 22.6397H11.0195Z"
        fill="#FF6E63"
      />
      <path
        d="M20.6595 30.5103C19.5695 30.5103 18.6895 29.6303 18.6895 28.5403C18.6895 27.4503 19.5695 26.5703 20.6595 26.5703C21.7495 26.5703 22.6295 27.4503 22.6295 28.5403C22.6295 29.6303 21.7495 30.5103 20.6595 30.5103Z"
        fill="#430062"
      />
      <path
        d="M20.6595 29.5103C21.1895 29.5103 21.6295 29.0803 21.6295 28.5403C21.6295 28.0003 21.1995 27.5703 20.6595 27.5703C20.1195 27.5703 19.6895 28.0003 19.6895 28.5403C19.6895 29.0803 20.1195 29.5103 20.6595 29.5103Z"
        fill="white"
      />
      <path
        d="M16.0794 30.5103C14.9894 30.5103 14.1094 29.6303 14.1094 28.5403C14.1094 27.4503 14.9894 26.5703 16.0794 26.5703C17.1694 26.5703 18.0494 27.4503 18.0494 28.5403C18.0494 29.6303 17.1694 30.5103 16.0794 30.5103Z"
        fill="#430062"
      />
      <path
        d="M16.0794 29.5103C16.6094 29.5103 17.0494 29.0803 17.0494 28.5403C17.0494 28.0003 16.6194 27.5703 16.0794 27.5703C15.5394 27.5703 15.1094 28.0003 15.1094 28.5403C15.1094 29.0803 15.5394 29.5103 16.0794 29.5103Z"
        fill="#FFC500"
      />
      <path
        d="M12.1406 25.1104C11.8606 25.1104 11.6406 24.8904 11.6406 24.6104C11.6406 24.3304 11.8606 24.1104 12.1406 24.1104H16.1406C16.4206 24.1104 16.6406 24.3304 16.6406 24.6104C16.6406 24.8904 16.4206 25.1104 16.1406 25.1104H12.1406ZM18.1406 25.1104C17.8606 25.1104 17.6406 24.8904 17.6406 24.6104C17.6406 24.3304 17.8606 24.1104 18.1406 24.1104H22.1406C22.4206 24.1104 22.6406 24.3304 22.6406 24.6104C22.6406 24.8904 22.4206 25.1104 22.1406 25.1104H18.1406ZM24.1406 25.1104C23.8606 25.1104 23.6406 24.8904 23.6406 24.6104C23.6406 24.3304 23.8606 24.1104 24.1406 24.1104H28.1406C28.4206 24.1104 28.6406 24.3304 28.6406 24.6104C28.6406 24.8904 28.4206 25.1104 28.1406 25.1104H24.1406ZM30.1406 25.1104C29.8606 25.1104 29.6406 24.8904 29.6406 24.6104C29.6406 24.3304 29.8606 24.1104 30.1406 24.1104H34.1406C34.4206 24.1104 34.6406 24.3304 34.6406 24.6104C34.6406 24.8904 34.4206 25.1104 34.1406 25.1104H30.1406ZM36.1406 25.1104C35.8606 25.1104 35.6406 24.8904 35.6406 24.6104C35.6406 24.3304 35.8606 24.1104 36.1406 24.1104H40.1406C40.4206 24.1104 40.6406 24.3304 40.6406 24.6104C40.6406 24.8904 40.4206 25.1104 40.1406 25.1104H36.1406ZM42.1406 25.1104C41.8606 25.1104 41.6406 24.8904 41.6406 24.6104C41.6406 24.3304 41.8606 24.1104 42.1406 24.1104H46.1406C46.4206 24.1104 46.6406 24.3304 46.6406 24.6104C46.6406 24.8904 46.4206 25.1104 46.1406 25.1104H42.1406ZM48.1406 25.1104C47.8606 25.1104 47.6406 24.8904 47.6406 24.6104C47.6406 24.3304 47.8606 24.1104 48.1406 24.1104H50.4806C50.7606 24.1104 50.9806 24.3304 50.9806 24.6104C50.9806 24.8904 50.7606 25.1104 50.4806 25.1104H48.1406Z"
        fill="#430062"
      />
      <path
        d="M14.1094 38.3799H25.6394V39.6899H14.1094V38.3799Z"
        fill="white"
      />
      <path
        d="M14.1094 40.3398H28.7894V41.6598H14.1094V40.3398Z"
        fill="white"
      />
      <path
        d="M14.1094 42.3096H28.7894V43.6196H14.1094V42.3096Z"
        fill="#FFC500"
      />
      <path
        d="M42.5996 41.3496C42.5996 40.2496 43.4896 39.3496 44.5996 39.3496H47.8496C48.9496 39.3496 49.8496 40.2496 49.8496 41.3496V42.6296C49.8496 43.7296 48.9596 44.6296 47.8496 44.6296H44.5996C43.4996 44.6296 42.5996 43.7296 42.5996 42.6296V41.3496Z"
        fill="#430062"
      />
      <path
        d="M43.5996 41.3496V42.6296C43.5996 43.1796 44.0496 43.6296 44.5996 43.6296H47.8496C48.3996 43.6296 48.8496 43.1796 48.8496 42.6296V41.3496C48.8496 40.7996 48.3996 40.3496 47.8496 40.3496H44.5996C44.0496 40.3496 43.5996 40.7996 43.5996 41.3496Z"
        fill="#FFC500"
      />
      <path
        d="M44.2894 40.3898C44.3894 40.3598 44.4894 40.3398 44.5994 40.3398H45.3294C45.4794 40.6398 45.7294 40.8498 46.0194 40.8498C46.3094 40.8498 46.5594 40.6498 46.7094 40.3398H47.7694C47.6494 40.8098 47.3994 41.2198 47.0494 41.4898H48.8494V42.4898H47.0494C47.3894 42.7598 47.6394 43.1598 47.7694 43.6198H46.7094C46.5594 43.3298 46.3094 43.1398 46.0394 43.1398C45.7694 43.1398 45.5094 43.3298 45.3594 43.6198H44.6094C44.4994 43.6198 44.3994 43.5998 44.3094 43.5698C44.4394 43.1298 44.6794 42.7498 45.0094 42.4898H43.6094V41.4898H45.0094C44.6694 41.2298 44.4194 40.8298 44.2994 40.3898H44.2894Z"
        fill="#D4A700"
      />
    </svg>
  );
};

export default Cards;
