import {
  Text,
  Flex,
  Link,
  Image,
  Stack,
  Button,
  chakra,
} from '@chakra-ui/react';

import { chakraStyled } from 'utils';

import store from 'assets/store.png';
import cards from 'assets/cards.png';
import safe from 'assets/safe.png';
import gift from 'assets/gift.png';
import logo from 'assets/logo_light_blue.png';
import background from 'assets/background.png';

export const Container = chakraStyled(Flex, {
  w: '100%',
  h: '100%',
  p: {
    base: '20px',
    md: '50px',
  },
  direction: {
    base: 'column-reverse',
    md: 'row',
  },
  backgroundImage: background,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '50px',
});

export const Column = chakraStyled(Flex, {
  flex: 1,
  direction: 'column',
});

export const Row = chakraStyled(Flex, {
  w: '100%',
  direction: 'row',
  alignItems: 'center',
  justifyContent: 'center',
});

export const FormContainer = chakraStyled(Stack, {
  flex: 1,
  p: '20px',
  bg: '#ffffff99',
  borderRadius: '10px',
  maxW: '500px',
  minH: '600px',
  w: '100%',
  alignSelf: 'center',
  align: 'center',
  backdropFilter: 'blur(20px)',
});

export const Title = chakraStyled(Text, {
  textAlign: 'center',
  fontSize: { base: '20px', md: '26px' },
  fontWeight: '600',
  color: 'primary.500',
  mb: '50px',
});

export const Card = chakraStyled(Flex, {
  p: '40px 20px',
  borderRadius: '5px',
  align: 'center',
  bg: '#ffffff99',
  flex: 1,
  minW: '300px',
  maxW: '400px',
  shadow: 'sm',
  mb: '2px',
  gap: '20px',
  backdropFilter: 'blur(20px)',
});

export const Spacer = chakraStyled(Flex, {
  flex: 0.6,
});

export const CardTitle = chakraStyled(Text, {
  fontSize: '16px',
  color: 'primary.500',
});

export const Store = chakraStyled(Image, {
  h: '60px',
  w: '60px',
  objectFit: 'contain',
  src: store,
});

export const Cards = chakraStyled(Image, {
  h: '60px',
  w: '60px',
  objectFit: 'contain',
  src: cards,
});

export const Safe = chakraStyled(Image, {
  h: '60px',
  w: '60px',
  objectFit: 'contain',
  src: safe,
});

export const Gift = chakraStyled(Image, {
  h: '60px',
  w: '60px',
  objectFit: 'contain',
  src: gift,
});

export const SubmitButton = chakraStyled(Button, {
  mt: '100px',
  type: 'submit',
  w: '100%',
});

export const FooterText = chakraStyled(Text, {
  mt: '30px',
  fontSize: '14px',
  color: 'primary.500',
  fontWeight: '600',
  textAlign: 'center',
  userSelect: 'none',
});

export const Span = chakraStyled(chakra.span, {
  as: Link,
  cursor: 'pointer',
  color: 'secondary.500',
  fontWeight: '600',
  alignSelf: 'center',
  fontSize: '14px',
});

export const Logo = chakraStyled(Image, {
  h: { base: '100px', md: '100px' },
  objectFit: 'contain',
  src: logo,
});

export const CoverTitle = chakraStyled(Text, {
  fontSize: { base: '40px', md: '60px' },
  fontWeight: '600',
  color: '#a8ccff',
  textAlign: 'center',
});
