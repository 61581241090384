import React, { FC, useCallback, useState } from 'react';

import logo from 'assets/onboarding/logo.png';
import logo0 from 'assets/onboarding/0.png';
import logo1 from 'assets/onboarding/business-info.webp';
import logo2 from 'assets/onboarding/calendar.webp';
import logo3 from 'assets/onboarding/man-skating.webp';
import logo4 from 'assets/onboarding/4.png';
import stripe from 'assets/onboarding/stripe.png';

import { CommonModal } from '../CommonModal';

import { OnboardingModalViewProps } from './OnboardingModal.props';

import {
  Row,
  Dot,
  Logo,
  Column,
  Heading,
  Container,
  Description,
  MotionContent,
} from './OnboardingModal.style';
import { Button, Grid, GridItem } from '@chakra-ui/react';
import { StripeConnectButton } from '../StripeConnectButton';

const OnboardingModalView: FC<OnboardingModalViewProps> = ({
  isOpen,
  onClose,
  onClearSearch,
}) => {
  const [index, setIndex] = useState(0);

  const renderScene = useCallback((_index: number) => {
    switch (_index) {
      case 0:
        return (
          <MotionContent key="slide-0">
            <Grid width="100%" gridTemplateColumns="repeat(2, 1fr)">
              <GridItem justifyContent="right">
                <Logo src={logo0} />
              </GridItem>
              <GridItem>
                <Logo src={logo} mb="20px" />
                <Heading>Welcome onboard!</Heading>
                <Description>Look at you! Happy you're here.</Description>
                <Button onClick={() => setIndex(1)}>Next</Button>
              </GridItem>
            </Grid>
          </MotionContent>
        );
      case 1:
        return (
          <MotionContent key="slide-1">
            <Grid width="100%" gridTemplateColumns="repeat(2, 1fr)">
              <GridItem>
                <Logo src={logo1} />
              </GridItem>
              <GridItem>
                <Heading>Complete your info</Heading>
                <Description>
                  Go to the “business” section and complete your business info
                </Description>

                <Row>
                  <Button variant="outline" onClick={() => setIndex(0)}>
                    Go Back
                  </Button>
                  <Button onClick={() => setIndex(2)}>Next</Button>
                </Row>
              </GridItem>
            </Grid>
          </MotionContent>
        );
      case 2:
        return (
          <MotionContent key="slide-2">
            <Grid width="100%" gridTemplateColumns="repeat(2, 1fr)">
              <GridItem>
                <Logo src={logo2} />
              </GridItem>
              <GridItem>
                <Heading>Create experiences</Heading>
                <Description>Your experiences fast and easy!</Description>
                <Row>
                  <Button variant="outline" onClick={() => setIndex(1)}>
                    Go Back
                  </Button>
                  <Button onClick={() => setIndex(3)}>Next</Button>
                </Row>
              </GridItem>
            </Grid>
          </MotionContent>
        );
      case 3:
        return (
          <MotionContent key="slide-3">
            <Grid width="100%" gridTemplateColumns="repeat(2, 1fr)">
              <GridItem>
                <Logo src={logo3} />
              </GridItem>
              <GridItem>
                <Heading>Sell your products</Heading>
                <Description>
                  All your products and experiences in just 1 place!
                </Description>
                <Row>
                  <Button variant="outline" onClick={() => setIndex(2)}>
                    Go Back
                  </Button>
                  <Button onClick={() => setIndex(4)}>Next</Button>
                </Row>
              </GridItem>
            </Grid>
          </MotionContent>
        );
      case 4:
        // stripe
        return (
          <MotionContent key="slide-4">
            <Grid width="100%" gridTemplateColumns="repeat(2, 1fr)">
              <GridItem>
                <Logo src={logo4} />
              </GridItem>
              <GridItem>
                <Heading>Set up your payment method</Heading>
                <Description>
                  This will allows us to process your payments faster
                </Description>
                <StripeConnectButton sx={{ ml: '0', mb: '10px' }} />
                <Button
                  onClick={() => {
                    onClearSearch();
                    onClose();
                  }}
                  variant="outline">
                  Skip for now
                </Button>
              </GridItem>
            </Grid>
          </MotionContent>
        );
      default:
        return (
          <MotionContent key="slide-5">
            <Column>
              <Logo src={logo} mb="20px" />
              <Heading>Welcome onboard!</Heading>
              <Description>Look at you! Happy you're here.</Description>
              <Button onClick={() => setIndex(1)}>Next</Button>
            </Column>
          </MotionContent>
        );
    }
  }, []);

  const renderPagination = useCallback(
    (_index: number) => (
      <Row
        alignSelf="flex-end"
        mt="60px"
        gap="5px"
        align="center"
        justify="center">
        {Array.from({
          length: 5,
        }).map((_, i) => (
          <Dot key={i} bg={i === _index ? '#737373' : '#D9D9D9'} />
        ))}
      </Row>
    ),
    [],
  );

  return (
    <CommonModal
      size="2xl"
      isOpen={isOpen}
      onClose={onClose}
      closeOnEsc={false}
      isClosable={false}
      closeOnOverlayClick={false}>
      <Container minHeight="28rem">
        {renderScene(index)}
        {renderPagination(index)}
      </Container>
    </CommonModal>
  );
};

export default OnboardingModalView;
