const Safe = () => {
  return (
    <svg
      width="43"
      height="49"
      viewBox="0 0 43 49"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.4203 47.82L19.3903 47.8C10.2903 42.83 6.58027 39.02 2.76027 30.94C1.56027 28.4 0.800272 24.36 0.370272 18.68C0.060272 14.66 -0.049728 10.22 0.020272 5.87C0.020272 5.63 0.020272 5.42 0.030272 5.22C0.030272 5.12 0.030272 5.04 0.030272 4.98L0.110272 2.36L2.73027 2.44C2.79027 2.44 2.84027 2.44 2.92027 2.44C3.80027 2.45 4.86027 2.44 5.99027 2.39C7.84027 2.31 9.63027 2.16 11.3103 1.92C11.4103 1.90667 11.5136 1.89333 11.6203 1.88C12.7703 1.71 14.0103 1.5 15.3203 1.26C16.5803 1.03 17.8503 0.77 19.1003 0.49C19.5003 0.4 19.8803 0.32 20.2303 0.24C20.4003 0.2 20.5503 0.17 20.6303 0.15L21.2403 0L21.8503 0.14C21.9303 0.16 22.0803 0.19 22.2503 0.23C22.6003 0.31 22.9803 0.39 23.3903 0.48C24.6503 0.75 25.9403 1.01 27.2003 1.25C28.4503 1.48 29.6303 1.68 30.7303 1.85C30.9103 1.88 31.0803 1.9 31.2603 1.93C32.9403 2.17 34.7303 2.32 36.5803 2.4C37.7103 2.45 38.7703 2.46 39.6503 2.45C39.7403 2.45 39.7803 2.45 39.8403 2.45L42.4603 2.37L42.5303 4.99C42.5303 5.04333 42.5303 5.12333 42.5303 5.23C42.5303 5.42 42.5303 5.64 42.5403 5.88C42.6103 10.23 42.5003 14.67 42.2003 18.69C41.7703 24.38 41.0103 28.42 39.8103 30.95C35.9903 39.04 32.2703 42.85 23.0503 47.87L21.3403 48.68L19.4103 47.81L19.4203 47.82Z"
        fill="#430062"
      />
      <path
        d="M21.3406 47.5895L22.6106 46.9895C31.6206 42.0695 35.2006 38.4195 38.9206 30.5295C40.0506 28.1295 40.8006 24.1895 41.2206 18.6195C41.5206 14.6395 41.6306 10.2195 41.5606 5.89953C41.5606 5.66953 41.5606 5.44953 41.5506 5.25953C41.5506 5.15953 41.5506 5.08286 41.5506 5.02953L41.5006 3.40953L39.8806 3.45953C39.8106 3.45953 39.7706 3.45953 39.6706 3.45953C38.7706 3.46953 37.6906 3.45953 36.5406 3.40953C34.6506 3.32953 32.8306 3.16953 31.1206 2.92953C30.9406 2.90953 30.7706 2.87953 30.5906 2.84953C29.4806 2.68953 28.2806 2.47953 27.0206 2.24953C25.7506 2.00953 24.4506 1.74953 23.1806 1.46953C22.7706 1.37953 22.3906 1.29953 22.0306 1.20953C21.8506 1.16953 21.7006 1.13953 21.6206 1.10953L21.2406 1.01953L20.8706 1.10953C20.7906 1.12953 20.6406 1.15953 20.4606 1.19953C20.1106 1.27953 19.7306 1.36953 19.3206 1.44953C18.0606 1.72953 16.7706 1.98953 15.5006 2.21953C14.1706 2.46953 12.9206 2.67953 11.7606 2.84953C11.6539 2.86286 11.5506 2.8762 11.4506 2.88953C9.74058 3.12953 7.92058 3.28953 6.03058 3.36953C4.88058 3.41953 3.80058 3.42953 2.90058 3.41953C2.81058 3.41953 2.76058 3.41953 2.69058 3.41953L1.07058 3.36953L1.02058 4.98953C1.02058 5.04286 1.02058 5.11953 1.02058 5.21953C1.02058 5.40953 1.02058 5.62953 1.01058 5.85953C0.940578 10.1795 1.05058 14.5895 1.35058 18.5795C1.77058 24.1495 2.52058 28.0995 3.65058 30.4895C7.37058 38.3695 10.9306 42.0195 19.8406 46.8895L21.3306 47.5595L21.3406 47.5895Z"
        fill="#6FB588"
      />
      <path
        d="M6.57918 29.1301C5.63918 27.1501 4.95918 23.4201 4.57918 18.3601C4.31918 14.8701 4.20918 11.0101 4.23918 7.17012V6.68012L4.72918 6.67012C5.18918 6.67012 5.67918 6.65012 6.16918 6.62012C8.15918 6.54012 10.0892 6.37012 11.8992 6.11012C12.0059 6.09678 12.1159 6.08012 12.2292 6.06012C13.4392 5.88012 14.7392 5.67012 16.0992 5.41012C17.3992 5.17012 18.7192 4.90012 20.0092 4.62012C20.4092 4.53012 20.7792 4.45012 21.1292 4.37012H21.3492C21.6992 4.45012 22.0792 4.53012 22.4792 4.62012C23.7792 4.90012 25.1092 5.17012 26.4092 5.41012C27.7092 5.65012 28.9392 5.86012 30.0992 6.03012C30.2892 6.06012 30.4692 6.08012 30.6592 6.11012C32.4792 6.37012 34.3992 6.53012 36.3892 6.62012C36.8792 6.64012 37.3592 6.66012 37.8292 6.67012H38.3192V7.17012C38.3492 11.0101 38.2392 14.8701 37.9792 18.3601C37.5992 23.4101 36.9192 27.1501 35.9792 29.1301C32.5892 36.3001 29.5492 39.4501 21.5292 43.8701L21.2892 44.0001L21.0492 43.8701C13.0192 39.4401 9.96918 36.2901 6.57918 29.1201V29.1301Z"
        fill="#430062"
      />
      <path
        d="M5.59035 18.2799C5.96035 23.2199 6.62035 26.8599 7.49035 28.6999C10.7503 35.6099 13.6304 38.6099 21.3004 42.8699C28.9604 38.6199 31.8304 35.6099 35.0904 28.7099C35.9604 26.8699 36.6204 23.2299 36.9904 18.2899C37.2404 14.9699 37.3504 11.3199 37.3304 7.66988C37.0104 7.66988 36.6904 7.64988 36.3604 7.63988C34.3404 7.54988 32.3804 7.37988 30.5304 7.11988C30.3404 7.08988 30.1604 7.06988 29.9704 7.03988C28.8004 6.86988 27.5604 6.65988 26.2404 6.40988C24.9204 6.16988 23.5904 5.89988 22.2804 5.60988C21.9204 5.52988 21.5704 5.44988 21.2504 5.37988C20.9304 5.44988 20.5903 5.52988 20.2303 5.60988C18.9303 5.89988 17.6004 6.15988 16.2904 6.40988C14.9104 6.66988 13.6004 6.88988 12.3804 7.05988C12.267 7.07322 12.157 7.08988 12.0504 7.10988C10.2004 7.36988 8.24035 7.53988 6.22035 7.62988C5.89035 7.63988 5.56035 7.65988 5.25035 7.65988C5.23035 11.3099 5.34035 14.9599 5.59035 18.2799V18.2799Z"
        fill="white"
      />
      <path
        d="M21.7297 46.5696L23.0797 45.9296C31.4797 41.3496 34.8997 37.8496 38.3797 30.4796C39.4897 28.1396 40.1897 24.4496 40.5697 19.2796C40.8397 15.6496 40.9397 11.6396 40.8797 7.70955C40.8797 7.49955 40.8797 7.29955 40.8697 7.11955C40.8697 7.02955 40.8697 6.97955 40.8697 6.89955L40.8197 4.98955C40.8097 4.48955 40.3897 4.08955 39.8897 4.10955C39.3897 4.11955 38.9897 4.53955 39.0097 5.03955L39.0597 6.94955C39.0597 7.01955 39.0597 7.06955 39.0597 7.14955C39.0597 7.31955 39.0597 7.51955 39.0697 7.71955C39.1297 11.5896 39.0397 15.5496 38.7697 19.1196C38.3997 24.0796 37.7297 27.5796 36.7497 29.6796C33.4397 36.6896 30.2897 39.9096 22.2597 44.2896L20.9497 44.9096C20.4997 45.1196 20.2997 45.6696 20.5197 46.1196C20.7297 46.5696 21.2797 46.7696 21.7297 46.5496V46.5696ZM21.0397 2.00955L20.5997 2.11955C20.5297 2.13955 20.3997 2.16955 20.2497 2.19955C19.9397 2.26955 19.5997 2.34955 19.2397 2.42955C18.1197 2.67955 16.9697 2.90955 15.8497 3.11955C14.6697 3.33955 13.5697 3.52955 12.5297 3.66955C12.4397 3.67955 12.3497 3.69955 12.2597 3.70955C10.7697 3.91955 9.16972 4.05955 7.51972 4.12955C6.50972 4.16955 5.56972 4.18955 4.77972 4.16955C4.69972 4.16955 4.63972 4.16955 4.60972 4.16955L2.69972 4.10955C2.19972 4.09955 1.77972 4.48955 1.76972 4.98955C1.75972 5.48955 2.14972 5.90955 2.64972 5.91955L4.55972 5.97955C4.55972 5.97955 4.66972 5.97955 4.74972 5.97955C5.56972 5.98955 6.54972 5.97955 7.58972 5.92955C9.29972 5.85955 10.9597 5.70955 12.5097 5.48955C12.5997 5.47955 12.6997 5.45955 12.7897 5.44955C13.8497 5.29955 14.9797 5.10955 16.1797 4.87955C17.3297 4.66955 18.4897 4.42955 19.6297 4.17955C19.9997 4.09955 20.3397 4.01955 20.6597 3.94955C20.8197 3.90955 20.9497 3.87955 21.0297 3.85955L21.4697 3.74955C21.9597 3.62955 22.2597 3.13955 22.1397 2.64955C22.0197 2.15955 21.5297 1.85955 21.0397 1.97955V2.00955Z"
        fill="#7FD883"
      />
      <path
        d="M22.0202 6.78953L35.5502 8.94953L35.5702 9.77953C35.5702 9.89953 35.5702 10.1195 35.5802 10.4395C35.6202 13.3995 35.5702 16.8195 35.3302 20.0695C35.0402 24.0895 34.5202 27.1695 33.6802 29.0195C31.0602 34.8095 28.5802 37.6195 21.9702 41.3795L21.7402 41.5095H20.8202L20.5902 41.3795C13.9802 37.6095 11.5002 34.8095 8.88024 29.0195C8.04024 27.1695 7.52024 24.0895 7.23024 20.0695C7.00024 16.8195 6.94024 13.3995 6.98024 10.4395C6.98024 10.1195 6.98024 9.89953 6.99024 9.77953L7.01024 8.94953L20.4902 6.79953C20.7302 6.75953 21.7802 6.75953 22.0102 6.79953L22.0202 6.78953Z"
        fill="#430062"
      />
      <path
        d="M21.8602 7.7801C21.7402 7.7601 20.7902 7.7701 20.6602 7.7801L8.00018 9.8001C8.00018 9.8001 7.62018 23.8201 9.79018 28.6001C11.9202 33.3101 13.8802 36.4001 21.0902 40.5001H21.4802C28.6802 36.4001 30.6502 33.3001 32.7802 28.6001C34.9502 23.8101 34.5702 9.8001 34.5702 9.8001L21.8602 7.7701V7.7801Z"
        fill="#6FB588"
      />
      <path
        d="M21.48 40.5098C28.68 36.4098 30.65 33.3098 32.78 28.6098C34.95 23.8198 34.57 9.80984 34.57 9.80984L21.86 7.77984C21.79 7.76984 21.58 7.77984 21.25 7.77984V40.5098H21.47H21.48Z"
        fill="#7FD883"
      />
      <path
        d="M14.9707 16.5895C14.9707 13.2295 17.8907 10.5195 21.4707 10.5195C25.0507 10.5195 27.9707 13.2295 27.9707 16.5895V22.1595C27.9707 23.4695 26.8407 24.5195 25.4607 24.5195H17.4807C16.1007 24.5195 14.9707 23.4695 14.9707 22.1595V16.5895ZM18.9707 20.7295H23.9707V16.5895C23.9707 15.3395 22.8607 14.3095 21.4707 14.3095C20.0807 14.3095 18.9707 15.3395 18.9707 16.5895V20.7295Z"
        fill="#430062"
      />
      <path
        d="M15.9707 16.5895C15.9707 13.7995 18.4307 11.5195 21.4707 11.5195C24.5107 11.5195 26.9707 13.7995 26.9707 16.5895V22.1595C26.9707 22.8995 26.3007 23.5195 25.4607 23.5195H17.4807C16.6407 23.5195 15.9707 22.8995 15.9707 22.1595V16.5895V16.5895ZM17.9707 21.7295H24.9707V16.5895C24.9707 14.7695 23.4007 13.3095 21.4707 13.3095C19.5407 13.3095 17.9707 14.7695 17.9707 16.5895V21.7295Z"
        fill="white"
      />
      <path
        d="M13.4707 22.0295C13.4707 20.3695 14.8107 19.0195 16.4807 19.0195H26.4707C28.1307 19.0195 29.4807 20.3695 29.4807 22.0295V30.0095C29.4807 31.6695 28.1407 33.0195 26.4707 33.0195H16.4807C14.8207 33.0195 13.4707 31.6695 13.4707 30.0095V22.0295V22.0295Z"
        fill="#430062"
      />
      <path
        d="M14.4707 22.0295V30.0095C14.4707 31.1195 15.3707 32.0195 16.4807 32.0195H26.4707C27.5807 32.0195 28.4807 31.1195 28.4807 30.0095V22.0295C28.4807 20.9195 27.5807 20.0195 26.4707 20.0195H16.4807C15.3707 20.0195 14.4707 20.9195 14.4707 22.0295V22.0295Z"
        fill="#FFDA48"
      />
      <path
        d="M21.9707 20.0195V32.0195H16.4807C15.3707 32.0195 14.4707 31.1195 14.4707 30.0095V22.0295C14.4707 20.9195 15.3707 20.0195 16.4807 20.0195H21.9707V20.0195Z"
        fill="#FFC500"
      />
      <path
        d="M20.9793 25.7895C20.5593 25.5195 20.2793 25.0495 20.2793 24.5195C20.2793 23.6895 20.9493 23.0195 21.7793 23.0195C22.6093 23.0195 23.2793 23.6895 23.2793 24.5195C23.2793 25.0595 22.9993 25.5295 22.5793 25.7895L23.2793 30.0195H20.2793L20.9793 25.7895Z"
        fill="#430062"
      />
    </svg>
  );
};

export default Safe;
