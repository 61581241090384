// NavbarContext.js
import React, {
  FC,
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useHistory } from 'react-router';

interface HideContextType {
  isVisible: boolean;
  hide: () => void;
}

const FooterContext = createContext<HideContextType>({
  isVisible: true,
  hide: () => {},
});

export const useFooter = () => useContext(FooterContext);

export const FooterProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [footerVisible, setFooterVisible] = useState(true);

  const history = useHistory();

  // Function to hide the navbar
  const hide = () => {
    setFooterVisible(false);
  };

  // Function to reset the navbar state
  const resetNavbar = () => {
    setFooterVisible(true); // Reset navbar visibility to true
  };

  // Listen for history changes and reset navbar state on navigation
  useEffect(() => {
    const unlisten = history.listen(() => {
      resetNavbar();
    });
    return () => {
      unlisten();
    };
  }, [history]);

  return (
    <FooterContext.Provider value={{ isVisible: footerVisible, hide }}>
      {children}
    </FooterContext.Provider>
  );
};

export default FooterProvider;
