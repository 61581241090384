import * as Yup from 'yup';

export const initialValues = {
  name: '',
  variants: [],
};

export const validationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  variants: Yup.array()
    .min(1, 'Atleast add one variant')
    .required('Variants is required'),
});
