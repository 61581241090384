import { Button, Flex, Image, Text } from '@chakra-ui/react';

import { chakraStyled } from 'utils';

import logo from 'assets/logo_white.png';

export const Container = chakraStyled(Flex, {
  px: { base: '10px', md: '8%' },
  py: '15px',
  zIndex: 5,
  alignItems: 'center',
  justifyContent: 'space-between',
  bg: '#7399c7',
});

export const Hug = chakraStyled(Flex, {
  alignItems: 'center',
  justifyItems: 'center',
  padding: '24px 12px 24px 12px',
});

export const Row = chakraStyled(Flex, {
  alignItems: 'center',
  gap: '20px',
  mx: '20px',
});

export const Logo = chakraStyled(Image, {
  maxW: '60px',
  cursor: 'pointer',
  src: logo,
});

export const Title = chakraStyled(Text, {
  fontSize: '26px',
  fontWeight: 'bold',
  color: '#fff',
  cursor: 'pointer',
});

export const NavSolidButton = chakraStyled(Button, {
  color: '#fff',
  padding: '8px 20px 8px 20px',
  borderWidth: '0px',
  height: '50px',
  backgroundColor: '#96B7DE',
  lineHeight: '20px',
});

export const NavBorderButton = chakraStyled(Button, {
  color: '#fff',
  padding: '8px 20px 8px 20px',
  borderWidth: '1px',
  height: '50px',
  backgroundColor: '#00000000',
  lineHeight: '20px',
});

export const NavButton = chakraStyled(Button, {
  color: '#fff',
  variant: 'outline',
  padding: '8px 20px 8px 20px',
  borderWidth: '1px',
  height: '50px',
  lineHeight: '20px',
});
