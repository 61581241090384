import { Box, Flex, Text } from '@chakra-ui/react';

import { chakraStyled } from 'utils';

export const HeroBox = chakraStyled(Flex, {
  direction: 'column',
  align: 'center',
  minH: '500px',
  px: { base: '20px', md: '50px' },
  py: { base: '40px', md: '80px' },
});

export const HeadingText = chakraStyled(Text, {
  fontSize: '40px',
  fontWeight: '500',
  marginBottom: '20px',
  textAlign: 'center',
});

export const MapBox = chakraStyled(Box, {
  mt: '40px',
  h: '100%',
  w: '100%',
  maxW: {
    base: '80vw',
    md: '50vw',
  },
  maxH: {
    base: '80vw',
    md: '40vh',
  },
  bg: '#afafaf',
});
