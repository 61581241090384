import { chakra, Checkbox, Flex, Image, Text } from '@chakra-ui/react';

import { chakraStyled } from 'utils';

export const Container = chakraStyled(Flex, {
  w: '100%',
  direction: 'column',
});

export const Span = chakraStyled(chakra.span, {
  opacity: 0.5,
});

export const Label = chakraStyled(Text, {
  fontSize: '16px',
  textAlign: 'left',
  mb: '20px',
});

export const AssetsContainer = chakraStyled(Flex, {
  w: '100%',
  direction: 'row',
  justify: 'flex-start',
  align: 'center',
  gap: '15px',
  flexWrap: 'wrap',
  p: '10px',
  borderRadius: '5px',
  boxShadow: '0px 0px 5px rgba(0,0,0,0.1)',
});

export const Asset = chakraStyled(Image, {
  h: '200px',
  w: '240px',
  borderRadius: '10px',
  overflow: 'hidden',
  objectFit: 'cover',
  bg: '#f1f1f1',
  fallbackSrc: 'https://via.placeholder.com/240x200?text=x',
});

export const PickerContainer = chakraStyled(Flex, {
  h: '200px',
  w: '240px',
  borderRadius: '10px',
  border: '2px solid',
  bg: 'secondary.100',
  borderColor: 'secondary.500',
  cursor: 'pointer',
  direction: 'column',
  align: 'center',
  justify: 'center',
  color: 'secondary.500',
  fontSize: '20px',
});

export const Row = chakraStyled(Flex, {
  w: '100%',
  direction: 'row',
  justify: 'center',
  align: 'center',
  flexWrap: 'wrap',
});

export const Column = chakraStyled(Flex, {
  direction: 'column',
  minW: '300px',
  flex: 1,
});

export const AmenityItem = chakraStyled(Checkbox, {
  alignItems: 'flex-start',
  maxW: '200px',
  colorScheme: 'primary',
});
