import { Flex, Box } from '@chakra-ui/react';

import { chakraStyled } from 'utils';

export const Footer = chakraStyled(Flex, {
  bg: '#f2f2f2',
  // h: '250px',
  py: '10px',
  flexDirection: 'column',
  alignItems: 'center',
});

export const LogoBox = chakraStyled(Box, {
  w: '200px',
  display: 'flex',
  alignItems: 'center',
});
