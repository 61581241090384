import { Flex, Image, Text } from '@chakra-ui/react';

import { chakraMotionStyled, chakraStyled } from 'utils';

import bg from 'assets/onboarding/modal_bg.png';

export const Container = chakraStyled(Flex, {
  bg: '#fff',
  minH: '400px',
  p: { base: '15px', md: '30px' },
  align: 'center',
  justify: 'center',
  direction: 'column',
  backgroundImage: bg,
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
});

export const MotionContent = chakraMotionStyled(
  Flex,
  {
    flex: 1,
    mt: '50px',
    width: '100%',
  },
  {
    initial: {
      x: -100,
      opacity: 0,
    },
    animate: {
      x: 0,
      opacity: 1,
    },
    exit: {
      x: -100,
      opacity: 0,
    },
    transition: {
      type: 'spring',
    },
  },
);

export const Row = chakraStyled(Flex, {
  flex: 1,
  w: '100%',
  justify: { base: 'center', md: 'flex-start' },
  align: 'flex-start',
  gap: '30px',
  flexWrap: 'wrap',
});

export const Column = chakraStyled(Flex, {
  direction: 'column',
  minW: '250px',
});

export const Logo = chakraStyled(Image, {
  h: 'auto',
  w: { base: '200px', md: '250px' },
  objectFit: 'contain',
  filter: 'drop-shadow(-4px 21px 20px #00000021)',
  marginX: '10px',
  alignSelf: { base: 'center', md: 'flex-start' },
});

export const Heading = chakraStyled(Text, {
  fontSize: '28px',
  fontWeight: '600',
  color: 'primary.500',
  maxW: '280px',
});

export const Description = chakraStyled(Text, {
  mb: '40px',
  fontSize: '16px',
  fontWeight: 'normal',
  color: 'primary.500',
  maxW: '260px',
});

export const Dot = chakraStyled(Flex, {
  h: '10px',
  w: '10px',
  borderRadius: '20px',
  bg: '#D9D9D9',
});
