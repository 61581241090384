import { Checkbox, Flex, Stack } from '@chakra-ui/react';

import { chakraStyled } from 'utils';

export const MainContainer = chakraStyled(Flex, {
  direction: 'column',
  flex: 1,
});

export const TableContainer = chakraStyled(Flex, {
  m: 2,
  w: '100%',
  css: {
    '&::-webkit-scrollbar': {
      width: '8px',
      height: '8px',
    },
    '&::-webkit-scrollbar-track': {
      width: '10px',
      height: '10px',
      background: '#85BFE950',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#2B689B',
      borderRadius: '24px',
    },
  },
  bg: '#fff',
  borderRadius: '20px',
  boxShadow: '0 0 5px 0 rgba(0,0,0,0.1)',
  maxW: '92vw',
  overflowX: 'auto',
});

export const TableOverflowContainer = chakraStyled(Flex, {
  direction: 'column',
  flex: 1,
});

export const HeaderRow = chakraStyled(Flex, {
  px: 6,
  py: 4,
  flex: 1,
  userSelect: 'none',
  fontWeight: 'bold',
  borderBottom: '1px solid #E0E0E0',
});

export const Check = chakraStyled(Checkbox, {
  mr: 6,
  colorScheme: 'secondary',
  borderColor: 'secondary.500',
});

export const ItemRow = chakraStyled(Flex, {
  px: 6,
  py: 4,
  flex: 1,
  cursor: 'pointer',
  _hover: {
    bg: 'primary.100',
    transition: '0.3s',
  },
  borderBottom: '1px solid #E0E0E0',
});

export const ItemsContainer = chakraStyled(Stack, {
  mt: 2,
  pb: 4,
  // @ts-ignore
  overflowY: 'overlay',
  css: {
    '&::-webkit-scrollbar': {
      width: '8px',
      height: '8px',
    },
    '&::-webkit-scrollbar-track': {
      width: '10px',
      height: '10px',
      background: '#85BFE950',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#2B689B',
      borderRadius: '24px',
    },
  },
});

export const PaginationContainer = chakraStyled(Flex, {
  p: '20px',
  m: '20px 10px',
  align: 'center',
  bg: '#fff',
  boxShadow: '0 0 5px 0 rgba(0,0,0,0.1)',
  borderRadius: '20px',
});

export const Row = chakraStyled(Flex, {
  w: '100%',
  direction: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  flexWrap: 'wrap',
});

export const Page = chakraStyled(Flex, {
  fontSize: '16px',
  fontWeight: 'bold',
  bg: 'secondary.100',
  h: '40px',
  w: '50px',
  borderRadius: '20px',
  color: 'secondary.600',
  mx: '10px',
  justify: 'center',
  align: 'center',
  borderWidth: '2px',
  borderColor: 'secondary.600',
});
