import React, { FC } from 'react';

import {
  Box,
  Flex,
  Spacer,
  Switch,
  Button,
  useMediaQuery,
} from '@chakra-ui/react';

import { AiOutlineMenu } from 'react-icons/ai';

import logoWithName from 'assets/onboarding/logo.png';

import { $styling } from 'stores';

import { useFormikContext } from 'formik';

import { Template } from 'types/style';
import { CustomSideBarProps } from './CustomSideBar.props';

import {
  InfoForm,
  LogoForm,
  StoreForm,
  CoverForm,
  AboutForm,
  ColorsForm,
  ContactForm,
  LocationForm,
} from './Forms';

import {
  Nav,
  Logo,
  Column,
  ItemFlex,
  ItemText,
  Container,
  HeaderText,
  generalAnimation,
  sectionsAnimation,
} from './CustomSideBar.style';

const QUERY = '(max-width: 1000px)';

const CustomSideBarView: FC<CustomSideBarProps> = (props) => {
  const [isMobile] = useMediaQuery(QUERY);

  const selectedSection = $styling.selectors.useSelectedSection();

  const { values, setFieldValue } = useFormikContext<Template>();

  if (isMobile) {
    return null;
  }

  const renderContent = () => {
    if (selectedSection === null) {
      return (
        <>
          <Column gap="0px">
            <HeaderText>General</HeaderText>
            <ItemFlex
              {...generalAnimation}
              onClick={() => {
                $styling.actions.setSelectedSection('logo');
              }}>
              <ItemText>Logo</ItemText>
            </ItemFlex>
            <ItemFlex
              {...generalAnimation}
              transition={{
                ...generalAnimation.transition,
                delay: 0.05,
              }}
              onClick={() => {
                $styling.actions.setSelectedSection('colors');
              }}>
              <ItemText>Colors</ItemText>
            </ItemFlex>
          </Column>

          <Column gap="0px" flex={1}>
            <HeaderText>Sections</HeaderText>
            <ItemFlex {...sectionsAnimation}>
              <Flex
                onClick={() => {
                  if (values.cover.enabled == false) {
                    return;
                  }

                  $styling.actions.setSelectedSection('cover');
                }}>
                <AiOutlineMenu size="20px" />
                <ItemText>Cover</ItemText>
              </Flex>
              <Spacer />
              <Switch
                size="sm"
                colorScheme="green"
                isChecked={values.cover.enabled}
                onChange={(e) => {
                  setFieldValue('cover.enabled', e.target.checked);
                }}
              />
            </ItemFlex>

            <ItemFlex
              {...sectionsAnimation}
              transition={{
                ...generalAnimation.transition,
                delay: 0.05,
              }}>
              <Flex
                onClick={() => {
                  if (values.store.enabled == false) {
                    return;
                  }

                  $styling.actions.setSelectedSection('store');
                }}>
                <AiOutlineMenu size="20px" />
                <ItemText>Experiences</ItemText>
              </Flex>
              <Spacer />
              <Box onClick={(e) => e.stopPropagation()}>
                <Switch
                  size="sm"
                  colorScheme="green"
                  isChecked={values.store.enabled}
                  onChange={(e) => {
                    setFieldValue('store.enabled', e.target.checked);
                  }}
                />
              </Box>
            </ItemFlex>

            <ItemFlex
              {...sectionsAnimation}
              transition={{
                ...generalAnimation.transition,
                delay: 0.08,
              }}>
              <Flex
                onClick={() => {
                  if (values.info.enabled == false) {
                    return;
                  }

                  $styling.actions.setSelectedSection('info');
                }}>
                <AiOutlineMenu size="20px" />
                <ItemText>Info</ItemText>
              </Flex>
              <Spacer />
              <Box onClick={(e) => e.stopPropagation()}>
                <Switch
                  size="sm"
                  colorScheme="green"
                  isChecked={values.info.enabled}
                  onChange={(e) => {
                    setFieldValue('info.enabled', e.target.checked);
                  }}
                />
              </Box>
            </ItemFlex>

            <ItemFlex
              {...sectionsAnimation}
              transition={{
                ...generalAnimation.transition,
                delay: 0.11,
              }}>
              <Flex
                onClick={() => {
                  if (values.about.enabled == false) {
                    return;
                  }

                  $styling.actions.setSelectedSection('about');
                }}>
                <AiOutlineMenu size="20px" />
                <ItemText>About</ItemText>
              </Flex>
              <Spacer />
              <Box onClick={(e) => e.stopPropagation()}>
                <Switch
                  size="sm"
                  colorScheme="green"
                  isChecked={values.about.enabled}
                  onChange={(e) => {
                    setFieldValue('about.enabled', e.target.checked);
                  }}
                />
              </Box>
            </ItemFlex>

            <ItemFlex
              {...sectionsAnimation}
              transition={{
                ...generalAnimation.transition,
                delay: 0.14,
              }}>
              <Flex
                onClick={() => {
                  if (values.location.enabled == false) {
                    return;
                  }

                  $styling.actions.setSelectedSection('location');
                }}>
                <AiOutlineMenu size="20px" />
                <ItemText>Location</ItemText>
              </Flex>
              <Spacer />
              <Box onClick={(e) => e.stopPropagation()}>
                <Switch
                  size="sm"
                  colorScheme="green"
                  isChecked={values.location.enabled}
                  onChange={(e) => {
                    setFieldValue('location.enabled', e.target.checked);
                  }}
                />
              </Box>
            </ItemFlex>

            <ItemFlex
              {...sectionsAnimation}
              transition={{
                ...generalAnimation.transition,
                delay: 0.17,
              }}>
              <Flex
                onClick={() => {
                  if (values.contact.enabled == false) {
                    return;
                  }

                  $styling.actions.setSelectedSection('contact');
                }}>
                <AiOutlineMenu size="20px" />
                <ItemText>Contact</ItemText>
              </Flex>
              <Spacer />
              <Box onClick={(e) => e.stopPropagation()}>
                <Switch
                  size="sm"
                  colorScheme="green"
                  isChecked={values.contact.enabled}
                  onChange={(e) => {
                    setFieldValue('contact.enabled', e.target.checked);
                  }}
                />
              </Box>
            </ItemFlex>

            {/* <ItemFlex
              {...sectionsAnimation}
              transition={{
                ...generalAnimation.transition,
                delay: 0.17,
              }}>
              <Flex
                onClick={() => {
                  if (values.footerLogo == false) {
                    return;
                  }

                  $styling.actions.setSelectedSection('footerLogo');
                }}>
                <AiOutlineMenu size="20px" />
                <ItemText>FooterLogo</ItemText>
              </Flex>
              <Spacer />
              <Box onClick={(e) => e.stopPropagation()}>
                <Switch
                  size="sm"
                  colorScheme="green"
                  isChecked={values.footerLogo}
                  onChange={(e) => {
                    setFieldValue('footerLogo', e.target.checked);
                  }}
                />
              </Box>
            </ItemFlex> */}
          </Column>
          {/* <Column>
              <PreviewBox>
                <PreviewText>Look how you're doing so far</PreviewText>
              </PreviewBox>
              <OutsidePreviewText>You need atleast 60% done</OutsidePreviewText>
            </Column> */}
          <PublishButton />
        </>
      );
    }

    switch (selectedSection) {
      case 'logo': {
        return <LogoForm />;
      }

      case 'colors': {
        return <ColorsForm />;
      }

      case 'cover': {
        return <CoverForm />;
      }

      case 'store': {
        return <StoreForm />;
      }

      case 'info': {
        return <InfoForm />;
      }

      case 'about': {
        return <AboutForm />;
      }

      case 'location': {
        return <LocationForm />;
      }

      case 'contact': {
        return <ContactForm />;
      }

      // case 'footerLogo': {
      //   return <LogoForm />;
      // }

      default: {
        return null;
      }
    }
  };

  return (
    <Container
      key="con-one"
      position="relative"
      animate={'visible'}
      zIndex={10}>
      <Container key="con-two" animate={'visible'}>
        <Nav>
          <Logo src={logoWithName} />
          <Column flex={1}>{renderContent()}</Column>
        </Nav>
      </Container>
    </Container>
  );
};

export default CustomSideBarView;

const PublishButton: FC = () => {
  const isDraft = $styling.selectors.useIsDraft();

  const { isSubmitting, handleSubmit } = useFormikContext<Template>();

  return (
    <Button
      w="full"
      type="submit"
      isDisabled={!isDraft}
      colorScheme="secondary"
      isLoading={isSubmitting}
      onClick={() => handleSubmit()}>
      PUBLISH
    </Button>
  );
};
