import { chakraStyled } from 'utils';
import { Text } from '@chakra-ui/react';

export const CoverTitle = chakraStyled(Text, {
  fontSize: { base: '50px', md: '80px' },
  fontWeight: '600',
  color: 'primary.500',
  minW: '300px',
  textAlign: 'center',
});

export const CoverSubTitle = chakraStyled(Text, {
  textAlign: 'center',
  fontSize: { base: '20px', md: '26px' },
  fontWeight: '600',
  color: 'primary.500',
});
