import React, { FC } from 'react';

import { DashboardCardProps } from './DashboardCard.props';

import {
  Card,
  Total,
  Column,
  SubTitle,
  IconContainer,
} from './DasboardCard.style';
import { MdKey } from 'react-icons/md';
import { IconType } from 'react-icons';

const DashboardCardView: FC<DashboardCardProps> = ({ title, icon, value }) => {
  return (
    <Card>
      <Column>
        <Total>{value ?? 0}</Total>
        <SubTitle>{title ?? ''}</SubTitle>
      </Column>
      <IconContainer>{icon}</IconContainer>
    </Card>
  );
};

export default DashboardCardView;
