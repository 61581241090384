enum ROUTES {
  // AUTH ROUTE ENTRY
  AUTH_COMING_SOON = 'AUTH_COMING_SOON',
  AUTH_PRIVACY_POLICY = 'AUTH_PRIVACY_POLICY',
  AUTH_REGISTER = 'AUTH_REGISTER',
  AUTH_LANDING = 'AUTH_LANDING',
  AUTH_LOGIN = 'AUTH_LOGIN',
  // MAIN ROUTE ENTRY
  MAIN_MANAGEMENT = 'MAIN_MANAGEMENT',
  MAIN_EDIT_PRODUCT = 'MAIN_EDIT_PRODUCT',
  MAIN_EDIT_ROOM = 'MAIN_EDIT_ROOM',
  MAIN_EDIT_EXPERIENCE = 'MAIN_EDIT_EXPERIENCE',
  MAIN_CREATE_TEMPLATE = 'MAIN_CREATE_TEMPLATE',
  MAIN_STYLES = 'MAIN_STYLES',
  MAIN_EXPERIENCE_DETAILS = 'MAIN_EXPERIENCE_DETAILS',
  MAIN_ACCOUNT = 'MAIN_ACCOUNT',
  MAIN_ATTRIBUTES = 'MAIN_ATTRIBUTES',
  MAIN_PRODUCTS = 'MAIN_PRODUCTS',
  MAIN_CREATE_PRODUCT = 'MAIN_CREATE_PRODUCT',
  MAIN_CREATE_ROOM = 'MAIN_CREATE_ROOM',
  MAIN_CREATE_EXPERIENCE = 'MAIN_CREATE_EXPERIENCE',
  MAIN_DASHBOARD = 'MAIN_DASHBOARD',
  MAIN_MANAGEMENT_DETAILS = 'MAIN_MANAGEMENT_DETAILS',
}

export const routesSettings = Object.freeze({
  // AUTH ROUTE SETTINGS ENTRY
  [ROUTES.AUTH_COMING_SOON]: {
    name: 'ComingSoon',
    path: '/coming-soon',
    getPath: () => `/coming-soon`,
  },
  [ROUTES.AUTH_PRIVACY_POLICY]: {
    name: 'PrivacyPolicy',
    path: '/privacy-policy',
    getPath: () => `/privacy-policy`,
  },
  [ROUTES.MAIN_ACCOUNT]: {
    name: 'Account',
    path: '/account',
    getPath: () => `/account`,
  },
  [ROUTES.AUTH_REGISTER]: {
    name: 'Register',
    path: '/register',
    getPath: () => `/register`,
  },
  [ROUTES.AUTH_LANDING]: {
    name: 'Landing',
    path: '/',
    getPath: () => `/`,
  },
  [ROUTES.AUTH_LOGIN]: {
    name: 'Login',
    path: '/login',
    getPath: () => `/login`,
  },
  // MAIN ROUTE SETTINGS ENTRY
  [ROUTES.MAIN_MANAGEMENT]: {
    name: 'Management',
    path: '/management',
    getPath: () => `/management`,
  },

  [ROUTES.MAIN_MANAGEMENT_DETAILS]: {
    name: 'ManagementDetails',
    path: '/management/details/:id',
    getPath: () => `/management/details/:id`,
  },

  [ROUTES.MAIN_EDIT_PRODUCT]: {
    name: 'EditProduct',
    path: '/product/edit/:id',
    getPath: () => `/product/edit/:id`,
  },
  [ROUTES.MAIN_EDIT_ROOM]: {
    name: 'EditRoom',
    path: '/room/edit/:id',
    getPath: () => `/room/edit/:id`,
  },
  [ROUTES.MAIN_EDIT_EXPERIENCE]: {
    name: 'EditExperience',
    path: '/experience/edit/:id',
    getPath: () => `/experience/edit/:id`,
  },
  [ROUTES.MAIN_CREATE_TEMPLATE]: {
    name: 'CreateTemplate',
    path: '/styles/template/create',
    getPath: () => `/styles/template/create`,
  },
  [ROUTES.MAIN_STYLES]: {
    name: 'Styles',
    path: '/styles',
    getPath: () => `/styles`,
  },
  [ROUTES.MAIN_EXPERIENCE_DETAILS]: {
    name: 'ExperienceDetails',
    path: '/experience/details/:id',
    getPath: () => `/experience/details/:id`,
  },
  [ROUTES.MAIN_ATTRIBUTES]: {
    name: 'Attributes',
    path: '/catalog/attributes',
    getPath: () => `/catalog/attributes`,
  },
  [ROUTES.MAIN_PRODUCTS]: {
    name: 'Products',
    path: '/catalog/experiences',
    getPath: () => `/catalog/experiences`,
  },
  [ROUTES.MAIN_CREATE_PRODUCT]: {
    name: 'CreateProduct',
    path: '/product/create',
    getPath: () => `/product/create`,
  },
  [ROUTES.MAIN_CREATE_ROOM]: {
    name: 'CreateRoom',
    path: '/room/create',
    getPath: () => `/room/create`,
  },
  [ROUTES.MAIN_CREATE_EXPERIENCE]: {
    name: 'CreateExperience',
    path: '/experience/create',
    getPath: () => `/experience/create`,
  },
  [ROUTES.MAIN_DASHBOARD]: {
    name: 'Dashboard',
    path: '/dashboard',
    getPath: () => `/dashboard`,
  },
});

export default ROUTES;
