import { useField } from 'formik';
import { useState } from 'react';

const useInputField = (name: string) => {
  const [field, , helper] = useField<string>(name);
  const [data, setData] = useState<any>(field.value);

  const set = (value: string) => {
    setData(value);
    helper.setValue(value);
  };

  return {
    data,
    field,
    helper,
    set,
  };
};

export default useInputField;
