import React, { FC, useState } from 'react';

import { Header } from 'components/layouts/Header';
import { ProductsCarousel } from 'components/modules/ProductsCarousel';
import { StripeHeaderNote } from 'components/modules/StripeHeaderNote';

import { DashboardViewProps } from './Dashboard.props';

import { Row, Title, SubTitle, Container } from './Dashboard.style';
import TotalIncomeCardView from 'components/modules/TotalIncomeCard/TotalIncomeCard.view';
import DashboardCard from 'components/primitives/DashboardCard';
import { FaBox } from 'react-icons/fa';
import { IconButton } from '@chakra-ui/react';
import { MdKey, MdPeopleAlt } from 'react-icons/md';

const DashboardView: FC<DashboardViewProps> = ({ userData, analyticsData }) => {
  return (
    <Container>
      <Header>Dashboard</Header>
      <StripeHeaderNote />
      <Container p="25px">
        <Title>Welcome onboard, {userData?.username}!</Title>
        <SubTitle>
          In your dashboard you’ll find a resume of your products
        </SubTitle>
      </Container>
      <Row>
        <TotalIncomeCardView value={analyticsData?.totalIncome!} />
        <DashboardCard
          title="Total Guests"
          icon={<MdPeopleAlt size="25px" />}
          value={analyticsData?.totalGuest!}
        />
        <DashboardCard
          title="Total Rooms"
          icon={<MdKey size="25px" />}
          value={analyticsData?.totalRoomsBooked!}
        />
        <DashboardCard
          title="Total Product/Services"
          icon={<FaBox size="25px" />}
          value={analyticsData?.productCount!}
        />
      </Row>
      <ProductsCarousel />
    </Container>
  );
};

export default DashboardView;
