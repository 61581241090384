import React from 'react';

import { $session } from 'stores';

import { login } from 'api/auth';
import { useMutation } from 'react-query';

import { routesSettings } from 'constants/routes';

import { LoginProps } from './Login.props';

import LoginView from './Login.view';

const LoginContainer = (props: LoginProps) => {
  const { mutate, isLoading } = useMutation(login, {
    onSuccess: ({ tokens, id, username }) => {
      $session.actions.setSession({
        id,
        tokens,
        username,
      });

      props.history.replace(routesSettings.MAIN_DASHBOARD.path);
    },
  });

  const goToSignUp = () => {
    props.history.replace('/register');
  };

  return (
    <LoginView
      isLoading={isLoading}
      goToSignUp={goToSignUp}
      onSubmit={(values) => mutate(values)}
    />
  );
};

export default LoginContainer;
