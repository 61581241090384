import React, { FC } from 'react';

import { MdPayments } from 'react-icons/md';

import { TotalIncomeCardViewProps } from './TotalIncomeCard.props';

import {
  Card,
  Column,
  Total,
  SubTitle,
  IconContainer,
} from './TotalIncomeCard.style';

const TotalIncomeCardView: FC<TotalIncomeCardViewProps> = (props) => {
  return (
    <Card>
      <IconContainer>
        <MdPayments size="28px" />
      </IconContainer>
      <Column>
        <Total>${props.value?.toFixed(2) ?? 0}</Total>
        <SubTitle>Total incomes</SubTitle>
      </Column>
    </Card>
  );
};

export default TotalIncomeCardView;
