import React, { FC } from 'react';

import { MdMail, MdPhone } from 'react-icons/md';
import { BsTwitter, BsFacebook, BsTelegram, BsInstagram } from 'react-icons/bs';

import { useFormikContext } from 'formik';

import { Template } from 'types/style';

import { TemplateContactInfoProps } from './TemplateContactInfo.props';

import { Section, Title, Description, Row, Value } from 'styles/template.style';
import { Box, Flex } from '@chakra-ui/react';
import { $styling } from 'stores';

const TemplateContactInfoView: FC<TemplateContactInfoProps> = (props) => {
  const { values } = useFormikContext<Template>();
  const initialValues = $styling.selectors.useFormValues();
  const dark = initialValues.colors.dark;

  const contact = values.contact;
  const socials = contact.value.socials;

  if (contact.enabled === false) {
    return null;
  }
  const isSocialEnabled =
    socials?.facebook.enabled ||
    socials?.instagram.enabled ||
    socials?.telegram.enabled ||
    socials?.twitter.enabled;

  return (
    <Section
      width={'100%'}
      color={values.colors.primaryColor ?? '#000'}
      id="contact"
      alignItems="center"
      // backgroundColor={values.colors.backgroundColor}
    >
      {/* <Title>Contact Info</Title> */}
      {/* <Description textAlign="center">
        {contact?.value?.description || ''}
      </Description> */}

      <Flex
        width="100%"
        color={values.colors.primaryColor ?? '#000'}
        justifyContent={isSocialEnabled ? 'space-between' : 'center'}
        gap={isSocialEnabled ? '' : '10%'}
        display="flex"
        paddingY="20px">
        <Box display="flex" justifyContent="center" gap="10px">
          <MdPhone size="28px" />
          <Value>{contact?.value?.phone || 'Phone number goes here'}</Value>
        </Box>

        <Box display="flex" justifyContent="center" gap="10px">
          <MdMail size="28px" />
          <Value>{contact?.value?.email || 'Email goes here'}</Value>
        </Box>

        {isSocialEnabled && (
          <Box display="flex" justifyContent="center" gap="20px">
            {socials?.facebook.enabled && <BsFacebook size="30px" />}

            {socials?.instagram.enabled && <BsInstagram size="30px" />}

            {socials?.telegram.enabled && <BsTelegram size="30px" />}

            {socials?.twitter.enabled && <BsTwitter size="30px" />}
          </Box>
        )}
      </Flex>
    </Section>
  );
};

export default TemplateContactInfoView;
