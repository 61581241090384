import { $session } from 'stores';
import { ApiResponse } from 'types/common';
import { Room, RoomImages } from 'types/room';
import api from './instance';
import sanitizeHtml from 'sanitize-html';

const URL = `${process.env.REACT_APP_API_URL}/rooms/host`;

export const fetchRooms = async (experience: string) => {
  const { data } = await api.get(`${URL}/list`, {
    params: {
      experience,
      limit: 100,
    },
  });

  return data.items as any[];
};

export const createRoom = async (payload: Room & { experience: string }) => {
  const { data } = await api.post<ApiResponse<Room>>(`${URL}/create`, payload);
  return data?.item;
};

export const uploadRoomImages = (
  files: File[],
  onProgress?: (progress: number) => void,
) => {
  return new Promise<string[]>((resolve, reject) => {
    const formData = new FormData();

    files.forEach((file) => {
      formData.append('images', file);
    });

    const jwtToken = $session.actions.getTokens()?.jwtToken || '';

    const url = `${URL}/upload-images`;

    const xhr = new XMLHttpRequest();

    xhr.open('POST', url, true);
    xhr.setRequestHeader('Authorization', `Bearer ${jwtToken}`);

    xhr.upload.onprogress = (e) => {
      if (e.lengthComputable) {
        const progress = (e.loaded / e.total) * 100;

        onProgress?.(progress);
      }
    };

    xhr.onload = () => {
      if (xhr.status === 201) {
        const response = JSON.parse(xhr.response);

        const files = response?.item as string[];

        resolve(files);
      } else {
        reject(
          new Error(
            `Request failed. Status: ${xhr.status}. Content: ${
              JSON.parse(xhr.response)?.message
            }`,
          ),
        );
      }
    };

    xhr.send(formData);
  });
};

export const deleteRoom = async (roomId: string) => {
  const { data } = await api.delete(`${URL}/remove-many`, {
    data: {
      ids: [roomId],
    },
  });

  return data;
};

export const updateRoom = async ({ _id, ...payload }: Room) => {
  const { data } = await api.patch(`${URL}/update/${_id}`, payload);

  return data;
};

export const updateRoomImages = async ({ _id, ...payload }: RoomImages) => {
  const { data } = await api.patch(`${URL}/update/${_id}`, payload);
  return data;
};
