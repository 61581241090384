import api from './instance';

import { ListResponse } from 'types/common';
import { Attribute, CreateAttributePayload } from 'types/attributes';

const URL = `${process.env.REACT_APP_API_URL}/attributes`;

export const fetchAttributes = async (params: {
  page: number;
  limit: number;
}) => {
  const { data } = await api.get<ListResponse<Attribute>>(`${URL}/list`, {
    params,
  });

  return data;
};

export const createAttribute = async (payload: CreateAttributePayload) => {
  const { data } = await api.post(`${URL}/create`, {
    ...payload,
    status: true,
  });

  return data;
};

export const getDefaultAttribute = async () => {
  const { data } = await api.get(`${URL}/get-default-attribute`);

  return data.item;
};
