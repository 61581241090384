import { Box, Flex, chakra, Text, Button, Image } from '@chakra-ui/react';

import { chakraStyled } from 'utils';

export const Container = chakraStyled(Flex, {
  flex: 1,
  width: '100%',
  direction: 'column',
});

export const Section = chakraStyled(chakra('section'), {
  display: 'flex',
  flexDirection: 'column',
  px: { base: '20px', md: '100px' },
  py: { base: '40px', md: '80px' },
});

export const Title = chakraStyled(Text, {
  fontSize: { base: '30px', md: '50px' },
  fontWeight: '500',
  maxW: '700px',
  lineHeight: '1.2',
  mb: '10px',
  textShadow: '1px 1px 5px #ccc',
});

export const SubTitle = chakraStyled(Text, {
  fontSize: '22px',
  fontWeight: '500',
});

export const Description = chakraStyled(Text, {
  fontSize: { base: '14px', md: '18px' },
  mt: '10px',
  letterSpacing: '1.5px',
});

export const Row = chakraStyled(Flex, {
  p: '20px 0px',
  gap: '20px',
  align: 'center',
  minW: { base: '200px', md: '350px' },
});

export const Value = chakraStyled(Text, {
  fontSize: '22px',
  fontWeight: '500',
});
