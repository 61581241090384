import { Experience } from './experience';
import { IconType } from 'react-icons';
import { LuBedSingle, LuBedDouble, LuSofa } from 'react-icons/lu';
import { MdOutlineKingBed, MdKingBed } from 'react-icons/md';
import { BiBed } from 'react-icons/bi';
import { GiBed } from 'react-icons/gi';
import { TbBedFilled } from 'react-icons/tb';
import { FaBed } from 'react-icons/fa';
import SingleBed from 'components/svg/SingleBed';
import TwinBed from 'components/svg/TwinBed';
import DoubleBed from 'components/svg/DoubleBed';
import QueenSizeBed from 'components/svg/QueenSizeBed';
import KingSizeBed from 'components/svg/KingSizeBed';
import CaliforniaKingBed from 'components/svg/CaliforniaKingBed';
import BunkBed from 'components/svg/BunkBed';
import FutonBed from 'components/svg/FutonBed';
import MurphyBed from 'components/svg/MurphyBed';
import DayBed from 'components/svg/DayBed';
import SingleWaterBed from 'components/svg/SingleWaterBed';
import SingleAirBed from 'components/svg/SingleAirBed';

export type Room<T = string> = {
  name: string;
  photos: T[];
  description: string;
  featuredImage: string;
  details: {
    beds: number;
    rooms: number;
    bathrooms: number;
  };
  availability: {
    startDate: string;
    endDate: string;
  };
  price: number;
  weekendPrice: number;
  quantity: number;
  // roomQuantity: number;
  type: string;
  amenities: Array<string>;
  features: Array<string>;
  marketing: Array<string>;

  //optional
  _id?: string;
  createdAt?: string;
  updatedAt?: string;
  isDeleted?: boolean;
  experience?: string | Experience;
};

export type RoomImages<T = string> = {
  _id?: string;
  photos: T[];
};

// export const roomTypes: string[] = [
//   'Single bed',
//   'Double bed',
//   'Queen size bed',
//   'King size bed',
//   'Zed Beds',
//   'Sofa beds',
//   'Sico beds',
//   'Bed Boards',
// ];

// export const ICONS_MAP: Record<string, IconType> = {
//   'Single bed': LuBedSingle,
//   'Double bed': LuBedDouble,
//   'Queen size bed': MdOutlineKingBed,
//   'King size bed': MdKingBed,
//   'Zed Beds': TbBedFilled,
//   'Sofa beds': LuSofa,
//   'Sico beds': BiBed,
//   'Bed Boards': GiBed,
// };

export const roomTypes: string[] = [
  'Single Bed',
  'Twin Bed',
  'Double Bed',
  'Queen Size Bed',
  'King Size Bed',
  'California King',
  'Bunk Bed',
  'Futon',
  'Murphy Bed',
  'Day Bed',
  'Water Bed',
  'Air Bed',
];

export const ICONS_MAP: Record<string, any> = {
  'Single Bed': SingleBed,
  'Twin Bed': TwinBed, // You can use the same icon for 'Twin Bed' if needed
  'Double Bed': DoubleBed, // You can use the same icon for 'Double Bed' if needed
  'Queen Size Bed': QueenSizeBed, // You can use the same icon for 'Queen Size Bed' if needed
  'King Size Bed': KingSizeBed, // You can use the same icon for 'King Size Bed' if needed
  'California King': CaliforniaKingBed, // You can use the same icon for 'California King' if needed
  'Bunk Bed': BunkBed, // You can use the same icon for 'Bunk Bed' if needed
  Futon: FutonBed, // You can use the same icon for 'Futon' if needed
  'Murphy Bed': MurphyBed, // You can use the same icon for 'Murphy Bed' if needed
  'Day Bed': DayBed, // You can use the same icon for 'Day Bed' if needed
  'Water Bed': SingleWaterBed, // You can use the same icon for 'Water Bed' if needed
  'Air Bed': SingleAirBed, // You can use the same icon for 'Air Bed' if needed
};
