import { Box, Button, Flex, Text } from '@chakra-ui/react';

import { chakraStyled } from 'utils';

export const Container = chakraStyled(Flex, {
  w: '100%',
  direction: 'column',
});

export const NavBarBox = chakraStyled(Box, {
  bg: '#f2f2f2',
  h: '70px',
  shadow: 'md',
});

export const HeroBox = chakraStyled(Box, {
  h: '500px',
  backgroundColor: '#d3d3d3',
});

export const SignUpFlex = chakraStyled(Flex, {
  justifyContent: 'center',
  alignItems: 'center',
});

export const LoginBox = chakraStyled(Box, {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

export const SignUpButton = chakraStyled(Button, {
  w: '90px',
  h: '30px',
  backgroundColor: '#fff',
  color: '#686A6C',
  borderRadius: '5px',
  ml: '10px',
  mr: '10px',
  _hover: {
    backgroundColor: '#808080',
    color: '#fff',
  },
});

export const LogInButton = chakraStyled(Button, {
  w: '90px',
  h: '30px',
  backgroundColor: '#808080',
  borderRadius: '5px',
  mr: '30px',
  _hover: {
    backgroundColor: '#fff',
    color: '#000',
  },
});

export const LogoBox = chakraStyled(Box, {
  ml: '20px',
  mt: '5px',
  w: '200px',
  h: '60px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

export const LogoText = chakraStyled(Text, {
  height: '60px',
  fontFamily: 'heading',
  fontSize: '30px',
  fontWeight: '900',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

export const NavItemText = chakraStyled(Text, {
  color: '##52595D',
  fontSize: '14px',
  cursor: 'pointer',
});

export const HeroContainer = chakraStyled(Box, {
  w: '600px',
  ml: '60px',
  mt: '150px',
});

export const HeroText = chakraStyled(Text, {
  fontSize: '30px',
  fontWeight: '600',
});

export const HeroTextDesc = chakraStyled(Text, {
  fontSize: '14px',
  mt: '20px',
});

export const ExploreSection = chakraStyled(Box, {
  h: '600px',
  backgroundColor: 'white',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

export const ExploreHeading = chakraStyled(Text, {
  fontSize: '26px',
  fontWeight: '500',
  width: '350px',
});

export const ExploreBox = chakraStyled(Box, {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  mt: '20px',
});

export const ExploreSectionBox = chakraStyled(Box, {
  bg: '#d3d3d3',
  w: '350px',
  h: '350px',
});

export const Footer = chakraStyled(Flex, {
  bg: '#f2f2f2',
  h: '250px',
  pl: '60px',
  pr: '60px',
  pt: '30px',
  pb: '30px',
  justifyContent: 'space-around',
});

export const ExtraInfo = chakraStyled(Box, {
  h: '1800px',
  bg: '#d3d3d3',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
});

export const HeadingText = chakraStyled(Text, {
  fontSize: '40px',
  fontWeight: '500',
  marginBottom: '20px',
  textAlign: 'center',
});

export const HorizontalRule = chakraStyled(Box, {
  w: '800px',
  h: '5px',
  backgroundColor: '#000',
  mt: '10%',
  borderRadius: '15%',
});

export const MapBox = chakraStyled(Box, {
  mt: '40px',
  h: '900px',
  bg: 'white',
  w: '100%',
});
