import { IconProps } from './type';

const SingleBed = (props: IconProps) => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.0897 6.5C9.35197 6.5 8.75391 7.09806 8.75391 7.83581V12.3531C8.0543 12.3954 7.5 12.9762 7.5 13.6864V17.3655V18.3654V21.2908H9.17188V22.4624C9.17188 22.9693 9.58283 23.3803 10.0898 23.3803H11.3435C11.8504 23.3803 12.2614 22.9693 12.2614 22.4624V21.2908H17.1113V22.4624C17.1113 22.9693 17.5223 23.3803 18.0292 23.3803H19.2829C19.7899 23.3803 20.2008 22.9693 20.2008 22.4624V21.2908H21.8729V18.3654V17.3655V13.6864C21.8729 12.9763 21.3188 12.3956 20.6194 12.3531V7.83581C20.6194 7.09806 20.0214 6.5 19.2836 6.5H10.0897ZM18.9481 12.3506H19.6194V7.83581C19.6194 7.65035 19.4691 7.5 19.2836 7.5H10.0897C9.90425 7.5 9.75391 7.65035 9.75391 7.83581V12.3506H10.4258V9.92547C10.4258 9.41853 10.8367 9.00757 11.3437 9.00757H18.0302C18.5371 9.00757 18.9481 9.41853 18.9481 9.92547V12.3506ZM11.3273 13.3507C11.3328 13.3508 11.3382 13.3508 11.3437 13.3508H18.0302C18.0356 13.3508 18.0411 13.3508 18.0465 13.3507H20.5443C20.7264 13.3545 20.8729 13.5033 20.8729 13.6864V17.3654H8.5V13.6864C8.5 13.5033 8.64649 13.3545 8.82865 13.3507H11.3273ZM17.9481 12.3506V10.0076H11.4258V12.3506H17.9481ZM20.2008 20.2908V20.2908H20.8729V18.3655H8.5V20.2908H9.17188V20.2908H12.2614V20.2908H17.1113V20.2908H20.2008ZM18.1113 22.3803V21.2908H19.2008V22.3803H18.1113ZM10.1719 21.2908H11.2614V22.3803H10.1719V21.2908Z"
        fill="#353535"
      />
    </svg>
  );
};

export default SingleBed;
