import React, { FC } from 'react';

import { SwatchColorPickerProps } from './SwatchColorPicker.props';
import { SwatchesPicker } from 'react-color';
import {
  Box,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Text,
} from '@chakra-ui/react';

const SwatchColorPickerView: FC<SwatchColorPickerProps> = ({
  label,
  initialValue = '#ffffff',
  onChange,
}) => {
  const [color, setColor] = React.useState<string>(initialValue);
  return (
    <div className="">
      <Popover>
        <Text mb={2} fontWeight={600}>
          {label ?? 'Color'}
        </Text>
        <PopoverTrigger>
          <Box
            cursor="pointer"
            width="100%"
            height="40px"
            backgroundColor={color}
            borderRadius={6}
            border="2px white solid"
          />
        </PopoverTrigger>
        <PopoverContent zIndex="10">
          <SwatchesPicker
            color={color}
            width={1000}
            height={160}
            onChange={(newColor) => {
              setColor(newColor.hex);
              onChange && onChange(newColor.hex);
            }}
          />
        </PopoverContent>
      </Popover>
    </div>
  );
};

export default SwatchColorPickerView;
