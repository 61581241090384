import React, { FC } from 'react';

import { AnimatedLoadingProps } from './AnimatedLoading.props';

import { Logo, Title, Layout, Container } from './AnimatedLoading.style';

const AnimatedLoadingView: FC<AnimatedLoadingProps> = (props) => {
  return (
    <Layout>
      <Container>
        <Logo />
        <Title>Loading...</Title>
      </Container>
    </Layout>
  );
};

export default AnimatedLoadingView;
