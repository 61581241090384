import React, { FC, useState } from 'react';

import { FormControl, FormLabel } from '@chakra-ui/react';

import GooglePlacesAutocomplete, {
  geocodeByAddress,
} from 'react-google-places-autocomplete';

import {
  LocalValue,
  PlacesAutocompleteProps,
} from './PlacesAutocomplete.props';

const API_KEY = process.env.REACT_APP_GOOGLE_PLACES_API_KEY;

const inputBgColor = '#fff';

const defaultInputStyle = {
  container: (provided: any) => ({
    ...provided,
    width: '100%',
    background: inputBgColor,
    paddingLeft: 3,
    borderWidth: 1,
    borderRadius: 6,
    borderColor: '#ccc8',
    textAlign: 'left',
  }),
  dropdownIndicator: (styles: any) => ({
    ...styles,
    background: inputBgColor,
    textAlign: 'left',
    display: 'none',
  }),
  indicatorSeparator: (styles: any) => ({
    ...styles,
    background: inputBgColor,

    display: 'none',
  }),
  input: (provided: any) => ({
    ...provided,
    color: 'blue',
    textAlign: 'left',
  }),
  control: (provided: any) => ({
    ...provided,
    background: inputBgColor,
    border: 'transparent',
    borderRadius: 6,
  }),
  placeholder: (provided: any) => ({
    ...provided,
    textAlign: 'left',
  }),
};

const PlacesAutocompleteView: FC<PlacesAutocompleteProps> = ({
  name = '',
  onChange,
  onSelect,
  labelText,
  defaultInputValue = '',
  value = '',
  ...props
}) => {
  const [localValue, setLocalValue] = useState<LocalValue>({
    label: value,
    value: value,
  });

  return (
    <FormControl mt={4} flex={1}>
      {labelText && (
        <FormLabel
          htmlFor={name}
          fontWeight="400"
          textTransform="capitalize"
          {...props.labelProps}>
          {labelText}
        </FormLabel>
      )}
      <GooglePlacesAutocomplete
        apiKey={API_KEY}
        selectProps={{
          value: localValue,
          onChange: async (data) => {
            let result: google.maps.GeocoderResult[] = [];

            setLocalValue(data);

            if (data?.label) {
              result = await geocodeByAddress(data.label);
            }

            onSelect?.(result);
          },
          isClearable: true,
          styles: defaultInputStyle,
          defaultInputValue,
        }}
        autocompletionRequest={{
          componentRestrictions: {
            country: 'us',
          },
          types: ['address'],
        }}
        debounce={200}
      />
    </FormControl>
  );
};

export default React.memo(PlacesAutocompleteView);
