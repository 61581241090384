import React, { FC, useMemo, useRef, useState } from 'react';

import { ProductVariantInputProps } from './ProductVariantInput.props';
import { Label } from 'routes/main/ManagementDetails/ManagementDetails.style';
import { generateCombinations } from 'utils';
import { useField, useFormikContext } from 'formik';
import { Attribute } from 'types/attributes';
import { useAttributes } from 'queries';
import { useMutation } from 'react-query';
import { getDefaultAttribute } from 'api/attributes';
import {
  Box,
  Button,
  Checkbox,
  IconButton,
  Input,
  useToast,
} from '@chakra-ui/react';
import { Column } from 'routes/main/CreateProduct/CreateProduct.style';
import { Row } from 'styles/template.style';
import { TbMoodMinus } from 'react-icons/tb';
import { FaMinusCircle, FaPlusCircle } from 'react-icons/fa';
import { MdOutlineRefresh } from 'react-icons/md';

type Combinations = ReturnType<typeof generateCombinations>;

const ProductVariantInputView: FC<ProductVariantInputProps> = ({
  editMode = false,
}) => {
  const [index, setIndex] = useState<number | null>(null);
  const [selected, setSelected] = useState<Attribute | null>(null);
  const [options, setOptions] = useState<Attribute[]>([]);
  const [isChecked, setIsChecked] = useState<boolean>(editMode ? true : false);
  const [savedAttributes, setSavedAttributes] = useState<Attribute[]>([]);
  const [priceField] = useField('price');
  const [field, meta, helpers] = useField<Combinations>('productOptions');

  const { data, isLoading } = useAttributes(({ items = [] }) =>
    setOptions(items),
  );

  const {
    mutate,
    isSuccess,
    isLoading: isFetching,
    data: defaultAttribute,
  } = useMutation(getDefaultAttribute, {
    onSuccess: (res) => {
      // Check if the variant with the same name already exists
      const isDuplicate = field.value.some(
        (item) => item.name === res.variants?.[0]?.name,
      );

      if (isDuplicate) {
        // Handle duplicate variant (e.g., display error message)
        console.error('Variant with the same name already exists!');
        // You can choose to return or do nothing to prevent adding the duplicate variant
        return;
      }

      helpers.setValue([
        ...field.value,
        {
          name: res.variants?.[0]?.name || '',
          price: Number(priceField.value || ''),
          quantity: 1,
          variations: [
            {
              attribute: res?._id,
              variant: res?.variants?.[0]?._id,
            },
          ],
        },
      ]);
    },
  });

  const items = data?.items || [];

  const variantionsLength = savedAttributes
    .map((s) => s.variants?.length || 0)
    .reduce((a, b) => a + b, 0);

  const combinations = useMemo(
    () => generateCombinations(savedAttributes, 0),
    [savedAttributes.length, variantionsLength],
  );

  const editProductOptionBalance = localStorage.getItem(
    'edit-product-options-balance',
  );

  const toast = useToast();
  const balance =
    editProductOptionBalance == null
      ? 0
      : JSON.parse(editProductOptionBalance!)[0].quantity;
  const [quantity, setQuantity] = useState<number>(balance);

  const EditComponent = () => {
    const addBalanceRef = useRef<HTMLInputElement>(null);

    const addBalance = () => {
      if (addBalanceRef.current) {
        const newValue = addBalanceRef.current.value;
        if (!newValue) return;
        const updatedBalance = quantity + Number(newValue);
        if (updatedBalance < 1) {
          toast({
            description: 'Minimum count is 1',
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
          return;
        }
        setQuantity(updatedBalance);
        addBalanceRef.current.value = ''; // Clearing the input value
        let payload = field.value;
        payload[0].quantity = updatedBalance;
        console.log(payload, 'Payload');
        helpers.setValue(payload);
      }
    };

    const resetBalance = () => {
      setQuantity(balance);
      let payload = field.value;
      payload[0].quantity = balance;
      helpers.setValue(payload);
    };

    return (
      <Column>
        <Row p={0}>
          Regular
          <Box fontWeight={900}>{quantity ?? 0}</Box>
        </Row>
        <Row p={0} mt={4}>
          <Input
            width={200}
            type="number"
            ref={addBalanceRef}
            placeholder="Edit Quantity"
            onChange={(e) => {}}
          />
          <Button onClick={addBalance}>Save</Button>
          <Button colorScheme="red" onClick={resetBalance}>
            Undo
          </Button>
          {/* <IconButton
            colorScheme="red"
            width={100}
            
            icon={<MdOutlineRefresh />}
            aria-label={''}
          /> */}
        </Row>
        <Row p={0} pt={2}>
          <Label>Decrease the count by entering a negative number</Label>
        </Row>
      </Column>
    );
  };

  return (
    <>
      {!editMode && (
        <Checkbox
          isChecked={isChecked}
          colorScheme="primary"
          mb="20px"
          onChange={(e) => {
            if (e.target.checked) {
              mutate();
            }

            setIsChecked(e.target.checked);
          }}>
          Use default attribute
        </Checkbox>
      )}

      {isSuccess && isChecked && (
        <Column>
          <Label>
            Name: <strong>{defaultAttribute?.variants?.[0]?.name || ''}</strong>
          </Label>

          <Input
            defaultValue={1}
            type="number"
            placeholder="Enter Amount"
            onChange={(e) => {
              field.value[0].quantity = Number(e.target.value || '1');
            }}
          />
        </Column>
      )}

      {editMode && <EditComponent />}
      {/* {isFetching ? (
        <Center>
          <Spinner />
        </Center>
      ) : isSuccess && isChecked ? (
        <Column>
          <Label>
            Name: <strong>{defaultAttribute?.variants?.[0]?.name || ''}</strong>
          </Label>

          <Input
            defaultValue={1}
            type="number"
            placeholder="Enter Amount"
            onChange={(e) => {
              field.value[0].quantity = Number(e.target.value || '1');
            }}
          />
        </Column>
      ) : (
        <Row
          align={undefined}
          flexWrap="wrap"
          borderRadius="5px"
          boxShadow="0 0 5px 0 rgba(0,0,0,0.1)">
          <Column>
            <Container>
              <Container
                pt="25px"
                px="25px"
                flex={1}
                borderBottom="1px solid #ccc">
                <Label>Attributes</Label>
              </Container>
              {savedAttributes.length > 0 ? (
                <Container p="20px">
                  {savedAttributes?.map((s, i) => (
                    <Row
                      mb="10px"
                      key={`${s.name}-${s._id}-${i}`}
                      p="20px"
                      gap="10px"
                      borderRadius="10px"
                      bg="#f1f1f1">
                      <Column flex={1} gap="10px">
                        <Label m="0">{s.name}</Label>
                        <Row justify="flex-start" gap="5px">
                          {s?.variants?.map((v, _i) => (
                            <Label
                              key={`${v._id}-${v._id}`}
                              p="2px 10px"
                              fontSize="12px"
                              bg="#ccc"
                              m="0"
                              borderRadius="40px">
                              {v?.name}
                            </Label>
                          ))}
                        </Row>
                      </Column>
                      <Button
                        onClick={() => {
                          setSelected(s);
                          onOpen();
                        }}>
                        Edit
                      </Button>
                    </Row>
                  ))}
                </Container>
              ) : null}
              {isOpen && (
                <Container minH="300px" px="20px" mt="20px">
                  <CustomSelect
                    property="name"
                    options={options}
                    isLoading={isLoading}
                    selected={selected}
                    setSelected={(v) => {
                      setSelected(v);
                    }}
                  />
                  {selected?.variants?.map((v, i) => (
                    <Row
                      justify="flex-start"
                      w="100%"
                      p="5px 20px"
                      pr="10px"
                      key={`${v._id}-${v._id}-${i}-${i}`}
                      border="1px solid #f1f1f1"
                      borderRadius="5px"
                      my="1">
                      <Label m="0" flex={1}>
                        {v?.name}
                      </Label>
                      <Delete
                        onClick={() => {
                          const newVariants = selected?.variants?.filter(
                            (_v) => _v._id !== v._id,
                          );

                          setSelected((prev) =>
                            prev
                              ? {
                                  ...prev,
                                  variants: newVariants,
                                }
                              : prev,
                          );
                        }}
                      />
                    </Row>
                  ))}
                  <Container my="10px" flex={1} justify="center" align="center">
                    <Button
                      onClick={() => {
                        if (!selected) {
                          return;
                        }

                        const isEdit = savedAttributes?.some(
                          (a) => a._id === selected._id,
                        );

                        if (!isEdit) {
                          const itemIndex = options?.findIndex(
                            (i) => i._id === selected._id,
                          );

                          setIndex(itemIndex);
                        }

                        onClose();
                        setSelected(null);
                        setSavedAttributes((prev) => [
                          ...prev?.filter((a) => a._id !== selected._id),
                          selected,
                        ]);
                      }}>
                      Save
                    </Button>
                  </Container>
                </Container>
              )}
              {savedAttributes.length >= items?.length || isOpen ? null : (
                <Container
                  p="10px"
                  justify="center"
                  align="center"
                  borderTop="1px solid #ccc">
                  <Button
                    onClick={() => {
                      if (!items?.length) {
                        return;
                      }

                      const filteredOptions =
                        typeof index === null
                          ? options
                          : options?.filter((_, ind) => ind !== index);

                      setOptions(filteredOptions);

                      onOpen();
                    }}
                    w="200px"
                    leftIcon={<MdAddCircleOutline size="20px" />}
                    rounded="full"
                    variant="ghost"
                    colorScheme="secondary"
                    color="secondary.500">
                    Add option
                  </Button>
                </Container>
              )}
            </Container>
          </Column>
          <Column
            flex={1}
            maxH="500px"
            overflowY="auto"
            borderLeft="1px solid #ccc">
            <AnimatePresence>
              <MotionItem p="0" pt="25px" pb="20px" key="motion-row">
                <Container w="35px" />
                <Label m="0" flex={1}>
                  Variants
                </Label>
                <Label flex={0.2} m="0">
                  Price
                </Label>
                <Label flex={0.2} m="0">
                  Amount
                </Label>
              </MotionItem>
              <MotionFlex pt="0">
                {combinations?.map((c, i) => (
                  <MotionItem
                    gap="10px"
                    key={`${c.name}-${c.variations?.length}-${i}`}>
                    <Checkbox
                      colorScheme="secondary"
                      onChange={(e) => {
                        if (e.target.checked) {
                          helpers.setValue([...field.value, c]);
                        } else {
                          helpers.setValue(
                            field.value.filter((v) => v.name !== c.name),
                          );
                        }
                      }}
                    />
                    <Label flex={1} m="0">
                      {c.name}
                    </Label>
                    <Input
                      flex={0.2}
                      size="sm"
                      defaultValue={c.price}
                      type="number"
                      inputMode="numeric"
                      pattern="[0-9]*"
                      border="1px solid #f1f1f1"
                      onChange={(e) => {
                        combinations[i].price = Number(e.target.value);

                        const itemIndex = field.value.findIndex(
                          (i) => i.name === c.name,
                        );

                        field.value[itemIndex].price = Number(e.target.value);
                      }}
                    />
                    <Input
                      flex={0.2}
                      size="sm"
                      defaultValue={c.quantity}
                      type="number"
                      inputMode="numeric"
                      pattern="[0-9]*"
                      border="1px solid #f1f1f1"
                      onChange={(e) => {
                        combinations[i].quantity = Number(e.target.value);

                        const itemIndex = field.value.findIndex(
                          (i) => i.name === c.name,
                        );

                        field.value[itemIndex].quantity = Number(
                          e.target.value,
                        );
                      }}
                    />
                  </MotionItem>
                ))}
              </MotionFlex>
            </AnimatePresence>
          </Column>
        </Row>
      )} */}
      {meta.error && meta.touched && (
        <Label color="red" fontSize="sm">
          {meta.error}
        </Label>
      )}
    </>
  );
};

export default ProductVariantInputView;
