import React, { FC } from 'react';

import { useToast } from '@chakra-ui/react';

import { useMutation } from 'react-query';
import { updateExperience } from 'api/experience';

import { CreatePlacesProps } from './CreatePlaces.props';

import CreatePlacesView from './CreatePlaces.view';

const CreatePlacesContainer: FC<CreatePlacesProps> = (props) => {
  const toast = useToast();

  const { mutate, isLoading } = useMutation(
    (amenities: string[]) =>
      updateExperience({ amenities }, props.experienceId),
    {
      onSuccess: () => {
        // toast({
        //   status: 'success',
        //   title: 'Amenities Added',
        //   duration: 3000,
        //   isClosable: true,
        // });

        props.onNextClick();
      },
    },
  );

  return (
    <CreatePlacesView
      amenities={props.amenities}
      isLoading={isLoading}
      experienceId={props.experienceId}
      onAddAmenities={(_amenities) => {
        // if (_amenities.length === 0) {
        //   toast({
        //     status: 'warning',
        //     title: 'Add Some Amenities',
        //     duration: 5000,
        //     isClosable: true,
        //   });

        //   return;
        // }

        mutate(_amenities);
      }}
    />
  );
};

export default CreatePlacesContainer;
