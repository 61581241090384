import React, { FC } from 'react';

import {
  Box,
  Tabs,
  Button,
  Center,
  Spinner,
  TabList,
  TabPanel,
  TabPanels,
  SimpleGrid,
  useMediaQuery,
} from '@chakra-ui/react';
import altLogo from 'assets/altLogoImage.jpg';

import { Header } from 'components/layouts/Header';

import StyleCard from 'components/modules/StyleCard';

import { StylesViewProps } from './Styles.props';

import { Container, Row, TabTitle } from './Styles.style';

const StylesView: FC<StylesViewProps> = ({
  template,
  isLoading,
  handlePreviewClick,
}) => {
  const [isMobile] = useMediaQuery('(max-width: 1000px)');

  return (
    <Container>
      <Header>
        <Row flexWrap="nowrap">
          Styles
          <Button
            mr={isMobile ? '40px' : '0'}
            w="auto"
            isDisabled={isLoading}
            onClick={handlePreviewClick}>
            Go to website
          </Button>
        </Row>
      </Header>
      <Tabs isManual variant="enclosed" mt="20px" h="100%">
        <TabList>
          <TabTitle borderBottomColor="#C2D4E2">Guest Website</TabTitle>
        </TabList>
        <Box bg="#C2D4E2" height="100%">
          <TabPanels>
            <TabPanel>
              {isLoading ? (
                <Center h="500px" flex={1}>
                  <Spinner size="lg" />
                </Center>
              ) : (
                <SimpleGrid
                  columns={{ base: 1, sm: 2, md: 3, lg: 3 }}
                  spacing={4}
                  p={2}>
                  <StyleCard
                    id={template?._id || ''}
                    logo={template?.logo || altLogo}
                    handlePreviewClick={handlePreviewClick}
                  />
                </SimpleGrid>
              )}
            </TabPanel>
          </TabPanels>
        </Box>
      </Tabs>
    </Container>
  );
};

export default StylesView;
