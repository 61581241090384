import React, { FC } from 'react';

import { StripeConnectProps } from './StripeConnect.props';

import StripeConnectView from './StripeConnect.view';

const StripeConnectContainer: FC<StripeConnectProps> = (props) => {
  return <StripeConnectView />;
};

export default StripeConnectContainer;
