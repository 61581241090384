import { FC } from 'react';

import { MdDelete } from 'react-icons/md';
import { Button, Flex, IconButton, Stack, Text } from '@chakra-ui/react';

import { FieldArray, useFormikContext } from 'formik';

import FormikInput from 'components/primitives/FormikInput';

export const Features: FC = () => {
  const { values, errors } = useFormikContext<any>();

  const features = (values?.features || []) as string[];
  const error = errors?.features as any;

  return (
    <>
      <FieldArray name="features">
        {({ remove, push }) => (
          <Stack spacing={2} maxW="300px">
            {features.length > 0
              ? features.map((_, index) => (
                  <Flex key={index} align="flex-end" gap="20px">
                    <FormikInput
                      name={`features[${index}]`}
                      label={`Feature ${index + 1}`}
                    />

                    <IconButton
                      w="auto"
                      colorScheme="red"
                      aria-label="add-icon"
                      icon={<MdDelete size="20px" />}
                      onClick={() => remove(index)}
                    />
                  </Flex>
                ))
              : null}
            {values.features.length < 3 && (
              <Button my="10px" onClick={() => push('')}>
                Add Feature
              </Button>
            )}
          </Stack>
        )}
      </FieldArray>
      {error && (
        <Text fontSize="14px" color="red.500">
          {error}
        </Text>
      )}
    </>
  );
};
