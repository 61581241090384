import React, { FC, useEffect } from 'react';

import { ComingSoonViewProps } from './ComingSoon.props';
import { useNavbar } from 'contexts/navbarContext';
import { useFooter } from 'contexts/footerContext';
import background from 'assets/background.png';
import { Flex, Heading, Text } from '@chakra-ui/react';
import { CoverSubTitle, CoverTitle } from './ComingSoon.style';

const ComingSoonView: FC<ComingSoonViewProps> = (props) => {
  const { hide: hideNavbar } = useNavbar();
  const { hide: hideFooter } = useFooter();

  useEffect(() => {
    hideNavbar();
    hideFooter();
  }, []);

  return (
    <Flex
      align="center"
      justify="center"
      height="100vh"
      backgroundImage={background}
      w="100vw"
      backgroundColor="gray.100"
      flexDirection="column">
      <CoverTitle as="h1" size="2xl" marginBottom="4">
        Coming Soon
      </CoverTitle>
      <CoverSubTitle fontSize="xl">
        We're working hard to bring you something amazing! Stay tuned for
        updates.
      </CoverSubTitle>
    </Flex>
  );
};

export default ComingSoonView;
