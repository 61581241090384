import React, { FC } from 'react';

import {
  Menu,
  MenuList,
  MenuItem,
  MenuButton,
  IconButton,
} from '@chakra-ui/react';
import { MdDelete, MdEdit, MdMoreVert } from 'react-icons/md';

import { ProductOptionsViewProps } from './ProductOptions.props';

const ProductOptionsView: FC<ProductOptionsViewProps> = ({
  onEdit,
  onDelete,
  isDeleting,
}) => {
  return (
    <Menu>
      <MenuButton
        size="xs"
        w="20px"
        as={IconButton}
        aria-label="Options"
        icon={<MdMoreVert size="16px" />}
        position="absolute"
        right={1}
        top={2.5}
        isLoading={isDeleting}
      />
      <MenuList>
        <MenuItem icon={<MdEdit size="16px" />} onClick={onEdit}>
          Edit
        </MenuItem>
        <MenuItem
          icon={<MdDelete color="red" size="16px" />}
          onClick={() => {
            onDelete();
          }}>
          Delete
        </MenuItem>
      </MenuList>
    </Menu>
  );
};

export default ProductOptionsView;
