import React, { FC } from 'react';

import { useMutation, useQueryClient } from 'react-query';
import { createExperience, uploadImages } from 'api/experience';

import { CreateActivityProps } from './CreateActivity.props';

import CreateActivityView from './CreateActivity.view';
import sanitizeHtml from 'sanitize-html';
import { initialValues } from './CreateActivity.utils';

const CreateActivityContainer: FC<CreateActivityProps> = (props) => {
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation(
    async (values: typeof initialValues) => {
      const featureImageName = values.featuredImage;

      const featuredImage = values.photos.filter(
        (e: File) => e.name === featureImageName,
      );

      const otherImages = values.photos.filter(
        (e: File) => e.name !== featureImageName,
      );

      const uploadedFeaturedImage = await uploadImages(featuredImage);
      const uploadedFiles = await uploadImages(otherImages);

      if (values.description) {
        values.description = sanitizeHtml(values.description);
      }

      const response = await createExperience({
        ...values,
        photos: uploadedFiles,
        amenities: [],
        featuredImage: uploadedFeaturedImage[0],
        lat: Number(values?.lat || '0'),
        lng: Number(values?.lng || '0'),
        price: Number(values?.price || '0'),
        terms: String(values?.terms || 'No Terms and Condition'),
      });

      return response;
    },
    {
      onSuccess: (exp) => {
        props.onSuccess(exp?._id);
      },
    },
  );

  queryClient.invalidateQueries({
    queryKey: [`fetch-experiences`],
  });

  const onSubmit = (values: any) => {
    mutate(values);
  };

  return <CreateActivityView onSubmit={onSubmit} isLoading={isLoading} />;
};

export default CreateActivityContainer;
