import React, { FC, useState } from 'react';

import {
  Text,
  Button,
  Divider,
  Select,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  FormControl,
  useToast,
} from '@chakra-ui/react';

import { Field, FieldProps, Form, Formik, useFormikContext } from 'formik';

import { Header } from 'components/layouts/Header';
import { Features } from 'components/modules/Features';
import { Amenities } from 'components/modules/Amenities';
import { Marketing } from 'components/modules/Marketing';

import FormikInput from 'components/primitives/FormikInput';
import FormikTextArea from 'components/primitives/FormikTextArea';
import FormikCalendar from 'components/primitives/FormikCalendar';

import { CreateRoomViewProps } from './CreateRoom.props';

import { Row, Span, Label, Container, Column } from './CreateRoom.style';

import { initialValues, validationSchema } from './CreateRoom.utils';
import ReactQuill from 'react-quill';
import FormikRichText from 'components/primitives/FormikRichText';
import FormikSelectView from 'components/primitives/FormikSelect/FormikSelect.view';
import SelectOption from 'components/primitives/SelectOption';
import { FaBed } from 'react-icons/fa';
import { MdArrowDropDown } from 'react-icons/md';

import SelectMenu from 'components/primitives/SelectMenu';
import { ICONS_MAP, roomTypes } from 'types/room';
import MultiImagePicker from 'components/primitives/MultiImagePicker';

const CreateRoomView: FC<CreateRoomViewProps> = ({
  onSubmit,
  onBackClick,
  isCreating,
}) => {
  const toast = useToast();
  return (
    <Container>
      <Formik
        onSubmit={(value, { setFieldError }) => {
          if (!value.featuredImage) {
            setFieldError('featuredImage', 'Please select featured image.');
            toast({
              description: 'Please select featured image.',
              status: 'error',
              duration: 5000,
              isClosable: true,
            });
            return;
          }
          onSubmit(value);
        }}
        initialValues={initialValues}
        validationSchema={validationSchema}>
        <Form>
          <Header isBackable onBackClick={onBackClick}>
            <Span>Create experience</Span>/Create type of room
          </Header>
          <Container p="40px" pb="50px" maxH="90vh" overflowY="auto">
            <Label>Upload 1 or more pictures about your room</Label>
            <MultiImagePicker key="" />
            <Divider />
            <Row my="20px" gap="25px" justify="flex-start" align="flex-start">
              <Column flex={2} gap="8px">
                <Row flexWrap="nowrap" gap="25px" alignItems="flex-start">
                  <FormikInput name="name" label="Room name" />
                  {/* <FormikInput
                    type="number"
                    name="roomQuantity"
                    label="Room Quantity"
                    pattern="[0-9]*"
                    inputMode="numeric"
                  /> */}
                  <FormikInput
                    type="number"
                    name="price"
                    label="Price"
                    pattern="[0-9]*"
                    inputMode="numeric"
                  />
                </Row>
                <Row flexWrap="nowrap" gap="25px" alignItems="flex-start">
                  <FormikInput
                    type="number"
                    name="weekendPrice"
                    label="Weekend Price"
                    pattern="[0-9]*"
                    inputMode="numeric"
                  />
                  <FormikInput
                    type="number"
                    name="quantity"
                    label="Maximum Occupancy"
                    pattern="[0-9]*"
                    inputMode="numeric"
                  />
                  <SelectMenu
                    name="type"
                    label="Select Room Type"
                    placeHolder="Select Room Type"
                    onChange={(index, setFieldValue) => {
                      setFieldValue('type', roomTypes[index]);
                    }}>
                    {roomTypes.map((type) => {
                      const Icon = ICONS_MAP[type];
                      return (
                        <SelectMenu.Item
                          leftIcon={<Icon size={25} />}
                          value={type}
                        />
                      );
                    })}
                  </SelectMenu>
                </Row>
                <FormikRichText name="description" label="Description" />
              </Column>

              <Column flex={1} align="center" mb="20px">
                <FormikCalendar
                  end="availability.endDate"
                  start="availability.startDate"
                  renderLabel={() => (
                    <Label mt="3" mb="0">
                      Availability
                    </Label>
                  )}
                />
              </Column>
            </Row>
            <Divider />
            <Row my="20px" gap="30px" align="flex-start">
              <Column w="full" justify="flex-start">
                <Text fontSize="16px">
                  Room Features{' '}
                  <Span opacity={0.6}>(You can add upto 3 features)</Span>
                </Text>
                <Features />
              </Column>

              <Column w="full" justify="flex-start">
                <Text fontSize="16px">
                  Room Amenities{' '}
                  <Span opacity={0.6}>(click on Icon to Add/Remove)</Span>
                </Text>
                <Amenities />
              </Column>

              <Column w="full" justify="flex-start">
                <Text fontSize="16px">Room Marketing</Text>
                <Marketing />
              </Column>
            </Row>

            <Column w="full" mt="40px" flex={1} align="center">
              <SubmitButton isCreating={isCreating} />
            </Column>
          </Container>
        </Form>
      </Formik>
    </Container>
  );
};

export default CreateRoomView;

const SubmitButton: FC<{ isCreating: boolean }> = ({ isCreating }) => {
  const { isValid } = useFormikContext();

  return (
    <Button
      w="300px"
      type="submit"
      isDisabled={!isValid}
      isLoading={isCreating}>
      Create
    </Button>
  );
};

const SelectOptionModal: FC<{ options: string[] }> = ({ options }) => {
  return (
    <div>
      <Select placeholder="Select room type"></Select>
    </div>
  );
};
