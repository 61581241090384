import api from './instance';

import { ConnectedAccount, StripeOnboardingResponse } from 'types/stripe';

const URL = `${process.env.REACT_APP_API_URL}/users/stripe/connect`;

export const getStripeAccount = async (stripeId: string) => {
  const { data } = await api.get(`${URL}/${stripeId}`);

  return data?.item as ConnectedAccount;
};

export const stripeOnboarding = async (
  stripeId: string,
  return_url: string,
) => {
  const { data } = await api.post<StripeOnboardingResponse>(
    `${URL}/${stripeId}/onboarding`,
    {
      return_url,
      refresh_url: return_url,
      type: 'account_onboarding',
    },
  );

  return data?.item;
};

export const getStripeDashboardUrl = async (stripeId: string) => {
  const { data } = await api.get<StripeOnboardingResponse>(
    `${URL}/${stripeId}/dashboard`,
  );

  return data?.item?.url;
};
