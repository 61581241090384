import React, { FC, useRef, useState } from 'react';

import { Tabs, TabList, TabPanels, TabPanel } from '@chakra-ui/react';

import { useLocation } from 'react-router-dom';

import { Rooms } from './Rooms';
import { Amenities } from './Amenities';

import ExperienceFormFooter from '../ExperienceFormFooter';

import { AmenitiesRef, CreatePlacesViewProps } from './CreatePlaces.props';

import { StyledTab } from './CreatePlaces.style';

const CreatePlacesView: FC<CreatePlacesViewProps> = ({
  amenities,
  isLoading,
  experienceId,
  onAddAmenities,
}) => {
  const { pathname } = useLocation();

  const [index, setIndex] = useState<number>(0);

  const amenitiesRef = useRef<AmenitiesRef>(null);

  const isDetailsPage = pathname.includes('/details/');

  const onSubmit = () => {
    // if (index === 0) {
    //   setIndex(1);

    //   return;
    // }

    const amenities = amenitiesRef.current?.getAmenities() || [];

    onAddAmenities(amenities);
  };

  return (
    <>
      <Rooms experienceId={experienceId} />
      {isDetailsPage ? null : (
        <ExperienceFormFooter
          progress={66}
          isDisabled={isLoading}
          isLoading={isLoading}
          onCancel={() => {}}
          onSubmit={onSubmit}
          buttonText={index === 0 ? 'Next' : 'Save'}
        />
      )}
    </>
  );
};

export default CreatePlacesView;
