import { IconProps } from './type';

const QueenSizeBed = (props: IconProps) => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.75391 7.83581C4.75391 7.09806 5.35197 6.5 6.08972 6.5H24.4775C25.2153 6.5 25.8133 7.09806 25.8133 7.83581V12.3531C26.5127 12.3956 27.0668 12.9763 27.0668 13.6864V17.3657V18.3654V21.2911H25.3942V22.4621C25.3942 22.9691 24.9832 23.38 24.4763 23.38H23.2226C22.7156 23.38 22.3047 22.9691 22.3047 22.4621V21.2911H8.2614V22.4621C8.2614 22.9691 7.85044 23.38 7.34349 23.38H6.08978C5.58283 23.38 5.17188 22.9691 5.17188 22.4621V21.2911H3.5V18.3654V17.3657V13.6864C3.5 12.9762 4.0543 12.3954 4.75391 12.3531V7.83581ZM15.2826 9.54681C15.4267 9.22865 15.747 9.00732 16.1191 9.00732H23.6414C24.1483 9.00732 24.5593 9.41828 24.5593 9.92523V12.3506H24.8133V7.83581C24.8133 7.65035 24.663 7.5 24.4775 7.5H6.08972C5.90425 7.5 5.75391 7.65035 5.75391 7.83581V12.3506H6.00586V9.92523C6.00586 9.41828 6.41682 9.00732 6.92376 9.00732H14.446C14.8181 9.00732 15.1384 9.22865 15.2826 9.54681ZM4.82865 13.3507C4.64649 13.3545 4.5 13.5033 4.5 13.6864V17.3654H26.0668V13.6864C26.0668 13.5033 25.9203 13.3545 25.7382 13.3507H4.82865ZM25.3942 20.2911V20.2905H22.3047V20.2911H8.2614V20.2905H5.17188V20.2911H4.5V18.3657H26.0668V20.2911H25.3942ZM23.3047 21.2911V22.38H24.3942V21.2911H23.3047ZM7.2614 21.2911H6.17188V22.38H7.2614V21.2911ZM7.00586 10.0073V12.3506H14.3639V10.0073H7.00586ZM16.2012 12.3506V10.0073H23.5593V12.3506H16.2012Z"
        fill="#353535"
      />
    </svg>
  );
};

export default QueenSizeBed;
