import { FC } from 'react';

import { FieldArray, useFormikContext } from 'formik';
import { Row } from '../CreatePlaces/CreatePlaces.style';
import { Button, Text } from '@chakra-ui/react';
import { MdAddCircle, MdClose, MdLocalFireDepartment } from 'react-icons/md';

const ITEMS = [
  'Popular',
  'Premium',
  'Only Few Left',
  'Discounted',
  'Private',
  'Shared',
] as const;
const COLORS = {
  Popular: '#FFC107', // Bright yellow
  Premium: '#4B0082', // Indigo
  'Only Few Left': '#E57373', // Light red
  Discounted: '#4CAF50', // Green
  Private: '#1E88E5', // Blue
  Shared: '#388E3C', // Dark green
} as const;

export const Marketing: FC = () => {
  const { errors, values } = useFormikContext<any>();

  const marketing = values.marketing;
  const error = errors?.marketing as any;

  return (
    <>
      <FieldArray name="marketing">
        {({ push, remove }) => (
          <Row mt="20px" gap="10px" justify="flex-start">
            {ITEMS.map((item) => {
              const isPresent = marketing.includes(item);
              return (
                <Button
                  w="auto"
                  key={item}
                  opacity={isPresent ? 0.6 : 1}
                  leftIcon={<MdLocalFireDepartment size="20px" />}
                  bg={COLORS[item]}
                  rightIcon={
                    isPresent ? (
                      <MdClose size="20px" />
                    ) : (
                      <MdAddCircle size="20px" />
                    )
                  }
                  onClick={() => {
                    if (isPresent) {
                      const _index = marketing.indexOf(item);

                      remove(_index);
                    } else {
                      push(item);
                    }
                  }}>
                  {item}
                </Button>
              );
            })}
          </Row>
        )}
      </FieldArray>
      {error && (
        <Text mt="10px" fontSize="14px" color="red.500">
          {error}
        </Text>
      )}
    </>
  );
};
