import * as Yup from 'yup';

import { Room } from 'types/room';
import { formatDateYYYYMMDD, handleTimeZone } from 'utils/dateTime';

export const getInitialValues = (room: Room) => {
  let start = handleTimeZone(room?.availability?.startDate || '');
  let end = handleTimeZone(room?.availability?.endDate || '');

  const startDate = formatDateYYYYMMDD(start);
  const endDate = formatDateYYYYMMDD(end);

  const tempPhotos = new Set([...(room?.photos ?? [])]);
  if (room.featuredImage) {
    tempPhotos.add(room?.featuredImage!);
  }
  const photos = Array.from(tempPhotos);

  return {
    name: room?.name || '',
    photos: photos || [],
    description: room?.description || '',
    details: room?.details || {
      beds: 0,
      rooms: 0,
      bathrooms: 0,
    },
    availability: {
      startDate: startDate,
      endDate: endDate,
    },
    // roomQuantity: room?.roomQuantity || 1,
    price: room?.price || 0,
    weekendPrice: room?.weekendPrice || 0,
    quantity: room?.quantity || 0,
    type: room?.type || '',
    amenities: room?.amenities || [],
    marketing: room?.marketing || [],
    features: room?.features || [],
    featuredImage: room.featuredImage,
  };
};

export const validationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  photos: Yup.array()
    .min(1, 'please upload at least 1 photo')
    .required('Photos are required'),
  description: Yup.string().required('Description is required'),
  details: Yup.object().shape({
    beds: Yup.number().required('Beds are required'),
    rooms: Yup.number().required('Rooms are required'),
    bathrooms: Yup.number().required('Bathrooms are required'),
  }),
  availability: Yup.object().shape({
    startDate: Yup.string().required('Start date is required'),
    endDate: Yup.string().required('End date is required'),
  }),
  price: Yup.number().required('Price is required'),
  weekendPrice: Yup.number().required('Weekend Price is required'),
  quantity: Yup.number().required('Quantity is required'),
  type: Yup.string().required('Type is required'),
  amenities: Yup.array(),
});
