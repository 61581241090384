import React, { FC, useMemo } from 'react';

import { Flex, HStack, Image } from '@chakra-ui/react';

import { $styling } from 'stores';

import { useFormikContext } from 'formik';

import { Template } from 'types/style';
import { TemplateNavBarProps } from './TemplateNavBar.props';

import {
  LogoBox,
  NavBarBox,
  NavItem,
  NavItemText,
} from './TemplateNavBar.style';

const NAV_ITEMS = ['Home', 'Experiences', 'About us'];

const TemplateNavBarView: FC<TemplateNavBarProps> = (props) => {
  const { values } = useFormikContext<Template>();
  const { logo } = $styling.selectors.usePreviewItems();

  const src = useMemo(() => logo ?? values?.logo, [values.logo, logo]);

  return (
    <NavBarBox
      bg={values.colors.headerColor}
      color={values.colors.primaryColor ?? '#000'}>
      <LogoBox>
        <Image
          src={src}
          h="120px"
          w="120px"
          borderRadius="3px"
          objectFit="cover"
        />
      </LogoBox>

      <Flex gap="5vw">
        {NAV_ITEMS.map((item, index) => (
          <NavItem
            key={`${item}-${index}`}
            // onClick={() => onItemPress(navMap[index])}
          >
            {item}
          </NavItem>
        ))}
      </Flex>

      {/* <HStack display={{ base: 'none', md: 'flex' }} spacing="30px">
        {NAV_ITEMS.map((item, i) => {
          return <NavItemText key={i}>{item}</NavItemText>;
        })}
      </HStack> */}
    </NavBarBox>
  );
};

export default TemplateNavBarView;
