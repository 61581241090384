import React, { FC, useCallback, useEffect, useState } from 'react';

import { Header } from 'components/layouts/Header';
import { CustomTable } from 'components/primitives/CustomTable';

import { ManagementViewProps } from './Management.props';

import { Row, Label, Title, Container } from './Management.style';
import { format } from 'date-fns';
import { CSVLink } from 'react-csv';
import { Box, Button, Flex } from '@chakra-ui/react';
import { useHistory, useLocation } from 'react-router-dom';
import OrderFilter from 'components/modules/OrderFilter';
import { handleTimeZone } from 'utils/dateTime';

const ManagementView: FC<ManagementViewProps> = ({
  orders,
  isLoading,
  pagination,
  onApplyFilter,
  onResetFilter,
}) => {
  const [csvData, setCSV] = useState<any[]>([]);
  const history = useHistory();
  const { search, pathname } = useLocation();

  const updateCSVData = (checkedIds: string[]): void => {
    const hasThisId = (id: string) => checkedIds.includes(id);
    setCSV(
      orders
        .filter((order) => hasThisId(order._id))
        .map((order) => ({
          Guest:
            (order.contactDetails?.fname || '') +
            ' ' +
            (order.contactDetails?.lname || ''),
          Email: order.contactDetails?.email || '',
          CheckIn:
            format(new Date(order.experience?.checkIn), 'MM/dd/yyyy') || '',
          CheckOut:
            format(new Date(order.experience?.checkOut), 'MM/dd/yyyy') || '',
          Room: order.rooms?.[0]?.item?.name || '',
          ExperienceName: order.experience?.item?.title || '',
          NumberOfPeople: order.experience?.people || '',
        })),
    );
  };

  const onChecked = useCallback(
    (checkedIds: string[]): void => {
      updateCSVData(checkedIds);
    },
    [orders],
  );

  const clearAllUrlParams = () => {
    history.replace({
      pathname,
      search: '',
    });
  };

  return (
    <Container>
      <Header>Manage your guests</Header>
      <Container p="20px">
        <Row>
          <Title ml="10px">Customer experience & room detail</Title>
          <Flex mr="3%">
            <Box mr="3%">
              <OrderFilter
                onApplyFilter={onApplyFilter}
                onResetFilter={onResetFilter}
              />
            </Box>
            <Button
              display={search.length > 0 ? 'block' : 'none'}
              onClick={(e) => clearAllUrlParams()}
              mr="3%"
              rounded="full"
              variant="solid"
              colorScheme="secondary">
              Clear Filter
            </Button>
            <Button
              as={CSVLink}
              data={csvData}
              onClick={(e) =>
                csvData.length === 0 ? e.preventDefault() : null
              }
              isDisabled={csvData.length === 0 ? true : false}
              mr="3%"
              rounded="full"
              variant="outline"
              colorScheme="secondary">
              Export
            </Button>
          </Flex>
        </Row>
        <CustomTable
          minW="1000px"
          data={orders}
          onChecked={onChecked}
          isLoading={isLoading}
          pagination={pagination}>
          {{
            headerRow: (
              <>
                <Label flex={1.5}>Guest</Label>
                <Label flex={1.2}>Email</Label>
                <Label flex={1}>Check In</Label>
                <Label flex={1}>Check Out</Label>
                <Label flex={1}>Room</Label>
                <Label flex={1}>Experience Name</Label>
                {/* <Label flex={1}>Room Type</Label> */}
                <Label flex={0.5} textAlign="center">
                  No of People
                </Label>
              </>
            ),
            renderRow: (order) => {
              const checkIn = handleTimeZone(order.experience.checkIn);
              const checkOut = handleTimeZone(order.experience.checkOut);
              return (
                <Row
                  gap="5px"
                  onClick={() => {
                    history.push(`/management/details/${order._id}`);
                  }}>
                  <Label flex={1.3} overflow="hidden" textOverflow="ellipsis">
                    {order?.contactDetails?.fname}
                    {order?.contactDetails?.lname}
                  </Label>
                  <Label flex={1.2} textAlign="center" noOfLines={1}>
                    {order?.contactDetails?.email || '-'}
                  </Label>
                  <Label flex={1} textAlign="center">
                    {format(checkIn, 'MM/dd/yyyy')}
                  </Label>
                  <Label flex={1}>{format(checkOut, 'MM/dd/yyyy')}</Label>
                  <Label flex={1} noOfLines={1}>
                    {order?.rooms?.[0]?.item?.name || '-'}
                  </Label>
                  <Label flex={1} noOfLines={1}>
                    {order?.experience?.item?.title || '-'}
                  </Label>
                  {/* <Label flex={1}>Room Type</Label> */}
                  <Label textAlign="center" flex={0.5}>
                    {order?.experience?.people || '-'}
                  </Label>
                </Row>
              );
            },
          }}
        </CustomTable>
      </Container>
    </Container>
  );
};

export default ManagementView;
