import { Flex, Tab } from '@chakra-ui/react';

import { chakraStyled } from 'utils';

export const Container = chakraStyled(Flex, {
  w: '100%',
  direction: 'column',
});

export const StyledTab = chakraStyled(Tab, {
  minW: '200px',
  border: '2px solid',
  _selected: {
    color: '#F66F4D',
    bg: 'secondary.100',
    borderColor: '#F66F4D',
  },
});
