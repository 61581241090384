import { FC, useEffect } from 'react';

import { Flex } from '@chakra-ui/react';

import { withMotion } from 'utils';

import { useFetchUser } from 'queries';

import { Switch, useHistory, useLocation } from 'react-router-dom';

import ROUTES, { routesSettings } from 'constants/routes';

// IMPORT ROUTE
import ManagementDetails from 'routes/main/ManagementDetails';
import Management from 'routes/main/Management';
import EditProduct from 'routes/main/EditProduct';
import EditRoom from 'routes/main/EditRoom';
import EditExperience from 'routes/main/EditExperience';
import CreateTemplate from 'routes/main/CreateTemplate';
import Styles from 'routes/main/Styles';
import ExperienceDetails from 'routes/main/ExperienceDetails';
import Account from 'routes/main/Account';
import Attributes from 'routes/main/Attributes';
import Products from 'routes/main/Products';
import CreateProduct from 'routes/main/CreateProduct';
import CreateRoom from 'routes/main/CreateRoom';
import CreateExperience from 'routes/main/CreateExperience';
import Dashboard from './Dashboard';

import SideBar from 'components/core/SideBar';
import StripeHandler from 'components/core/StripeHandler';
import AnimatedLoading from 'components/layouts/AnimatedLoading';
import RouteWithSubRoutes from 'components/core/RouteWithSubRoutes';

const routeConfig = Object.freeze({
  // MAIN ROUTE ENTRY
  [ROUTES.MAIN_MANAGEMENT_DETAILS]: {
    component: ManagementDetails,
    path: routesSettings.MAIN_MANAGEMENT_DETAILS.path,
  },
  [ROUTES.MAIN_MANAGEMENT]: {
    component: Management,
    path: routesSettings.MAIN_MANAGEMENT.path,
  },
  [ROUTES.MAIN_EDIT_PRODUCT]: {
    component: EditProduct,
    path: routesSettings.MAIN_EDIT_PRODUCT.path,
  },
  [ROUTES.MAIN_EDIT_ROOM]: {
    component: EditRoom,
    path: routesSettings.MAIN_EDIT_ROOM.path,
  },
  [ROUTES.MAIN_EDIT_EXPERIENCE]: {
    component: EditExperience,
    path: routesSettings.MAIN_EDIT_EXPERIENCE.path,
  },
  [ROUTES.MAIN_CREATE_TEMPLATE]: {
    component: CreateTemplate,
    path: routesSettings.MAIN_CREATE_TEMPLATE.path,
  },
  [ROUTES.MAIN_STYLES]: {
    component: Styles,
    path: routesSettings.MAIN_STYLES.path,
  },
  [ROUTES.MAIN_EXPERIENCE_DETAILS]: {
    component: ExperienceDetails,
    path: routesSettings.MAIN_EXPERIENCE_DETAILS.path,
  },
  [ROUTES.MAIN_ACCOUNT]: {
    component: Account,
    path: routesSettings.MAIN_ACCOUNT.path,
  },
  [ROUTES.MAIN_ATTRIBUTES]: {
    component: Attributes,
    path: routesSettings.MAIN_ATTRIBUTES.path,
  },
  [ROUTES.MAIN_PRODUCTS]: {
    component: Products,
    path: routesSettings.MAIN_PRODUCTS.path,
  },
  [ROUTES.MAIN_CREATE_PRODUCT]: {
    component: CreateProduct,
    path: routesSettings.MAIN_CREATE_PRODUCT.path,
  },
  [ROUTES.MAIN_CREATE_ROOM]: {
    component: CreateRoom,
    path: routesSettings.MAIN_CREATE_ROOM.path,
  },
  [ROUTES.MAIN_CREATE_EXPERIENCE]: {
    component: CreateExperience,
    path: routesSettings.MAIN_CREATE_EXPERIENCE.path,
  },
  [ROUTES.MAIN_DASHBOARD]: {
    component: Dashboard,
    path: routesSettings.MAIN_DASHBOARD.path,
  },
});

const MainRoutes: FC = () => {
  const history = useHistory();
  const location = useLocation();

  const { isLoading } = useFetchUser();

  const isRoomRoute = location.pathname.includes('room');
  const isProductRoute = location.pathname.includes('product');
  const isExperienceRoute =
    location.pathname.includes('experience') &&
    !location.pathname.includes('experiences');

  const isCreateTemplate = location.pathname.includes('styles/template/create');

  const isSideBar =
    !isLoading &&
    !isExperienceRoute &&
    !isRoomRoute &&
    !isProductRoute &&
    !isCreateTemplate;

  useEffect(() => {
    const isDefaultPath = history.location.pathname === '/';

    if (isDefaultPath) {
      history.replace(routeConfig.MAIN_DASHBOARD.path);
    }
  }, []);

  return (
    <Flex flex={1}>
      {isSideBar && <SideBar />}
      <Flex flexGrow={1}>
        {isLoading ? (
          <AnimatedLoading />
        ) : (
          <StripeHandler>
            <Switch>
              {Object.entries(routeConfig).map(([key, val]) => (
                <RouteWithSubRoutes
                  exact
                  key={key}
                  path={val.path}
                  component={withMotion(val.component as any)}
                />
              ))}
            </Switch>
          </StripeHandler>
        )}
      </Flex>
    </Flex>
  );
};

export default MainRoutes;
