import React, { useState } from 'react';

import { useQuery } from 'react-query';
import { fetchAttributes } from 'api/attributes';

import { AttributesProps } from './Attributes.props';

import AttributesView from './Attributes.view';

const ITEMS_PER_PAGE = 10;

const AttributesContainer = (props: AttributesProps) => {
  const [page, setPage] = useState(1);

  const queryKey = ['fetch-attributes', page];
  const queryFn = () =>
    fetchAttributes({
      page,
      limit: ITEMS_PER_PAGE,
    });

  const { data, isLoading, isFetching } = useQuery(queryKey, queryFn);

  const attributes = data?.items || [];
  const totalCount = data?.total || 0;
  const totalPages = data?.totalPages || 0;

  const pagination = {
    page,
    setPage,
    totalPages,
    totalCount,
  };

  return (
    <AttributesView
      attributes={attributes}
      pagination={pagination}
      isLoading={isLoading || isFetching}
    />
  );
};

export default AttributesContainer;
