import { extendTheme } from '@chakra-ui/react';
import '@fontsource/poppins';
import '@fontsource/poppins/400.css';
import '@fontsource/poppins/500.css';
import '@fontsource/poppins/600.css';
import '@fontsource/poppins/700.css';

export const theme = extendTheme({
  fonts: {
    heading: 'Poppins',
    body: 'Poppins',
    mono: 'Poppins',
  },
  fontSizes: {
    xs: '12px',
    sm: '14px',
    md: '16px',
    lg: '18px',
    xl: '24px',
    '2xl': '26px',
    '3xl': '28px',
  },
  lineHeights: {
    normal: 'normal',
    none: '1',
    shorter: '1.25',
    short: '1.375',
    base: '1.5',
    tall: '1.625',
    taller: '2',
  },
  letterSpacings: {
    tighter: '-0.05em',
    tight: '-0.025em',
    normal: '0',
    wide: '0.025em',
    wider: '0.05em',
    widest: '0.1em',
  },
  colors: {
    primary: {
      50: '#E0F0FB',
      100: '#B3D8F2',
      200: '#85BFE9',
      300: '#58A6E1',
      400: '#2B8ED8',
      500: '#2B689B',
      600: '#205376',
      700: '#153D51',
      800: '#0A282C',
      900: '#010207',
    },
    secondary: {
      50: '#FEF3F0',
      100: '#FEDCCE',
      200: '#FEC5AC',
      300: '#FDAE8A',
      400: '#FD9768',
      500: '#EE8764',
      600: '#C16A4F',
      700: '#934D3A',
      800: '#653025',
      900: '#371310',
    },
  },
  components: {
    Button: {
      baseStyle: {
        width: '132px',
        borderRadius: '8px',
      },
      sizes: {
        md: {
          width: '132px',
          height: '2.5rem',
          fontSize: '14px',
        },
        lg: {
          fontSize: '16px',
          height: '2.5rem',
          width: '300px',
        },
      },
      variants: {
        outline: {
          border: '2px solid',
        },
      },
      defaultProps: {
        variant: 'solid',
        colorScheme: 'primary',
      },
    },
  },
});
