import React, { FC } from 'react';

import { Flex, FormLabel, Text } from '@chakra-ui/react';

import { MdChevronLeft, MdChevronRight } from 'react-icons/md';

import { useFormikContext } from 'formik';

import 'react-calendar/dist/Calendar.css';
import { CustomCalendar } from './FormikCalendar.style';

import { FormikCalendarProps } from './FormikCalendar.props';
import { formatDateYYYYMMDD } from 'utils/dateTime';

export const FormikCalendar: FC<FormikCalendarProps> = ({
  end,
  start,
  labelProps,
  renderLabel,
  defaultValue,
}) => {
  const { values, errors, touched, setFieldValue, setFieldTouched } =
    useFormikContext<any>();

  return (
    <Flex gap="10px" direction="column">
      {renderLabel ? (
        renderLabel()
      ) : (
        <FormLabel mt={2} fontWeight="bold" {...labelProps}>
          Date Range
        </FormLabel>
      )}
      <CustomCalendar
        calendarType="Hebrew"
        onClickDay={() => {
          setTimeout(() => {
            setFieldTouched(start, true);
            setFieldTouched(end, true);
          }, 100);
        }}
        onChange={(dates) => {
          if (!Array.isArray(dates)) {
            return;
          }

          const [startDate, endDate] = dates;

          if (!startDate || !endDate) {
            return;
          }
          setFieldValue(start, formatDateYYYYMMDD(new Date(startDate)));
          setFieldValue(end, formatDateYYYYMMDD(new Date(endDate)));
        }}
        defaultValue={defaultValue ? defaultValue : [new Date(), new Date()]}
        selectRange
        returnValue="range"
        prevLabel={<MdChevronLeft size="20px" style={{ marginLeft: 40 }} />}
        nextLabel={<MdChevronRight size="20px" style={{ marginRight: 40 }} />}
        next2Label={null}
        prev2Label={null}
        minDate={new Date()}
      />
      {errors?.availability && touched?.availability && (
        <Text color="red.500" fontSize="sm" textAlign="left">
          Please select the date range
        </Text>
      )}
    </Flex>
  );
};
export default FormikCalendar;
