import React, { FC } from 'react';

import {
  Tabs,
  Button,
  Center,
  Spinner,
  TabList,
  TabPanel,
  TabPanels,
} from '@chakra-ui/react';
import { MdAddCircleOutline } from 'react-icons/md';

import { useProducts } from 'queries';

import { useHistory, useLocation } from 'react-router-dom';
import { routesSettings } from 'constants/routes';

import { getPhotoUrl } from 'utils';

import ProductOptions from '../ProductOptions';
import ExperienceFormFooter from '../ExperienceFormFooter';

import { StandardProductsViewProps } from './StandardProducts.props';

import {
  Row,
  Label,
  Title,
  Column,
  SubTitle,
  Container,
  StyledTab,
  ProductCard,
  AddProductBg,
  ProductImage,
} from './StandardProducts.style';

const StandardProductsView: FC<StandardProductsViewProps> = (props) => {
  const { push } = useHistory();
  const { pathname } = useLocation();
  const { data, isLoading, isFetching } = useProducts(props.experienceId);

  const products = data || [];

  const isDetailsPage = pathname.includes('/details/');

  return (
    <>
      <Container>
        {isLoading || isFetching ? (
          <Center h="400px">
            <Spinner size="lg" />
          </Center>
        ) : (
          <>
            <Label>Product/Services</Label>
            <Row>
              <ProductCard>
                <AddProductBg>
                  <MdAddCircleOutline size="50px" />
                </AddProductBg>
                <Title>Product/Services</Title>
                <Row pb="0">
                  <Button
                    onClick={() => {
                      if (pathname.includes('/details/')) {
                        push({
                          pathname: routesSettings.MAIN_CREATE_PRODUCT.path,
                          state: {
                            experienceId: props.experienceId,
                            redirect: pathname,
                          },
                        });

                        return;
                      }

                      push({
                        pathname: routesSettings.MAIN_CREATE_PRODUCT.path,
                        state: {
                          index: 2,
                          filled: {
                            0: true,
                            1: true,
                            2: true,
                          },
                          experienceId: props.experienceId,
                        },
                      });
                    }}>
                    Create
                  </Button>
                </Row>
              </ProductCard>
              {products.map((product, index) => (
                <ProductCard key={product?.name + product?.description + index}>
                  {isDetailsPage && <ProductOptions product={product} />}
                  <ProductImage src={getPhotoUrl(product?.featuredImage)} />
                  <Title>{product?.name}</Title>
                  <Row pb="0">
                    <Column>
                      <div
                        style={{ maxHeight: '20px', overflow: 'hidden' }}
                        dangerouslySetInnerHTML={{
                          __html: product?.description,
                        }}
                      />
                      {/* <SubTitle>{}</SubTitle> */}
                    </Column>
                  </Row>
                </ProductCard>
              ))}
            </Row>
          </>
        )}
      </Container>
      {isDetailsPage ? null : (
        <ExperienceFormFooter
          progress={90}
          buttonText="Finish"
          isDisabled={false}
          isLoading={false}
          onCancel={() => {}}
          onSubmit={() => {
            props.onFinishClick();
          }}
        />
      )}
    </>
  );
};

export default StandardProductsView;
