import React, { FC } from 'react';

import { $stripe } from 'stores';

import { StripeConnectButton } from 'components/modules/StripeConnectButton';

import { StripeHeaderNoteProps } from './StripeHeaderNote.props';

import {
  Span,
  SubTitle,
  Container,
} from 'routes/main/Dashboard/Dashboard.style';

const StripeHeaderNoteView: FC<StripeHeaderNoteProps> = () => {
  const isAccountEnabled = $stripe.selectors.useIsAccountEnabled();
  const isQueryInProgress = $stripe.selectors.useIsQueryInProgress();

  if (isQueryInProgress || isAccountEnabled) {
    return null;
  }

  return (
    <Container p="10px" bg="secondary.500">
      <SubTitle color="#fff" textAlign="center">
        Don’t forget to connect <Span>stripe</Span> to start processing payments{' '}
        <Span>
          <StripeConnectButton />
        </Span>
      </SubTitle>
    </Container>
  );
};

export default StripeHeaderNoteView;
