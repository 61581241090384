import React, { FC, useEffect, useRef, useState } from 'react';

import { MdCloudUpload } from 'react-icons/md';
import { Button, Image, Input, Textarea } from '@chakra-ui/react';

import { $styling } from 'stores';

import { useFormikContext } from 'formik';
import { useDropzone } from 'react-dropzone';

import { useMutation } from 'react-query';
import { uploadResources } from 'api/style';

import {
  Label,
  Column,
  BackButton,
  PickerContainer,
  MotionContainer,
} from '../CustomSideBar.style';

import { Template } from 'types/style';
import useIsDataChanged from 'hooks/useIsDataChanged';

export const CoverForm: FC = () => {
  const { values, setFieldValue } = useFormikContext<Template>();

  const coverValues = values?.cover?.value;

  const fileRef = useRef<File | null>(null);

  const { coverBg } = $styling.selectors.usePreviewItems();

  const [title, setTitle] = useState(coverValues?.title ?? '');
  const [description, setDescription] = useState(
    coverValues?.description ?? '',
  );

  const [localValue, setLocalValue] = useState(
    () => coverValues?.backgroundImage ?? null,
  );

  const { isDataChanged } = useIsDataChanged([title, description, localValue]);

  const { mutate, isLoading } = useMutation(uploadResources, {
    onSuccess: ({ fileName, previewUrl }) => {
      $styling.actions.setPreviewCoverBg(previewUrl);
      setFieldValue('cover.value.title', title);
      setFieldValue('cover.value.description', description);
      setFieldValue('cover.value.backgroundImage', fileName);
      goBack();
    },
  });

  useEffect(() => {
    setFieldValue('cover.value.title', title);
    setFieldValue('cover.value.description', description);
  }, [title, description]);

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/*': [],
    },
    onDrop: ([file]) => {
      if (file) {
        const fileUrl = URL.createObjectURL(file);

        setLocalValue(fileUrl);
        $styling.actions.setPreviewCoverBg(fileUrl);

        fileRef.current = file;
      }
    },
  });

  const goBack = () => {
    $styling.actions.setSelectedSection(null);
  };

  const onConfirm = () => {
    if (localValue === null) {
      return;
    }

    if (fileRef.current) {
      mutate(fileRef.current);
      fileRef.current = null;
    } else {
      setFieldValue('cover.value.backgroundImage', localValue);
      setFieldValue('cover.value.title', title);
      setFieldValue('cover.value.description', description);
    }
    goBack();
  };

  return (
    <MotionContainer flex={1} key="cover-form">
      <BackButton onClick={goBack}>Cover</BackButton>

      <Column flex={1}>
        <Column gap="0px">
          <Label fontSize="14px">Background Image</Label>
          <PickerContainer {...getRootProps()}>
            {localValue || coverBg ? (
              <Image
                src={
                  localValue?.includes('blob') || localValue?.includes('https')
                    ? localValue
                    : coverBg || undefined
                }
                h="60px"
                w="150px"
                objectFit="cover"
              />
            ) : (
              <MdCloudUpload size="25px" />
            )}
            <input {...getInputProps()} />
          </PickerContainer>
        </Column>

        <Column gap="0px">
          <Label>Title</Label>
          <Input
            size="sm"
            bg="#fff"
            color="#000"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </Column>

        <Column gap="0px">
          <Label>Description</Label>
          <Textarea
            size="sm"
            bg="#fff"
            color="#000"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </Column>
      </Column>

      <Column direction="row" gap="5px">
        <Button size="sm" onClick={goBack}>
          Back
        </Button>
        <Button
          size="sm"
          variant="solid"
          colorScheme="whiteAlpha"
          onClick={onConfirm}
          isLoading={isLoading}
          isDisabled={!isDataChanged}>
          Confirm
        </Button>
      </Column>
    </MotionContainer>
  );
};
