import manClimbingMountain from './man-climbing-mountain.webp';
import womenWatchingPhotos from './women-watchin-photos.webp';
import customerImage from './customerImage.webp';
import screenshotCollage from './screenshots-collage.webp';
import manWorkingOnLaptop from './man-working-on-laptop.webp';
import squareUlisellLogo from './square-logo.png';
import ccaHeadshot from './CCA_headShot.webp';

const assets = {
  customerImage,
  screenshotCollage,
  manClimbingMountain,
  womenWatchingPhotos,
  manWorkingOnLaptop,
  squareUlisellLogo,
  ccaHeadshot,
};

export default assets;
