import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';

import { User } from 'types/auth';

type State = {
  user: User | null;
};

const useUserStore = create(
  immer<State>(() => ({
    user: null,
  })),
);

const selectors = {
  useUser: () => useUserStore((state) => state.user),
  useStripeId: () => useUserStore((state) => state.user?.stripeId),
  useWebsiteId: () => useUserStore((state) => state.user?.website),
};

const actions = {
  setUser: (user: User) => {
    const set = useUserStore.setState;

    set((state) => {
      state.user = user;
    });
  },
};

/**
 * **User Store**
 *
 * this store is used to store user data
 *
 * **"$"** is a convention to indicate that this is a store
 */
const $user = {
  actions,
  selectors,
};

export default $user;
