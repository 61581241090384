import { disableToast, enableToast } from 'utils/queryClient';
import api from './instance';

import {
  LoginPayload,
  LoginResponse,
  RegisterPayload,
  RegisterResponse,
} from 'types/auth';

const URL = `${process.env.REACT_APP_API_URL}`;

export const login = async (payload: LoginPayload) => {
  disableToast();
  const { data } = await api.post<LoginResponse>(`${URL}/auth/login`, payload);
  enableToast();
  return data?.item;
};

export const register = async (payload: RegisterPayload) => {
  disableToast();
  await api.post<RegisterResponse>(`${URL}/auth/register/host`, payload);

  const { id, tokens, username } = await login({
    username: payload.username,
    password: payload.password,
  });
  enableToast();
  return {
    id,
    tokens,
    username,
  };
};
