import React, { useCallback } from 'react';

import { useToast } from '@chakra-ui/react';

import { updateRoom, updateRoomImages, uploadRoomImages } from 'api/rooms';
import { useMutation, useQueryClient } from 'react-query';

import { Room } from 'types/room';

import { EditRoomProps } from './EditRoom.props';

import EditRoomView from './EditRoom.view';
import sanitizeHtml from 'sanitize-html';
import { initialValues } from '../CreateRoom/CreateRoom.utils';

const EditRoomContainer = (props: EditRoomProps) => {
  const toast = useToast();
  const queryClinet = useQueryClient();

  const room = props.location.state;
  const experienceId =
    typeof room?.experience !== 'string' ? room?.experience?._id : '';

  const beforeDeleteCallback = useCallback(
    async (photos: string[], featuredImage: string) => {
      const otherImages = photos.filter(
        (e: any) => typeof e !== 'string' && e.name !== featuredImage,
      );

      await updateRoomImages({
        _id: room._id,
        photos: otherImages,
      });
    },
    [],
  );

  const { mutate, isLoading } = useMutation(
    async (payload: any) => {
      const featureImageName = payload.featuredImage;

      let photos: string[] = payload?.photos?.filter(
        (p: any) => typeof p === 'string' && p !== featureImageName,
      );

      const featuredImage = payload.photos.filter(
        (e: any) => typeof e !== 'string' && e.name === featureImageName,
      );

      const otherImages = payload.photos.filter(
        (e: any) => typeof e !== 'string' && e.name !== featureImageName,
      );

      if (otherImages?.length > 0) {
        const uploadedFiles = await uploadRoomImages(otherImages);
        photos = [...photos, ...uploadedFiles];
      }

      if (featuredImage.length > 0) {
        const uploadedFeaturedImage = await uploadRoomImages(featuredImage);
        payload.featuredImage = uploadedFeaturedImage[0];
      }

      if (payload.description) {
        payload.description = sanitizeHtml(payload.description);
      }

      const response = await updateRoom({
        ...payload,
        _id: room?._id,
        experience: experienceId,
        photos,
      });

      return response;
    },
    {
      onSuccess: () => {
        queryClinet.invalidateQueries({
          queryKey: ['fetch-rooms', experienceId],
        });
        toast({
          title: 'Success',
          description: 'Your room has been updated',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
        props.history.goBack();
      },
    },
  );

  const onSubmit = (payload: Room<File | string>) => {
    payload = {
      ...payload,
      details: {
        beds: Number(payload.details.beds || '1'),
        rooms: Number(payload.details.rooms || '1'),
        bathrooms: Number(payload.details.bathrooms || '1'),
      },
      price: Number(payload.price || '0'),
      weekendPrice: Number(payload.weekendPrice || '0'),
      quantity: Number(payload.quantity || '0'),
    };

    mutate(payload);
  };

  return (
    <EditRoomView
      room={room}
      isUpdating={isLoading}
      onSubmit={onSubmit}
      beforeDeleteCallback={beforeDeleteCallback}
    />
  );
};

export default EditRoomContainer;
