import * as React from 'react';

import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

import { ChakraProvider } from '@chakra-ui/react';

import { queryClient, theme } from 'utils';

import Routes from 'routes';

import { BrowserRouter } from 'react-router-dom';

export const App: React.FC = () => (
  <ChakraProvider theme={theme} resetCSS>
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <Routes />
      </BrowserRouter>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  </ChakraProvider>
);
