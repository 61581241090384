import React, {
  forwardRef,
  ComponentRef,
  ComponentProps,
  ComponentPropsWithoutRef,
} from 'react';

import { ChakraComponent } from '@chakra-ui/react';

const styled = <T extends ChakraComponent<keyof JSX.IntrinsicElements, any>>(
  Component: T,
  props: ComponentPropsWithoutRef<T>,
) => {
  const NewChakraComponent = forwardRef<ComponentRef<T>, ComponentProps<T>>(
    (moreProps, ref) => {
      const combinedProps = {
        ...props,
        ...moreProps,
      };

      return (
        <Component
          ref={ref}
          {...(combinedProps as JSX.LibraryManagedAttributes<T, any>)}
        />
      );
    },
  );

  // set display name for debug
  NewChakraComponent.displayName = Component.displayName || Component.name;

  return NewChakraComponent;
};

export default styled;
