import { FC } from 'react';

import { Box, Checkbox, useToast } from '@chakra-ui/react';

import { Form, Formik } from 'formik';

import ExperienceFormFooter from '../ExperienceFormFooter';

import FormikInput from 'components/primitives/FormikInput';
import FormikCalendar from 'components/primitives/FormikCalendar';
import PlacesAutocomplete from 'components/primitives/PlacesAutocomplete';

import { CreateActivityViewProps } from './CreateActivity.props';

import {
  Row,
  Label,
  Column,
  Container,
  OverflowContainer,
} from './CreateActivity.style';

import { initialValues, validationScehema } from './CreateActivity.utils';
import FormikRichText from 'components/primitives/FormikRichText';
import MultiImagePicker from 'components/primitives/MultiImagePicker';
import { toast } from 'utils/queryClient';
import FormikCheckBox from 'components/primitives/FormikCheckBox';

const CreateActivityView: FC<CreateActivityViewProps> = ({
  onSubmit,
  isLoading,
}) => {
  const toast = useToast();
  return (
    // Example: Manually throw an error for the 'email' field
    <Formik
      enableReinitialize={false} // Disable automatic reinitialization
      onSubmit={(value, { setFieldError }) => {
        if (!value.featuredImage) {
          setFieldError('featuredImage', 'Please select featured image.');
          toast({
            description: 'Please select featured image.',
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
          return;
        }
        onSubmit(value);
      }}
      initialValues={initialValues}
      validationSchema={validationScehema}>
      {({ isValid, values, setFieldValue, handleSubmit }) => (
        <Form>
          <OverflowContainer>
            <Container>
              <Box>
                <FormikInput
                  value={values.title ?? ''}
                  name="title"
                  label="Title"
                />
                <PlacesAutocomplete
                  labelText="Address"
                  name="address"
                  value={values?.address || ''}
                  onSelect={([result]) => {
                    const address = result?.formatted_address;
                    const lat = result?.geometry?.location?.lat();
                    const lng = result?.geometry?.location?.lng();
                    setFieldValue('lat', lat);
                    setFieldValue('lng', lng);
                    setFieldValue('address', address);
                  }}
                />
                <FormikInput
                  value={`${values.price ?? ''}`}
                  type="number"
                  name="price"
                  label="Price"
                />
              </Box>
              <Label mt="20px">
                Upload 1 or more pictures about your experiences
              </Label>

              <MultiImagePicker />

              <Row mt="20px">
                <Column flex={2}>
                  <FormikRichText name="description" label="Description" />
                </Column>
                <Column>
                  <FormikCalendar
                    end="availability.endDate"
                    start="availability.startDate"
                    renderLabel={() => <Label>Availability</Label>}
                  />
                  {/* <FormikCheckBox
                    name="fixedDateRange"
                    label="Set a fixed date range"
                    hint="In this experience type, guests will not be able to choose specific dates."
                  /> */}
                </Column>
              </Row>
              <Column mt="20px">
                <FormikRichText name="terms" label="Terms & Conditions" />
              </Column>
            </Container>
          </OverflowContainer>
          <ExperienceFormFooter
            buttonText="Save"
            progress={33}
            isDisabled={!isValid}
            isLoading={isLoading}
            onCancel={() => {}}
            onSubmit={() => handleSubmit()}
          />
        </Form>
      )}
    </Formik>
  );
};

export default CreateActivityView;
