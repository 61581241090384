import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

type State = {
  isOpen: boolean;
};

const STORAGE_KEY = 'ULTISELL-NAVIGATION';

const useNavigationStore = create(
  persist(
    immer<State>(() => ({
      isOpen: false,
    })),
    {
      name: STORAGE_KEY,
    },
  ),
);

const selectors = {
  useIsNavOpen: () => useNavigationStore((state) => state.isOpen),
};

const actions = {
  setIsOpen: (_isOpen: boolean) => {
    const set = useNavigationStore.setState;

    set((state) => {
      state.isOpen = _isOpen;
    });
  },
};

/**
 * **Navigation Store**
 *
 * this store is used to store Sidebar navigation toggle state
 *
 * **"$"** is a convention to indicate that this is a store
 */
const $navigation = {
  actions,
  selectors,
};

export default $navigation;
