import React, { FC } from 'react';

import { useMutation } from 'react-query';
import { getStripeDashboardUrl, stripeOnboarding } from 'api/stripe';

import { $stripe, $user } from 'stores';

import { StripeConnectButtonProps } from './StripeConnectButton.props';

import StripeConnectButtonView from './StripeConnectButton.view';

const StripeConnectButtonContainer: FC<StripeConnectButtonProps> = ({ sx }) => {
  const stripeId = $user.selectors.useStripeId();
  const isAccountEnabled = $stripe.selectors.useIsAccountEnabled();
  const isDetailsSubmitted = $stripe.selectors.useIsDetailsSubmitted();

  const onboaringMtn = useMutation(
    async () => {
      const returnUrl = `${window.location.origin}${window.location.pathname}?fromStripe=true`;

      return stripeOnboarding(String(stripeId), returnUrl);
    },
    {
      onSuccess: ({ url }) => {
        window.open(url, '_self');
      },
    },
  );

  const dashboardUrlMtn = useMutation(
    () => getStripeDashboardUrl(stripeId || ''),
    {
      onSuccess: (stripeUrl: string) => {
        window.open(stripeUrl, '_blank');
      },
    },
  );

  return (
    <StripeConnectButtonView
      sx={sx}
      isAccountEnabled={isAccountEnabled}
      isDetailsSubmitted={isDetailsSubmitted}
      isLoading={onboaringMtn.isLoading || dashboardUrlMtn.isLoading}
      onConnectClick={() => {
        if (isAccountEnabled) {
          dashboardUrlMtn.mutate();
        } else {
          onboaringMtn.mutate();
        }
      }}
    />
  );
};

export default StripeConnectButtonContainer;
