import React, { FC, memo, useCallback, useState } from 'react';

import { MdDelete, MdSpa } from 'react-icons/md';
import {
  Box,
  Button,
  Flex,
  IconButton,
  Input,
  useToast,
} from '@chakra-ui/react';

import { Form, Formik, useField } from 'formik';

import { useInitializer } from 'hooks';

import { getPhotoUrl } from 'utils';

import { Header } from 'components/layouts/Header';

import FormikInput from 'components/primitives/FormikInput';
import FormikTextArea from 'components/primitives/FormikTextArea';
import FormikCalendar from 'components/primitives/FormikCalendar';
import PlacesAutocomplete from 'components/primitives/PlacesAutocomplete';

import { getInitialValues } from './EditExperience.utils';

import {
  AssetItemProps,
  AssetProp,
  EditExperienceViewProps,
} from './EditExperience.props';

import {
  Row,
  Asset,
  Label,
  Column,
  Container,
  AmenityItem,
  RemoveButton,
  AssetsContainer,
  AddAmenityButton,
  AmenitiesContainer,
} from './EditExperience.style';
import { useHistory } from 'react-router-dom';
import FormikRichText from 'components/primitives/FormikRichText';
import { handleTimeZone } from 'utils/dateTime';
import MultiImagePicker from 'components/primitives/MultiImagePicker';
import FormikCheckBox from 'components/primitives/FormikCheckBox';

const Amenities: FC = () => {
  const [field, meta, helpers] = useField<Array<string>>('amenities');

  return (
    <AmenitiesContainer>
      <Label mb="10px">Experience Amenities</Label>

      <Column gap="10px">
        {field.value?.map((amenity, index) => (
          <AmenityItem key={amenity + index}>
            <MdSpa size="20px" color="#2B689B" />
            <Input
              size="sm"
              w="250px"
              defaultValue={field.value?.[index]}
              onChange={(e) => {
                field.value[index] = e.target.value;
              }}
            />
            <RemoveButton
              aria-label="delete-amenity"
              onClick={() => {
                helpers.setValue(
                  field.value?.filter((_, ind) => ind !== index),
                );
              }}
            />
          </AmenityItem>
        ))}
      </Column>

      <AddAmenityButton
        onClick={() => helpers.setValue([...(field.value || []), ''])}>
        Add Amenities
      </AddAmenityButton>
    </AmenitiesContainer>
  );
};

const EditExperienceView: FC<EditExperienceViewProps> = ({
  onSubmit,
  isLoading,
  experience,
  beforeDeleteCallback,
}) => {
  const startDate = handleTimeZone(experience?.availability.startDate);
  const endDate = handleTimeZone(experience?.availability.endDate);
  const history = useHistory();
  const toast = useToast();
  return (
    <Container>
      <Header isBackable>Edit Experience</Header>
      <Box p={10}>
        <Formik
          enableReinitialize
          onSubmit={(value, { setFieldError }) => {
            if (!value.featuredImage) {
              setFieldError('featuredImage', 'Please select featured image.');
              toast({
                description: 'Please select featured image.',
                status: 'error',
                duration: 5000,
                isClosable: true,
              });
              return;
            }
            onSubmit(value);
          }}
          initialValues={getInitialValues(experience)}>
          {({ values, setFieldValue }) => {
            return (
              <Form>
                <Container>
                  <Container>
                    <Box>
                      <FormikInput label="Title" name="title" minH="40px" />
                      <PlacesAutocomplete
                        labelText="Address"
                        name="address"
                        value={values?.address || ''}
                        onSelect={([result]) => {
                          const address = result?.formatted_address;
                          const lat = result?.geometry?.location?.lat();
                          const lng = result?.geometry?.location?.lng();

                          setFieldValue('lat', lat);
                          setFieldValue('lng', lng);
                          setFieldValue('address', address);
                        }}
                      />
                      <FormikInput
                        type="number"
                        label="Price"
                        name="price"
                        minH="40px"
                      />
                    </Box>
                    <Label mt="20px">
                      Upload 1 or more pictures about your experiences
                    </Label>
                    {/* <Assets _id={experience?._id} /> */}

                    <MultiImagePicker
                      featuredImage={experience?.featuredImage}
                      beforeDeleteCallback={beforeDeleteCallback}
                      onDelete={(currentPhotos: (string | File)[]) => {
                        history.replace(history.location.pathname, {
                          ...(history.location.state as any),
                          photos: currentPhotos,
                        } as any);
                      }}
                    />

                    {/* <Amenities /> */}
                    <Row mt="20px">
                      <Column flex={2}>
                        <FormikRichText
                          name="description"
                          label="Description"
                        />
                      </Column>
                      <Column>
                        <FormikCalendar
                          end="availability.endDate"
                          start="availability.startDate"
                          defaultValue={[startDate, endDate]}
                          renderLabel={() => <Label>Availability</Label>}
                        />

                        <FormikCheckBox
                          name="fixedDateRange"
                          label="Set a fixed date range"
                          hint="In this experience type, guests will not be able to choose specific dates."
                        />
                      </Column>
                    </Row>
                    <Column mt="20px">
                      <FormikRichText name="terms" label="Terms & Conditions" />
                    </Column>
                  </Container>
                </Container>
                <Flex alignItems="center" justifyContent="center">
                  <Button
                    mt="20px"
                    isLoading={isLoading}
                    type="submit"
                    size="lg"
                    colorScheme="secondary">
                    Update
                  </Button>
                </Flex>
              </Form>
            );
          }}
        </Formik>
      </Box>
    </Container>
  );
};

export default EditExperienceView;
