import { ManagementDetailsProps } from './ManagementDetails.props';

import ManagementDetailsView from './ManagementDetails.view';
import { useQuery } from 'react-query';
import { fetchOrderDetails } from 'api/orders';

const ManagementDetailsContainer = (props: ManagementDetailsProps) => {
  const orderId = props.match.params.id;
  const queryKey = ['fetch-order-details', orderId];
  const queryFn = () => fetchOrderDetails(orderId);
  const { data, isFetching } = useQuery(queryKey, queryFn);
  const order = data?.item;
  return <ManagementDetailsView order={order} isFetching={isFetching} />;
};

export default ManagementDetailsContainer;
