import React, { FC } from 'react';
import { ManagementDetailsViewProps } from './ManagementDetails.props';
import { Header } from 'components/layouts/Header';
import {
  Row,
  Title,
  Container,
  Section,
  SectionFlex,
  SubTitle,
  RoomImage,
  ProductImage,
} from './ManagementDetails.style';
import {
  FaCalendarDay,
  FaEllipsisH,
  FaUserFriends,
  FaBed,
} from 'react-icons/fa';

import {
  Avatar,
  Card,
  CardBody,
  Wrap,
  WrapItem,
  Divider,
  Center,
  CircularProgress,
  Box,
  Flex,
  Icon,
  VStack,
  Grid,
  GridItem,
} from '@chakra-ui/react';
import { format } from 'date-fns';
import TitleIconWithData from 'components/primitives/TitleIconWithData';
import { getPhotoUrl } from 'utils';
import { ICONS_MAP } from 'types/room';
import { handleTimeZone } from 'utils/dateTime';

const ManagementDetailsView: FC<ManagementDetailsViewProps> = ({
  order,
  isFetching,
}) => {
  let fullName =
    (order?.contactDetails?.fname || '') +
    ' ' +
    (order?.contactDetails?.lname || '');

  const formatDate = (date: string) => {
    if (date) {
      return format(new Date(date), "EEEE, MMMM d yyyy 'at' h:mm a");
    }
    return '';
  };

  const getIcon = (type: string) => {
    const Icon = ICONS_MAP[type];
    if (Icon) {
      return <Icon color="#F66F4D" size={25} />;
    }
  };

  return isFetching ? (
    <Container>
      <Center minH="60vh">
        <CircularProgress isIndeterminate color="primary.400" />
      </Center>
    </Container>
  ) : (
    <Container>
      <Header isBackable={true}>{fullName} experience details</Header>
      <Container p="20px">
        <Card borderRadius="20px">
          <CardBody marginX="0" paddingX="0">
            <SectionFlex>
              <Wrap justify="space-between" align="center">
                <WrapItem>
                  <Avatar size="lg" name={fullName} />
                </WrapItem>
                <Title ml="20px">{fullName}</Title>
              </Wrap>
              <FaEllipsisH />
            </SectionFlex>
            <Divider />
            <Section>
              <Title>{order?.experience.item.title}</Title>
              <Box h={4} />
              <Flex>
                <Box flex="1">
                  <Flex direction="row">
                    <Box flex="1">
                      <TitleIconWithData
                        title="How many persons"
                        icon={<FaUserFriends color="#F66F4D" />}
                        data={
                          order?.experience.people +
                          ' ' +
                          (order?.experience?.people! > 1
                            ? 'Persons'
                            : 'Person')
                        }
                      />
                    </Box>
                    <Box flex="1">
                      <TitleIconWithData
                        title="Date Booking"
                        icon={<FaCalendarDay color="#F66F4D" />}
                        data={
                          order?.createdAt
                            ? formatDate(order?.createdAt)
                            : 'Invalid Date'
                        }
                      />
                    </Box>
                  </Flex>
                </Box>
                <Box flex="1"></Box>
              </Flex>
            </Section>
            <Divider />

            {order?.rooms?.length! > 0 ? (
              <VStack>
                {order?.rooms.map((room) => {
                  let roomData = room.item;

                  const checkIn = handleTimeZone(order.experience.checkIn);
                  const checkOut = handleTimeZone(order.experience.checkOut);

                  return (
                    <Section>
                      <Flex>
                        <Box flex="1">
                          <Flex direction="row">
                            <Box flex="1">
                              <Title width="90%">{roomData.name}</Title>
                              <SubTitle>Room #123</SubTitle>
                            </Box>
                            <Box flex="3">
                              <Flex>
                                {roomData.photos.map((photo) => {
                                  return (
                                    <RoomImage
                                      backgroundImage={`url(${getPhotoUrl(
                                        photo,
                                      )})`}
                                    />
                                  );
                                })}
                              </Flex>
                            </Box>
                          </Flex>
                        </Box>
                      </Flex>
                      <Box h={4} />
                      <Flex>
                        <Box flex="1">
                          <Flex direction="row">
                            <Box flex="1">
                              <TitleIconWithData
                                title="Type"
                                icon={getIcon(roomData.type)}
                                data={roomData.type}
                              />
                            </Box>
                            <Box flex="1">
                              <TitleIconWithData
                                title="Check in"
                                icon={<FaCalendarDay color="#F66F4D" />}
                                data={
                                  order.experience.checkIn
                                    ? format(
                                        checkIn,
                                        "EEEE, MMMM d yyyy ', 4:00 PM'",
                                      )
                                    : 'Invalid Date'
                                }
                              />
                            </Box>
                          </Flex>
                          <Box h={4} />

                          <Flex direction="row">
                            <Box flex="1">
                              <TitleIconWithData
                                title="Room Capacity"
                                icon={<FaUserFriends color="#F66F4D" />}
                                data={`${roomData.quantity}`}
                              />
                            </Box>
                            <Box flex="1">
                              <TitleIconWithData
                                title="Check out"
                                icon={<FaCalendarDay color="#F66F4D" />}
                                data={
                                  order.experience.checkOut
                                    ? format(
                                        checkOut,
                                        "EEEE, MMMM dd yyyy ', 10:00 AM'",
                                      )
                                    : 'Invalid Date'
                                }
                              />
                            </Box>
                          </Flex>
                        </Box>
                        <Box flex="1">
                          {/* <Flex direction="row">
                            <Box flex="1"></Box>
                            <Box flex="5">
                              <Container>
                                <SubTitle>Ammenities</SubTitle>
                                <Box h={1} />
                                <Grid
                                  templateRows={`repeat(3, 1fr)`}
                                  templateColumns={`repeat(${numColumns}, 1fr)`}
                                  gap={1}>
                                  {roomData?.amenities.map((record, index) => (
                                    <GridItem key={index}>
                                      <SubTitle>{record}</SubTitle>
                                    </GridItem>
                                  ))}
                                </Grid>
                              </Container>
                            </Box>
                          </Flex> */}
                        </Box>
                      </Flex>
                    </Section>
                  );
                })}
                <Divider />
              </VStack>
            ) : (
              <Container></Container>
            )}

            {order?.products?.length! > 0 ? (
              <VStack>
                <Section>
                  <Title>Equipment rentals & Extras</Title>
                  <Box h={4} />
                  {order?.products.map((productData) => {
                    let product = productData.product;
                    let item = productData.item;
                    return (
                      <Container>
                        <Flex>
                          <Box flex={1}>
                            <Flex>
                              <ProductImage
                                backgroundImage={`url(${getPhotoUrl(
                                  product.photos[0],
                                )})`}
                              />
                              <Container>
                                <Box h={1} />
                                <SubTitle
                                  fontSize={14}
                                  opacity={8}
                                  fontWeight={600}>
                                  {product.name}
                                </SubTitle>

                                <Flex>
                                  <SubTitle opacity={1} fontWeight={600}>
                                    Quantity :{' '}
                                  </SubTitle>
                                  <SubTitle ml={1}> {item.quantity}</SubTitle>
                                  <Box w={4} />
                                  <SubTitle opacity={8} fontWeight={600}>
                                    Price :{' '}
                                  </SubTitle>
                                  <SubTitle ml={1}>
                                    {'$'}
                                    {product.price}
                                  </SubTitle>
                                </Flex>
                              </Container>
                            </Flex>
                          </Box>
                        </Flex>
                        <Box h={4} />
                      </Container>
                    );
                  })}
                </Section>
                <Divider />
              </VStack>
            ) : (
              <Container></Container>
            )}
          </CardBody>
        </Card>
      </Container>
    </Container>
  );
};

export default ManagementDetailsView;
