import React, { useEffect } from 'react';

import { Center, Spinner, useToast } from '@chakra-ui/react';

import { $styling } from 'stores';

import { useTemplate } from 'queries';

import { publishSite } from 'api/style';
import { useMutation, useQueryClient } from 'react-query';

import { CreateTemplateProps } from './CreateTemplate.props';

import CreateTemplateView from './CreateTemplate.view';

const CreateTemplateContainer = (props: CreateTemplateProps) => {
  const toast = useToast();
  const queryClient = useQueryClient();

  const { templateId } = props.location.state;

  const { isLoading } = useTemplate(templateId, {
    onSuccess: ({ values, signedURLs }) => {
      const isDraft = $styling.actions.getIsDraft();

      if (isDraft) {
        return;
      }

      console.log(JSON.stringify(values));

      $styling.actions.setValues(
        {
          info: values?.info,
          logo: values?.logo,
          cover: values?.cover,
          about: values?.about,
          store: values?.store,
          colors: values?.colors,
          contact: {
            enabled: values?.contact?.enabled ?? true,
            value: {
              ...values?.contact?.value,
              // change this later once availability logic is set
              availability: {},
            },
          },
          footerLogo: values?.footerLogo ?? true,
          location: values?.location ?? true,
        },
        false,
      );

      $styling.actions.setPreviewLogo(signedURLs.logo);
      $styling.actions.setPreviewCoverBg(signedURLs.backgroundImage);
    },
  });

  const { mutateAsync } = useMutation(publishSite, {
    onSuccess: () => {
      $styling.actions.reset();

      queryClient.invalidateQueries(['fetch-all-templates']);
      queryClient.invalidateQueries(['fetch-template', templateId]);

      toast({
        duration: 3000,
        status: 'success',
        isClosable: true,
        title: 'Website Published',
      });

      props.history.goBack();
    },
  });

  useEffect(() => {
    // remove query on un-mount
    return () => {
      queryClient.removeQueries(['fetch-template']);
    };
  }, []);

  if (isLoading) {
    return (
      <Center flex={1}>
        <Spinner size="xl" />
      </Center>
    );
  }

  return <CreateTemplateView onSubmit={mutateAsync} />;
};

export default CreateTemplateContainer;
