import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';

import { ConnectedAccount } from 'types/stripe';

type StripeStore = {
  isAccountEnabled: boolean;
  isDetailsSubmitted: boolean;
  account: ConnectedAccount | null;
  isQueryInProgress: boolean;
};

const useStripeStore = create(
  immer<StripeStore>(() => ({
    account: null,
    isAccountEnabled: false,
    isDetailsSubmitted: false,
    isQueryInProgress: true,
  })),
);

const checkIfDetaialsSubmitted = (c: ConnectedAccount) => {
  if (c?.details_submitted) {
    return true;
  } else if (c?.tos_acceptance) {
    if ('date' in c?.tos_acceptance && c?.tos_acceptance?.date !== null) {
      return true;
    }
  } else {
    return false;
  }

  return false;
};

const actions = {
  setAccountData: (_account: ConnectedAccount) => {
    const set = useStripeStore.setState;

    const _isAccountEnabled = Boolean(_account?.charges_enabled);
    const _isDetailsSubmitted = checkIfDetaialsSubmitted(_account);

    set((state) => {
      state.account = _account;

      if (_isAccountEnabled) {
        state.isAccountEnabled = true;
      }

      if (_isDetailsSubmitted) {
        state.isDetailsSubmitted = true;
      }
    });
  },

  setProgress: () => {
    const set = useStripeStore.setState;
    set((state) => {
      state.isQueryInProgress = true;
    });
  },
  unsetProgress: () => {
    const set = useStripeStore.setState;
    set((state) => {
      state.isQueryInProgress = false;
    });
  },
};

const selectors = {
  useAccount: () => useStripeStore((state) => state.account),
  useIsAccountEnabled: () => useStripeStore((state) => state.isAccountEnabled),
  useIsDetailsSubmitted: () =>
    useStripeStore((state) => state.isDetailsSubmitted),
  useIsQueryInProgress: () =>
    useStripeStore((state) => state.isQueryInProgress),
};

/**
 * **Stripe Store**
 *
 * this store is used to store stripe connect data
 *
 * **"$"** is a convention to indicate that this is a store
 */
const $stripe = {
  actions,
  selectors,
};

export default $stripe;
