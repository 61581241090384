const Experience = () => {
  return (
    <svg
      width="52"
      height="49"
      viewBox="0 0 52 49"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M25.5 13.5C25.5 12.3954 26.3954 11.5 27.5 11.5H28.5C29.6046 11.5 30.5 12.3954 30.5 13.5V46.5C30.5 47.6046 29.6046 48.5 28.5 48.5H27.5C26.3954 48.5 25.5 47.6046 25.5 46.5V13.5Z"
        fill="#505572"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M28.5 12.5H27.5C26.9477 12.5 26.5 12.9477 26.5 13.5V46.5C26.5 47.0523 26.9477 47.5 27.5 47.5H28.5C29.0523 47.5 29.5 47.0523 29.5 46.5V13.5C29.5 12.9477 29.0523 12.5 28.5 12.5ZM27.5 11.5C26.3954 11.5 25.5 12.3954 25.5 13.5V46.5C25.5 47.6046 26.3954 48.5 27.5 48.5H28.5C29.6046 48.5 30.5 47.6046 30.5 46.5V13.5C30.5 12.3954 29.6046 11.5 28.5 11.5H27.5Z"
        fill="#430062"
      />
      <path
        d="M8.5 47L10.5 41H6L4 47.5C5.2 47.9 7.83333 47.3333 8.5 47Z"
        fill="#BF7267"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.52223 40.853C5.58679 40.6432 5.78062 40.5 6.00012 40.5H10.5001C10.6608 40.5 10.8118 40.5773 10.9057 40.7076C10.9997 40.838 11.0253 41.0056 10.9745 41.1581L8.97446 47.1581C8.93253 47.2839 8.84234 47.3879 8.72373 47.4472C8.31753 47.6503 7.42843 47.8843 6.52407 48.0195C6.06169 48.0886 5.57443 48.1353 5.11819 48.1375C4.669 48.1397 4.21693 48.0993 3.84201 47.9743C3.58433 47.8885 3.44236 47.6126 3.52223 47.353L5.52223 40.853ZM6.36941 41.5L4.64077 47.1181C4.7831 47.1318 4.94129 47.1383 5.1133 47.1375C5.50915 47.1356 5.94689 47.0947 6.37618 47.0305C7.10227 46.9219 7.74779 46.7556 8.0972 46.6276L9.80641 41.5H6.36941Z"
        fill="#430062"
      />
      <path
        d="M40 47L38 41H42.5L44.5 47.5C43.3 47.9 40.6667 47.3333 40 47Z"
        fill="#BF7267"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M42.9778 40.853C42.9132 40.6432 42.7194 40.5 42.4999 40.5H37.9999C37.8392 40.5 37.6882 40.5773 37.5943 40.7076C37.5003 40.838 37.4747 41.0056 37.5255 41.1581L39.5255 47.1581C39.5675 47.2839 39.6577 47.3879 39.7763 47.4472C40.1825 47.6503 41.0716 47.8843 41.9759 48.0195C42.4383 48.0886 42.9256 48.1353 43.3818 48.1375C43.831 48.1397 44.2831 48.0993 44.658 47.9743C44.9157 47.8885 45.0576 47.6126 44.9778 47.353L42.9778 40.853ZM42.1306 41.5L43.8592 47.1181C43.7169 47.1318 43.5587 47.1383 43.3867 47.1375C42.9909 47.1356 42.5531 47.0947 42.1238 47.0305C41.3977 46.9219 40.7522 46.7556 40.4028 46.6276L38.6936 41.5H42.1306Z"
        fill="#430062"
      />
      <path
        d="M45 41.0004L51 23.5004C46.6 22.3004 44.1667 25.3337 43.5 27.0004L41 34.5004C31.1667 34.3337 10.4 34.1004 6 34.5004C1.6 34.9004 0.5 39.0004 0.5 41.0004H45Z"
        fill="#CC8679"
      />
      <path d="M43 40.5L49 23.5H51L45 40.5H43Z" fill="#BF7267" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M43.9696 27.1729L41.4743 34.6585C41.4053 34.8657 41.2098 35.004 40.9915 35.0003C31.1383 34.8333 10.4105 34.6015 6.04527 34.9984C4.02054 35.1824 2.77742 36.2054 2.02445 37.4147C1.38574 38.4405 1.09985 39.6045 1.02252 40.5004H44.6429L50.3483 23.8596C48.5457 23.4982 47.1534 23.9166 46.1248 24.5932C44.9873 25.3413 44.2738 26.4205 43.9696 27.1729ZM45.5752 23.7577C46.9331 22.8646 48.7889 22.3791 51.1316 23.018C51.2649 23.0544 51.3772 23.1443 51.4418 23.2664C51.5065 23.3885 51.5178 23.5319 51.473 23.6626L45.473 41.1626C45.4037 41.3647 45.2136 41.5004 45 41.5004H0.5C0.223858 41.5004 0 41.2766 0 41.0004C0 39.9274 0.291413 38.3061 1.17555 36.8861C2.07258 35.4455 3.57946 34.2184 5.95473 34.0025C10.335 33.6043 30.686 33.8285 40.6417 33.9943L43.0257 26.8423C43.0288 26.833 43.0321 26.8238 43.0358 26.8147C43.4013 25.901 44.236 24.6385 45.5752 23.7577Z"
        fill="#430062"
      />
      <path
        d="M45 18.5096H11.5C11.6667 12.3429 15.3 0.109574 28.5 0.509574C41.7 0.909574 45 12.6762 45 18.5096Z"
        fill="#FFBC5A"
      />
      <path
        d="M34.5002 18.0098C36.5002 7.20977 31.3335 2.17643 28.5002 1.00977C22.1002 2.60977 20.8335 13.0098 21.0002 18.0098H34.5002Z"
        fill="#E05555"
      />
      <path
        d="M43 18.0098C41.4 5.60977 33.6667 1.50977 30 1.00977C41.6 1.80977 44.8333 12.6764 45 18.0098H43Z"
        fill="#EF9D3A"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.6995 6.76522C17.3643 2.86056 21.7267 -0.195904 28.5151 0.00980591C35.2893 0.215084 39.5586 3.34998 42.1163 7.19516C44.6577 11.0158 45.5 15.5181 45.5 18.5096C45.5 18.7857 45.2761 19.0096 45 19.0096H11.5C11.365 19.0096 11.2358 18.955 11.1417 18.8583C11.0476 18.7616 10.9965 18.631 11.0002 18.4961C11.0854 15.3435 12.053 10.6431 14.6995 6.76522ZM12.0213 18.0096H44.492C44.4027 15.1797 43.5549 11.1635 41.2837 7.749C38.8914 4.15251 34.9107 1.20407 28.4849 1.00935C22.0733 0.815057 18.019 3.67526 15.5255 7.32893C13.1527 10.8057 12.1893 15.0136 12.0213 18.0096Z"
        fill="#430062"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M20.5015 18.5577C19.283 5.96723 25.0831 1.12132 28.3416 0.0351562L28.6578 0.98384C25.9163 1.89767 20.3152 6.25177 21.4968 18.4613L20.5015 18.5577Z"
        fill="#430062"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M35.4993 18.5577C36.7147 5.99797 31.4419 1.12962 28.1585 0.0351562L27.8423 0.98384C30.5589 1.88937 35.6885 6.22103 34.5039 18.4613L35.4993 18.5577Z"
        fill="#430062"
      />
    </svg>
  );
};

export default Experience;
