import React, { FC, useMemo } from 'react';

import { Box, Flex, Link, Text, Image } from '@chakra-ui/react';

import { $styling } from 'stores';

import { BsTelephone } from 'react-icons/bs';
import { MdOutlineLocationOn, MdOutlineMailOutline } from 'react-icons/md';

import { useFormikContext } from 'formik';

import { Template } from 'types/style';
import { TemplateFooterProps } from './TemplateFooter.props';

import { Footer, LogoBox } from './TemplateFooter.style';
import TemplateContactInfo from '../TemplateContactInfo';

const TemplateFooterView: FC<TemplateFooterProps> = (props) => {
  const { values } = useFormikContext<Template>();
  const { logo } = $styling.selectors.usePreviewItems();

  const footerLogo = values?.footerLogo;
  const contactNo = values?.contact?.value?.phone;
  const email = values?.contact?.value?.email;
  const address = values?.location?.value?.address;

  const src = useMemo(() => logo ?? values?.logo, [values.logo, logo]);

  const darkColor = values?.colors?.dark;

  return (
    <Footer
      bg="#000"
      color={values.colors.primaryColor ?? '#000'}
      backgroundColor={values.colors.footerColor}>
      <TemplateContactInfo />
      <Text>@Ultisell, All rights reserved</Text>
      {/* <Box marginTop="auto" marginBottom="auto">
        * <Text h="50px" fontSize="24px">
          logoipsum
        </Text> 
        {footerLogo && (
          <LogoBox mb={3}>
            <Image
              src={src}
              h="55px"
              w="150px"
              borderRadius="3px"
              objectFit="cover"
            />
          </LogoBox>
        )}
        <Flex justifyContent="center" alignItems="center">
          <MdOutlineLocationOn />
          <Text>{address}</Text>
        </Flex>
       
      </Box>

      <Flex justifyContent="center" alignItems="center">
        <BsTelephone />
        <Text ml="10px">{contactNo}</Text>
      </Flex>

      <Flex justifyContent="center" alignItems="center">
        <MdOutlineMailOutline />
        <Text ml="10px">{email}</Text>
      </Flex>

      <Flex marginTop="auto" marginBottom="auto" direction="column">
        <Text mb="20px" fontWeight="500">
          Support
        </Text>
        <Text as={Link}>Privacy Policy</Text>
        <Text as={Link}>Terms of service</Text>
      </Flex> */}
    </Footer>
  );
};

export default TemplateFooterView;
