export const S3_URL_PREFIX = process.env.REACT_APP_BUCKET_URL;

export const getPhotoUrl = (fileName: string) => {
  if (!fileName) {
    return undefined;
  }

  if (fileName.includes('https')) {
    return fileName;
  } else {
    return `${S3_URL_PREFIX}/${fileName}`;
  }
};
