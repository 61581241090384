import axios from 'axios';

import { $session } from 'stores';

import { LoginResponse } from 'types/auth';

import createAuthRefreshInterceptor from 'axios-auth-refresh';

const URL = `${process.env.REACT_APP_API_URL}`;

const DEFAULT_HEADERS: Record<string, string> = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

const instance = axios.create({
  baseURL: URL,
  headers: DEFAULT_HEADERS,
});

instance.interceptors.request.use((request) => {
  const tokens = $session.actions.getTokens();
  const jwtToken = tokens?.jwtToken || '';

  if (jwtToken) {
    request.headers.Authorization = `Bearer ${jwtToken}`;
  }

  return request;
});

const refreshAuthToken = async (payload: {
  username: string;
  refreshToken: string;
}) => {
  const { data } = await axios.post(`${URL}/auth/refresh-token`, payload);

  return data as LoginResponse;
};

const refreshTokenLogic = async (callbackRequest: any) => {
  try {
    if (callbackRequest.response.data.code === 'expired_token') {
      const tokens = $session.actions.getTokens();
      const username = $session.actions.getUsername() || '';

      if (!tokens) {
        return Promise.reject({
          response: {
            data: {
              message: 'Please Log in',
            },
          },
        });
      }

      const refreshToken = tokens.refreshToken;

      const data = await refreshAuthToken({
        username,
        refreshToken,
      })
        .then((data) => data)
        .catch(() => null);

      if (!data) {
        return Promise.reject({
          response: {
            data: {
              type: 'session_expired',
              message: 'Your session has expired. Please Log in again.',
            },
          },
        });
      }

      $session.actions.setSession({
        id: data.item.id,
        tokens: data.item.tokens,
        username: data.item.username,
      });

      callbackRequest.response.config.headers['Authorization'] =
        `Bearer ${data.item.tokens.jwtToken}`;

      return Promise.resolve();
    } else {
      window.location.href = '/login';

      $session.actions.clearSession();

      return Promise.resolve();
    }
  } catch (error: any) {
    throw new Error(error);
  }
};

const options = { statusCodes: [401] };

createAuthRefreshInterceptor(instance, refreshTokenLogic, options);

export default instance;
