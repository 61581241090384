import { IconProps } from './type';

const CaliforniaKingBed = (props: IconProps) => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.17188 7.83581C3.17188 7.09806 3.76994 6.5 4.50768 6.5H24.5671C25.3048 6.5 25.9029 7.09806 25.9029 7.83581V12.3501H26.2385C26.9762 12.3501 27.5743 12.9482 27.5743 13.6859V17.3652V18.365V21.2906H25.902V22.4626C25.902 22.9696 25.4911 23.3805 24.9841 23.3805H23.7304C23.2235 23.3805 22.8125 22.9696 22.8125 22.4626V21.2906H6.2614V22.4626C6.2614 22.9696 5.85044 23.3805 5.34349 23.3805H4.08978C3.58283 23.3805 3.17188 22.9696 3.17188 22.4626V21.2906H1.5V18.365V17.3652V13.6859C1.5 12.9482 2.09806 12.3501 2.83581 12.3501H3.17188V7.83581ZM14.873 12.3501H14.2018V9.92523C14.2018 9.41828 13.7909 9.00732 13.2839 9.00732H5.76165C5.25471 9.00732 4.84375 9.41828 4.84375 9.92523V12.3501H4.17188V7.83581C4.17188 7.65035 4.32222 7.5 4.50768 7.5H24.5671C24.7526 7.5 24.9029 7.65035 24.9029 7.83581V12.3501H24.2311V9.92523C24.2311 9.41828 23.8202 9.00732 23.3132 9.00732H15.791C15.284 9.00732 14.873 9.41828 14.873 9.92523V12.3501ZM23.2311 12.3501H15.873V10.0073H23.2311V12.3501ZM13.2018 10.0073V12.3501H5.84375V10.0073H13.2018ZM3.17188 13.3501H2.83581C2.65035 13.3501 2.5 13.5004 2.5 13.6859V17.365H26.5743V13.6859C26.5743 13.5004 26.4239 13.3501 26.2385 13.3501H25.9029V13.3507H3.17188V13.3501ZM2.5 18.3652V20.2906H26.5743V18.3652H2.5ZM4.17188 21.291V22.3805H5.2614V21.291H4.17188ZM23.8125 22.3805V21.291H24.902V22.3805H23.8125Z"
        fill="#353535"
      />
    </svg>
  );
};

export default CaliforniaKingBed;
