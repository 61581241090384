import { markAsFeatured } from 'api/experience';
import { useMutation } from 'react-query';

const useMarkFeaturedExperience = (onSuccess?: (res: any) => void) => {
  return useMutation((experienceId: string) => markAsFeatured(experienceId), {
    onSuccess: (response) => {
      onSuccess && onSuccess(response);
    },
  });
};

export default useMarkFeaturedExperience;
