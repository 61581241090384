import api from './instance';

import { $session } from 'stores';
import {
  CreateExperiencePayload,
  Experience,
  ExperienceImages,
  ExperienceVisibilityPayload,
  FeatureExperienceResponse,
} from 'types/experience';
import { ListResponse } from 'types/common';

const URL = `${process.env.REACT_APP_API_URL}/experiences`;

export const uploadImages = (
  files: File[],
  onProgress?: (progress: number) => void,
) => {
  return new Promise<string[]>((resolve, reject) => {
    const formData = new FormData();

    files.forEach((file) => {
      formData.append('images', file);
    });

    const jwtToken = $session.actions.getTokens()?.jwtToken || '';

    const url = `${URL}/host/upload-images`;

    const xhr = new XMLHttpRequest();

    xhr.open('POST', url, true);
    xhr.setRequestHeader('Authorization', `Bearer ${jwtToken}`);

    xhr.upload.onprogress = (e) => {
      if (e.lengthComputable) {
        const progress = (e.loaded / e.total) * 100;

        onProgress?.(progress);
      }
    };

    xhr.onload = () => {
      if (xhr.status === 201) {
        const response = JSON.parse(xhr.response);

        const files = response?.item as string[];

        resolve(files);
      } else {
        reject(
          new Error(
            `Request failed. Status: ${xhr.status}. Content: ${
              JSON.parse(xhr.response)?.message
            }`,
          ),
        );
      }
    };

    xhr.send(formData);
  });
};

export const createExperience = async (payload: CreateExperiencePayload) => {
  const { data } = await api.post(`${URL}/host/create`, payload);

  return data?.item;
};

export const fetchExperiences = async (params?: {
  page?: number;
  limit?: number;
  host?: string;
}) => {
  const { data } = await api.get<ListResponse<Experience>>(`${URL}/host/list`, {
    params,
  });

  return data;
};

export const updateExperience = async (
  payload: Partial<CreateExperiencePayload>,
  experienceId: string,
) => {
  const { data } = await api.patch(
    `${URL}/host/update/${experienceId}`,
    payload,
  );
  return data;
};

export const updateExperienceImages = async ({
  _id,
  ...payload
}: ExperienceImages) => {
  const { data } = await api.patch(`${URL}/host/update/${_id}`, payload);
  return data;
};

export const fetchExperienceById = async (experienceId: string) => {
  const { data } = await api.get<{ item: Experience }>(
    `${URL}/host/detail/${experienceId}`,
  );
  return data?.item;
};

export const deleteExperienceById = async (params: { ids: string[] }) => {
  const { ids } = params;
  const { data } = await api.delete(`${URL}/host/remove-many`, {
    data: { ids },
  });

  return data?.message;
};

export const markAsFeatured = async (experienceId: string) => {
  const { data } = await api.patch(`${URL}/mark-as-featured/${experienceId}`);
  return data?.message;
};

export const getFeaturedExperience = async (websiteId: string) => {
  const { data } = await api.get<FeatureExperienceResponse>(
    `${URL}/get-featured/${websiteId}`,
  );
  return data?.item;
};

export const setVisibility = async (payload: ExperienceVisibilityPayload) => {
  const visibility = payload.visibility;
  const { data } = await api.patch(`${URL}/host/update/${payload.id}`, {
    visibility,
  });
  return data?.message;
};
