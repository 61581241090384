import React, { FC, useState } from 'react';

import { Header } from 'components/layouts/Header';
import { StripeConnect } from 'components/modules/StripeConnect';

import { Text, Flex, Switch } from '@chakra-ui/react';

import { AccountViewProps } from './Account.props';

import {
  AccountDetailBox,
  Container,
  DetailsBox,
  Title,
  BasicInfo,
  ActiveText,
  EmailNotificationFlex,
  EmailBox,
  NameBox,
  TitleText,
} from './Account.style';

const AccountView: FC<AccountViewProps> = ({ userData }) => {
  const [isChecked, setIsChecked] = useState(false);
  return (
    <Container>
      <Header>Account information</Header>
      <AccountDetailBox>
        <DetailsBox>
          <Title>Account details</Title>
          <BasicInfo>
            <EmailBox>
              <TitleText>Email</TitleText>
              <Text>{userData?.email}</Text>
            </EmailBox>
            <NameBox>
              <TitleText>Name</TitleText>
              <Text>{userData?.username}</Text>
            </NameBox>
          </BasicInfo>
          {/* <EmailNotificationFlex>
            <TitleText>Email Notifications</TitleText>
            <Flex>
              <Switch
                mt={1}
                isChecked={isChecked}
                onChange={() => setIsChecked(!isChecked)}
                colorScheme="red"
              />
              {isChecked ? <ActiveText>Active</ActiveText> : null}
            </Flex>
          </EmailNotificationFlex> */}
        </DetailsBox>
      </AccountDetailBox>

      <StripeConnect />

      {/* Pricing Table  */}
      {/* <PricingTableBox>
        <Title>Plans</Title>
      </PricingTableBox> */}
    </Container>
  );
};

export default AccountView;
