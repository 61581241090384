import { setVisibility } from 'api/experience';
import { useMutation } from 'react-query';
import { ExperienceVisibilityPayload } from 'types/experience';

const useSetExperienceVisibility = (onSuccess?: () => void) =>
  useMutation(
    (payload: ExperienceVisibilityPayload) => setVisibility(payload),
    { onSuccess: onSuccess },
  );

export default useSetExperienceVisibility;
