import React, { useEffect, useRef, useState } from 'react';

const useIsDataChanged = (deps: any[]) => {
  const didMount = useRef(false);
  const [isDataChanged, setIsDataChanged] = useState<boolean>(false);

  useEffect(() => {
    if (didMount.current) {
      setIsDataChanged(true);
    } else didMount.current = true;
  }, deps);

  return {
    isDataChanged,
  };
};

export default useIsDataChanged;
