import React, { FC, useCallback, useRef } from 'react';

import { Tabs, TabList, TabPanels, TabPanel } from '@chakra-ui/react';

import { Header } from 'components/layouts/Header';
import { CreatePlaces } from 'components/modules/CreatePlaces';
import { SuccessModal } from 'components/modules/SuccessModal';
import { CreateActivity } from 'components/modules/CreateActivity';
import { StandardProducts } from 'components/modules/StandardProducts';

import { CreateExperienceViewProps } from './CreateExperience.props';

import { StyledTab, Container } from './CreateExperience.style';

const CreateExperienceView: FC<CreateExperienceViewProps> = ({
  index,
  filled,
  goBack,
  onComplete,
  onBackClick,
  experienceId,
  onChangeIndex,
  setExperienceId,
}) => {
  const successModalRef = useRef<any>(null);

  const getTabProps = useCallback(
    (isFilled: boolean) => ({
      isDisabled: !isFilled,
      color: isFilled ? 'secondary.500' : 'auto',
      bg: isFilled ? 'secondary.100' : '#fff',
      borderColor: isFilled ? 'secondary.100' : 'auto',
    }),
    [],
  );

  return (
    <Container>
      <Header isBackable isCentered onBackClick={onBackClick}>
        Create Experience
      </Header>

      <Tabs mt="30px" align="center" variant="soft-rounded" index={index}>
        <TabList gap="20px" flexWrap="wrap">
          <StyledTab {...getTabProps(filled[0])}>
            1. Create Experience
          </StyledTab>
          <StyledTab {...getTabProps(filled[1])}>2. Create Rooms</StyledTab>
          <StyledTab {...getTabProps(filled[2])}>
            3. Create products/services
          </StyledTab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <CreateActivity
              onSuccess={(_experienceId) => {
                setExperienceId(_experienceId);
                onChangeIndex(1);
              }}
            />
          </TabPanel>
          <TabPanel p="0">
            <CreatePlaces
              experienceId={experienceId}
              onNextClick={() => {
                onChangeIndex(2);
              }}
            />
          </TabPanel>
          <TabPanel p="0">
            <StandardProducts
              experienceId={experienceId}
              onFinishClick={() => {
                successModalRef.current?.onOpen();
              }}
            />

            <SuccessModal
              onCancel={goBack}
              ref={successModalRef}
              onComplete={onComplete}
            />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Container>
  );
};

export default CreateExperienceView;
