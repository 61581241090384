import React, { FC } from 'react';

import { Image, Box, IconButton } from '@chakra-ui/react';

import { useHistory } from 'react-router-dom';

import { StyleCardProps } from './StyleCard.props';

import {
  CardBox,
  UseButton,
  WebsiteName,
  PreviewButton,
} from './StyleCard.style';
import { MdEdit } from 'react-icons/md';
import { FaEdit } from 'react-icons/fa';

const StyleCardView: FC<StyleCardProps> = ({
  id,
  logo,
  handlePreviewClick,
}) => {
  const history = useHistory();

  const handleClick = () => {
    history.push('/styles/template/create', {
      templateId: id,
    });
  };

  return (
    <CardBox position="relative">
      <Image width="100%" src={logo} borderRadius="lg" />
      <Box position="absolute" right={2} top={2}>
        <IconButton
          onClick={handleClick}
          width={10}
          height={10}
          size="sm"
          icon={<FaEdit size={20} />}
          aria-label={'edit-template'}
        />
      </Box>
    </CardBox>
  );
};

export default StyleCardView;
