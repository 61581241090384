import React, { FC } from 'react';

import { Text, Button, Divider, useToast } from '@chakra-ui/react';

import { Field, FieldProps, Form, Formik } from 'formik';

import { Header } from 'components/layouts/Header';
import { Features } from 'components/modules/Features';
import { Amenities } from 'components/modules/Amenities';
import { Marketing } from 'components/modules/Marketing';

import FormikInput from 'components/primitives/FormikInput';
import { Option } from 'components/primitives/SelectMenu/SelectMenu.utils';
import FormikCalendar from 'components/primitives/FormikCalendar';

import { EditRoomViewProps } from './EditRoom.props';

import { getInitialValues, validationSchema } from './EditRoom.utils';

import { Row, Label, Container, Column, Span } from './EditRoom.style';
import FormikRichText from 'components/primitives/FormikRichText';

import SelectMenu from 'components/primitives/SelectMenu';

import { ICONS_MAP, roomTypes } from 'types/room';
import { handleTimeZone } from 'utils/dateTime';
import MultiImagePicker from 'components/primitives/MultiImagePicker';
import { useHistory } from 'react-router-dom';

const EditRoomView: FC<EditRoomViewProps> = ({
  room,
  onSubmit,
  beforeDeleteCallback,
  isUpdating,
}) => {
  const getIcon = (type: string) => {
    const Icon = ICONS_MAP[type];
    if (Icon) {
      return <Icon size={25} />;
    }
  };

  const startDate = handleTimeZone(room?.availability.startDate);
  const endDate = handleTimeZone(room?.availability.endDate);

  const history = useHistory();
  const toast = useToast();
  return (
    <Container>
      <Formik
        enableReinitialize
        onSubmit={(value, { setFieldError }) => {
          if (!value.featuredImage) {
            setFieldError('featuredImage', 'Please select featured image.');
            toast({
              description: 'Please select featured image.',
              status: 'error',
              duration: 5000,
              isClosable: true,
            });
            return;
          }
          onSubmit(value);
        }}
        initialValues={getInitialValues(room)}
        validationSchema={validationSchema}>
        {({ isValid }) => (
          <Form>
            <Header isBackable>Edit room</Header>
            <Container p="40px" pb="50px" maxH="90vh" overflowY="auto">
              <Label>Upload 1 or more pictures about your room</Label>
              <MultiImagePicker
                featuredImage={room?.featuredImage}
                beforeDeleteCallback={beforeDeleteCallback}
                onDelete={(currentPhotos: (string | File)[]) => {
                  history.replace(history.location.pathname, {
                    ...(history.location.state as any),
                    photos: currentPhotos,
                  } as any);
                }}
              />

              <Divider />
              <Row my="20px" gap="25px" justify="flex-start" align="flex-start">
                <Column flex={2} gap="8px">
                  <Row flexWrap="nowrap" gap="25px" alignItems="flex-start">
                    <FormikInput name="name" label="Room name" />
                    {/* <FormikInput
                      type="number"
                      name="roomQuantity"
                      label="Room Quantity"
                      pattern="[0-9]*"
                      inputMode="numeric"
                    /> */}
                    <FormikInput
                      type="number"
                      name="price"
                      label="Price"
                      pattern="[0-9]*"
                      inputMode="numeric"
                    />
                  </Row>

                  <Row flexWrap="nowrap" gap="25px" alignItems="flex-start">
                    <FormikInput
                      type="number"
                      name="weekendPrice"
                      label="Weekend Price"
                      pattern="[0-9]*"
                      inputMode="numeric"
                    />
                    <FormikInput
                      type="number"
                      name="quantity"
                      label="Maximum Occupancy"
                      pattern="[0-9]*"
                      inputMode="numeric"
                    />

                    <SelectMenu
                      name="type"
                      label="Select Room Type"
                      placeHolder="Select Room Type"
                      initialValue={
                        room.type
                          ? ({
                              value: room.type,
                              leftIcon: getIcon(room.type),
                            } as Option)
                          : null
                      }
                      onChange={(index, setFieldValue) => {
                        setFieldValue('type', roomTypes[index]);
                      }}>
                      {roomTypes.map((type) => {
                        return (
                          <SelectMenu.Item
                            leftIcon={getIcon(type)}
                            value={type}
                          />
                        );
                      })}
                    </SelectMenu>
                  </Row>
                  <FormikRichText name="description" label="Description" />
                </Column>
                {/* <Column>
                      <FormikTextArea
                        renderLabel={() => (
                          <Label mt="3" mb="2">
                            Description
                          </Label>
                        )}
                        name="description"
                        minH="315px"
                      />
                    </Column> */}
                <Column flex={1} align="center" mb="20px">
                  <FormikCalendar
                    end="availability.endDate"
                    start="availability.startDate"
                    defaultValue={[startDate, endDate]}
                    renderLabel={() => (
                      <Label mt="3" mb="0">
                        Availability
                      </Label>
                    )}
                  />
                </Column>
              </Row>
              <Divider />
              <Row my="20px" gap="30px" align="flex-start">
                <Column w="full" justify="flex-start">
                  <Text fontSize="16px">
                    Room Features{' '}
                    <Span opacity={0.6}>(You can add upto 3 features)</Span>
                  </Text>
                  <Features />
                </Column>

                <Column w="full" justify="flex-start">
                  <Text fontSize="16px">
                    Room Amenities{' '}
                    <Span opacity={0.6}>(click on Icon to Add/Remove)</Span>
                  </Text>
                  <Amenities />
                </Column>

                <Column w="full" justify="flex-start">
                  <Text fontSize="16px">Room Marketing</Text>
                  <Marketing />
                </Column>
              </Row>
              <Container mt="40px" justify="center" align="center">
                <Button
                  w="200px"
                  size="lg"
                  type="submit"
                  isDisabled={!isValid}
                  colorScheme="secondary"
                  isLoading={isUpdating}>
                  Update
                </Button>
              </Container>
            </Container>
          </Form>
        )}
      </Formik>
    </Container>
  );
};

export default EditRoomView;
