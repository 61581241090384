import { Box, Flex, IconButton, Image, Link, Text } from '@chakra-ui/react';

import { chakraMotionStyled, chakraStyled } from 'utils';

const variants = {
  hidden: {
    width: '60px',
  },
  visible: {
    width: '200px',
  },
};

export const Container = chakraMotionStyled(
  Flex,
  {
    direction: 'column',
    gap: '20px',
    h: '100%',
    position: 'fixed',
    left: '0',
    top: '0',
    bottom: '0',
  },
  {
    initial: false,
    variants,
    transition: {
      duration: 0.3,
    },
  },
);

export const Nav = chakraStyled(Box, {
  as: 'nav',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'flex-start',
  w: 'full',
  h: 'full',
  p: '6',
  bg: 'primary.500',
  color: '#fff',
  overflow: 'hidden',
});

export const ToggleContainer = chakraStyled(Flex, {
  justify: 'flex-end',
  align: 'flex-start',
});

export const Toggle = chakraStyled(IconButton as any, {
  w: '40px',
  'aria-label': 'toggle sidebar',
  variant: 'ghost',
  colorScheme: 'whiteAlpha',
  color: '#fff',
});

export const Logo = chakraStyled(Image, {
  w: 'full',
  minW: '50px',
  h: '50px',
  objectFit: 'contain',
  borderRadius: '10px',
  bg: '#fff',
  cursor: 'pointer',
  userSelect: 'none',
  draggable: false,
  my: '20px',
  p: '5px',
});

export const LinkItem = chakraStyled(Flex, {
  as: Link,
  align: 'center',
  justify: 'flex-start',
  gap: '10px',
  p: '6px',
  borderRadius: '3px',
  w: '100%',
});

export const Name = chakraStyled(Text, {
  fontSize: '16px',
  fontWeight: '600',
  userSelect: 'none',
  noOfLines: 1,
});
