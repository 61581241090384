import { Flex, Text } from '@chakra-ui/react';

import { chakraStyled } from 'utils';

export const Container = chakraStyled(Flex, {
  w: '100%',
  direction: 'column',
});

export const Row = chakraStyled(Flex, {
  w: '100%',
  direction: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  flexWrap: 'wrap',
});

export const Label = chakraStyled(Text, {
  fontSize: '14px',
});
