import { Flex, Text } from '@chakra-ui/react';

import { chakraStyled } from 'utils';

export const Card = chakraStyled(Flex, {
  flex: 1,
  p: '15px',
  borderRadius: '10px',
  boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.05)',
  gap: '10px',
  minW: '200px',
});

export const IconContainer = chakraStyled(Flex, {
  p: '10px',
  h: '50px',
  w: '50px',
  borderRadius: '10px',
  bg: 'secondary.50',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: 'secondary.500',
});

export const Column = chakraStyled(Flex, {
  direction: 'column',
  flex: 1,
});

export const Total = chakraStyled(Text, {
  fontSize: '24px',
});

export const SubTitle = chakraStyled(Text, {
  fontSize: '14px',
});
