import React, { FC, useState } from 'react';

import {
  Tab,
  Box,
  Flex,
  Menu,
  Tabs,
  Text,
  Wrap,
  Modal,
  Button,
  Center,
  WrapItem,
  TabPanel,
  TabPanels,
  MenuItem,
  MenuList,
  ModalBody,
  MenuButton,
  IconButton,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  CircularProgress,
  ModalCloseButton,
} from '@chakra-ui/react';

import { SlCalender, SlOptions } from 'react-icons/sl';
import { MdDeleteForever, MdEdit } from 'react-icons/md';

import { useHistory } from 'react-router-dom';

import { Header } from 'components/layouts/Header';
import { CreatePlaces } from 'components/modules/CreatePlaces';
import { StandardProducts } from 'components/modules/StandardProducts';

import AssetsCarousel from 'components/modules/AssetsCarousel';

import { ExperienceDetailsViewProps } from './ExperienceDetails.props';

import {
  Container,
  DeleteText,
  ExpSubTitle,
  OuterContainer,
} from './ExperienceDetails.style';

const ExperienceDetailsView: FC<ExperienceDetailsViewProps> = ({
  isDeleting,
  experience,
  isLoading,
  onDeleteClick,
}) => {
  const history = useHistory();
  const [isOpen, setIsOpen] = useState(false);

  const onClose = () => {
    setIsOpen(false);
  };

  const convertISOToCustomFormat = (isoDate: string) => {
    const daysOfWeek = [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
    ];
    const date = new Date(isoDate);
    const dayOfWeek = daysOfWeek[date.getUTCDay()];

    const year = date.getUTCFullYear();
    const month = date.toLocaleString('default', { month: 'long' });
    const day = date.getUTCDate();

    let hours = date.getUTCHours();
    let ampm = hours >= 12 ? 'pm' : 'am';

    hours = hours % 12;
    hours = hours === 0 ? 12 : hours;

    const minutes = String(date.getUTCMinutes()).padStart(2, '0');

    const formattedDate = `${dayOfWeek}, ${month} ${day} ${year} at ${hours}:${minutes}${ampm}`;

    return formattedDate;
  };

  let convertedStartDate: any;
  let convertedEndDate: any;

  if (experience?.availability.startDate && experience?.availability.endDate) {
    convertedStartDate = convertISOToCustomFormat(
      experience?.availability.startDate,
    );
    convertedEndDate = convertISOToCustomFormat(
      experience?.availability.endDate,
    );
  }

  const photosTemp = new Set([...(experience?.photos ?? [])]);
  if (experience?.featuredImage) {
    photosTemp.add(experience?.featuredImage!);
  }

  const photos = Array.from(photosTemp);

  return (
    <Container>
      {isLoading || isDeleting ? (
        <Center minH="90vh">
          {isDeleting ? (
            <>
              <CircularProgress isIndeterminate color="primary.400" />
              <DeleteText>Deleting experience...</DeleteText>
            </>
          ) : (
            <CircularProgress isIndeterminate color="primary.400" />
          )}
        </Center>
      ) : (
        <>
          <Header isBackable>{experience?.title}</Header>
          <OuterContainer>
            <Box p={5}>
              <Flex justifyContent="space-between">
                <Box>
                  <Text fontSize={20} mb={3} fontWeight="500">
                    Description
                  </Text>

                  <div
                    style={{ fontSize: 'none' }}
                    dangerouslySetInnerHTML={{
                      __html: experience?.description ?? '',
                    }}
                  />
                </Box>
                <Menu>
                  <MenuButton
                    as={IconButton}
                    aria-label="Options"
                    icon={<SlOptions />}
                    bg="white"
                    color="black"
                    width={30}
                    _hover={{ bg: 'primary.500', color: 'white' }}
                  />
                  <MenuList>
                    <MenuItem
                      icon={<MdEdit fontSize={20} />}
                      onClick={() => {
                        history.push(
                          `/experience/edit/${experience?._id}`,
                          experience,
                        );
                      }}>
                      Edit
                    </MenuItem>
                    <MenuItem
                      icon={<MdDeleteForever fontSize={20} color="red" />}
                      // onClick={() => {
                      //   if (!experience?._id) {
                      //     return;
                      //   }
                      //   onDeleteClick({
                      //     ids: [experience?._id],
                      //   });
                      // }}
                      onClick={() => setIsOpen(true)}>
                      Delete
                    </MenuItem>
                    {isOpen && (
                      <>
                        <Modal
                          isOpen={isOpen}
                          onClose={onClose}
                          isCentered
                          size="xl"
                          scrollBehavior="inside"
                          motionPreset="scale">
                          <ModalOverlay />
                          <ModalContent>
                            <ModalHeader>Delete Experience</ModalHeader>
                            <ModalCloseButton />
                            <ModalBody>
                              Are you sure you want to delete{' '}
                              <Text color="primary.400" display="inline">
                                '{experience?.title}'
                              </Text>
                              ?
                            </ModalBody>

                            <ModalFooter>
                              <Button
                                colorScheme="red"
                                mr={3}
                                onClick={() => {
                                  if (!experience?._id) {
                                    return;
                                  }
                                  onDeleteClick({
                                    ids: [experience?._id],
                                  });
                                }}>
                                Delete
                              </Button>
                              <Button onClick={onClose}>Cancel</Button>
                            </ModalFooter>
                          </ModalContent>
                        </Modal>
                      </>
                    )}
                  </MenuList>
                </Menu>
              </Flex>
              <Box mt={5} mb={5}>
                <ExpSubTitle opacity={1} mb={2} fontWeight="500">
                  Location
                </ExpSubTitle>
                <ExpSubTitle>{experience?.address || '-'}</ExpSubTitle>
              </Box>

              <Box mt={5} mb={5}>
                <ExpSubTitle opacity={1} mb={2} fontWeight="500">
                  Price
                </ExpSubTitle>
                <ExpSubTitle fontSize="26px">
                  ${experience?.price?.toLocaleString() || '-'}
                </ExpSubTitle>
              </Box>
              <ExpSubTitle fontWeight="500">Booking dates</ExpSubTitle>
              <Box mt={3}>
                <Flex style={{ flexWrap: 'wrap' }}>
                  <Box mr={5}>
                    <ExpSubTitle style={{ opacity: '1' }}>
                      Start Date
                    </ExpSubTitle>
                    <Flex>
                      <SlCalender color="red" style={{ marginRight: '7px' }} />
                      <ExpSubTitle>{convertedStartDate}</ExpSubTitle>
                    </Flex>
                  </Box>
                  <Box>
                    <ExpSubTitle style={{ opacity: '1' }}>End Date</ExpSubTitle>
                    <Flex>
                      <SlCalender color="red" style={{ marginRight: '7px' }} />
                      <ExpSubTitle>{convertedEndDate}</ExpSubTitle>
                    </Flex>
                  </Box>
                </Flex>
              </Box>
            </Box>

            <hr />

            <AssetsCarousel
              featuredPhoto={experience?.featuredImage}
              photos={photos || []}
            />

            <hr />
            <Box p={5}>
              <Text fontSize={20} fontWeight="500">
                Rooms and Amenities
              </Text>
              <CreatePlaces
                onNextClick={() => {}}
                amenities={experience?.amenities}
                experienceId={experience?._id || ''}
              />
            </Box>
            <hr />
            <Box p={5}>
              <Text fontSize={20} fontWeight="500">
                Equipment rental and extras
              </Text>
              <StandardProducts
                experienceId={experience?._id || ''}
                onFinishClick={() => {}}
              />
            </Box>
          </OuterContainer>
        </>
      )}
    </Container>
  );
};

export default ExperienceDetailsView;
