import React, { FC, useRef } from 'react';

import { Button, Switch, Tag } from '@chakra-ui/react';

import {
  CreateAttributeModal,
  CreateAttributeModalRef,
} from 'components/modules/CreateAttributeModal';
import { Header } from 'components/layouts/Header';
import { CustomTable } from 'components/primitives/CustomTable';

import { AttributesViewProps } from './Attributes.props';

import { Container, Label, Row } from './Attributes.style';

const AttributesView: FC<AttributesViewProps> = ({
  isLoading,
  attributes,
  pagination,
}) => {
  const createAttributeModalRef = useRef<CreateAttributeModalRef>(null);

  return (
    <Container>
      <Header>
        <Row>
          Attributes
          <Button
            w="auto"
            mr="40px"
            onClick={() => createAttributeModalRef.current?.onOpen?.()}>
            Create new attribute
          </Button>
        </Row>
      </Header>
      <Container p="20px">
        <CustomTable
          minW="1000px"
          data={attributes}
          isLoading={isLoading}
          isSelectable={false}
          pagination={pagination}>
          {{
            headerRow: (
              <>
                <Label flex={1}>Name</Label>
                <Label flex={1}>Status</Label>
                <Label flex={1}>Variants</Label>
              </>
            ),
            renderRow: (attribute) => (
              <Row>
                <Label flex={1} noOfLines={1}>
                  <Tag>{attribute?.name}</Tag>
                </Label>
                <Label flex={1}>
                  <Switch isChecked={attribute?.status} colorScheme="green">
                    {attribute?.status ? 'Active' : 'Disabled'}
                  </Switch>
                </Label>
                <Label flex={1}>
                  {attribute?.variants?.map((v) => v?.name)?.join(', ')}
                </Label>
              </Row>
            ),
          }}
        </CustomTable>
        <CreateAttributeModal ref={createAttributeModalRef} />
      </Container>
    </Container>
  );
};

export default AttributesView;
