const baseURL = String(process.env.REACT_APP_ULTISTAY_URL);

type Params<T extends object> = {
  pathname: string;
  searchParams?: {
    [K in keyof T]: string;
  };
};

export const getPreviewURL = <T extends object>({
  pathname,
  searchParams,
}: Params<T>) => {
  const url = new URL(baseURL);

  url.pathname = pathname;

  if (searchParams) {
    Object.entries(searchParams).forEach(([key, value]) => {
      url.searchParams.set(key, value as string);
    });
  }

  return url.toString();
};
