import { IconProps } from './type';

const Cancel = (props: IconProps) => {
  return (
    <svg
      width={props.w ?? '100'}
      height={props.h ?? '100'}
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 297 297">
      <g>
        <g>
          <g>
            <g>
              <circle
                style={{
                  fill: '#ee8764',
                }}
                cx="148.5"
                cy="148.5"
                r="148.5"
              />
            </g>
          </g>
        </g>
        <path
          style={{
            fill: '#d37757',
          }}
          d="M223.53,86.581L85.344,222.292l74.293,74.293c73.135-5.423,131.525-63.813,136.948-136.948
		L223.53,86.581z"
        />
        <polygon
          style={{
            fill: '#ECF0F1',
          }}
          points="231.823,94.875 202.125,65.177 148.5,118.802 94.875,65.177 65.177,94.875 118.802,148.5 
		65.177,202.125 94.875,231.823 148.5,178.198 202.125,231.823 231.823,202.125 178.198,148.5 	"
        />
      </g>
    </svg>
  );
};

export default Cancel;
