import React, { FC } from 'react';
import { LandingViewProps } from './Landing.props';

import {
  Row,
  Logo,
  CoverTitle,
  CoverSubTitle,
  Title,
  SubTitle,
  HeroSection,
  CenterContent,
  Card,
  SubmitButton,
  Container,
  Section,
  IconTitleWrapper,
  CustomerReviewCard,
  GradientCard,
} from './Landing.style';

import { Text } from '@chakra-ui/react';

import MailchimpSubscribe from 'react-mailchimp-subscribe';

import { Box, Flex, Grid, GridItem, Heading } from '@chakra-ui/layout';
import { Avatar, Button, Image } from '@chakra-ui/react';
import { Form, Formik } from 'formik';
import FormikInput from 'components/primitives/FormikInput';
import assets from 'assets';

import Cards from 'components/svg/Cards';
import Cart from 'components/svg/Cart';
import Chart from 'components/svg/Chart';
import Experience from 'components/svg/Experience';
import Safe from 'components/svg/Safe';
import { initialValues, validationSchema } from './Landing.utils';
import { scrollIntoViewById } from 'utils/common';

const howDoesItWorkList: { icon: any; title: string }[] = [
  { icon: Experience, title: 'Set up your experience and customize it' },
  { icon: Chart, title: 'Grow with us' },
  { icon: Cart, title: 'Attract customers' },
  { icon: Cards, title: 'Set payment methods' },
  { icon: Safe, title: 'Safe platform' },
  { icon: Cards, title: 'Benifits for hosts and guests' },
];

const LandingView: FC<LandingViewProps> = (props) => {
  const waitlistActionUrl =
    'https://ultisell.us14.list-manage.com/subscribe/post?u=55bf50773b49fb9a33cb861d5&id=b2bd0c8cf6&f_id=00bc92e0f0';

  return (
    <Container gap="20px">
      <HeroSection>
        <CenterContent>
          <Row>
            <Logo />
            <CoverTitle>UltiSell</CoverTitle>
          </Row>
          <CoverSubTitle>
            Join the Waitlist for a One-of-a-Kind Experience Management!
          </CoverSubTitle>
          <Card>
            <MailchimpSubscribe
              url={waitlistActionUrl}
              render={({ subscribe, status, message }) => {
                return (
                  <Formik
                    onSubmit={(data, { resetForm }) => {
                      resetForm();
                      subscribe(data);
                    }}
                    initialValues={initialValues}
                    validationSchema={validationSchema}>
                    {({ errors, values }) => {
                      return (
                        <Form>
                          <Flex
                            direction={{ base: 'column', md: 'row' }}
                            alignItems="end"
                            gap="20px">
                            <FormikInput
                              hideError
                              mt={0}
                              value={values.FNAME ?? ''}
                              type="text"
                              name="FNAME"
                              placeholder="Name"
                            />
                            <FormikInput
                              mt={0}
                              hideError
                              value={values.EMAIL ?? ''}
                              type="email"
                              name="EMAIL"
                              placeholder="Email"
                            />

                            <SubmitButton
                              isLoading={status === 'sending'}
                              mt={0}>
                              Join the waitlist!
                            </SubmitButton>
                          </Flex>
                          <Text mt={2} ml={3} fontSize={13}>
                            {typeof message == 'string' && message}
                            <Box color="red.600">
                              <ul>
                                {errors.FNAME && <li>{errors.FNAME}</li>}
                                {errors.EMAIL && <li>{errors.EMAIL}</li>}
                              </ul>
                            </Box>
                          </Text>
                        </Form>
                      );
                    }}
                  </Formik>
                );
              }}
            />
          </Card>
        </CenterContent>
      </HeroSection>
      <Section
        display="flex"
        direction={{
          base: 'column',
          md: 'row',
        }}
        justifyContent="space-between"
        alignItems={{
          base: 'start',
          md: 'center',
        }}>
        <Box
          width={{
            base: '100%',
            md: 'auto',
          }}>
          <Image
            objectFit="contain"
            height="379px"
            width="auto"
            src={assets.womenWatchingPhotos}
          />
        </Box>
        <Box
          width={{
            base: '90%',
            md: '60%',
          }}
          padding={{
            base: '40px',
            md: '20px',
          }}
          marginTop="20px"
          gap="20px">
          <Title>
            Are you an experience host with a passion for creating unique and
            memorable travel experiences for fellow travel enthusiasts
          </Title>
          <SubTitle>
            Do you offer active lifestyle experiences to individuals from all
            over the world? Our platform is the solution you've been searching
            for! There is a better way to organize and sell your unique
            experiences. Our platform enables you to design your own
            personalized experience page where you can showcase your offerings
            and have a fully integrated client management system, allowing you
            to focus on delivering unforgettable experiences for your guests.
          </SubTitle>
        </Box>
        <Box
          display={{
            base: 'flex',
            md: 'block',
          }}
          justifyContent={{
            base: 'end',
            md: 'auto',
          }}
          width={{
            base: '100%',
            md: 'auto',
          }}>
          <Image
            height="379px"
            width="auto"
            objectFit="contain"
            src={assets.manClimbingMountain}
          />
        </Box>
      </Section>

      <Section
        id="how-it-works-section"
        display="flex"
        direction="column"
        pb="40px"
        alignItems="center"
        justifyContent="center">
        <Title>How does it work</Title>

        <Grid
          p="20px"
          gap="20px"
          mt="20px"
          templateColumns={{
            base: 'repeat(1, 1fr)',
            sm: 'repeat(2, 1fr)',
            md: 'repeat(3, 1fr)',
          }}>
          {howDoesItWorkList.map((data) => {
            const Icon = data.icon;
            return (
              <GridItem shadow="lg">
                <IconTitleWrapper>
                  <Section
                    alignItems="center"
                    gap="10px"
                    justifyContent="center">
                    <Icon size={50} />
                    <SubTitle
                      maxWidth="12rem"
                      textAlign="left"
                      fontSize="16px"
                      color="grey">
                      {data.title}
                    </SubTitle>
                  </Section>
                </IconTitleWrapper>
              </GridItem>
            );
          })}
        </Grid>
      </Section>

      <Section
        id="about-section"
        bgColor="#025AD7"
        pb="30px"
        paddingX={{
          base: '4%',
          sm: '4%',
          md: '8%',
        }}>
        <Grid
          gap="20px"
          my="30px"
          alignItems="center"
          justifyContent="center"
          templateColumns={{
            base: 'repeat(1, 1fr)',
            md: 'repeat(2, 1fr)',
          }}>
          <GridItem>
            <Box width="100%" position="relative">
              <Avatar
                padding={10}
                objectFit="contain"
                size="full"
                src={assets.ccaHeadshot}
              />
              {/* <CustomerReviewCard
                position="absolute"
                gap="10px"
                bottom="-25px"
                left="-15">
                <Flex flex="1" gap="4" alignItems="center" flexWrap="wrap">
                  <Avatar
                    name="Segun Adebayo"
                    src="https://bit.ly/sage-adebayo"
                  />
                  <Box>
                    <Heading size="sm">Segun Adebayo</Heading>
                    <Heading fontSize="14px" color="grey" fontWeight={'normal'}>
                      Customer
                    </Heading>
                  </Box>
                </Flex>
                <SubTitle
                  my="10px"
                  textAlign="left"
                  fontSize="14px"
                  color="grey">
                  “very happy to shop here, complete and practical”
                </SubTitle>

                <svg
                  width="97"
                  height="17"
                  viewBox="0 0 97 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_709_10783)">
                    <path
                      d="M8.5026 12.3568L12.6226 14.8434L11.5293 10.1568L15.1693 7.00342L10.3759 6.59676L8.5026 2.17676L6.62927 6.59676L1.83594 7.00342L5.47594 10.1568L4.3826 14.8434L8.5026 12.3568Z"
                      fill="#F3B760"
                    />
                  </g>
                  <g clip-path="url(#clip1_709_10783)">
                    <path
                      d="M28.5026 12.3568L32.6226 14.8434L31.5293 10.1568L35.1693 7.00342L30.3759 6.59676L28.5026 2.17676L26.6293 6.59676L21.8359 7.00342L25.4759 10.1568L24.3826 14.8434L28.5026 12.3568Z"
                      fill="#F3B760"
                    />
                  </g>
                  <g clip-path="url(#clip2_709_10783)">
                    <path
                      d="M48.5026 12.3568L52.6226 14.8434L51.5293 10.1568L55.1693 7.00342L50.3759 6.59676L48.5026 2.17676L46.6293 6.59676L41.8359 7.00342L45.4759 10.1568L44.3826 14.8434L48.5026 12.3568Z"
                      fill="#F3B760"
                    />
                  </g>
                  <g clip-path="url(#clip3_709_10783)">
                    <path
                      d="M68.5026 12.3568L72.6226 14.8434L71.5293 10.1568L75.1693 7.00342L70.3759 6.59676L68.5026 2.17676L66.6293 6.59676L61.8359 7.00342L65.4759 10.1568L64.3826 14.8434L68.5026 12.3568Z"
                      fill="#F3B760"
                    />
                  </g>
                  <g clip-path="url(#clip4_709_10783)">
                    <path
                      d="M88.5026 12.3568L92.6226 14.8434L91.5293 10.1568L95.1693 7.00342L90.3759 6.59676L88.5026 2.17676L86.6293 6.59676L81.8359 7.00342L85.4759 10.1568L84.3826 14.8434L88.5026 12.3568Z"
                      fill="#F3B760"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_709_10783">
                      <rect
                        width="16"
                        height="16"
                        fill="white"
                        transform="translate(0.5 0.84375)"
                      />
                    </clipPath>
                    <clipPath id="clip1_709_10783">
                      <rect
                        width="16"
                        height="16"
                        fill="white"
                        transform="translate(20.5 0.84375)"
                      />
                    </clipPath>
                    <clipPath id="clip2_709_10783">
                      <rect
                        width="16"
                        height="16"
                        fill="white"
                        transform="translate(40.5 0.84375)"
                      />
                    </clipPath>
                    <clipPath id="clip3_709_10783">
                      <rect
                        width="16"
                        height="16"
                        fill="white"
                        transform="translate(60.5 0.84375)"
                      />
                    </clipPath>
                    <clipPath id="clip4_709_10783">
                      <rect
                        width="16"
                        height="16"
                        fill="white"
                        transform="translate(80.5 0.84375)"
                      />
                    </clipPath>
                  </defs>
                </svg>
              </CustomerReviewCard> */}
            </Box>
          </GridItem>
          <GridItem>
            <Section direction="column" gap="20px" alignItems="start">
              <Title color="white">About Us</Title>
              <SubTitle fontSize="16px" color="white" textAlign="left">
                UltiSell was founded in 2022 on the fundamental concept that
                participating in meaningful and memorable activities or as we
                call them “Experiences” are the most precious commodities we can
                invest in. With this premise in mind, we embarked on a mission
                to revolutionize how consumers invest in unforgettable
                experiences. Whether you're someone who loves organizing camping
                adventures under the stars, soaring through the sky paragliding
                over picturesque mountains, or embarking on expeditions to
                explore ancient ruins in a remote jungle, our platform provides
                the perfect opportunity for individuals like you to become hosts
                and share your unique experiences with others. By enabling hosts
                to effortlessly design and deploy fully-featured e-commerce
                websites, guests are provided with a seamless and hassle-free
                way to access and enjoy your unique experience.
              </SubTitle>
              <SubTitle fontSize="16px" color="white" textAlign="left">
                It is UltiSell's mission to help you transform your passion into
                a lifestyle.
              </SubTitle>
            </Section>
          </GridItem>
        </Grid>
      </Section>

      <Section
        pb="30px"
        paddingRight={0}
        paddingLeft={{
          base: '4%',
          sm: '4%',
          md: '8%',
        }}>
        <Grid
          gap="20px"
          my="30px"
          alignItems="center"
          justifyContent="center"
          templateColumns={{
            base: 'repeat(1, 1fr)',
            md: 'repeat(2, 1fr)',
          }}>
          <GridItem>
            <Section direction="column" gap="20px" alignItems="start">
              <Title textAlign="left">
                Top-notch technology and user centered experience
              </Title>
              <SubTitle fontSize="16px" textAlign="left">
                With our cutting-edge technology, you can easily create a
                stunning page that showcases your expertise, your travels, and
                your packages. Our user-friendly interface makes it simple for
                you to upload photos, write about your trips, and share your
                knowledge and experiences with others. And, with a global reach,
                you'll have the opportunity to connect with travelers from all
                around the world.
              </SubTitle>
            </Section>
          </GridItem>
          <GridItem>
            <Flex justifyContent="end">
              <Image objectFit="contain" src={assets.screenshotCollage} />
            </Flex>
          </GridItem>
        </Grid>
      </Section>

      <Section
        id="join-waitlist-section"
        py="40px"
        bgColor="#72A7F3"
        paddingX={{
          base: '4%',
          sm: '4%',
          md: '8%',
        }}>
        <Grid
          gap="20px"
          alignItems="center"
          justifyContent="center"
          templateColumns={{
            base: 'repeat(1, 1fr)',
            md: 'repeat(2, 1fr)',
          }}>
          <GridItem>
            <GradientCard>
              <MailchimpSubscribe
                url={waitlistActionUrl}
                render={({ subscribe, message, status }) => {
                  return (
                    <Formik
                      onSubmit={(data, { resetForm }) => {
                        subscribe(data);
                        resetForm();
                      }}
                      initialValues={initialValues}
                      validationSchema={validationSchema}>
                      {({ values }) => {
                        return (
                          <Form>
                            <Flex direction="column" gap="20px">
                              <Title
                                color="#72A7F3"
                                fontSize="60px"
                                textAlign="center">
                                Be the first to try it!
                              </Title>
                              <FormikInput
                                opacity={0.6}
                                mt={0}
                                value={values.FNAME ?? ''}
                                type="text"
                                name="FNAME"
                                placeholder="Name"
                                // containerProps={containerProps}
                              />
                              <FormikInput
                                opacity={0.6}
                                type="email"
                                mt={0}
                                value={values.EMAIL ?? ''}
                                name="EMAIL"
                                placeholder="Email"
                                // containerProps={containerProps}
                              />
                              <SubmitButton
                                isLoading={status == 'sending'}
                                opacity={0.6}
                                mt={0}>
                                Join the waitlist!
                              </SubmitButton>
                            </Flex>
                            <Text mt={2} ml={2} fontSize={13}>
                              {typeof message == 'string' && message}
                            </Text>
                          </Form>
                        );
                      }}
                    </Formik>
                  );
                }}></MailchimpSubscribe>
            </GradientCard>
          </GridItem>
          <GridItem>
            <Section
              direction="column"
              gap="20px"
              alignItems="start"
              paddingLeft={{
                base: '1%',
                sm: '2%',
                md: '4%',
              }}>
              <Title textAlign="right" color="white">
                So, why wait? Join our waitlist today and be one of the first to
                experience the future of travel!
              </Title>
              <SubTitle fontSize="16px" color="white" textAlign="right">
                Share your adventures, offer your packages, and make a
                difference in the lives of travelers everywhere. Sign up now!
              </SubTitle>
            </Section>
          </GridItem>
        </Grid>
      </Section>

      <Section
        id="host-benifits-section"
        pb="30px"
        paddingX={{
          base: '4%',
          sm: '4%',
          md: '8%',
        }}>
        <Grid
          gap="20px"
          my="30px"
          alignItems="center"
          justifyContent="center"
          templateColumns={{
            base: 'repeat(1, 1fr)',
            md: 'repeat(2, 1fr)',
          }}>
          <GridItem>
            <Section direction="column" gap="20px" alignItems="start">
              <Title textAlign="left" color="primary.500">
                Benefits for you and your guests
              </Title>

              <BenifitInfoItem
                title="Effortless Setup"
                description="With UltiSell, you can effortlessly create your own customized web-based storefront, complete with personalized themes and branding options. Say goodbye to the need for coders, designers, or programmers—saving you valuable time and resources. Now, you can focus on what truly matters: crafting unforgettable experiences for your customers."
              />

              <BenifitInfoItem
                title="Streamlined Customer Management"
                description="Seamlessly oversee lodging arrangements, coordinate guest arrivals and departures, and maintain clear and effective communication with guests. With UltiSell, every aspect of guest interaction is simplified, ensuring a seamless and hassle-free experience for everyone involved."
              />
              <BenifitInfoItem
                title="Seamless Checkout Experience for Enhanced Customer Satisfaction"
                description="Trusted options like Stripe, Venmo, and PayPal ensure seamless payments, quick confirmations, and a familiar customer shopping experience. Your customers can confidently complete purchases, fostering trust and satisfaction with every transaction."
              />
              <Box>
                <SubTitle fontSize="16px" textAlign="left" color="primary.500">
                  Don't miss out on this exciting opportunity to share your
                  travel experiences and offer unique travel packages to the
                  world. Join the waitlist today!
                </SubTitle>
              </Box>
              <Button
                onClick={() => scrollIntoViewById('join-waitlist-section')}
                mt="20px"
                size="md"
                bgColor="#F66F4D"
                paddingY="12px">
                Join!
              </Button>
            </Section>
          </GridItem>
          <GridItem>
            <Image objectFit="contain" src={assets.manWorkingOnLaptop} />
          </GridItem>
        </Grid>
      </Section>
    </Container>
  );
};

const BenifitInfoItem: FC<{ title: string; description: string }> = ({
  title,
  description,
}) => {
  return (
    <Box mb={4}>
      <Text color="primary.600" fontWeight={'bold'}>
        {title}
      </Text>
      <Text color="primary.500">{description}</Text>
    </Box>
  );
};

export default LandingView;
