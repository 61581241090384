import React, { useCallback } from 'react';

import { useToast } from '@chakra-ui/react';

import { updateProduct, updateProductImages, uploadImages } from 'api/products';
import { useMutation, useQueryClient } from 'react-query';

import { EditProductProps } from './EditProduct.props';

import EditProductView from './EditProduct.view';
import sanitize from 'sanitize-html';

const EditProductContainer = (props: EditProductProps) => {
  const toast = useToast();
  const queryClinet = useQueryClient();

  const product = props.location.state;

  const experienceId =
    typeof product?.experience !== 'string' ? product?.experience?._id : '';

  const beforeDeleteCallback = useCallback(
    async (photos: string[], featuredImage: string) => {
      const otherImages = photos.filter(
        (e: any) => typeof e !== 'string' && e.name !== featuredImage,
      );
      await updateProductImages({
        _id: product._id,
        photos: otherImages,
      });
    },
    [],
  );

  const { mutate, isLoading } = useMutation(
    async (payload: any) => {
      const featureImageName = payload.featuredImage;

      let photos: string[] = payload?.photos?.filter(
        (p: any) => typeof p === 'string' && p !== featureImageName,
      );

      const featuredImage = payload.photos.filter(
        (e: any) => typeof e !== 'string' && e.name === featureImageName,
      );

      const otherImages = payload.photos.filter(
        (e: any) => typeof e !== 'string' && e.name !== featureImageName,
      );

      if (otherImages?.length > 0) {
        const uploadedFiles = await uploadImages(otherImages);
        photos = [...photos, ...uploadedFiles];
      }

      if (featuredImage.length > 0) {
        const uploadedFeaturedImage = await uploadImages(featuredImage);
        payload.featuredImage = uploadedFeaturedImage[0];
      }

      if (payload.description) {
        payload.description = sanitize(payload.description);
      }
      const response = await updateProduct({
        photos,
        _id: product?._id,
        featuredImage: payload.featuredImage,
        name: payload?.name,
        experience: experienceId,
        productOptions: payload.productOptions,
        description: payload?.description,
        price: Number(payload?.price || '0'),
      });

      return response;
    },
    {
      onSuccess: () => {
        queryClinet.invalidateQueries({
          queryKey: ['fetch-products'],
        });

        toast({
          title: 'Success',
          description: 'Your product has been updated',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });

        props.history.goBack();
      },
    },
  );

  return (
    <EditProductView
      product={product}
      onSubmit={mutate}
      isUpdating={isLoading}
      beforeDeleteCallback={beforeDeleteCallback}
    />
  );
};

export default EditProductContainer;
