import { Flex, Image, Text, Button } from '@chakra-ui/react';
import { Box } from '@chakra-ui/layout';

import { chakraStyled } from 'utils';

import logo from 'assets/logo_blue.png';
import background from 'assets/background.png';

export const HeroSection = chakraStyled(Flex, {
  w: '100%',
  h: '100vh',
  direction: 'column',
  backgroundImage: background,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  justifyContent: 'center',
  alignItems: 'center',
});

export const Section = chakraStyled(Flex, {
  w: '100%',
  py: '20px',
  alignItems: 'center',
  justifyContent: 'center',
});

export const Container = chakraStyled(Flex, {
  w: '100%',
  direction: 'column',
});

export const GradientCard = chakraStyled(Box, {
  width: '100%',
  paddingX: {
    base: '2%',
    sm: '2%',
    md: '16%',
  },
  paddingY: {
    base: '2%',
    sm: '2%',
    md: '8%',
  },
  borderRadius: '8px',
  background:
    'radial-gradient(circle at left center, rgba(255, 255, 255, 0) 0%, rgb(255 255 255 / 58%) 58%)',
});

export const CustomerReviewCard = chakraStyled(Box, {
  borderRadius: '5px',
  p: '20px',
  maxWidth: '15rem',
  bg: '#ffffff',
  boxShadow: 'sm',
  backdropFilter: 'blur(10px)',
});

export const SubmitButton = chakraStyled(Button, {
  type: 'submit',
  w: '100%',
});

export const Card = chakraStyled(Flex, {
  p: {
    md: '40px 20px',
    base: '80px 40px',
  },
  borderRadius: '5px',
  align: 'center',
  bg: '#ffffff99',
  flex: 1,
  shadow: 'sm',
  gap: '20px',
  backdropFilter: 'blur(10px)',
});

export const CenterContent = chakraStyled(Flex, {
  p: '30px',
  bg: 'transparent',
  alignItems: 'center',
  justifyContent: 'center',
  flexWrap: 'wrap',
  gap: '20px',
  direction: 'column',
});

export const Row = chakraStyled(Flex, {
  w: '100%',
  gap: '20px',
  direction: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  flexWrap: 'wrap',
});

export const Logo = chakraStyled(Image, {
  h: { base: '150px', md: '150px' },
  objectFit: 'contain',
  src: logo,
});

export const CoverTitle = chakraStyled(Text, {
  fontSize: { base: '50px', md: '80px' },
  fontWeight: '600',
  color: 'primary.500',
  minW: '300px',
  textAlign: 'center',
});

export const CoverSubTitle = chakraStyled(Text, {
  textAlign: 'center',
  fontSize: { base: '20px', md: '26px' },
  fontWeight: '600',
  color: 'primary.500',
});

export const Title = chakraStyled(Text, {
  fontSize: { base: '24px', md: '28px' },
  fontWeight: '600',
  marginBottom: '10px',
  textAlign: 'center',
});

export const SubTitle = chakraStyled(Text, {
  fontSize: { base: '14px', md: '16px' },
  fontWeight: '400',
  textAlign: 'center',
});

export const IconTitleWrapper = chakraStyled(Flex, {
  border: '1px solid ',
  borderColor: '#ff5f43',
  height: '100%',
  borderRadius: '5px',
  padding: '8px 25px',
});
