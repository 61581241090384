const Cart = () => {
  return (
    <svg
      width="51"
      height="50"
      viewBox="0 0 51 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.0663 49.1695C7.29633 49.1695 4.23633 46.0595 4.23633 42.2195C4.23633 38.3795 7.28633 35.2695 11.0663 35.2695C14.8463 35.2695 17.8963 38.3795 17.8963 42.2195C17.8963 46.0595 14.8463 49.1695 11.0663 49.1695V49.1695ZM11.0663 44.9395C12.5363 44.9395 13.7263 43.7195 13.7263 42.2095C13.7263 40.6995 12.5363 39.4795 11.0663 39.4795C9.59633 39.4795 8.40633 40.6995 8.40633 42.2095C8.40633 43.7195 9.59633 44.9395 11.0663 44.9395Z"
        fill="#430062"
      />
      <path
        d="M11.0663 48.1695C7.84633 48.1695 5.23633 45.5095 5.23633 42.2195C5.23633 38.9295 7.84633 36.2695 11.0663 36.2695C14.2863 36.2695 16.8963 38.9295 16.8963 42.2195C16.8963 45.5095 14.2863 48.1695 11.0663 48.1695V48.1695ZM11.0663 45.9395C13.0963 45.9395 14.7263 44.2695 14.7263 42.2095C14.7263 40.1495 13.0863 38.4795 11.0663 38.4795C9.04633 38.4795 7.40633 40.1495 7.40633 42.2095C7.40633 44.2695 9.04633 45.9395 11.0663 45.9395Z"
        fill="#66A6FF"
      />
      <path
        d="M33.0663 49.1695C29.2963 49.1695 26.2363 46.0595 26.2363 42.2195C26.2363 38.3795 29.2863 35.2695 33.0663 35.2695C36.8463 35.2695 39.8963 38.3795 39.8963 42.2195C39.8963 46.0595 36.8463 49.1695 33.0663 49.1695ZM33.0663 44.9395C34.5363 44.9395 35.7263 43.7195 35.7263 42.2095C35.7263 40.6995 34.5363 39.4795 33.0663 39.4795C31.5963 39.4795 30.4063 40.6995 30.4063 42.2095C30.4063 43.7195 31.5963 44.9395 33.0663 44.9395V44.9395Z"
        fill="#430062"
      />
      <path
        d="M33.0663 48.1695C29.8463 48.1695 27.2363 45.5095 27.2363 42.2195C27.2363 38.9295 29.8463 36.2695 33.0663 36.2695C36.2863 36.2695 38.8963 38.9295 38.8963 42.2195C38.8963 45.5095 36.2863 48.1695 33.0663 48.1695ZM33.0663 45.9395C35.0963 45.9395 36.7263 44.2695 36.7263 42.2095C36.7263 40.1495 35.0863 38.4795 33.0663 38.4795C31.0463 38.4795 29.4063 40.1495 29.4063 42.2095C29.4063 44.2695 31.0463 45.9395 33.0663 45.9395V45.9395Z"
        fill="#66A6FF"
      />
      <path
        d="M14.3259 8.51953V32.9795H10.5059V8.51953H14.3259ZM23.0759 8.51953V32.9795H19.2559V8.51953H23.0759ZM31.8259 8.51953V32.9795H28.0059V8.51953H31.8259Z"
        fill="#430062"
      />
      <path
        d="M11.5059 31.9795H13.3259V9.51953H11.5059V31.9795Z"
        fill="#FF554B"
      />
      <path
        d="M20.2461 31.9795H22.0761V9.51953H20.2461V31.9795Z"
        fill="#FF554B"
      />
      <path
        d="M28.9961 31.9795H30.8161V9.51953H28.9961V31.9795Z"
        fill="#FF554B"
      />
      <path
        d="M7.09656 20.1204L10.0166 29.8004C10.1266 30.1704 10.6866 30.5904 11.0766 30.5904H35.2866V34.7604H11.0766C8.84656 34.7604 6.66656 33.1404 6.02656 31.0104L0.186564 11.6504C-0.593436 9.06043 1.15656 6.69043 3.86656 6.69043H39.6166V10.8604H4.29656L6.07656 16.7404H38.7066V20.1204H7.09656V20.1204Z"
        fill="#430062"
      />
      <path
        d="M11.077 31.5802C10.247 31.5802 9.297 30.8802 9.057 30.0802L5.747 19.1202H37.707V17.7402H5.337L3.217 10.7302C3.197 10.6502 3.177 10.6002 3.167 10.5602C3.127 10.4002 3.137 10.2502 3.257 10.0902C3.377 9.9302 3.517 9.8802 3.677 9.8702C3.727 9.8702 3.777 9.8702 3.857 9.8702H38.617V7.7002H3.867C1.827 7.7002 0.556999 9.4102 1.147 11.3702L6.987 30.7102C7.507 32.4202 9.297 33.7502 11.077 33.7502H34.287V31.5902H11.077V31.5802Z"
        fill="#99C4FF"
      />
      <path
        d="M47.0564 6.48C45.2664 6.48 43.8164 5.03 43.8164 3.24C43.8164 1.45 45.2664 0 47.0564 0C48.8464 0 50.2964 1.45 50.2964 3.24C50.2964 5.03 48.8464 6.48 47.0564 6.48Z"
        fill="#430062"
      />
      <path
        d="M47.0564 5.48C48.2964 5.48 49.2964 4.48 49.2964 3.24C49.2964 2 48.2964 1 47.0564 1C45.8164 1 44.8164 2 44.8164 3.24C44.8164 4.48 45.8164 5.48 47.0564 5.48Z"
        fill="#FF554B"
      />
      <path
        d="M34.546 42.7101C34.336 43.6701 33.486 44.3501 32.516 44.3501H11.746C10.596 44.3501 9.66602 43.4201 9.66602 42.2701C9.66602 41.1201 10.596 40.1901 11.746 40.1901H30.836L39.286 2.72008C39.496 1.76008 40.346 1.08008 41.316 1.08008H46.576C47.726 1.08008 48.656 2.01008 48.656 3.16008C48.656 4.31008 47.726 5.24008 46.576 5.24008H42.986L34.536 42.7001L34.546 42.7101Z"
        fill="#430062"
      />
      <path
        d="M11.746 41.1796C11.146 41.1796 10.666 41.6696 10.666 42.2596C10.666 42.8496 11.156 43.3396 11.746 43.3396H32.506C33.016 43.3396 33.456 42.9896 33.566 42.4896L42.186 4.25961H46.576C47.176 4.25961 47.656 3.76961 47.656 3.17961C47.656 2.58961 47.166 2.09961 46.576 2.09961H41.316C40.806 2.09961 40.366 2.44961 40.256 2.94961L31.636 41.1896H11.746V41.1796Z"
        fill="white"
      />
      <path
        d="M41.1564 4.1899C41.2164 3.9199 41.4864 3.7499 41.7564 3.8099C42.0264 3.8699 42.1964 4.1399 42.1364 4.4099L33.4764 42.4299C33.4164 42.6999 33.1464 42.8699 32.8764 42.8099C32.6064 42.7499 32.4364 42.4799 32.4964 42.2099L41.1564 4.1899Z"
        fill="#99C4FF"
      />
    </svg>
  );
};

export default Cart;
