import React, { FC, useEffect, useState } from 'react';

import { Button, Input, Textarea } from '@chakra-ui/react';

import { $styling } from 'stores';

import { useFormikContext } from 'formik';

import {
  Label,
  Column,
  BackButton,
  MotionContainer,
} from '../CustomSideBar.style';

import { Template } from 'types/style';
import useIsDataChanged from 'hooks/useIsDataChanged';

export const InfoForm: FC = () => {
  const { values, setFieldValue } = useFormikContext<Template>();

  const infoValues = values?.info?.value;

  const [title, setTitle] = useState(infoValues?.title ?? '');
  const [description, setDescription] = useState(infoValues?.description ?? '');

  const { isDataChanged } = useIsDataChanged([title, description]);

  const goBack = () => {
    $styling.actions.setSelectedSection(null);
  };

  useEffect(() => {
    setFieldValue('info.value.title', title);
    setFieldValue('info.value.description', description);
  }, [title, description]);

  const onConfirm = () => {
    setFieldValue('info.value.title', title);
    setFieldValue('info.value.description', description);
    goBack();
  };

  return (
    <MotionContainer flex={1} key="info-form">
      <BackButton onClick={goBack}>Info</BackButton>

      <Column flex={1}>
        <Column gap="0px">
          <Label>Title</Label>
          <Input
            size="sm"
            bg="#fff"
            color="#000"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </Column>

        <Column gap="0px">
          <Label>Description</Label>
          <Textarea
            size="sm"
            bg="#fff"
            color="#000"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </Column>
      </Column>

      <Column direction="row" gap="5px">
        <Button size="sm" onClick={goBack}>
          Back
        </Button>
        <Button
          size="sm"
          variant="solid"
          colorScheme="whiteAlpha"
          onClick={onConfirm}
          isDisabled={!isDataChanged}>
          Confirm
        </Button>
      </Column>
    </MotionContainer>
  );
};
