import { useQuery } from 'react-query';
import { fetchAnalytics } from 'api/dashboard';

const useAnalytics = () => {
  const queryKey = ['fetch-dashboard-analytics'];
  const queryFn = () => fetchAnalytics();

  const query = useQuery(queryKey, queryFn);

  return query;
};

export default useAnalytics;
