import React, { FC, useEffect, useState } from 'react';

import { Button, Textarea } from '@chakra-ui/react';

import { useField } from 'formik';

import { $styling } from 'stores';

import {
  Label,
  Column,
  BackButton,
  MotionContainer,
} from '../CustomSideBar.style';
import useIsDataChanged from 'hooks/useIsDataChanged';

export const AboutForm: FC = () => {
  const [descField, , descHelpers] = useField('about.value.description');
  const [description, setDescription] = useState<string>(descField.value);

  const { isDataChanged } = useIsDataChanged([description]);

  useEffect(() => {
    descHelpers.setValue(description);
  }, [description]);

  const goBack = () => {
    $styling.actions.setSelectedSection(null);
  };

  const onConfirm = () => {
    descHelpers.setValue(description);

    goBack();
  };

  return (
    <MotionContainer flex={1} key="about-form">
      <BackButton
        onClick={() => {
          $styling.actions.setSelectedSection(null);
        }}>
        About Us
      </BackButton>

      <Column flex={1}>
        <Column gap="0px">
          <Label>Description</Label>
          <Textarea
            bg="#fff"
            color="#000"
            fontSize="12px"
            minH="250px"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </Column>
      </Column>

      <Column direction="row" gap="5px">
        <Button size="sm" onClick={goBack}>
          Back
        </Button>
        <Button
          size="sm"
          variant="solid"
          colorScheme="whiteAlpha"
          onClick={onConfirm}
          isDisabled={!isDataChanged}>
          Confirm
        </Button>
      </Column>
    </MotionContainer>
  );
};
