import * as Yup from 'yup';

export const initialValues = {
  title: '',
  amenities: [],
  fixedDateRange: false,
  photos: [],
  price: 0,
  featuredImage: '',
  description: '',
  availability: {
    startDate: '',
    endDate: '',
  },
  address: '',
  lat: 0,
  lng: 0,
  terms: '',
};

export const validationScehema = Yup.object().shape({
  photos: Yup.array()
    .min(1, 'Please upload at least 1 photo')
    .required('Please upload at least 1 photo'),
  price: Yup.string().required('Price is required'),
  title: Yup.string().required('Please enter a title'),
  description: Yup.string().required('Please enter a description'),
  availability: Yup.object().shape({
    startDate: Yup.string().required('Please select a start date'),
    endDate: Yup.string().required('Please select an end date'),
  }),
  address: Yup.string().required('Address is required'),
  lat: Yup.number(),
  lng: Yup.number(),
  terms: Yup.string().required('Terms and Conditions are required'),
});
