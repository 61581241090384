import { Attribute } from 'types/attributes';

type Combination = {
  variations: {
    attribute: string;
    variant: string;
  }[];
  price: number;
  quantity: number;
  name: string;
};

function generateCombinations(
  attributes: Attribute[],
  index: number,
): Combination[] {
  if (index >= attributes.length) {
    return [];
  }

  const currentAttribute = attributes[index];
  const currentVariants = currentAttribute.variants;
  const remainingCombinations = generateCombinations(attributes, index + 1);

  if (remainingCombinations.length === 0) {
    return currentVariants.map((variant) => ({
      variations: [
        {
          attribute: currentAttribute._id,
          variant: variant._id,
        },
      ],
      price: 0,
      quantity: 0,
      name: variant.name,
    }));
  }

  const combinations: Combination[] = [];

  currentVariants.forEach((variant) => {
    remainingCombinations.forEach((combination) => {
      combinations.push({
        variations: [
          ...combination.variations,
          {
            attribute: currentAttribute._id,
            variant: variant._id,
          },
        ],
        price: combination.price,
        quantity: combination.quantity,
        name: `${variant.name}/${combination.name}`,
      });
    });
  });

  return combinations;
}

export default generateCombinations;
