import { Box, Flex, Link, Text } from '@chakra-ui/react';

import { chakraStyled } from 'utils';

export const NavBarBox = chakraStyled(Flex, {
  bg: '#f2f2f2',
  h: '140px',
  shadow: 'md',
  justify: 'space-between',
  alignItems: 'center',
  px: { base: '20px', md: '30px' },
});

export const LogoBox = chakraStyled(Box, {
  w: '120px',
  h: '120px',
  display: 'flex',
  alignItems: 'center',
});

export const LogoText = chakraStyled(Text, {
  fontSize: '30px',
  fontWeight: '900',
});

export const NavItemText = chakraStyled(Text, {
  color: '##52595D',
  fontSize: '14px',
  cursor: 'pointer',
});

export const NavItem = chakraStyled(Link, {
  fontWeight: '500',
});
