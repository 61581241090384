import React, { FC, useMemo } from 'react';

import { useFormikContext } from 'formik';

import { $styling } from 'stores';

import { Template } from 'types/style';
import { TemplateHeroSectionProps } from './TemplateHeroSection.props';

import { Section, Description, Title } from 'styles/template.style';

const TemplateHeroSectionView: FC<TemplateHeroSectionProps> = (props) => {
  const { values } = useFormikContext<Template>();

  const { coverBg } = $styling.selectors.usePreviewItems();

  const cover = values.cover;

  const src = useMemo(
    () => coverBg ?? cover?.value?.backgroundImage,
    [cover?.value?.backgroundImage, coverBg],
  );

  const backgroundColor = values.colors.backgroundColor;
  if (cover.enabled === false) {
    return null;
  }

  return (
    <Section
      id="cover"
      bgSize="cover"
      bgPosition="center"
      bgRepeat="no-repeat"
      justifyContent="center"
      color={values.colors.primaryColor ?? '#000'}
      backgroundColor={backgroundColor ?? '#fff'}
      bgImage={src}
      css={{
        maskImage: `linear-gradient(#000,#000,#000,transparent)`,
      }}>
      <Title>{cover?.value?.title || 'Cover title goes here'}</Title>
      <Description zIndex={2}>
        {cover?.value?.description || 'Cover description goes here'}
      </Description>
    </Section>

    // <HeroBox
    //   bgImage={src}
    //   bgPosition="center"
    //   bgRepeat="no-repeat"
    //   bgSize="cover">
    //   <HeroText>{cover?.value?.title || 'Cover Title goes here'}</HeroText>
    //   <HeroTextDesc>
    //     {cover?.value?.description || 'Cover Description goes here'}
    //   </HeroTextDesc>
    // </HeroBox>
  );
};

export default TemplateHeroSectionView;
