import { Flex, Image, Text } from '@chakra-ui/react';

import { chakraStyled } from 'utils';

export const Container = chakraStyled(Flex, {
  mx: '20px',
  mb: '20px',
  py: '20px',
  maxW: '90vw',
  flex: 1,
  overflowX: 'auto',
  gap: '20px',
});

export const Asset = chakraStyled(Image, {
  h: '200px',
  w: '240px',
  minW: '240px',
  borderRadius: '10px',
  overflow: 'hidden',
  objectFit: 'cover',
  bg: '#f1f1f1',
  fallbackSrc: 'https://via.placeholder.com/240x200?text=x',
});

export const Title = chakraStyled(Text, {
  fontSize: '20px',
  mx: '20px',
  mt: '20px',
  fontWeight: '500',
});
