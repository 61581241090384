import { chakraStyled } from 'utils';

import { Image } from '@chakra-ui/react';

export const Asset = chakraStyled(Image, {
  h: '200px',
  w: '240px',
  borderRadius: '10px',
  overflow: 'hidden',
  objectFit: 'cover',
  bg: '#f1f1f1',
  fallbackSrc: 'https://via.placeholder.com/240x200?text=x',
});
