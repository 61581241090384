import {
  Box,
  Flex,
  Text,
  Link,
  Image,
  Stack,
  Button,
  IconButton,
} from '@chakra-ui/react';

import { MdChevronLeft } from 'react-icons/md';

import { chakraMotionStyled, chakraStyled } from 'utils';

const variants = {
  hidden: {
    width: '70px',
  },
  visible: {
    width: '200px',
  },
};

export const Container = chakraMotionStyled(
  Flex,
  {
    direction: 'column',
    gap: '20px',
    h: '100%',
    position: 'fixed',
    left: '0',
    top: '0',
    bottom: '0',
  },
  {
    initial: false,
    variants,
    transition: {
      duration: 0.3,
    },
  },
);

export const Nav = chakraStyled(Box, {
  as: 'nav',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'flex-start',
  w: 'full',
  h: 'full',
  p: '6',
  bg: 'primary.500',
  color: '#fff',
  overflow: 'hidden',
});

export const ToggleContainer = chakraStyled(Flex, {
  justify: 'flex-end',
  align: 'flex-start',
});

export const Toggle = chakraStyled(IconButton as any, {
  w: '40px',
  'aria-label': 'toggle sidebar',
  variant: 'ghost',
  colorScheme: 'whiteAlpha',
  color: '#fff',
});

export const Logo = chakraStyled(Image, {
  w: 'full',
  minW: '50px',
  h: '50px',
  objectFit: 'contain',
  borderRadius: '10px',
  bg: '#fff',
  cursor: 'pointer',
  userSelect: 'none',
  draggable: false,
  my: '20px',
  p: '5px',
});

export const LinkItem = chakraStyled(Flex, {
  as: Link,
  align: 'center',
  justify: 'flex-start',
  gap: '10px',
  p: '6px',
  borderRadius: '3px',
  w: '100%',
});

export const Name = chakraStyled(Text, {
  fontSize: '16px',
  fontWeight: '600',
  userSelect: 'none',
  noOfLines: 1,
});

export const ItemFlex = chakraMotionStyled(Flex, {
  backgroundColor: 'primary.300',
  width: '170px',
  height: '40px',
  borderRadius: '8px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '0px 10px 0px 10px',
  marginBottom: '10px',
  as: 'button',
});

export const ItemText = chakraStyled(Text, {
  fontSize: '14px',
  color: 'white',
  marginLeft: '5px',
  fontWeight: 'bold',
});

export const PreviewBox = chakraStyled(Box, {
  backgroundColor: 'primary.600',
  width: '140px',
  height: '70px',
  display: 'Flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '5px',
  padding: '5px',
});

export const PreviewText = chakraStyled(Text, {
  color: 'whiteAlpha.500',
  fontSize: '12px',
  textAlign: 'center',
  cursor: 'pointer',
});

export const OutsidePreviewText = chakraStyled(Text, {
  color: 'white',
  fontSize: '10px',
  textAlign: 'center',
});

export const CustomIconButton = chakraStyled(IconButton as any, {
  w: '20px',
  top: '10px',
  right: '10px',
  position: 'fixed',
  ariaLabel: 'ham-menu',
});

export const HeaderText = chakraStyled(Text, {
  fontWeight: '500',
  textAlign: 'left',
});

export const Column = chakraStyled(Stack, {
  gap: '15px',
  w: '100%',
});

export const BackButton = chakraStyled(Button, {
  leftIcon: MdChevronLeft({ size: '24px', color: '#fff' }),
  variant: 'ghost',
  w: 'auto',
  color: '#fff',
  justifyContent: 'flex-start',
  fontSize: '18px',
});

export const Label = chakraStyled(Text, {
  fontSize: '16px',
  fontWeight: '500',
});

export const PickerContainer = chakraStyled(Flex, {
  align: 'center',
  justify: 'center',
  w: '150px',
  h: '60px',
  border: '1px dashed #fff',
  borderRadius: '10px',
  overflow: 'hidden',
  as: 'button',
});

export const HelperText = chakraStyled(Text, {
  fontSize: '12px',
  whiteSpace: 'pre-line',
});

export const layoutAnimation = {
  initial: {
    x: -100,
    opacity: 0,
  },
  animate: {
    x: 0,
    opacity: 1,
  },
  transition: {
    type: 'linear',
    duration: 0.5,
    ease: 'easeInOut',
  },
};

export const MotionContainer = chakraMotionStyled(
  Stack,
  {
    w: '100%',
    gap: '15px',
  },
  layoutAnimation,
);

export const generalAnimation = {
  initial: {
    x: -100,
    opacity: 0,
  },
  animate: {
    x: 0,
    opacity: 1,
  },
  transition: {
    type: 'spring',
    stiffness: 70,
  } as any,
};

export const sectionsAnimation = {
  initial: {
    y: 100,
    opacity: 0,
  },
  animate: {
    y: 0,
    opacity: 1,
  },
  transition: {
    type: 'spring',
    stiffness: 70,
  } as any,
};
