const Chart = () => {
  return (
    <svg
      width="48"
      height="49"
      viewBox="0 0 48 49"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3 36.1641C3 35.0541 3.9 34.1641 5 34.1641H9.26C10.36 34.1641 11.26 35.0541 11.26 36.1641V45.1541C11.26 46.2641 10.36 47.1541 9.26 47.1541H5C3.9 47.1541 3 46.2641 3 45.1541V36.1641ZM14 29.1541C14 28.0541 14.9 27.1641 16 27.1641H20.26C21.36 27.1641 22.26 28.0641 22.26 29.1541V45.1741C22.26 46.2741 21.36 47.1641 20.26 47.1641H16C14.9 47.1641 14 46.2641 14 45.1741V29.1541V29.1541ZM25 22.1741C25 21.0641 25.9 20.1641 27 20.1641H31.26C32.36 20.1641 33.26 21.0641 33.26 22.1741V45.1641C33.26 46.2741 32.36 47.1741 31.26 47.1741H27C25.9 47.1741 25 46.2741 25 45.1641V22.1741V22.1741ZM36 15.1641C36 14.0641 36.9 13.1641 38 13.1641H42.26C43.36 13.1641 44.26 14.0541 44.26 15.1641V45.1641C44.26 46.2641 43.36 47.1641 42.26 47.1641H38C36.9 47.1641 36 46.2741 36 45.1641V15.1641V15.1641Z"
        fill="#430062"
      />
      <path
        d="M4 36.1641C4 35.6141 4.45 35.1641 5 35.1641H9.26C9.81 35.1641 10.26 35.6141 10.26 36.1641V45.1541C10.26 45.7041 9.81 46.1541 9.26 46.1541H5C4.45 46.1541 4 45.7041 4 45.1541V36.1641ZM15 29.1541C15 28.6141 15.45 28.1641 16 28.1641H20.26C20.81 28.1641 21.26 28.6141 21.26 29.1541V45.1741C21.26 45.7141 20.81 46.1641 20.26 46.1641H16C15.45 46.1641 15 45.7141 15 45.1741V29.1541V29.1541ZM26 22.1741C26 21.6141 26.45 21.1641 27 21.1641H31.26C31.81 21.1641 32.26 21.6141 32.26 22.1741V45.1641C32.26 45.7241 31.81 46.1741 31.26 46.1741H27C26.45 46.1741 26 45.7241 26 45.1641V22.1741V22.1741ZM37 15.1641C37 14.6141 37.45 14.1641 38 14.1641H42.26C42.81 14.1641 43.26 14.6141 43.26 15.1641V45.1641C43.26 45.7141 42.81 46.1641 42.26 46.1641H38C37.45 46.1641 37 45.7141 37 45.1641V15.1641V15.1641Z"
        fill="#FFE796"
      />
      <path d="M0 43.1641H48V48.1641H0V43.1641Z" fill="#430062" />
      <path d="M1 44.1641V47.1641H47V44.1641H1Z" fill="#66A6FF" />
      <path
        d="M5.02989 30.0842C4.07989 30.6442 2.84989 30.3342 2.28989 29.3742C1.72989 28.4242 2.03989 27.1942 2.99989 26.6342L40.5899 4.44422L38.2899 3.95422C37.2099 3.72422 36.5199 2.66422 36.7499 1.58422C36.9799 0.50422 38.0399 -0.18578 39.1199 0.0442205L46.2999 1.57422C47.3799 1.80422 48.0699 2.86422 47.8399 3.94422L46.3099 11.1242C46.0799 12.2042 45.0199 12.8942 43.9399 12.6642C42.8599 12.4342 42.1699 11.3742 42.3999 10.2942L42.9499 7.70422L5.02989 30.0842Z"
        fill="#430062"
      />
      <path
        d="M44.3999 5.67405L43.3799 10.484C43.2699 11.024 43.6099 11.554 44.1499 11.674C44.6899 11.784 45.2199 11.444 45.3399 10.904L46.8699 3.72405C46.9799 3.18405 46.6399 2.65405 46.0999 2.53405L38.9199 1.00405C38.3799 0.894047 37.8499 1.23405 37.7299 1.77405C37.6099 2.31405 37.9599 2.84405 38.4999 2.96405L43.3099 3.98405L3.50994 27.504C3.02994 27.784 2.87994 28.394 3.15994 28.874C3.43994 29.354 4.04994 29.504 4.52994 29.224L44.3999 5.67405Z"
        fill="#FF554B"
      />
    </svg>
  );
};

export default Chart;
