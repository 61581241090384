import React, { FC } from 'react';

import { MdArrowBack } from 'react-icons/md';

import { Box, Flex, IconButton } from '@chakra-ui/react';

import { useHistory } from 'react-router-dom';

import { HeaderProps } from './Header.props';

const HeaderView: FC<HeaderProps> = ({
  children,
  isBackable,
  isCentered,
  onBackClick,
}) => {
  const { goBack } = useHistory();

  return (
    <Flex p="25px" bg="#E7F0FA" maxH="80px" align="center">
      {isBackable && (
        <IconButton
          w="50px"
          variant="ghost"
          colorScheme="primary"
          aria-label="back-button"
          icon={<MdArrowBack size="26px" />}
          onClick={onBackClick ? onBackClick : goBack}
        />
      )}
      <Box
        w="100%"
        fontSize="22px"
        fontWeight="bold"
        userSelect="none"
        color="primary.500"
        textAlign={isCentered ? 'center' : 'left'}>
        {children}
      </Box>
    </Flex>
  );
};

export default HeaderView;
