import { useQuery, UseQueryOptions } from 'react-query';

import { fetchTemplate } from 'api/style';

import { GetTemplateResponse } from 'types/style';

type Options = UseQueryOptions<
  GetTemplateResponse,
  unknown,
  GetTemplateResponse,
  string[]
>;

const useTemplate = (templateId: string, options?: Options) => {
  const queryKey = ['fetch-template', templateId];
  const queryFn = () => fetchTemplate(templateId);
  options = { ...options, enabled: !!templateId };

  const query = useQuery(queryKey, queryFn, options);

  return query;
};

export default useTemplate;
