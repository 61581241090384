import * as yup from 'yup';

export const initialValues = {
  username: '',
  email: '',
  password: '',
  confirmationPassword: '',
};

export const validationSchema = yup.object().shape({
  username: yup.string().required('Username is required'),
  email: yup.string().email('Invalid email').required('Email is required'),
  password: yup
    .string()
    .required('Password is required')
    .min(8)
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&]).{8,}$/,
      'Password too weak',
    ),
  confirmationPassword: yup
    .string()
    .oneOf([yup.ref('password')], 'Passwords must match')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&]).{8,}$/,
      'Password too weak',
    )
    .required('Repeat password is required'),
});
