import * as Yup from 'yup';

import { Product } from 'types/product';

export const getInitialValues = (product: Product) => {
  const tempPhotos = new Set([...(product?.photos ?? [])]);
  if (product.featuredImage) {
    tempPhotos.add(product?.featuredImage!);
  }

  const productOptions = product.productOptions;

  //work around to also send variants list in product option
  product.productOptions.forEach((e, index) => {
    e.variations = productOptions[index].attributes;
  });

  localStorage.setItem(
    'edit-product-options-balance',
    JSON.stringify(productOptions),
  );

  const photos = Array.from(tempPhotos);

  return {
    name: product?.name || '',
    description: product?.description || '',
    photos: photos || [],
    productOptions: productOptions,
    price: product?.price || 0,
    featuredImage: product.featuredImage || '',
  };
};

export const validationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  description: Yup.string().required('Description is required'),
  productOptions: Yup.array(),
  photos: Yup.array()
    .min(2, 'at least upload 2 photos')
    .required('Photos are required'),
  price: Yup.string().required('Price is required'),
});
