import React from 'react';

import { LandingProps } from './Landing.props';

import LandingView from './Landing.view';

const LandingContainer = (props: LandingProps) => {
  return <LandingView />;
};

export default LandingContainer;
