import { Experience } from './experience';

export enum ProductType {
  STANDARD = 0,
  SCHEDULED = 1,
}

export type ProductVariation = {
  attribute: string;
  variant: string;
};

export type ProductOption = {
  variations: ProductVariation[];
  attributes: ProductVariation[];
  price: number;
  quantity: number;
};

export type Product<T = string> = {
  name: string;
  description: string;
  featuredImage: string;
  type: ProductType;
  experience: string | Experience;
  productOptions: ProductOption[];
  photos: T[];
  _id?: string;
  price: number;
};

export type ProductImages<T = string> = {
  _id?: string;
  photos: T[];
};
