import { Center, Image, Text } from '@chakra-ui/react';

import { chakraMotionStyled, chakraStyled } from 'utils';

import logo from 'assets/logo_blue.png';

export const Layout = chakraStyled(Center, {
  flex: 1,
});

export const Container = chakraStyled(Center, {
  p: '40px 60px',
  bg: '#fff',
  flexDirection: 'column',
  borderRadius: '5px',
  boxShadow: '0px 0px 50px 0px rgba(0,0,0,0.1)',
});

export const Logo = chakraMotionStyled(
  Image,
  {
    maxW: '100px',
    cursor: 'pointer',
    src: logo,
    userSelect: 'none',
  },
  {
    animate: {
      y: [0, 15, 0],
    },
    transition: {
      duration: 1.5,
      repeat: Infinity,
      ease: 'easeInOut',
    },
  },
);

export const Title = chakraStyled(Text, {
  mt: '25px',
  fontSize: '20px',
  fontWeight: '600',
  color: 'secondary.500',
  userSelect: 'none',
});
