import React, { useEffect, useState } from 'react';

import { useToast } from '@chakra-ui/react';

import { routesSettings } from 'constants/routes';

import { CreateExperienceProps } from './CreateExperience.props';

import CreateExperienceView from './CreateExperience.view';

const FILLED_MAP: Record<number, boolean> = {
  0: true,
  1: false,
  2: false,
};

const CreateExperienceContainer = (props: CreateExperienceProps) => {
  const toast = useToast();

  const state = props.location.state;

  const [index, setIndex] = useState(() => (state?.index ? state.index : 0));
  const [filled, setFilled] = useState(() =>
    state?.filled ? state.filled : FILLED_MAP,
  );
  const [experienceId, setExperienceId] = useState(() =>
    state?.experienceId ? state.experienceId : '',
  );

  const onChangeIndex = (_index: number) => {
    setIndex(_index);

    setFilled((prevFilled) => ({
      ...prevFilled,
      [_index]: true,
    }));
  };

  const onComplete = () => {
    toast({
      title: 'Experience created',
      duration: 3000,
      status: 'success',
      isClosable: true,
    });

    props.history.replace(`/experience/details/${experienceId}`);
  };

  const onBackClick = () => {
    if (index === 2) {
      setIndex(1);

      return;
    }

    props.history.replace(routesSettings.MAIN_PRODUCTS.path);
  };

  useEffect(() => {
    const unlisten = props.history.listen((_, action) => {
      if (action === 'POP') {
        props.history.replace(routesSettings.MAIN_PRODUCTS.path);
      }
    });

    return () => {
      unlisten();
    };
  }, []);

  return (
    <CreateExperienceView
      index={index}
      filled={filled}
      experienceId={experienceId}
      onChangeIndex={onChangeIndex}
      setExperienceId={setExperienceId}
      onComplete={onComplete}
      onBackClick={onBackClick}
      goBack={props.history.goBack}
    />
  );
};

export default CreateExperienceContainer;
