import { Box, Flex, Image, Tab, Text } from '@chakra-ui/react';

import { MdDelete } from 'react-icons/md';

import { chakraStyled, chakraMotionStyled } from 'utils';

export const Container = chakraStyled(Flex, {
  w: '100%',
  direction: 'column',
});

export const Column = chakraStyled(Flex, {
  direction: 'column',
  minW: '300px',
  flex: 1,
});

export const Row = chakraStyled(Flex, {
  w: '100%',
  direction: 'row',
  justify: 'center',
  align: 'center',
  flexWrap: 'wrap',
});

export const Label = chakraStyled(Text, {
  fontSize: '16px',
  textAlign: 'left',
  mb: '20px',
});

export const AssetsContainer = chakraStyled(Flex, {
  w: '100%',
  direction: 'row',
  justify: 'flex-start',
  align: 'center',
  gap: '15px',
  flexWrap: 'wrap',
  p: '10px',
  borderRadius: '5px',
  boxShadow: '0px 0px 5px rgba(0,0,0,0.1)',
});

export const Asset = chakraStyled(Image, {
  h: '200px',
  w: '240px',
  borderRadius: '10px',
  overflow: 'hidden',
  objectFit: 'cover',
  bg: '#f1f1f1',
  fallbackSrc: 'https://via.placeholder.com/240x200?text=x',
});

export const PickerContainer = chakraStyled(Flex, {
  h: '200px',
  w: '240px',
  borderRadius: '10px',
  border: '2px solid',
  bg: 'secondary.100',
  borderColor: 'secondary.500',
  cursor: 'pointer',
  direction: 'column',
  align: 'center',
  justify: 'center',
  color: 'secondary.500',
  fontSize: '20px',
});

export const Delete = chakraStyled(Box, {
  as: MdDelete,
  size: '18px',
  color: 'secondary.500',
  cursor: 'pointer',
});

const fadeIn = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      duration: 0.5,
    },
  },
};

const stagger = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.5,
    },
  },
};

export const MotionFlex = chakraMotionStyled(
  Flex,
  {
    direction: 'column',
    align: 'flex-start',
    justify: 'flex-start',
    w: '100%',
    flex: 1,
    py: '25px',
  },
  {
    initial: 'hidden',
    animate: 'show',
    exit: 'hidden',
    variants: stagger,
  },
);

export const MotionItem = chakraMotionStyled(
  Flex,
  {
    direction: 'row',
    p: '2',
    borderBottom: '1px solid #ccc',
    w: '100%',
    align: 'center',
  },
  {
    initial: 'hidden',
    animate: 'show',
    exit: 'hidden',
    variants: fadeIn,
  },
);

export const StyledTab = chakraStyled(Tab, {
  minW: '200px',
  border: '2px solid',
  _selected: {
    color: '#F66F4D',
    bg: 'secondary.100',
    borderColor: '#F66F4D',
  },
});
