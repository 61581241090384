import React, { FC, useState } from 'react';

import {
  Text,
  Input,
  FormLabel,
  IconButton,
  FormControl,
  FormHelperText,
} from '@chakra-ui/react';
import { IoMdEye, IoMdEyeOff } from 'react-icons/io';

import { useField } from 'formik';

import { FormikInputProps } from './FormikInput.props';

const FormikInputView: FC<FormikInputProps> = ({
  name,
  label,
  error,
  isReadOnly,
  helperText,
  labelProps = { fontWeight: '400' },
  defaultValue,
  containerProps,
  type: initialType,
  hideError,
  mt = 3,
  ...props
}) => {
  const [field, meta, helpers] = useField(name);
  const [type, setType] = useState(initialType);
  const numberInputOnWheelPreventChange = (e: any) => {
    e.target.blur();
  };
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
      e.preventDefault();
    }
  };
  return (
    <FormControl mt={mt} {...containerProps}>
      {label && (
        <FormLabel htmlFor={name} {...labelProps} textTransform="capitalize">
          {label}
        </FormLabel>
      )}
      <Input
        onWheel={numberInputOnWheelPreventChange}
        type={type || name}
        id={name}
        isInvalid={meta.touched && meta.error ? true : false}
        aria-describedby={`${name}-helper-text`}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          helpers.setValue(e.target.value)
        }
        onKeyDown={(e) => handleKeyDown(e)}
        value={field.value || defaultValue}
        bg={isReadOnly ? '#DEDCDC' : 'inputColor'}
        onBlur={() => helpers.setTouched(true)}
        isReadOnly={isReadOnly}
        fontSize="14px"
        {...props}
      />
      {initialType === 'password' && (
        <IconButton
          w="auto"
          alignSelf="center"
          aria-label="password-toggle"
          icon={
            type === 'text' ? (
              <IoMdEye size="20px" />
            ) : (
              <IoMdEyeOff size="20px" />
            )
          }
          position="absolute"
          right={0}
          variant="ghost"
          _focus={{ border: 'none' }}
          zIndex={3}
          onClick={() =>
            setType((prev) => (prev === 'text' ? 'password' : 'text'))
          }
        />
      )}
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
      {(error || meta.error) && !hideError && (
        <Text color="red.500" fontSize="sm">
          {(meta.touched && meta.error) || error}
        </Text>
      )}
    </FormControl>
  );
};

export default FormikInputView;
