import { useQuery } from 'react-query';
import { fetchAttributes } from 'api/attributes';

import { ListResponse } from 'types/common';
import { Attribute } from 'types/attributes';

const useAttributes = (onSuccess?: (data: ListResponse<Attribute>) => void) => {
  const queryKey = [`fetch-all-attributes`];
  const queryFn = () =>
    fetchAttributes({
      limit: 100,
      page: 1,
    });

  const query = useQuery(queryKey, queryFn, {
    onSuccess,
  });

  return query;
};

export default useAttributes;
