import { IconProps } from './type';

const DoubleBed = (props: IconProps) => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.91797 7.83581C4.91797 7.09806 5.51603 6.5 6.25378 6.5H24.6416C25.3793 6.5 25.9774 7.09806 25.9774 7.83581V12.716C26.2347 12.9595 26.3952 13.3042 26.3952 13.6864V17.3652V18.3654V21.2906H24.7243V22.4626C24.7243 22.9696 24.3133 23.3805 23.8064 23.3805H22.5527C22.0457 23.3805 21.6348 22.9696 21.6348 22.4626V21.2906H9.2614V22.4626C9.2614 22.9696 8.85044 23.3805 8.34349 23.3805H7.08978C6.58283 23.3805 6.17188 22.9696 6.17188 22.4626V21.2906H4.5V18.3654V17.3652V13.6864C4.5 13.3041 4.66058 12.9593 4.91797 12.7158V7.83581ZM15.4476 9.54467C15.5922 9.22765 15.9119 9.00732 16.2831 9.00732H23.8054C24.3124 9.00732 24.7233 9.41828 24.7233 9.92523V12.3506H24.9774V7.83581C24.9774 7.65035 24.827 7.5 24.6416 7.5H6.25378C6.06832 7.5 5.91797 7.65035 5.91797 7.83581V12.3506H6.17188V9.92523C6.17188 9.41828 6.58283 9.00732 7.08978 9.00732H14.6121C14.9832 9.00732 15.303 9.22765 15.4476 9.54467ZM5.82865 13.3507C5.64649 13.3545 5.5 13.5033 5.5 13.6864V17.3652H25.3952V13.6864C25.3952 13.5033 25.2487 13.3545 25.0666 13.3507H5.82865ZM5.5 18.3654V20.2906H25.3952V18.3654H5.5ZM7.17188 21.291V22.3805H8.2614V21.291H7.17188ZM22.6348 22.3805V21.291H23.7243V22.3805H22.6348ZM7.17188 10.0073V12.3506H14.53V10.0073H7.17188ZM16.3652 12.3506V10.0073H23.7233V12.3506H16.3652Z"
        fill="#353535"
      />
    </svg>
  );
};

export default DoubleBed;
