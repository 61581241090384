import { Template } from 'types/style';

export const initialValues: Template = {
  logo: '',
  colors: {
    light: '',
    dark: '',
    button: '',
    backgroundColor: '#FFFFFF',
    primaryColor: '#000000',
    headerColor: '#FFFFFF',
    footerColor: '#FFFFFF',
  },
  cover: {
    enabled: false,
    value: {
      title: '',
      description: '',
      backgroundImage: '',
    },
  },
  store: {
    enabled: false,
    value: {
      title: '',
      description: '',
    },
  },
  info: {
    enabled: false,
    value: {
      title: '',
      description: '',
    },
  },
  about: {
    enabled: false,
    value: {
      description: '',
    },
  },
  location: {
    enabled: false,
    showMap: false,
    value: {
      lat: 0,
      lng: 0,
      state: '',
      city: '',
      zip: '',
      address: '',
    },
  },
  contact: {
    enabled: false,
    value: {
      description: '',
      phone: '',
      email: '',
      socials: {
        facebook: {
          enabled: false,
          value: '',
        },
        instagram: {
          enabled: false,
          value: '',
        },
        telegram: {
          enabled: false,
          value: '',
        },
        twitter: {
          enabled: false,
          value: '',
        },
      },
      availability: {},
    },
  },
  footerLogo: false,
};
