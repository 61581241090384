import React, { FC } from 'react';

import { Text } from '@chakra-ui/react';

import { useFormikContext } from 'formik';

import { Template } from 'types/style';
import { TemplateAboutUsProps } from './TemplateAboutUs.props';
import { Title, Section, Description } from 'styles/template.style';

const TemplateAboutUsView: FC<TemplateAboutUsProps> = (props) => {
  const { values } = useFormikContext<Template>();

  const about = values.about;

  if (about.enabled === false) {
    return null;
  }

  return (
    <Section
      id="about"
      color={values.colors.primaryColor ?? '#000'}
      alignItems="center"
      justifyContent="center"
      backgroundColor={values.colors.backgroundColor}>
      <Title>About Us</Title>
      <Description textAlign="center">
        {about?.value?.description || 'Description goes here'}
      </Description>
    </Section>
  );
};

export default TemplateAboutUsView;
