import React from 'react';

import { $session } from 'stores';

import { useQuery } from 'react-query';
import { fetchAllTemplates } from 'api/style';

import { getPreviewURL } from 'constants/ultistay.config';

import { StylesProps } from './Styles.props';

import StylesView from './Styles.view';

const StylesContainer = (props: StylesProps) => {
  const userId = $session.selectors.useUserId();
  const username = $session.selectors.useUsername();

  const queryKey = ['fetch-all-templates', userId];
  const queryFn = () => fetchAllTemplates(userId || '');
  const options = { enabled: !!userId };

  const { data, isLoading, isFetching } = useQuery(queryKey, queryFn, options);

  const template = data?.items?.[0];

  const handlePreviewClick = () => {
    if (!username) {
      return;
    }

    const previewURL = getPreviewURL({ pathname: username });

    window.open(previewURL, '_blank');
  };

  return (
    <StylesView
      template={template}
      isLoading={isLoading || isFetching}
      handlePreviewClick={handlePreviewClick}
    />
  );
};

export default StylesContainer;
