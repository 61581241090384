import { Box, Button, Flex, Text } from '@chakra-ui/react';
import { chakraStyled } from 'utils';

export const Container = chakraStyled(Flex, {
  w: '100%',
  direction: 'column',
  height: '100%',
});

export const CardBox = chakraStyled(Box, {
  maxWidth: '220px',
  maxHeight: '220px',
  borderRadius: 'lg',
  boxShadow: 'xl',
  mr: 'auto',
  width: '90%',
  height: '90%',
  backgroundColor: '#fff',
});

export const WebsiteName = chakraStyled(Text, {
  mb: 4,
  fontSize: 'lg',
  color: 'primary.500',
});

export const UseButton = chakraStyled(Button, {
  maxWidth: '90px',
  color: 'primary.500',
  mr: '5px',
  flex: 1,
  variant: 'outline',
});

export const PreviewButton = chakraStyled(Button, {
  backgroundColor: 'primary.500',
  flex: 1,
});
