import React, { FC, useEffect, useRef } from 'react';

import { useHistory } from 'react-router-dom';

import { $stripe, $user } from 'stores';

import { useQuery } from 'react-query';
import { getStripeAccount } from 'api/stripe';

import { ConnectedAccount } from 'types/stripe';
import { StripeHandlerProps } from './StripeHandler.props';

const checkIfDetaialsSubmitted = (c: ConnectedAccount) => {
  if (c?.details_submitted) {
    return true;
  } else if (c?.tos_acceptance) {
    if ('date' in c?.tos_acceptance && c?.tos_acceptance?.date !== null) {
      return true;
    }
  } else {
    return false;
  }

  return false;
};

const StripeHandlerContainer: FC<StripeHandlerProps> = ({ children }) => {
  const history = useHistory();
  const stripeId = $user.selectors.useStripeId();

  const refetchCountRef = useRef(0);

  const queryKey = [`fetch-stripe-account`, stripeId];

  /**
   * This function recursively queries a Stripe account and returns it once it meets certain
   * conditions, with a maximum of 4 retries.
   * @returns The function `queryFn` returns a Promise that resolves to a `ConnectedAccount` object.
   *
   * we are doing this because stripe takes a bit of time to reflect the changes made by the user
   */
  const queryFn = async (): Promise<ConnectedAccount> => {
    const account = await getStripeAccount(String(stripeId));

    if (refetchCountRef.current >= 4) {
      return Promise.resolve(account);
    }

    const _isAccountEnabled = Boolean(account?.charges_enabled);
    const _isDetailsSubmitted = checkIfDetaialsSubmitted(account);

    if (_isAccountEnabled && _isDetailsSubmitted) {
      return Promise.resolve(account);
    }

    if (
      _isAccountEnabled &&
      !_isDetailsSubmitted &&
      refetchCountRef.current == 3
    ) {
      return Promise.resolve(account);
    }

    // sleep for 3 seconds
    await new Promise((resolve) => setTimeout(resolve, 3000));

    refetchCountRef.current++;

    return queryFn();
  };

  useQuery(queryKey, queryFn, {
    enabled: !!stripeId,
    onSuccess: (account) => {
      if (Boolean(account)) {
        $stripe.actions.setAccountData(account);
      }
      $stripe.actions.unsetProgress();
    },
    onError: () => {
      refetchCountRef.current = 0;
      $stripe.actions.unsetProgress();
    },
  });

  useEffect(() => {
    const onStripeRedirect = async () => {
      history.replace({ search: '' });
    };

    const search = new URLSearchParams(history.location?.search);
    const fromStripe = search.get('fromStripe');

    if (fromStripe !== 'true') {
      return;
    }

    onStripeRedirect();
  }, []);

  return <>{children}</>;
};

export default StripeHandlerContainer;
