import React, { FC, useRef, useState } from 'react';

import {
  Box,
  Stack,
  Drawer,
  VStack,
  Collapse,
  IconButton,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  useDisclosure,
  useMediaQuery,
  useOutsideClick,
} from '@chakra-ui/react';

import {
  MdMenu,
  MdStyle,
  MdPerson,
  MdDashboard,
  MdShoppingCart,
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
  MdOutlineFeaturedPlayList,
} from 'react-icons/md';
import { BiPackage } from 'react-icons/bi';
import { IoMdLogOut } from 'react-icons/io';
import { BsCardChecklist } from 'react-icons/bs';

import logo from 'assets/logo_blue.png';
import logoWithName from 'assets/onboarding/logo.png';

import { routesSettings } from 'constants/routes';
import { useHistory, useLocation } from 'react-router-dom';

import { $session, $styling, $navigation } from 'stores';

import { SideBarProps } from './SideBar.props';

import {
  Nav,
  Logo,
  Name,
  Toggle,
  LinkItem,
  Container,
  ToggleContainer,
} from './SideBar.style';

const QUERY = '(max-width: 1000px)';

const ITEMS = [
  {
    name: 'Dashboard',
    path: routesSettings.MAIN_DASHBOARD.path,
    Icon: MdDashboard,
  },
  {
    name: 'Management',
    path: '/management',
    Icon: BsCardChecklist,
  },
  {
    name: 'Experiences',
    path: routesSettings.MAIN_PRODUCTS.path,
    Icon: BiPackage,
  },
  {
    name: 'Attributes',
    path: routesSettings.MAIN_ATTRIBUTES.path,
    Icon: MdOutlineFeaturedPlayList,
  },
  {
    name: 'Style',
    path: '/styles',
    Icon: MdStyle,
  },
  {
    name: 'Account',
    path: '/account',
    Icon: MdPerson,
  },
];

const CollapseItem: FC<{
  subItems: {
    name: string;
    path: string;
    Icon: any;
  }[];
  isFullWidth: boolean;
  children: {
    renderChildren: (onOpen: () => void) => JSX.Element;
  };
}> = ({ subItems, isFullWidth, children }) => {
  const history = useHistory();
  const location = useLocation();

  const boxRef = useRef<HTMLDivElement>(null);

  const { isOpen, onOpen, onClose } = useDisclosure();

  useOutsideClick({
    ref: boxRef,
    handler: onClose,
  });

  return (
    <Box w="100%" ref={boxRef}>
      {children.renderChildren(onOpen)}
      <Collapse in={isOpen} animateOpacity>
        {subItems.map((item) => {
          const isCurrent = item.path.includes(location.pathname);

          return (
            <LinkItem
              key={item.name}
              bg={isCurrent ? '#fff' : 'primary.500'}
              color={isCurrent ? 'primary.500' : '#fff'}
              onClick={() => {
                history.push(item.path as string);
              }}>
              <item.Icon size="20px" />
              {isFullWidth && <Name ml="10px">{item.name}</Name>}
            </LinkItem>
          );
        })}
      </Collapse>
    </Box>
  );
};

const SideBarView: FC<SideBarProps> = (props) => {
  const history = useHistory();
  const location = useLocation();

  const [isMobile] = useMediaQuery(QUERY);

  const isOpen = $navigation.selectors.useIsNavOpen();
  const setIsOpen = $navigation.actions.setIsOpen;

  const [isFullWidth, setIsFullWidth] = useState(isOpen);

  const handleToggleSidebar = () => {
    setIsOpen(!isOpen);

    setIsFullWidth(!isFullWidth);
  };

  const onOpen = () => setIsOpen(true);
  const onClose = () => setIsOpen(false);

  const onLogout = () => {
    onClose();

    $styling.actions.reset();
    $session.actions.clearSession();

    history.replace('/');

    history.go(0);
  };

  if (isMobile) {
    return (
      <>
        <IconButton
          mt="8px"
          w="20px"
          position="fixed"
          top={3}
          right={3}
          aria-label="ham-menu"
          icon={<MdMenu />}
          onClick={onOpen}
        />
        <Drawer size="xs" placement="right" onClose={onClose} isOpen={isOpen}>
          <DrawerOverlay />
          <DrawerContent maxW="250px">
            <DrawerBody>
              <Stack h="100%" justify="space-between">
                <Logo src={isFullWidth ? logoWithName : logo} />
                <VStack flex={1} mt="20px" gap="10px">
                  {ITEMS.map((item) => {
                    if (Array.isArray(item.path)) {
                      const isCurrent = item.path.some((i) =>
                        i.path.includes(location.pathname),
                      );

                      return (
                        <CollapseItem
                          key={item.name}
                          subItems={item.path}
                          isFullWidth={isFullWidth}>
                          {{
                            renderChildren: (onOpen) => (
                              <LinkItem
                                onClick={onOpen}
                                bg={isCurrent ? 'primary.500' : '#fff'}
                                color={isCurrent ? '#fff' : 'primary.500'}>
                                <item.Icon size="20px" />
                                <Name>{item.name}</Name>
                              </LinkItem>
                            ),
                          }}
                        </CollapseItem>
                      );
                    }

                    const isCurrent = item.path.includes(location.pathname);

                    return (
                      <LinkItem
                        key={item.name}
                        bg={isCurrent ? 'primary.500' : '#fff'}
                        color={isCurrent ? '#fff' : 'primary.500'}
                        onClick={() => {
                          history.push(item.path as string);
                          onClose();
                        }}>
                        <item.Icon size="20px" />
                        <Name>{item.name}</Name>
                      </LinkItem>
                    );
                  })}
                </VStack>

                <LinkItem onClick={onLogout}>
                  <IoMdLogOut />
                  <Name>Logout</Name>
                </LinkItem>
              </Stack>
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      </>
    );
  }

  return (
    <Container position="relative" animate={isFullWidth ? 'visible' : 'hidden'}>
      <Container animate={isFullWidth ? 'visible' : 'hidden'}>
        <Nav>
          <ToggleContainer>
            <Toggle
              icon={
                isFullWidth ? (
                  <MdKeyboardArrowLeft size="25px" />
                ) : (
                  <MdKeyboardArrowRight size="25px" />
                )
              }
              onClick={handleToggleSidebar}
            />
          </ToggleContainer>
          <Logo src={isFullWidth ? logoWithName : logo} />
          <VStack flex={1} mt="20px" gap="10px">
            {ITEMS.map((item) => {
              if (Array.isArray(item.path)) {
                const isCurrent = item.path.some((i) =>
                  i.path.includes(location.pathname),
                );

                return (
                  <CollapseItem
                    key={item.name}
                    subItems={item.path}
                    isFullWidth={isFullWidth}>
                    {{
                      renderChildren: (onOpen) => (
                        <LinkItem
                          onClick={onOpen}
                          bg={isCurrent ? '#fff' : 'primary.500'}
                          color={isCurrent ? 'primary.500' : '#fff'}>
                          <item.Icon size="20px" />
                          {isFullWidth && <Name>{item.name}</Name>}
                        </LinkItem>
                      ),
                    }}
                  </CollapseItem>
                );
              }

              const isCurrent = item.path.includes(location.pathname);

              return (
                <LinkItem
                  key={item.name}
                  bg={isCurrent ? '#fff' : 'primary.500'}
                  color={isCurrent ? 'primary.500' : '#fff'}
                  onClick={() => {
                    history.push(item.path as string);
                  }}>
                  <item.Icon size="20px" />
                  {isFullWidth && <Name>{item.name}</Name>}
                </LinkItem>
              );
            })}
          </VStack>
          <LinkItem onClick={onLogout}>
            <IoMdLogOut />
            <Name>Logout</Name>
          </LinkItem>
        </Nav>
      </Container>
    </Container>
  );
};

export default SideBarView;
