import { IconProps } from './type';

const KingSizeBed = (props: IconProps) => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.75391 7.83581C3.75391 7.09806 4.35197 6.5 5.08972 6.5H24.7312C25.469 6.5 26.067 7.09806 26.067 7.83581V12.3536C26.7665 12.3961 27.3205 12.9768 27.3205 13.6869V17.3657V18.3659V21.2911H25.6481V22.4626C25.6481 22.9696 25.2372 23.3805 24.7302 23.3805H23.4765C22.9696 23.3805 22.5586 22.9696 22.5586 22.4626V21.2911H7.25944V22.4626C7.25944 22.9696 6.84848 23.3805 6.34154 23.3805H5.08783C4.58088 23.3805 4.16992 22.9696 4.16992 22.4626V21.2911H2.5V18.3659V17.3657V13.6869C2.5 12.9766 3.0543 12.3959 3.75391 12.3535V7.83581ZM26.3205 17.3657H3.5V13.6869C3.5 13.5014 3.65035 13.3511 3.83581 13.3511H25.9847C26.1702 13.3511 26.3205 13.5014 26.3205 13.6869V17.3657ZM3.5 18.3659V20.2911H4.16992V20.291H7.25944V20.2911H22.5586V20.291H25.6481V20.2911H26.3205V18.3659H3.5ZM5.08972 7.5C4.90425 7.5 4.75391 7.65035 4.75391 7.83581V12.3507H5.42578V9.92572C5.42578 9.41877 5.83674 9.00781 6.34369 9.00781H13.866C14.3729 9.00781 14.7839 9.41877 14.7839 9.92572V12.3507H15.0371V9.92572C15.0371 9.41877 15.4481 9.00781 15.955 9.00781H23.4773C23.9842 9.00781 24.3952 9.41877 24.3952 9.92572V12.3507H25.067V7.83581C25.067 7.65035 24.9167 7.5 24.7312 7.5H5.08972ZM23.3952 12.3507V10.0078H16.0371V12.3507H23.3952ZM6.42578 12.3507H13.7839V10.0078H6.42578V12.3507ZM23.5586 21.2911V22.3805H24.6481V21.2911H23.5586ZM6.25944 21.2911H5.16992V22.3805H6.25944V21.2911Z"
        fill="#353535"
      />
    </svg>
  );
};

export default KingSizeBed;
