import React, { FC } from 'react';

import { Center, Divider, Flex, useUpdateEffect } from '@chakra-ui/react';

import { $styling } from 'stores';

import { Formik, useFormikContext } from 'formik';

import CustomSideBar from 'components/core/CustomSideBar';
import TemplateNavBar from 'components/modules/TemplateNavBar';
import TemplateFooter from 'components/modules/TemplateFooter';
import TemplateAboutUs from 'components/modules/TemplateAboutUs';
import TemplateLocation from 'components/modules/TemplateLocation';
import TemplateContactInfo from 'components/modules/TemplateContactInfo';
import TemplateHeroSection from 'components/modules/TemplateHeroSection';
import TemplateInfoSection from 'components/modules/TemplateInfoSection';
import TemplateExploreSection from 'components/modules/TemplateExploreSection';

import { Container } from './CreateTemplate.style';

import { Template } from 'types/style';
import { CreateTemplateViewProps } from './CreateTemplate.props';

const CreateTemplateView: FC<CreateTemplateViewProps> = ({ onSubmit }) => {
  const initialValues = $styling.selectors.useFormValues();
  const dark = initialValues.colors.dark;
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async (values) => {
        await onSubmit(values);
      }}>
      <Flex flexGrow={1}>
        <CustomSideBar />
        <Container flex={1}>
          <TemplateNavBar />
          <TemplateHeroSection />
          <TemplateExploreSection />
          <TemplateInfoSection />
          <TemplateAboutUs />
          <TemplateLocation />

          <TemplateFooter />
        </Container>
        <FormChangeListener />
      </Flex>
    </Formik>
  );
};

export default CreateTemplateView;

const FormChangeListener: FC = () => {
  const { values } = useFormikContext<Template>();

  useUpdateEffect(() => {
    $styling.actions.setValues(values, true);
  }, [values]);

  return null;
};
