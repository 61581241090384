export function generateKeyFrom(value: string) {
  let hash = 0;
  if (value.length == 0) return hash;

  for (let i = 0; i < value.length; i++) {
    let char = value.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash = hash & hash; // Convert to 32bit integer
  }

  return hash.toString();
}

export const scrollIntoViewById = (id: string) => {
  const targetSection = document.getElementById(id);
  if (targetSection) {
    targetSection.scrollIntoView({ behavior: 'smooth' });
  }
};

export function deepCopy(obj: any) {
  if (obj === null || typeof obj !== 'object') {
    // If the input is a primitive value or null, return it directly
    return obj;
  }

  // Initialize a new object or array to store the copied values
  const copy: any = Array.isArray(obj) ? [] : {};

  // Iterate over each key in the input object
  for (let key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      // Recursively copy nested objects and arrays
      copy[key] = deepCopy(obj[key]);
    }
  }

  return copy;
}
