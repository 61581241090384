import React, { FC, memo, useCallback, useState } from 'react';

import { MultiImagePickerProps } from './MultiImagePicker.props';
import { AssetsContainer } from './MultiImagePicker.style';
import { useField, useFormikContext } from 'formik';
import CustomAlertDialog from '../DeleteImageAlertDialog';
import { Label } from '../FormikRichText/FormikRichText.style';
import AssetsPicker from './AssetsPicker';
import { generateKeyFrom } from 'utils/common';
import { Box, Button, useDisclosure } from '@chakra-ui/react';

const MultiImagePickerView: FC<MultiImagePickerProps> = ({
  beforeDeleteCallback,
  onDelete,
  enableFeaturedImageSelection = true,
  featuredImage,
}) => {
  const [_, meta, helper] = useField<File[]>('photos');

  const { setFieldValue, values } = useFormikContext<any>();

  const field = values;

  const [fileIndex, setFileIndex] = useState<number>();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [featuredImageField, featuredImageMeta, featuredImageHelper] =
    useField<string>('featuredImage');

  const [isDeleteLoading, setIsLoading] = useState<boolean>(false);

  const deleteImageAlert = useCallback((index: number) => {
    setFileIndex(index);
    onOpen();
  }, []);

  const handleDelete = async () => {
    setIsLoading(true);

    const currentPhotos = field.photos || [];

    const image = currentPhotos[fileIndex!];

    //handle featured image

    const isNeworkImage = (image: string | File) => typeof image === 'string';

    if (!isNeworkImage(image)) {
      //handle featured image deletion
      if (image.name == featuredImageField.value) {
        featuredImageHelper.setValue('');
      }
    }

    if (isNeworkImage(image)) {
      if (beforeDeleteCallback) {
        const photos: string[] = currentPhotos
          .filter(
            (_image: any, index: number) =>
              isNeworkImage(_image) && index !== fileIndex!,
          )
          .map((_image: any) => _image as unknown as string);

        await beforeDeleteCallback(photos, featuredImage ?? '');
      }
    }

    const updatedImage = currentPhotos.filter(
      (_image: any, index: number) => index !== fileIndex!,
    );

    setFieldValue('photos', updatedImage);

    onDelete && onDelete(updatedImage);

    onClose();
    setIsLoading(false);
  };

  const key = generateKeyFrom(window.location.pathname);

  return (
    <div>
      <AssetsContainer key={key}>
        <AssetsPicker name="photos" />
        <PhotosPreview
          photos={field.photos}
          featuredImage={featuredImage}
          onDelete={deleteImageAlert}
          enableFeaturedImageSelection={enableFeaturedImageSelection}
        />
        <CustomAlertDialog
          onClickDelete={handleDelete}
          onClose={onClose}
          isOpen={isOpen}
          isLoading={isDeleteLoading}
        />
      </AssetsContainer>
      {meta.error && meta.touched && (
        <Label color="red" fontSize="sm" mt={2}>
          {meta.error}
        </Label>
      )}
      {!meta.error && featuredImageMeta.error && featuredImageMeta.touched && (
        <Label color="red" fontSize="sm" mt={2}>
          {featuredImageMeta.error}
        </Label>
      )}
    </div>
  );
};

type PhotosPreviewProp = {
  photos: (string | File)[];
  featuredImage: any;
  onDelete: (index: number) => void;
  enableFeaturedImageSelection: boolean;
};
const PhotosPreview: FC<PhotosPreviewProp> = memo(
  ({
    photos,
    featuredImage,
    onDelete,
    enableFeaturedImageSelection = false,
  }) => {
    const [featuredImageField, featuredImageMeta, featuredImageHelper] =
      useField<string>('featuredImage');
    const key = generateKeyFrom(window.location.pathname);

    const handleOnClickFeatured = (fileName: string) => {
      featuredImageHelper.setValue(fileName);
    };

    return (
      <>
        {photos?.map((file: any, index: number) => {
          const name = typeof file === 'string' ? file : file?.name ?? '';
          //this checks with the experience data
          const isFeaturedImage = name == featuredImage;
          //this checks with the temporary react state data
          const isFeaturedImageTemp = name == featuredImageField.value;
          const uniqueKey = `${key}_${index}`; // Append index to the container key
          return (
            <Box key={uniqueKey} position="relative">
              {typeof file === 'string' ? (
                <AssetsPicker.NetworkImage
                  key={index}
                  disableDelete={isFeaturedImage}
                  url={file}
                  onDelete={() => onDelete(index)}
                />
              ) : (
                <AssetsPicker.AssetImage
                  key={index}
                  disableDelete={isFeaturedImage}
                  file={file}
                  onDelete={() => onDelete(index)}
                />
              )}
              <>
                {enableFeaturedImageSelection && (
                  <Button
                    onClick={() =>
                      !isFeaturedImageTemp && handleOnClickFeatured(name)
                    }
                    opacity={isFeaturedImageTemp ? 1 : 0.8}
                    _hover={{ opacity: 1 }}
                    size="sm"
                    colorScheme={isFeaturedImageTemp ? 'whatsapp' : 'blue'}
                    top={2}
                    left={2}
                    position="absolute">
                    {isFeaturedImageTemp ? 'Featured' : 'Make Featured'}
                  </Button>
                )}
              </>
            </Box>
          );
        })}
      </>
    );
  },
);

export default MultiImagePickerView;
