import { IconProps } from './type';

const DayBed = (props: IconProps) => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.26172 7.83581C5.26172 7.09806 5.85978 6.5 6.59753 6.5H22.4779C23.2156 6.5 23.8137 7.09806 23.8137 7.83581V8.21364C23.9206 8.18606 24.0328 8.17139 24.1483 8.17139H25.402C26.1398 8.17139 26.7378 8.76945 26.7378 9.5072V12.4471C27.2282 12.6448 27.5743 13.1252 27.5743 13.6864V17.3652V18.3654V21.2906H25.902V22.4621C25.902 22.9691 25.4911 23.38 24.9841 23.38H23.7304C23.2235 23.38 22.8125 22.9691 22.8125 22.4621V21.2906H6.2614V22.4621C6.2614 22.9691 5.85044 23.38 5.34349 23.38H4.08978C3.58283 23.38 3.17188 22.9691 3.17188 22.4621V21.2906H1.5V18.3654V17.3652V13.6864C1.5 13.1254 1.84583 12.6452 2.33594 12.4473V9.50719C2.33594 8.76945 2.934 8.17139 3.67175 8.17139H4.92546C5.0416 8.17139 5.15429 8.18621 5.26172 8.21407V7.83581ZM5.26172 13.3507V13.3506H2.83581C2.65035 13.3506 2.5 13.5009 2.5 13.6864V17.3652H26.5743V13.6864C26.5743 13.5009 26.4239 13.3506 26.2385 13.3506H23.8137V13.3507H5.26172ZM22.8125 9.50719V12.3506H16.2917V9.92523C16.2917 9.41828 15.8807 9.00732 15.3738 9.00732H7.8515C7.34455 9.00732 6.93359 9.41828 6.93359 9.92523V12.3506H6.26172V7.83581C6.26172 7.65035 6.41207 7.5 6.59753 7.5H22.4779C22.6634 7.5 22.8137 7.65034 22.8137 7.83581V9.45002C22.8129 9.46898 22.8125 9.48804 22.8125 9.50719ZM26.5743 18.3654H2.5V20.2906H3.17188V20.2905H6.2614V20.2906H22.8125V20.2905H25.902V20.2906H26.5743V18.3654ZM23.8137 12.3504H25.7378V9.5072C25.7378 9.32173 25.5875 9.17139 25.402 9.17139H24.1483C23.9725 9.17139 23.8282 9.30651 23.8137 9.47859V12.3504ZM3.33594 9.50719C3.33594 9.32173 3.48628 9.17139 3.67175 9.17139H4.92546C5.11092 9.17139 5.26127 9.32173 5.26127 9.5072V12.3504H3.33594V9.50719ZM23.8125 22.38V21.2906H24.902V22.38H23.8125ZM4.17188 21.2906H5.2614V22.38H4.17188V21.2906ZM7.93359 12.3506V10.0073H15.2917V12.3506H7.93359Z"
        fill="#353535"
      />
    </svg>
  );
};

export default DayBed;
