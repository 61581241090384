import React, { FC } from 'react';

import { getPhotoUrl } from 'utils';

import { AssetsCarouselProps } from './AssetsCarousel.props';

import { Asset, Title, Container } from './AssetsCarousel.style';
import { Box, Button } from '@chakra-ui/react';

const AssetsCarouselView: FC<AssetsCarouselProps> = ({
  photos,
  featuredPhoto,
}) => {
  if (photos?.length === 0) {
    return null;
  }

  return (
    <>
      <Title>Photos</Title>
      <Container>
        {photos.map((photo, index) => {
          const isFeaturedImage = photo == featuredPhoto;
          return (
            <Box key={photo + index} position="relative">
              <Asset src={getPhotoUrl(photo)} />
              {isFeaturedImage && (
                <Button
                  top={2}
                  left={2}
                  colorScheme="whatsapp"
                  size="sm"
                  position="absolute">
                  Featured
                </Button>
              )}
            </Box>
          );
        })}
      </Container>
    </>
  );
};

export default AssetsCarouselView;
