// NavbarContext.js
import React, {
  FC,
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useHistory } from 'react-router';

interface HideContextType {
  isVisible: boolean;
  hide: () => void;
}

const NavbarContext = createContext<HideContextType>({
  isVisible: true,
  hide: () => {},
});

export const useNavbar = () => useContext(NavbarContext);

export const NavbarProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [navbarVisible, setNavbarVisible] = useState(true);
  const history = useHistory();

  // Function to hide the navbar
  const hide = () => {
    setNavbarVisible(false);
  };

  // Function to reset the navbar state
  const resetNavbar = () => {
    setNavbarVisible(true); // Reset navbar visibility to true
  };

  // Listen for history changes and reset navbar state on navigation
  useEffect(() => {
    const unlisten = history.listen(() => {
      resetNavbar();
    });
    return () => {
      unlisten();
    };
  }, [history]);

  return (
    <NavbarContext.Provider value={{ isVisible: navbarVisible, hide }}>
      {children}
    </NavbarContext.Provider>
  );
};

export default NavbarProvider;
